import React, { useCallback, useState, useEffect } from 'react';
import useBoolean from '../../hooks/useBoolean/useBoolean';

const MeetingSettingsContext = React.createContext({
    
    // Behaviour
    isActiveSpeakerEnabled: true,
    isPinningEnabled: true,
    isSelfMinimized: false,
    isSelfFocused: false,
    isRestrictedModeEnabled: true,

    // Functionality
    setLayout: () => null,
    setPinnable: () => null,
    setIsSelfFocused: () => null,
    setIsSelfMinimized: () => null,
    setIsSelfFocusedTrue: () => null,
    setIsSelfFocusedFalse: () => null,

    // Aethetics
    layout: 'teams',
});

export const views = ['grid', 'teams'];

export const MeetingSettingsProvider = ({ settings, children }) => {
    const {
        layout: defaultLayout = 'grid',
        isActiveSpeakerEnabled  = false,
        isRestrictedModeEnabled = true,
        isPinningEnabled: defaultIsPinningEnabled = true,
    } = settings || {};
    
    const [currentLayout, setCurrentLayout] = useState(defaultLayout);
    const [isPinnable, setIsPinnable] = useState(null);
    const [isSelfMinimized, setIsSelfMinimized] = useBoolean(false);
    const [isSelfFocused, setIsSelfFocused, setIsSelfFocusedTrue, setIsSelfFocusedFalse] = useBoolean(false);

    const setLayout = useCallback(nextLayout => {
        const isAcceptedView = nextView => views.some(view => view === nextView);

        const isValidNextLayout = !!nextLayout && isAcceptedView(nextLayout);
        if (! isValidNextLayout) {
            return;
        }

        setCurrentLayout(nextLayout);
    }, [setCurrentLayout]
    );

    const setPinnable = useCallback(isPinnable => setIsPinnable(!!isPinnable), [setIsPinnable]);

    const isPinningEnabled = isPinnable ?? defaultIsPinningEnabled;
    const layout = currentLayout ?? defaultLayout;

    const meetingSettings = {
        layout,
        setLayout,
        setPinnable,
        defaultLayout,
        isSelfFocused,
        isSelfMinimized,
        isPinningEnabled,
        setIsSelfFocused,
        setIsSelfMinimized,
        setIsSelfFocusedTrue,
        setIsSelfFocusedFalse,
        isActiveSpeakerEnabled,
        isRestrictedModeEnabled,
    };

    return (
        <MeetingSettingsContext.Provider value={meetingSettings}>
            { children }
        </MeetingSettingsContext.Provider>
    );
}

export default MeetingSettingsContext;
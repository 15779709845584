import React from "react";
import PropTypes from "prop-types";
import HealthIndicator from "./HealthIndicator";

import { useMeasureUnits } from "@aetonix/hooks";

export default function BloodSugarMetrics(props, context) {
	var model = props.model;
	var myhealth = model.myhealth;
	var bloodsugar = myhealth.bloodsugar;

	var steps = bloodsugar.cumulative;
	var reverse_steps = bloodsugar.reverse; // needed for chart

	var localize = context.localize;

	const { glucometerUnits: glucoseUnits } = useMeasureUnits();
	var oldUnits = glucoseUnits === "mg" ? "meanblood" : "glucose";
	var units = localize("bloodsugar." + glucoseUnits);
	var today = null;
	var section = model.page;
	var pagetitle = localize("bloodsugar.status");

	var textformat = "{" + glucoseUnits + "}";

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = adjust_time(reverse_steps, null, glucoseUnits, oldUnits).slice(-10); // for chart
		today = (steps[0].history[glucoseUnits] || steps[0].history[oldUnits]);
	}

	return (
		<HealthIndicator model={model} section={section} textformat={textformat} pagetitle={pagetitle} units={units} latestReading={today} reverseEntries={graphdata} activeModel={bloodsugar} xaxis={"x"} yaxis={"y"} managed />
	);
}

function adjust_time(data, forBoxGraphic, glucoseUnits, oldUnits) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, forBoxGraphic);
		var value = (typeof item.history[glucoseUnits] === "string")
			? parseFloat(item.history[glucoseUnits])
			: item.history[glucoseUnits];
		if (!value) {
			value = (typeof item.history[oldUnits] === "string")
				? parseFloat(item.history[oldUnits])
				: item.history[oldUnits];
		}
		return {
			x: time,
			y: value
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return (parsed.month + "-" + parsed.day + "  " + parsed.time);
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}

BloodSugarMetrics.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

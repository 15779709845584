import PropTypes from "prop-types";
import React from "react";

import {
	View,
	Dimensions,
	TouchableOpacity
} from "react-native";

import Modal from "../Layout/Modal";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledIconButton from "ae-base/Base/StyledIconButton";

import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0: {
		outer: {
			backgroundColor: "white",
			borderRadius: 10,
			padding: 30,
			justifyContent: "center",
			alignItems: "center"
		},
		buttons: {
			flexDirection: "row",
			justifyContent: "center",
			marginTop: Style.layout.marginSmall
		},
		item: {
			width: 100,
			height: 100,
			alignSelf: "stretch",
			borderRadius: 20,
			margin: 10
		},
		button: {
			flex: 1,
			overflow: "hidden",
			borderRadius: 20,
			backgroundColor: Style.colors.secondary
		},
		content: {
			flex: 1,
			alignItems: "center",
			justifyContent: "center",
			overflow: "hidden",
			alignSelf: "stretch",
			borderRadius: 20
		},
		closeContainer: {
			flexDirection: "row",
			justifyContent: "space-between",
			alignSelf: "stretch"
		}
	},
	370: {
		item: {
			width: 128,
			height: 128,
			alignSelf: "stretch",
			borderRadius: 20,
			margin: 10
		}
	},
	700: {
		item: {
			width: 256,
			height: 256,
			alignSelf: "stretch",
			borderRadius: 20,
			margin: 10
		}
	}
});

export default function SimplifiedReading(props, context){
	var model = props.model;
	var localize = context.localize;

	var outerStyles = [styles.outer, {
		width: (Dimensions.get("window").width / 10) * 8
	}];

	var deviceType = model.deviceType;

	return (
		<Modal open={model.open}>
			<View style={outerStyles}>
				<View style={styles.closeContainer}>
					<StyledIcon style={{opacity: 0}} name="delete" large />
					<StyledText large center>{localize("simplifiedreading.title")}</StyledText>
					<StyledIconButton icon="delete" smallHeading onPress={context.dispatch("reading:trigger:close")} />
				</View>
				<View style={styles.buttons}>
					<Button onPress={closeAndShowManual(context.dispatch)} icon="compose">{localize("simplifiedreading.manual")}</Button>
					<Button onPress={closeAndShowDeviceRead(context.dispatch, deviceType)} icon="watch">{localize("simplifiedreading.device")}</Button>
				</View>
			</View>
		</Modal>
	);
}

function Button(props){
	var onPress = props.onPress;
	var icon = props.icon;
	var textSize = "emphasized";
	var iconSize = "hugeIcon";

	var width = Dimensions.get("window").width;

	if(width < 700){
		textSize = "standard";
		iconSize = "hugeHeading";
	}

	return (
		<View style={styles.item}>
			<TouchableOpacity accessibilityComponentType="button" style={styles.button} onPress={onPress}>
				<View style={styles.content}>
					<StyledIcon name={icon} size={iconSize} contrast />
					<StyledText size={textSize} contrast centered>{props.children}</StyledText>
				</View>
			</TouchableOpacity>
		</View>
	);
}

function closeAndShowManual(dispatch){
	return function(){
		dispatch("reading:trigger:close")();
		dispatch("newreading:trigger:show")();
	};
}

function closeAndShowDeviceRead(dispatch, deviceType){
	return function(){
		dispatch("reading:trigger:close")();
		dispatch("myhealthdevice:trigger:showinfo", {
			deviceType: deviceType
		})();
	};
}

SimplifiedReading.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};
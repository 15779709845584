/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";
import PropTypes from "prop-types";
import Submission from "./Submission";
import GroupFormHeader from "./GroupFormHeader";
import ListAdd from "ae-base/List/ListAdd";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import MainContent from "../../Layout/MainContent";
import ActiveGroupFormSubmission from "./ActiveGroupFormSubmission";

import Style from "ae-style";

var styles = StyleSheet.create({
	more: {
		marginVertical: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center"
	}
});

export default function GroupFormSubmissions(props, context){

	var dispatch = context.dispatch;

	var model = props.model;
	var toast = model.toast;
	var groupformsubmissions = model.groupformsubmissions.submissions || [];
	var groupformlist = model.groupformlist;
	var currentform = groupformlist[model.groupformsubmissions.form];
	var localizeFrom = context.localizeFrom;
	var localize = context.localize;

	var localization = currentform.localization;
	var loadmore = localize("groupforms.loadmore");
	var getMore = dispatch("groupformsubmissions:trigger:loadMore");

	var title = localizeFrom(localization, ".") || currentform.name || currentform._id;

	var shouldShowMore = groupformsubmissions.length >= 32;

	var renderedSubmissions = groupformsubmissions.map(function(submission){
		var id = submission._id;
		return (
			<Submission schema={model.groupformsubmissions.schema} submission={submission} key={id} type={"group"} targetType={currentform.targetType} />
		);
	});

	var onAddSubmission = dispatch("activegroupformsubmission:trigger:creating", {
		id: model.groupformsubmissions.form,
		schema: currentform.schema
	});

	var renderMore = shouldShowMore ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="dropdown" onPress={getMore} />
		</View>
	) : null;

	var addNew = localize("groupforms.addnew");
	var formType = currentform.formType;

	if(formType === "single") {
		if(groupformsubmissions.length === 1) {
			return <ActiveGroupFormSubmission model={model} submission={groupformsubmissions[0]} singleForm />;
		}
	}

	var renderAdd = (formType === "multi" || groupformsubmissions.length < 1) ? (
		<ListAdd title={addNew} onPress={onAddSubmission} />
	) : null;

	return (
		<PageContainer toast={toast}>
			<MainScroll>
				<GroupFormHeader title={title} />
				<MainContent>
					{renderAdd}
					{renderedSubmissions}
					{renderMore}
				</MainContent>
			</MainScroll>
		</PageContainer>
	);
}

GroupFormSubmissions.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};

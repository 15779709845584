import React from "react";
import PropTypes from "prop-types";
import HealthIndicator from "./HealthIndicator";

export default function BloodPressureMetrics(props, context) {
	var model = props.model;
	var myhealth = model.myhealth;
	var bloodpressure = myhealth.bloodpressure;

	var steps = bloodpressure.cumulative;
	var reverse_steps = bloodpressure.reverse; // needed for chart

	var localize = context.localize;
	var pagetitle = localize("bloodpressure.status");
	var units = localize("bloodpressure.rightLabel");
	var today = null;
	var today2 = null;
	var section = model.page;
	// Note: There is actually a invisble space at the end for formatting purposes
	var textformat = "{systolic}/{diastolic}\n{bpPulserate}​​";

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = adjust_time(reverse_steps).slice(-10); // for chart
		today = steps[0].history.systolic + "/" + steps[0].history.diastolic;
		today2 = steps[0].history.bpPulserate || "--";

		var pulseratedata = adjust_time_pulserate(reverse_steps.filter((item)=>item.history.bpPulserate)).slice(-10);
	}

	return (
		<HealthIndicator
			model={model}
			section={section}
			textformat={textformat}
			pagetitle={pagetitle}
			units={units}
			latestReading={today}
			latestSecondaryReading={today2}
			reverseEntries={graphdata}
			activeModel={bloodpressure}
			xaxis={"x"}
			yaxis={"y"}
			bar
			managed
			secondaryGraph={pulseratedata}
			secondaryGraphXLabel={localize("graphlabels.pulserate.xaxis")}
			secondaryGraphYLabel={localize("graphlabels.pulserate.yaxis")}
			secondaryGraphTitle={localize("graphlabels.pulserate.title")}
			secondaryBar={false}
		/>
	);
}

function adjust_time_pulserate(data) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, false);
		var pulseHistory = item.history.bpPulserate;
		var pulserate = 0;

		if (pulseHistory) {
			if (typeof pulseHistory === "string") pulserate = parseFloat(pulseHistory);
			else pulserate = pulseHistory;
		}
		return {
			x: time,
			y: pulserate
		};
	});
	return adjusted;
}

function adjust_time(data) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, false);
		var systolic =
			typeof item.history.systolic === "string" ? parseFloat(item.history.systolic) : item.history.systolic;
		var diastolic =
			typeof item.history.diastolic === "string" ? parseFloat(item.history.diastolic) : item.history.diastolic;
		return {
			x: time,
			y: systolic,
			y0: diastolic
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return parsed.month + "-" + parsed.day + "  " + parsed.time;
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = data < 10 ? "0" + data : data;
	return item;
}

BloodPressureMetrics.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

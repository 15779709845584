import PropTypes from "prop-types";
import React from "react";
import SimpleState from "../SimpleState";

export default function Busy(props, context) {
	var localize = context.localize;
	var who = props.who;

	var message = localize("atouchawaycalling.isbusy", who);

	return (
		<SimpleState who={who} message={message} />
	);
}

Busy.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import ManagedSection from "../ManagedSection";
import ListAdd from "ae-base/List/ListAdd";
import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import StyledButton from "ae-base/Base/StyledButton";

import PendingReminder from "./PendingReminder";
import StandardReminder from "./StandardReminder";
import HistoryReminder from "./HistoryReminder";

ManagedReminders.propTypes = {
	model: PropTypes.object
};

var styles = StyleSheet.create({
	more: {
		marginVertical: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center"
	}
});

export default function ManagedReminders(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;

	var model = props.model;
	var account = model.manage.account;
	var pendingReminderList = model.manage.pendingReminders || [];
	var reminderList = model.manage.reminders.list || [];
	var reminderHistoryList = model.manage.managedreminderhistory || [];
	var toast = model.toast;

	var reminders = localize("managedreminders.reminders");
	var setupanewreminder = localize("managedreminders.setupanewreminder");
	var loadmore = localize("managedreminders.loadmore");
	var pendingText = localize("managedreminders.pending");
	var manageText = localize("managedreminders.manage");
	var historyText = localize("managedreminders.history");

	var renderedReminders = reminderList.map(function(reminder) {
		return (
			<StandardReminder key={reminder._id} reminder={reminder} />
		);
	});

	var renderPendingReminders = pendingReminderList.map(function (history) {
		return (
			<PendingReminder key={history._id} history={history} />
		);
	});

	var renderedRemindersHistory = reminderHistoryList.map(function (history) {
		if(!(history.response || history.response === 0)) return null;
		return (
			<HistoryReminder key={history._id} history={history} />
		);
	});
	var renderPendingRemindersTitle = pendingReminderList.length === 0 ? null : <StyledText bold smallHeading>{pendingText}</StyledText>;
	var renderedRemindersTitle = reminderList.length === 0 ? null : <StyledText bold smallHeading>{manageText}</StyledText>;
	var renderedRemindersHistoryTitle = reminderHistoryList.length === 0 ? null : <StyledText bold smallHeading>{historyText}</StyledText>;

	var shouldShowMore = reminderHistoryList.length >= 16;

	var addReminder = dispatch("managedremindermaker:trigger:add:start");
	var loadMoreHistory = dispatch("managedreminderhistory:trigger:more");

	var renderMore = shouldShowMore ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="dropdown" onPress={loadMoreHistory} />
		</View>
	) : null;

	return (
		<ManagedSection online={model.online} title={reminders} contact={account} toast={toast}>
			{renderPendingRemindersTitle}
			{renderPendingReminders}
			{renderedRemindersTitle}
			{renderedReminders}
			<ListAdd title={setupanewreminder} onPress={addReminder} />
			{renderedRemindersHistoryTitle}
			{renderedRemindersHistory}
			{renderMore}
		</ManagedSection>
	);
}

ManagedReminders.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

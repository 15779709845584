import PropTypes from "prop-types";
import React from "react";

import {StyleSheet} from "react-native";
import Style from "ae-style";
import BlockView from "ae-base/Base/BlockView";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

import Breakpoints from "react-native-dimensions-breakpoints";

var styles = StyleSheet.create({
	container: {
		marginTop: Style.layout.marginContentSmall,
		marginBottom: Style.layout.marginContentSmall
	},
	text: {
		marginBottom: Style.layout.marginContentSmall
	},
	button: {
		marginVertical: Style.layout.marginContentSmall
	}
});

export default function BluetoothSettings(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;

	var sectionTitle = localize("bluetoothsettings.title");
	var title = localize("devices.managedbutton");
	var action = dispatch("owndevices:trigger:view");

	var textSize = "emphasized";
	if(Breakpoints.smallerThan("small"))
		textSize = "small";
	else if(Breakpoints.smallerThan("mobile"))
		textSize = "standard";

	return (
		<BlockView style={styles.container}>
			<StyledText bold size={textSize} style={styles.text}>{sectionTitle}</StyledText>
			<StyledButton contrast id={context.id_prefix + "addDevice"} title={title} onPress={action} style={styles.button} />
		</BlockView>
	);
}

BluetoothSettings.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	id_prefix: PropTypes.string
};
import PropTypes from "prop-types";
import React from "react";
import {
	Platform,
	StyleSheet
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";

var styles = StyleSheet.create({
	buttonMobile: {
		padding: 0
	}
});

export default function PowerButtonNew(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;
	var settings = props.settings || {};

	var poweroff = localize("atouchawayheader.poweroff");
	var hidescreen = localize("atouchawayheader.hidescreen");
	var handlePowerButton = null;
	var powerText = "";
	if (!settings.power_button || settings.power_button === "standby") {
		powerText = poweroff;
		handlePowerButton = dispatch("app:trigger:power");
	}
	if (settings.power_button === "hide") {
		powerText = hidescreen;
		handlePowerButton = dispatch("screenhider:trigger:hide");
	}

	const style = [];
	if(Platform.OS === "android" || Platform.OS === "ios"){
		style.push(styles.buttonMobile);
	}
	return (<StyledButton contrast onPress={handlePowerButton} stretch largeHeading icon="power-off" centered style={style} />
	);
}

PowerButtonNew.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Component
} from "react";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";

export default class AppearOfflineBanner extends Component {
	render(){
		var context = this.context;
		var color;
		var content;

		var localize = context.localize;

		color = Style.colors.white;
		content = localize("banner.appearoffline");
		var text_style = {
			color: Style.colors.red
		};

		return (
			<Notification
				color={color}
				icon={"user-times"}
				iconColor={"red"}>
				<StyledText style={text_style} standard>
					{content}
				</StyledText>
			</Notification>
		);
	}
}

AppearOfflineBanner.contextTypes = {
	localize: PropTypes.func
};

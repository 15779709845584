import React from "react";
import PropTypes from "prop-types";

import MyHealthSection from "../MyHealthSection";
import DeviceItem from "../../../Devices/DeviceItem";

import ListAdd from "ae-base/List/ListAdd";

export default function Devices(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;

	var model = props.model;
	var devices = model.owndevices;
	var devicesList = devices.list || [];
	var toast = model.toast;

	var devicesTitle = localize("devices.title");
	var addDevice = localize("devices.add") || "Add a new device";

	var renderDevicesList = devicesList.map(function(device) {
		var onClick = dispatch("owndevices:trigger:update:start", {
			device: device
		});
		return (
			<DeviceItem key={device._id} device={device} onClick={onClick} />
		);
	});

	var add = dispatch("owndevices:trigger:add:start");
	return (
		<MyHealthSection online={model.online} title={devicesTitle}M toast={toast}>
			{renderDevicesList}
			<ListAdd onPress={add} title={addDevice} />
		</MyHealthSection>
	);
}

Devices.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Banner from "./Banner";

import Style from "ae-style";

export default function ConnectedBanner(props, context) {
	var color;
	var message_line1;
	var clear;
	var from_id;
	var banner_type;

	var localize = context.localize;
	var dispatch = context.dispatch;

	var banner = props.banner;
	var time = banner.updated_at;
	var date = banner.formatted_date;
	var from = banner.from;

	from_id = from._id;
	banner_type = banner.banner_type;

	color = Style.colors.white;
	message_line1 = localize("banner.braceletreconnect", {
		lname: from.lname,
		fname: from.fname
	});

	clear = dispatch("banner:trigger:clear", {
		from: from_id,
		type: banner_type
	});

	return (
		<Banner
			time={time}
			date={date}
			message_line1={message_line1}
			color={color}
			onPress={clear}
			border
		/>
	);
}

ConnectedBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import CardPage from "../../Layout/CardPage";
import ConfirmationModal from "ae-base/Base/ConfirmationModal.js";
import EmailInviteSchema from "../../../schemas/EmailInvite";
import Form from "ae-forms";
import SearchSchema from "../../../schemas/Search";
import StyledText from "ae-base/Base/StyledText";
import ToRequest from "../../Contacts/ToRequest";

import Style from "ae-style";

var styles = StyleSheet.create({
	searchText: {
		marginVertical: Style.layout.marginLarge
	},
	searchBox: {
		marginBottom: Style.layout.marginLarge
	}
});

export default function Request(props, context) {
	var dispatch = context.dispatch;
	var ownID = context.config.id;
	var model = props.model;

	var screen = model.size;

	var localize = context.localize;
	var requests = model.requests;
	var searchcontent = localize("request.searchcontent");
	var noresult = localize("request.noresult");
	var noemailresult = localize("request.noemailresult");
	var sendinvite = localize("request.sendinvite");
	var goback = localize("request.goback");
	var gobackcontact = localize("request.gobackcontact");
	var addcontact = localize("request.addacontact");

	var doSearch = dispatch("search:trigger:search");

	var search = model.search;
	var searchResults = search.search || [];

	var contacts = searchResults.filter(function(obj){
		return obj.qualifier === "existing";
	});
	var contactList = contacts.map(function(obj){
		return obj._id;
	});

	var inProgress = searchResults.filter(function(obj){
		return obj.qualifier === "requested";
	});
	var inProgressList = inProgress.map(function(obj){
		return obj._id;
	});

	var renderedResults = searchResults.filter(function(result){
		return result._id !== ownID;
	}).map(function(result){
		var id = result._id;
		var message = localize("request.sendrequest");
		var icon = "plus";

		var action = dispatch("requests:trigger:request", {
			who: id
		});

		if(result && result.qualifier === "blocked"){
			action = dispatch("blocking:trigger:unblock", {
				who: id
			});
			message = localize("request.unblock");
			icon = "unlock";
		}
		if(contactList && contactList.indexOf(id) !== -1){
			action = null;
			message = localize("request.alreadycontact");
			icon = "user";
		}
		if(inProgress && inProgressList.indexOf(id) !== -1){
			action = null;
			message = localize("request.inprogress");
			icon = "sync";
		}
		return (
			<ToRequest key={id}
				who={result}
				action={action}
				icon={icon}
				message={message}
				screen={screen} />
		);
	});

	var searchForm = (
		<View>
			<StyledText style={styles.searchText}>{searchcontent}</StyledText>
			<View style={styles.searchBox}>
				<Form schema={SearchSchema} defaultData={search} autoSubmit onSubmit={doSearch} />
			</View>
		</View>
	);

	var noResults = search.query && !searchResults.length;

	var notFound = (noResults &&  !search.isEmail) ? (
		<StyledText>{noresult}</StyledText>
	) : null;

	if(noResults && search.isEmail){
		var sendEmailInvite = dispatch("accountinvites:trigger:send");
		var cancelInvite = dispatch("search:trigger:clear");
		var defaultInvite = {
			query: search.query
		};
		searchForm = (
			<View>
				<StyledText style={styles.searchText}>{noemailresult}</StyledText>
				<Form
					schema={EmailInviteSchema}
					defaultData={defaultInvite}
					submitText={sendinvite}
					cancelText={goback}
					onSubmit={sendEmailInvite}
					onCancel={cancelInvite} />
			</View>
		);
	}

	var unblockTitle = localize("request.unblocktitle");
	var unblockConfirm = localize("request.unblockconfirm");
	var unblockCancel = localize("request.unblockcancel");
	var unblockOnConfirm = dispatch("blocking:trigger:unblock:confirm");
	var unblockOnCancel = dispatch("requests:trigger:unblock:reset");
	var renderConfirmation = (
		<ConfirmationModal
			open={requests.unblocking}
			title={unblockTitle}
			titleIcon={"alert"}
			confirmText={unblockConfirm}
			cancelText={unblockCancel}
			onConfirm={unblockOnConfirm}
			onCancel={unblockOnCancel}
		/>
	);

	return (
		<CardPage backTitle={gobackcontact} title={addcontact} icon="contacts" external={renderConfirmation} screen={screen} online={model.online}>
			{searchForm}
			{notFound}
			{renderedResults}
		</CardPage>
	);
}

Request.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object,
};

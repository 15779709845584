import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function UrgencyAccepted(props, context) {
	var notification = props.notification;
	var localize = context.localize;
	var urgencycallaccepted = localize("banner.urgency_accepted", notification.content.to);
	var screen = props.screen;

	return (
		<TimelineItem content={urgencycallaccepted} iconName="info"  notification={notification} screen={screen} />
	);
}

UrgencyAccepted.contextTypes = {
	localize: PropTypes.func,
};

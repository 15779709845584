import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function BatteryLow(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var batterylow = localize("banner.batterylow", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={batterylow} iconName="info"  notification={notification} screen={screen} />
	);

}

BatteryLow.contextTypes = {
	localize: PropTypes.func,
};

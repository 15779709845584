import PropTypes from "prop-types";
import React from "react";
import {
	View,
} from "react-native";
import Breakpoints from "../../Breakpoints";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		hideContainer: {
			marginVertical: Style.layout.marginSmall,
			alignItems: "flex-end"
		}
	},
	512: {
		hideContainer: {
			marginVertical: Style.layout.marginLarge,
		}
	}
});

export default function ContactHide(props, context) {
	var localize = context.localize;
	var contrast = props.contrast;
	var hideTextKey = props.hiding ? "homepage.show_contacts_" : "homepage.hide_contacts_";
	hideTextKey += Breakpoints.smallerThan("mobile") ? "small" : "large";

	var hideText = localize(hideTextKey);

	return (
		<View style={styles.hideContainer}>
			<BlockView>
				<StyledButton title={hideText} contrast={contrast} onPress={props.onPress} />
			</BlockView>
		</View>
	);
}

ContactHide.contextTypes = {
	localize: PropTypes.func
};

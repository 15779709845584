import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";

import Form from "ae-forms";

import UtilitiesSchema from "../../../../../schemas/aTouchAwayUtilities";

import SettingSection from "../SettingSection";

export default function Utilities(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;
	var display = props.display;
	var model = props.model;
	var toggle = props.onToggle;

	var atouchaway = model.manage.atouchaway || {};
	var account = model.manage.account || {};

	var title = localize("atasettings.utilities") || "Utilities";

	if(!account.type.includes("user:mobile")) return null;

	var renderBody = display ? (
		<Form
			schema={UtilitiesSchema}
			defaultData={atouchaway}
			onSubmit={dispatch("managedatouchaway:trigger:update")}
		/>
	) : null;

	return (
		<View>
			<SettingSection section="utilities" title={title} display={display} onToggle={toggle} />
			{renderBody}
		</View>
	);
}

Utilities.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
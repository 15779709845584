import PropTypes from "prop-types";
import React, {
	Component
} from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";
import VideoView from "../Calling/VideoView";
import NavBanner from "ae-base/Base/NavBanner";

import Breakpoints from "../../Breakpoints";

var styles = StyleSheet.create({
	footer: {
		flexDirection: "row",
		backgroundColor: Style.colors.dark
	},
	nameBar: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: Style.colors.black,
		justifyContent: "space-between"
	},
	fullLength: {
		flex: 1,
		flexDirection: "column"
	},
	streams: {
		flexDirection: "row",
		width: Style.imageSize.verysmall,
		justifyContent: "flex-end"
	},
	video: {
		flex: 1
	},
	videoHidden: {
		position: "absolute",
		width: 0,
		height: 0
	}
});

export default class ATouchAwayConnectedHeader extends Component {

	render(){
		var context = this.context;
		var props = this.props;

		var localize = context.localize;

		var fullscreen = localize("connectedfooter.fullscreen");
		var minimize = localize("connectedfooter.minimize");
		var connectedto = localize("connectedfooter.connectedto");
		var endcall = localize("connectedfooter.endcall");
		var dispatch = context.dispatch;
		var calling = props.calling;
		var who = calling.who;
		var callgroup = calling.callgroup;

		if (props.page === "calling") return null;

		if(!who) return null;

		if(calling.state !== "connected") return null;

		var name = calling.who.lname + ", " + calling.who.fname;
		if(callgroup) name = callgroup.name;
		var hangUp = dispatch("calling:trigger:hangup");
		var openCalling = dispatch("atouchawaymobile:trigger:page", {
			page: "calling"
		});
		var closeCalling = dispatch("atouchawaymobile:trigger:page", {
			page: "contacts"
		});

		var isConferenceCallConnected = calling.conference_state === "connected";
		var conferenceURL = calling.streams.conference;
		var remoteURL = calling.streams.remote;
		var voiceOnly = calling.streams.voiceOnly;
		var streams = [];
		if (!props.incall) {
			streams.push(
				<Video id={"openCalling"} onPress={openCalling} stream={remoteURL} />
			);

			if(isConferenceCallConnected) {
				streams.push(
					<Video id={"openCallingConference"} onPress={openCalling} stream={conferenceURL} />
				);
			}
		}

		var callContent = Breakpoints.smallerThan("mobile") ? (
			<StyledButton id={"hangUp"} title={endcall} icon="aeto-hang_up-2" onPress={hangUp} alert fullwidth />
		) : (
			<View style={styles.nameBar}>
				<TouchableOpacity style={styles.nameBar} onPress={openCalling}>
					<StyledIcon contrast name="aeto-phone" large />
					<StyledText contrast >  {connectedto} {name} </StyledText>
				</TouchableOpacity>
				<StyledButton id={"hangUp"} title={endcall} icon="aeto-hang_up-2" onPress={hangUp} alert />
			</View>
		);

		var streamStyle = [styles.streams];

		if(voiceOnly || props.incall)
			streamStyle.push(styles.videoHidden);

		return (
			<View style={styles.footer}>
				<View style={styles.fullLength} >
					{callContent}
					<NavBanner
						id={"NavBannerDown"}
						title={props.incall ? minimize : fullscreen}
						icon={props.incall ? "compress" : "window-maximize"}
						onPress={props.incall ? closeCalling : openCalling}
					/>
				</View>
				<View style={streamStyle} id="ae_remote_video">
					{streams}
				</View>
			</View>
		);
	}
}

class Video extends Component{
	componentDidMount(){
		var dispatch = this.context.dispatch;
		setTimeout(dispatch("buzz:trigger:video:recalculate"), 16);
		setTimeout(dispatch("buzz:trigger:video:show"), 64);
	}

	render(){
		var props = this.props;
		var stream = props.stream;
		var onPress = props.onPress;
		var id = props.id;

		return (
			<TouchableOpacity id={id} style={styles.video} onPress={onPress}>
				<VideoView stream={stream} />
			</TouchableOpacity>
		);
	}
}

ATouchAwayConnectedHeader.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

Video.contextTypes = {
	dispatch: PropTypes.func,
};

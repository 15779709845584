import PropTypes from "prop-types";
import React, { useCallback, useContext }  from "react";
import Style from "ae-style";

import Banner from "./Banner";
import MeetingContext from "../../Calling/context/MeetingContext";

export default function MeetingStartBanner(props, context){

    const meetingManager = useContext(MeetingContext);

	const banner = props.banner;
	const time = banner.updated_at;
	const date = banner.formatted_date;

	const textColor = Style.colors.primary;
	const iconColor = Style.colors.primary;;

	const message_line1 = 'Ongoing Call'; //@pahul localize
    const message_line2 = `${banner.content.meeting.title}` //@pahul localize

    const joinMeeting = useCallback(() => {
		meetingManager.onJoinMeeting(banner.content.meeting._id);
	}, [meetingManager.onJoinMeeting, banner.content.meeting._id]);

	return (
		<Banner
            time={time}
            date={date} 
            message_line1={message_line1}
            message_line2={message_line2}
            textColor={textColor}
            iconColor={iconColor}
            onPress={joinMeeting}
            icon="aeto-video_call"
            border
        />
	);
}

MeetingStartBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

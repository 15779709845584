/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import PropTypes from "prop-types";

import ManagedSection from "../ManagedSection";
import Markdown from "ae-base/Base/Markdown";

ResourceView.propTypes = {
	model: PropTypes.object
};

export default function ResourceView(props, context) {
	var localizeFrom = context.localizeFrom;
	var model = props.model;
	var account = model.manage.account;
	var resources = model.manage.resources;
	var resource = resources.resourceDetail || {};
	var toast = model.toast;

	var resourceContent = resource.data || "";
	var resourceTitle = resource.name || " ";

	if(typeof resourceContent === "object" && typeof resourceTitle === "object"){
		resourceContent = localizeFrom(resource.data || {}, ".");
		resourceTitle = localizeFrom(resource.name || {}, ".");
	}

	return (
		<ManagedSection online={model.online} title={resourceTitle} contact={account} toast={toast} actionId={model.workflowaction._id}>
			<Markdown>
				{resourceContent}
			</Markdown>
		</ManagedSection>
	);
}

ResourceView.contextTypes = {
	localizeFrom: PropTypes.func
};
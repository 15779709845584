/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";
import PropTypes from "prop-types";
import Submission from "./Submission";
import GroupFormHeader from "./GroupFormHeader";
import ListAdd from "ae-base/List/ListAdd";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import MainContent from "../../Layout/MainContent";
import ActiveStaffFormSubmission from "./ActiveStaffFormSubmission";

import Style from "ae-style";

var styles = StyleSheet.create({
	more: {
		marginVertical: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center"
	}
});

export default function StaffFormSubmissions(props, context) {
	var dispatch = context.dispatch;

	var model = props.model;
	var toast = model.toast;
	var ownformsubmissions = model.ownformsubmissions.submissions || [];
	var forms_sections = (model.atouchawaysettings || {}).forms_sections || {};

	var ownformlist = model.ownformlist;
	const formId = model.ownformsubmissions.form;
	var currentform = ownformlist[formId];

	var currentSchema = model.ownformsubmissions.schema;
	var localizeFrom = context.localizeFrom;
	var localize = context.localize;

	const targetType = currentform.targetType;

	var localization = currentform.localization;
	var loadmore = localize("groupforms.loadmore");
	var getMore = dispatch("ownformsubmissions:trigger:loadMore");

	var title = localizeFrom(localization, ".") || currentform.name || currentform._id;

	var shouldShowMore = model.ownformsubmissions.hasMore;
	const permission = typeof forms_sections[formId] === "boolean" ? (forms_sections[formId] ? 2 : 0) : forms_sections[formId];

	var renderedSubmissions = ownformsubmissions.map(function(submission){
		var id = submission._id;
		return (
			<Submission schema={currentSchema} submission={submission} key={id} type={"staff"} permission={permission} targetType={targetType} />
		);
	});

	var onAddSubmission = dispatch("activeownformsubmission:trigger:creating", {
		id: model.ownformsubmissions.form,
		schema: currentform.schema
	});

	var renderMore = shouldShowMore ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="dropdown" onPress={getMore} />
		</View>
	) : null;

	var addNew = localize("groupforms.addnew");
	var formType = currentform.formType;

	if(formType === "single") {
		if(ownformsubmissions.length === 1) {
			return <ActiveStaffFormSubmission model={model} submission={ownformsubmissions[0]} singleForm />;
		}
	}

	var renderAdd = (formType === "multi" || ownformsubmissions.length < 1) ? (
		<ListAdd title={addNew} onPress={onAddSubmission} />
	) : null;

	const canWrite = permission > 1 ||  permission < -1;
	if (!canWrite)
		renderAdd = null;

	return (
		<PageContainer toast={toast}>
			<MainScroll>
				<GroupFormHeader title={title} />
				<MainContent>
					{renderAdd}
					{renderedSubmissions}
					{renderMore}
				</MainContent>
			</MainScroll>
		</PageContainer>
	);
}

StaffFormSubmissions.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};

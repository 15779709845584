import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

var styles = StyleSheet.create({
	container: {
		alignItems: "center"
	}
});

export default function NotFound(props, context){
	var model = props.model;
	var page = model.page;
	var localize = context.localize;
	var backbutton = localize("common.back");
	var couldnotfind = localize("notfound.couldnotfind");
	var pages = localize("notfound.page");

	var onBack = context.dispatch("desktop:trigger:page:back");

	return (
		<View style={styles.container}>
			<StyledText>{couldnotfind} {page} {pages}</StyledText>
			<StyledButton title={backbutton} onPress={onBack} />
		</View>
	);
}

NotFound.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

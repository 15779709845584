import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import StyledText from "ae-base/Base/StyledText";

import Emoji from "./Emoji";
import Reminder from "./Reminder";

export default function HistoryReminder(props, context){
	var localize = context.localize;
	var responseTitle = localize("reminder.responsetitle");

	var history = props.history;
	var title = history.readable;

	var response = Emoji.check(history.response);
	if (response === "Accepted")
		response = localize("reminder.Accepted");
	else if (response === "Denied")
		response = localize("reminder.Denied");
	else if (response === "Yes")
		response = localize("common.yes");
	else if (response === "No")
		response = localize("common.no");
	var content = history.content;

	var contentElement = [
		(<StyledText>{content}</StyledText>),
		(<StyledText bold>{responseTitle} {response}</StyledText>)
	];

	return (
		<Reminder title={title} content={contentElement} />
	);
}

HistoryReminder.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Header from "./Header";
import Contacts from "./Contacts";
import Calling from "./Calling";
import Reminder from "../Reminder";
import NoticePage from "./NoticePage";
import ConfirmPage from "./ConfirmPage";
import Hide from "./Hide";
import Sidebar from "./Sidebar";
import About from "../About";

var styles = StyleSheet.create({
	main: {
		flex: 1,
		backgroundColor: "#000000",
	},
	content: {
		flex: 1,
		flexDirection: "row",
	},
});

export default function Legacy(props) {
	var model = props.model;
	var latest = model.latest;
	var currentPage = model.page;
	var urgency = model.urgency;
	var offline = model.offline;
	var bracelet = model.bracelet;
	var screen = model.size;

	if(model.hide)
		return (
			<Hide />
		);

	if(model.aboutbox.open)
		return (
			<About aboutbox={model.aboutbox} />
		);

	var renderMainContent = (
		<Contacts contacts={model.contacts} latest={latest} />
	);

	if(currentPage === "calling")
		renderMainContent = (
			<Calling model={model} />
		);

	if(urgency.cancelling){
		var renderNoticePage = (
			<ConfirmPage urgency={urgency} />
		);
	}else if(offline){
		renderNoticePage = (
			<NoticePage />
		);
	}else{
		renderNoticePage = null;
	}

	var renderReminder = model.reminders ? (
		<Reminder reminder={model.reminders} />
	) : null;

	return (
		<View style={styles.main}>
			<Header footer={model.footer} personal={model.personal} bracelet={bracelet} screen={screen} />
			<View style={styles.content}>
				{renderMainContent}
				<Sidebar urgency={model.urgency} settings={model.settings} />
			</View>
			{renderNoticePage}
			{renderReminder}
		</View>
	);
}

Legacy.contextTypes = {
	dispatch: PropTypes.func,
};

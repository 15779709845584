import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
	View,
	Text,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import PageContainer from "../../Layout/PageContainer";

import Form from "ae-forms";
import MobiletokenloginSchema from "../../../schemas/Mobiletokenlogin";

import LoginSection from "./LoginSection";
import LoginLogo from "./LoginLogo";
import LoginBack from "./LoginBack";
import LoginSpacer from "./LoginSpacer";
import MainScroll from "../../Layout/MainScroll";
import VersionNumber from "../Myprofile/VersionNumber";

var styles = StyleSheet.create({
	submitAlign: {
		justifyContent: "flex-start"
	},
	backButton: {
		zIndex: 1,
		position: "absolute",
		top: -10,
		left: 0,
		right: 0,
		bottom: 0
	},
	content: {
		flexDirection: "column"
	}
});

export default function Mobiletokenlogin(props, context) {
	var dispatch = context.dispatch;
	var model = props.model;
	var toast = model.toast;
	var saving = model.save;
	var localize = context.localize;

	var signinasamanageduser = localize("mobiletokenlogin.signinasamanageduser");
	var signintoatouchaway = localize("mobiletokenlogin.signintoatouchaway");
	var already = localize("login.already");
	var haveanatouchawayaccount = localize("login.haveanatouchawayaccount");
	var signinwithanaccount = localize("mobiletokenlogin.signinwithaccount");

	return (
		<PageContainer dark saving={saving} toast={toast}>
			<MainScroll>
				<View style={styles.backButton}>
					<LoginBack onPress={dispatch("app:trigger:login")} />
				</View>

				<LoginSection header>
					<LoginLogo />
				</LoginSection>

				<LoginSection contrast >
					<View style={styles.content}>
						<StyledText bold large>{signinasamanageduser}</StyledText>
					</View>

					<LoginSpacer>
						<Form key={signintoatouchaway} schema={MobiletokenloginSchema} submitText={signintoatouchaway} onSubmit={dispatch("mobiletokenlogin:trigger:token")} submitStyle={styles.submitAlign} />
					</LoginSpacer>
				</LoginSection>

				<LoginSection>
					<Text>
						<StyledText contrast>{already}</StyledText>
						<StyledText bold contrast>{haveanatouchawayaccount}</StyledText>
					</Text>
					<LoginSpacer>
						<StyledButton title={signinwithanaccount} onPress={dispatch("app:trigger:login")} />
					</LoginSpacer>
				</LoginSection>
				<VersionNumber config={model.config} model={model} />
			</MainScroll>
		</PageContainer>
	);
}

Mobiletokenlogin.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {
	View,
	TouchableOpacity,
	StyleSheet,
	Dimensions
} from "react-native";
import SquareGrid from "react-native-square-grid";

import AtaContainer from "../aTouchAway/AtaContainer";
import SimplifiedHeader from "./SimplifiedHeader";
import SimplifiedFooter from "./SimplifiedFooter";

import StyledIcon from "ae-base/Base/StyledIcon";
import StyledText from "ae-base/Base/StyledText";

import format from "string-template";
import { useMeasureUnits } from "@aetonix/hooks";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		overflow: "hidden"
	},
	item: {
		flex: 1,
		alignSelf: "stretch",
		borderRadius: 20,
	},
	outer: {
		flex: 1,
		overflow: "hidden",
		borderRadius: 20,
		backgroundColor: "#131618"
	},
	itemContainer: {
		flex: 1,
		alignSelf: "stretch",
		padding: 16
	},
	contentContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center"
	},
	content: {
		width: null,
		height: null,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		borderRadius: 20
	}
});

var HEALTH_INDICATORS = [
	{name: "activity", icon: "bicycle", imperial: "{cumulative}", metric: "{cumulative}"},
	{name: "bloodsugar", icon: "tint", mg: "{mg} mg/L", mmol: "{mmol} mmol/L"},
	{name: "weight", icon: "user", lbs: "{lbs} lbs", kg: " {kg} kg"},
	{name: "bloodpressure", icon: "heartbeat", imperial: "{systolic}/{diastolic}\n{bpPulserate}", metric: "{systolic}/{diastolic}\n{bpPulserate}"},
	{name: "bloodoxygen", icon: "stethoscope", imperial: "{oxygensaturation}%, {pulserate} bpm", metric: "{oxygensaturation}%, {pulserate} bpm"},
	{name: "bodytemperature", icon: "thermometer", fahrenheit: "{fahrenheit} °F", celsius: "{celsius} °C"},
];

export default SimplifiedHome = (props) => {
	const measureUnits = useMeasureUnits();

	return (
		<SimplifiedHomeComp {...props} measureUnits={measureUnits} />
	)
}

class SimplifiedHomeComp extends PureComponent{
	constructor(props){
		super(props);

		this._renderItem = renderItem.bind(this);
	}

	render(){
		var model = this.props.model;
		var settings = model.settings || {};

		var healthSettings = settings.myhealth_sections || {};
		var items = HEALTH_INDICATORS.filter(function(i){
			return !(i.name in healthSettings) || healthSettings[i.name];
		});

		var rows = items.length <= 3 ? 1 : 2;
		var columns = items.length <= 3 ? items.length : 3;

		if(items.length <= 3)
			rows = 1;

		var windowDimensions = Dimensions.get("window");
		var w = windowDimensions.width;
		var h = windowDimensions.height;

		if(w < h){
			var temp = rows;
			rows = columns;
			columns = temp;
		}

		return (
			<AtaContainer>
				<SimplifiedHeader model={model} />
				<View style={styles.container}>
					<SquareGrid key={"buttons"} columns={columns} rows={rows} items={items} renderItem={this._renderItem(model)} />
				</View>
				<SimplifiedFooter model={model} />
			</AtaContainer>
		);
	}
}

function renderItem(model){
	return function(item){
		const measureUnits = this.props.measureUnits;
		var context = this.context;
		var localize = context.localize;
		var dispatch = context.dispatch;

		var type = item.name;
		var name = localize("simplified." + type);
		var onPress = dispatch("reading:trigger:open", type);

		var data = ((model[type] || {}).cumulative || [])[0] || {};
		if(item.name !== "activity")
			data = data.history;
		else {
			var modelType = model[type] || {};
			var cumulative = modelType.cumulative || [];
			data = cumulative[cumulative.length - 1] || {};
		}

		let displayUnits = measureUnits.measureUnits;
		if(type === "weight"){
			displayUnits = measureUnits.weightUnits;
		} else if (type === "bloodsugar"){
			displayUnits = measureUnits.glucometerUnits;
		} else if (type === "bodytemperature"){
			displayUnits = measureUnits.temperatureUnits;
		}


		var formatted = data ? format(item[displayUnits], data) : null;

		return (
			<View style={styles.itemContainer}>
				<View style={styles.contentContainer}>
					<Button onPress={onPress} name={name} icon={item.icon} data={formatted} />
				</View>
			</View>
		);
	}.bind(this);
}

function Button(props){
	var onPress = props.onPress;
	var name = props.name;
	var icon = props.icon;
	var data = props.data;

	var renderData = data ? (
		<StyledText maxSize="emphasized" centered contrast>{data}</StyledText>
	) : null;

	return (
		<View style={styles.item}>
			<TouchableOpacity accessibilityComponentType="button" style={styles.outer} onPress={onPress}>
				<View style={styles.content}>
					<StyledIcon name={icon} contrast hugeIcon />
					<StyledText maxSize="emphasized" centered contrast emphasized>{name}</StyledText>
					{renderData}
				</View>
			</TouchableOpacity>
		</View>
	);
}

SimplifiedHomeComp.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};
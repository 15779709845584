import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
	TouchableOpacity,
	Dimensions,
} from "react-native";

import MessagingInput from "../../Pages/Messaging/MessagingInput";
import Messages from "./Messages";
import MessageScroll from "../../Layout/MessageScroll";
import PageContainer from "../../Layout/PageContainer";
import StyledButton from "ae-base/Base/StyledButton";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";

import Style from "@aetonix/styles";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		messages: {
			marginHorizontal: Style.layout.marginContent / 2
		},
		messagesPending: {
			justifyContent: "center"
		},
		divider: {
			marginHorizontal: Style.layout.marginLarge,
			alignSelf: "flex-end"
		},
		more: {
			alignItems: "center",
			justifyContent: "center"
		},
		messageRead: {
			alignItems: "flex-end",
			marginHorizontal: Style.layout.marginContent / 2,
			padding: Style.layout.paddingSlim
		},
		messageReadAlternate: {
			alignItems: "flex-start"
		},
		headerContainer: {
			padding: Style.layout.paddingButton,
			paddingRight: Style.layout.paddingLarge,
			alignItems: "center"
		},
		moreButton: {
			flexDirection: "row"
		},
		paddingPlus: {
			padding: Style.layout.paddingSlim
		},
		paddingMinus: {
			padding: Style.layout.paddingSmall
		}
	},
	512: {
		messages: {
			marginHorizontal: Style.layout.marginContent
		},
		messageRead: {
			marginHorizontal: Style.layout.marginContent
		},
		more: {
			alignItems: "center",
			justifyContent: "center"
		}
	}
});

export default function Messaging(props, context) {
	var model = props.model;
	var localize = context.localize;
	var dispatch = context.dispatch;
	var groupedmessages = model.groupedmessages;
	var messages = model.messaging;
	var who = messages.who;
	var personal = model.personal;
	var userId = who._id;
	var loadmore = localize("timeline.loadmore");
	var getMore = dispatch("atouchawaymessaging:trigger:next");
	var name = who.fname ? who.lname + ", " + who.fname : who.lname;
	var loadMoreVal = messages.loadMore;
	var isGroup = !!who.members;
	var lastMessage;
	var halfView = model.keyboard;
	var isAlternate;

	var screen = model.size;

	var renderedGroupedMessages = groupedmessages
		.map(function(list){
			var from = list[0].from;
			isAlternate = from._id !== personal._id;
			var person = isAlternate ? from : personal;
			lastMessage = list[list.length - 1];
			if(!isGroup && isAlternate)
				lastMessage = null;

			return (
				<Messages person={person} alternate={isAlternate} messages={list} screen={screen} key={list[0]._id} fullName={isGroup} />
			);
		});

	var renderMore = loadMoreVal ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="chevron-circle-up" onPress={getMore} />
		</View>
	) : null;

	var sendMessage = function(content){
		dispatch("atouchawaymessaging:trigger:send", {
			message: content,
			who: who
		})();
	};

	var messageReadStyle = [styles.messageRead];

	if(isAlternate)
		messageReadStyle.push(styles.messageReadAlternate);

	var renderMessageRead = null;
	if(lastMessage){
		if(isGroup && Array.isArray(lastMessage.read)){
			var membersRead = lastMessage.read.map(function(person){
				if(typeof person !== "string")
					return person.lname + ", " + person.fname.slice(0, 1);
				else
					return "";
			});
			var readMessageGroup = localize("groupchat.readmessage", {
				people: membersRead.join(", ")
			});
			if(membersRead.length !== 0)
				renderMessageRead = (<StyledText contrast small>{readMessageGroup}</StyledText>);
		} else if(lastMessage.read) {
			var readMessageSingle = localize("messaging.readmessage", lastMessage);
			renderMessageRead = (<StyledText contrast small>{readMessageSingle}</StyledText>);
		}
	}

	var header = <Header contact={who} />;
	var smallMode = Dimensions.get("window").height < 450;
	var defaultMessage = model?.messaging?.defaultMessage;
	if(isGroup)
		header = <GroupHeader contact={who} />;

	return (
		<PageContainer borderRadius blackGray half={halfView}>
			{!smallMode ? header : null}
			<MessageScroll>
				{renderMore}
				<View style={styles.messages} >
					{renderedGroupedMessages}
				</View>
				<View style={messageReadStyle} >
					{renderMessageRead}
				</View>
			</MessageScroll>
			<MessagingInput invertColors messages={messages} aTouchAway={true} name={name} defaultMessage={defaultMessage} id={userId}  sendMessage={sendMessage} noFileUploadButton />
		</PageContainer>
	);
}

class GroupHeader extends PureComponent {
	constructor(props){
		super(props);
		this.state = {
			toggle: false
		};
		this.toggle = toggle.bind(this);
	}

	render(){
		var props = this.props;
		var context = this.context;
		var localize = context.localize;
		var contact = props.contact;
		var name = contact.name;
		var members = contact.members;
		var disabled = true;

		var shownMembers = this.state.toggle ? members : members.slice(0, 3);
		var nameList = shownMembers.map(function(member){
			if(member.fname || member.lname)
				return member.lname + ", " + member.fname;
			return null;
		});

		var icon;
		var renderIcon = null;
		var membersText = nameList.join(", ");
		var padding = [styles.paddingPlus];
		if(members.length > 3){
			if(this.state.toggle){
				icon = "minus";
				padding.push(styles.paddingMinus);
			} else {
				icon = "plus";
				membersText = localize("atouchawaymessaging.members", {
					list: membersText
				});
			}
			renderIcon = (
				<StyledIcon style={padding} contrast name={icon} />
			);
			disabled = false;
		}

		var renderMembers = nameList[0] ? (
			<TouchableOpacity style={styles.moreButton} onPress={this.toggle} disabled={disabled}>
				{renderIcon}
				<StyledText style={styles.paddingPlus} contrast>{membersText}</StyledText>
			</TouchableOpacity>
		) : null;

		return (
			<View style={styles.headerContainer}>
				<StyledText large contrast>{name}</StyledText>
				{renderMembers}
			</View>
		);
	}
}

function Header(props){
	var contact = props.contact;
	var name = contact.lname + ", " + contact.fname;
	return (
		<View style={styles.headerContainer}>
			<StyledText large contrast>{name}</StyledText>
		</View>
	);
}

function toggle(){
	this.setState({
		toggle: !this.state.toggle
	});
}

Messaging.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func,
};

GroupHeader.contextTypes = {
	localize: PropTypes.func
};
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet
} from "react-native";
import Form from "ae-forms";
import CardPage from "../../Layout/CardPage";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import Loader from "ae-base/Base/Loader";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import PropTypes from "prop-types";

var DEFAULT_DATA = {};

var styles = StyleSheet.create({
	deleteBtn: {
		alignSelf: "flex-end",
		flex: 1
	},
	lock: {
		alignSelf: "flex-end",
		marginTop: 4
	}
});

export default function SubmissionUpdate(props, context){
	var model = props.model;
	var dispatch = context.dispatch;
	var localizeFrom = context.localizeFrom;
	var localize = context.localize;
	var activegroupformsubmission = model.activegroupformsubmission || {};
	var groupform = model.groupformsubmissions.form;
	var groupformlist = model.groupformlist;
	var screen = model.screen;
	var saving = model.saving;
	var defaultData = activegroupformsubmission.data || DEFAULT_DATA;
	var confirmView = activegroupformsubmission.confirmation;
	var deleteView = activegroupformsubmission.delete;

	var currentForm = groupformlist[groupform];
	var schema = currentForm.schema;
	var hasSchema = (schema && schema.properties);

	if(!hasSchema) return <Loader />;
	var disableLock = schema.disableLock;

	var submitUpdate = dispatch("activegroupformsubmission:trigger:update:submit");
	var goBack = dispatch("activegroupformsubmission:trigger:cancel");

	var deleteSubmission = dispatch("activegroupformsubmission:trigger:delete");
	var lockSubmission = dispatch("activegroupformsubmission:trigger:lock");

	var localization = currentForm.localization;
	var schemaTitle = localizeFrom(localization, ".") || currentForm.name || currentForm._id;
	var editText = `${schemaTitle} - ${localize("groupforms.editsub")}`;

	var delText = localize("groupforms.delete");
	var discardchanges = localize("groupforms.discardchanges");
	var lockText = localize("groupforms.lock");
	var confirmLock = dispatch("activegroupformsubmission:trigger:confirmlock");
	var cancelLock = dispatch("activegroupformsubmission:trigger:cancelLock");
	var confirmDelete = dispatch("activegroupformsubmission:trigger:confirmdelete");
	var cancelDelete = dispatch("activegroupformsubmission:trigger:canceldelete");

	var renderContent = null;
	if(confirmView) {
		renderContent = (
			<ConfirmationModal open={confirmView} title={localize("groupform.confirmTitle")} titleIcon="warning" confirmText={localize("groupform.yesLock")} cancelText={localize("groupform.cancelLock")} onConfirm={confirmLock} onCancel={cancelLock} >
				<StyledText contrast>{localize("groupform.confirmLock")}</StyledText>
			</ConfirmationModal>
		);
	} else if(deleteView) {
		renderContent = (
			<ConfirmationModal open={deleteView} title={localize("groupform.confirmDelete")} titleIcon="warning" confirmText={localize("groupform.yesDelete")} cancelText={localize("groupform.cancelDelete")} onConfirm={confirmDelete} onCancel={cancelDelete} >
				<StyledText contrast>{localize("groupform.confirmDelete")}</StyledText>
			</ConfirmationModal>
		);
	} else {
		renderContent = (
			<CardPage title={editText} icon="notebook" saving={saving} screen={screen} toast={model.toast} online={model.online}>
				<StyledButton alert title={delText} icon="delete" onPress={deleteSubmission} style={styles.deleteBtn} />
				{disableLock ? null : <StyledButton contrast title={lockText} icon="check" onPress={lockSubmission} style={styles.lock} />}
				<Form schema={schema} defaultData={defaultData} onSubmit={submitUpdate} onCancel={goBack} cancelText={discardchanges} />
			</CardPage>
		);
	}

	return renderContent;
}

SubmissionUpdate.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};

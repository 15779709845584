/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet
} from "react-native";
import Form from "ae-forms";
import CardPage from "../../Layout/CardPage";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import PropTypes from "prop-types";

var DEFAULT_DATA = {};

var styles = StyleSheet.create({
	deleteBtn: {
		alignSelf: "flex-end",
		flex: 1
	},
	lock: {
		alignSelf: "flex-end",
		marginTop: 4
	}
});

export default function StaffSubmissionUpdate(props, context){
	var model = props.model;
	var dispatch = context.dispatch;
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;
	var activeownformsubmission = model.activeownformsubmission || {};
	var ownform = model.ownformsubmissions.form;
	var ownformlist = model.ownformlist;
	var screen = model.screen;
	var saving = model.saving;
	var defaultData = activeownformsubmission.data || DEFAULT_DATA;

	var locking = activeownformsubmission.locking;
	var deleting = activeownformsubmission.deleting;

	var submitUpdate = dispatch("activeownformsubmission:trigger:update:submit");
	var goBack = dispatch("activeownformsubmission:trigger:cancel");

	var deleteSubmission = dispatch("activeownformsubmission:trigger:delete:start");

	var currentForm = ownformlist[ownform];
	var schema = currentForm.schema;

	var localization = currentForm.localization;
	var schemaTitle = localizeFrom(localization, ".") || currentForm.name || currentForm._id;
	var editText = `${schemaTitle} - ${localize("groupforms.editsub")}`;

	var delText = localize("groupforms.delete");
	var discardchanges = localize("groupforms.discardchanges");

	var confirmLock = dispatch("activeownformsubmission:trigger:lock:confirm");
	var cancelLock = dispatch("activeownformsubmission:trigger:lock:cancel");
	var confirmDelete = dispatch("activeownformsubmission:trigger:delete:confirm");
	var cancelDelete = dispatch("activeownformsubmission:trigger:delete:cancel");

	var renderContent = null;
	if(locking) {
		renderContent = (
			<ConfirmationModal
				open={locking}
				title={localize("groupform.confirmTitle")}
				titleIcon="warning"
				confirmText={localize("groupform.yesLock")}
				cancelText={localize("groupform.cancelLock")}
				onConfirm={confirmLock}
				onCancel={cancelLock}
			>
				<StyledText contrast>{localize("groupform.confirmLock")}</StyledText>
			</ConfirmationModal>
		);
	} else if(deleting) {
		renderContent = (
			<ConfirmationModal
				open={deleting}
				title={localize("groupform.confirmDelete")}
				titleIcon="warning"
				confirmText={localize("groupform.yesDelete")}
				cancelText={localize("groupform.cancelDelete")}
				onConfirm={confirmDelete}
				onCancel={cancelDelete}
			>
				<StyledText contrast>{localize("groupform.confirmDelete")}</StyledText>
			</ConfirmationModal>
		);
	} else {
		renderContent = (
			<CardPage title={editText} icon="notebook" saving={saving} screen={screen} toast={model.toast}>
				<StyledButton alert title={delText} icon="delete" onPress={deleteSubmission} style={styles.deleteBtn} />
				<Form schema={schema} defaultData={defaultData} onSubmit={submitUpdate} onCancel={goBack} cancelText={discardchanges} />
			</CardPage>
		);
	}

	return renderContent;
}

StaffSubmissionUpdate.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";
import prop from "prop";

var getVisitDate = prop("VisitDate");
var getDischargeDate = prop("DischargeDate");
var getVisitReason = prop("VisitReason");
var getComplications = prop("Complications");
var getHospitalPhysicianName = prop("HospitalPhysicianName");
var getHospitalPhysicianPhone = prop("HospitalPhysicianPhone");
var getHospitalAdviceNote = prop("HospitalAdviceNote");
var getFollowupAppointmentMadeWith = prop("FollowupAppointmentMadeWith");
var getFollowupAppointmentDate = prop("FollowupAppointmentDate");
var getHospitalName = prop("Hospital.Name");
var getVisitTypeName = prop("VisitType.Name");

export default function Hospitalvisits(props, context) {
	var careplan = props.careplan;

	var localize = context.localize;
	var hospitalvisitsTitle = localize("hospitalvisits.hospitalvisit_s");
	var hospitalvisitsinformation = localize("hospitalvisits.hospitalvisitsinformation");

	var hospitalvisits = careplan.HospitalVisit || [];
	var renderHospitalvisit = hospitalvisits.map(function(hospitalvisit, index){
		return (
			<Hospitalvisit key={index} hospitalvisit={hospitalvisit} index={index} />
		);
	}
	);

	return (
		<Section title={hospitalvisitsTitle}>
			<Title title={hospitalvisitsinformation} />
			{renderHospitalvisit}
		</Section>
	);
}

class Hospitalvisit extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var hospitalvisit = props.hospitalvisit;

		var localize = context.localize;
		var hospitalname = localize("hospitalvisits.hospitalname");
		var visitdate = localize("hospitalvisits.visitdate");
		var typeofvisit = localize("hospitalvisits.typeofvisit");
		var dateofdischarge = localize("hospitalvisits.dateofdischarge");
		var reasonofvisit = localize("hospitalvisits.reasonofvisit");
		var complications = localize("hospitalvisits.complications");
		var hospitalphysicianname = localize("hospitalvisits.hospitalphysicianname");
		var hospitalphysiciantelephonenumber = localize("hospitalvisits.hospitalphysiciantelephonenumber");
		var keyadvicefromhospitalphysician = localize("hospitalvisits.keyadvicefromhospitalphysician");
		var followupappointmentmadewith = localize("hospitalvisits.followupappointmentmadewith");
		var dateoffollowupappointment = localize("hospitalvisits.dateoffollowupappointment");

		return (
			<Group>
				<Item title={hospitalname} value={getHospitalName(hospitalvisit)} />

				<Item title={visitdate} value={getVisitDate(hospitalvisit)} />

				<Item title={typeofvisit} value={getVisitTypeName(hospitalvisit)} />

				<Item title={dateofdischarge} value={getDischargeDate(hospitalvisit)} />

				<Item title={reasonofvisit} value={getVisitReason(hospitalvisit)} />

				<Item title={complications} value={getComplications(hospitalvisit)} />

				<Item title={hospitalphysicianname} value={getHospitalPhysicianName(hospitalvisit)} />

				<Item title={hospitalphysiciantelephonenumber} value={getHospitalPhysicianPhone(hospitalvisit)} />

				<Item title={keyadvicefromhospitalphysician} value={getHospitalAdviceNote(hospitalvisit)} />

				<Item title={followupappointmentmadewith} value={getFollowupAppointmentMadeWith(hospitalvisit)} />

				<Item title={dateoffollowupappointment} value={getFollowupAppointmentDate(hospitalvisit)} />
			</Group>
		);
	}
}
Hospitalvisits.contextTypes = {
	config: PropTypes.object,
	localize: PropTypes.func
};

Hospitalvisit.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";

import LanguageSchema from "../../../../schemas/Language";
import Form from "ae-forms";

import Divider from "../../../Layout/Divider";
import BlockView from "ae-base/Base/BlockView";

export default function Language(props, context){
	var dispatch = context.dispatch;
	var language = {
		language: props.language
	};

	return (
		<BlockView>
			<Form id_prefix={context.id_prefix + "languageForm"} key={language} schema={LanguageSchema} defaultData={language} autoSubmit onSubmit={dispatch("preferences:trigger:preferences:update")} />
			<Divider />
		</BlockView>
	);

}

Language.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
};

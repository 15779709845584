import PropTypes from "prop-types";
import React from "react";

import ManagedSection from "../Managed/ManagedSection";
import ContactSettingsSchema from "../../../schemas/ContactSettings";
import Form from "ae-forms";

export default function ContactSettings(props, context){
	const localize = context.localize;
	const dispatch = context.dispatch;
	const model = props.model;

	const contact = model.contactprofile || {};
	const toast = model.toast;
	const defaultData = model.owncontactsettings || {};

	const title = localize("contactsettings.title");

	return (
		<ManagedSection online={model.online} key={"contactsettings"} contact={contact} title={title} toast={toast}>
			<Form schema={ContactSettingsSchema} defaultData={defaultData} onSubmit={dispatch("owncontactsettings:trigger:update")} />
		</ManagedSection>
	);
}

ContactSettings.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};
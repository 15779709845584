
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Component
} from "react";
import {
	View,
	StyleSheet
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Notification from "../../Layout/Notification";
import PropTypes from "prop-types";
import Style from "ae-style";
import { WEEKDAY_SHORT_DATE_TIME_FORMAT } from "../../../utils/date-utils";

var styles = StyleSheet.create({
	text_style: {
		color: Style.colors.white,
		paddingRight: Style.layout.paddingSmall,
		justifyContent: "center",
	},
	text_container: {
		flex: 1
	}
});

export default function ReminderBanners(props) {
	var reminders = props.reminders || [];
	var banners = reminders.map(function(reminder){
		return (<ReminderBanner reminder={reminder} key={reminder._id} />);
	});

	return (
		<View >
			{banners}
		</View>
	);
}

class ReminderBanner extends Component {
	render(){
		var props = this.props;
		var context = this.context;
		var formatDate = context.formatDate;

		var reminder = props.reminder;

		var color =  Style.colors.primary;
		var localize = context.localize;
		var dispatch = context.dispatch;
		var reminderText = localize("atouchawayreminder.reminder");

		var viewReminder = dispatch("ownactivereminder:trigger:view", reminder);
		var formatedDate = formatDate(reminder.updated_at, WEEKDAY_SHORT_DATE_TIME_FORMAT);

		return (
			<Notification
				id={"viewreminder"}
				color={color}
				iconColor={"white"}
				onPress={viewReminder} >
				<View style={styles.text_container}>
					<StyledText style={styles.text_style} emphasized>
						{formatedDate}
					</StyledText>
					<StyledText style={styles.text_style} emphasized>
						{reminderText + reminder.reminder.content}
					</StyledText>
				</View>
			</Notification>);
	}
}

ReminderBanners.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

ReminderBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	formatDate: PropTypes.func
};

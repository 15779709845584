import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";
import memoize from "memoizee";
import xtend from "xtend";

import Form from "ae-forms";

import FormsSchema from "../../../../../schemas/aTouchAwayForms";

import SettingSection from "../SettingSection";

var combineSchemas = memoize(combineSchemasRaw);

export default function Forms(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;
	var display = props.display;
	var model = props.model;
	var toggle = props.onToggle;
	var groupForms = model.manage.managedgroupformlist;
	var orderedGroupForms = model.manage.managedgroupformorderedlist;
	var atouchaway = model.manage.atouchaway || {};
	const userType = ((model.manage.account || {}).type) || [];
	const formsSections = atouchaway.forms_sections || {};

	var title = localize("atasettings.forms") || "Forms";

	const changeAllToDetails = {
		enum: [0, 1, 2],
		description: "groupforms.changeallto.title",
		value_description: "groupforms"
	};

	let formsData = {};

	const groupFormsProperties = orderedGroupForms.reduce((groupFormsObj, formId, index) => {
		if (formsSections[formId] === true) formsData[formId] = 2;
		else if (formsSections[formId]) formsData[formId] = formsSections[formId];
		if (!formsData[formId]) formsData[formId] = 0;
		const defaultEnums = [0, 1, 2];
		groupFormsObj[formId] = {
			order: index,
			type: "number",
			localization: groupForms[formId].localization,
			value_description: "groupforms",
			enum: defaultEnums
		};
		return groupFormsObj;
	}, {});

	const changeAllTo = orderedGroupForms.length > 0 ? true : false;

	var groupFormsSchema = {
		title: "Forms Permissions",
		type: "object",
		properties: {
			forms_sections: {
				order: 12,
				type: "object",
				changeAllTo: changeAllTo,
				changeAllToDetails: changeAllToDetails,
				properties: groupFormsProperties
			}
		}
	};

	if (userType.indexOf("atouchaway") != -1) {
		formsData = [];

		const value_localization = orderedGroupForms.reduce((acc, formKey) => {
			if (formsSections[formKey]) {
				formsData.push(formKey);
			}
			const locale = groupForms[formKey].localization || {};
			acc["en"][formKey] = locale.en;
			acc["fr"][formKey] = locale.fr || locale.en;
			acc["pt"][formKey] = locale.pt || locale.en;
			acc["es"][formKey] = locale.es || locale.en;
			acc["cn_s"][formKey] = locale.cn_s || locale.en;
			acc["cn_t"][formKey] = locale.cn_t || locale.en;
			acc["ta"][formKey] = locale.ta || locale.en;
			return acc;
		}, {
			en: {},
			fr: {},
			pt: {},
			es: {},
			cn_t: {},
			cn_s: {},
			ta: {}
		});

		const itemEnum = orderedGroupForms;
		const checkboxItem = {
			type: "string",
			enum: itemEnum,
			value_localization: value_localization
		};
		groupFormsSchema = {
			title: "Forms Permissions",
			type: "object",
			properties: {
				forms_sections: {
					order: 12,
					type: "array",
					selectAll: true,
					inputType: "checkboxes",
					items: checkboxItem
				}
			}
		};
	}

	var defaultData = xtend(atouchaway, {
		forms_sections: formsData
	});

	var schema = combineSchemas([FormsSchema, groupFormsSchema]);

	var renderBody = display ? (
		<Form schema={schema} defaultData={defaultData} onSubmit={dispatch("managedatouchaway:trigger:update")} />
	) : null;

	return (
		<View>
			<SettingSection section="forms" title={title} display={display} onToggle={toggle} />
			{renderBody}
		</View>
	);
}

function combineSchemasRaw(schemas) {
	return schemas.reduce(function(prev, current) {
		var prevProperties = prev.properties;
		var currentProperties = current.properties;

		var mergedProperties = xtend(prevProperties, currentProperties);

		return xtend(prev, {
			properties: mergedProperties
		});
	});
}

Forms.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

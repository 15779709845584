import PropTypes from "prop-types";
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";

export default function FallValidation(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;

	// Parameters for fall banner confirmation
	var fallinvalidator = props.fallinvalidator;
	var fromID = props.from;

	var confirmationQuestion = localize("banner.fallFalseAlarmQuestion");
	var answerYes = localize("common.yes");
	var answerNo = localize("common.no");
	var onConfirm = dispatch("fallinvalidator:trigger:cancel", {
		from: fromID,
		type: "urgency:accepted"
	});
	var onDeny = dispatch("fallinvalidator:trigger:confirm");

	return (
		<ConfirmationModal
			open={fallinvalidator}
			title={confirmationQuestion}
			titleIcon={"exclamation-triangle"}
			confirmText={answerYes}
			cancelText={answerNo}
			onConfirm={onConfirm}
			onCancel={onDeny} />
	);
}

FallValidation.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";
import SimpleState from "../SimpleState";
import SquareButton from "../../SquareButton";

var styles = StyleSheet.create({
	callActionContainer: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "stretch"
	},
	spacer: {
		flex: 1,
		minHeight: 10
	},
	callAction: {
		flex: 1,
		alignSelf: "flex-start"
	},
});

export default function Incoming(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;
	var who = props.who;

	var message = localize("atouchawaycalling.iscallingyou", who);
	var accept = localize("atouchawaycalling.accept");
	var deny = localize("atouchawaycalling.deny");

	var answerCall = dispatch("calling:trigger:accept");
	var cancelCall = dispatch("calling:trigger:deny", {
		reason: "denied"
	});

	return (
		<SimpleState who={who} message={message}>
			<View style={styles.callActionContainer}>
				<SquareButton title={accept} icon="phone" style={styles.callAction} onPress={answerCall} small contrast secondary />
				<View style={styles.spacer} />
				<SquareButton title={deny} icon="aeto-hang_up-2" style={styles.callAction} onPress={cancelCall} small contrast highlight />
			</View>
		</SimpleState>
	);
}

Incoming.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Style from "ae-style";

import Banner from "../Banners/Banner";
import StyledText from "ae-base/Base/StyledText";

export default function WorkflowActionBanner(props, context) {
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;
	var dispatch = context.dispatch;
	var formatDate = context.formatDate;
	var config = context.config;
	var action = props.action;

	var owner = action.ongoingWorkflow.owner;
	var formSchema = action.ongoingWorkflow.workflow.actions[action.action] || {};
	var message_line1 = localize("workflow.actionforuser", {
		fname: owner.fname,
		lname: owner.lname
	});

	var color = Style.colors.white;
	const label = action.ongoingWorkflow.label;

	const actionOptions = action?.options || {};

	const descriptionOptions = actionOptions?.banner?.descriptionOptions || {};
	let message_line2;
	if (actionOptions?.banner?.description) {
		message_line2 =
			localizeFrom(actionOptions.banner.description, ".", descriptionOptions)
			|| actionOptions.banner.description;
	} else {
		message_line2 =
			localizeFrom(formSchema.localization, ".", descriptionOptions)
			|| formSchema.localization;
	}

	if(label) message_line2 = label + " - " + message_line2;

	var onPress = dispatch("activeworkflowaction:trigger:view", {
		action: action._id
	});

	var bannerProperties = formSchema.banner || {};

	var description = bannerProperties.description;
	if(description){
		message_line1 = localizeFrom(description, ".");
		message_line2 = owner._id !== config.id ? `${owner.lname}, ${owner.fname}` : null;
	}

	var bannerColor = actionOptions?.banner?.bannerColor ||  bannerProperties.bannerColor;
	if(bannerColor) color = bannerColor;

	var formTextColor = actionOptions?.banner?.textColor || bannerProperties.textColor;
	var textColor = formTextColor ? formTextColor : null;

	var formIconColor = actionOptions?.banner?.iconColor || bannerProperties.iconColor;
	var iconColor = formIconColor ? formIconColor : null;

	var formIcon = actionOptions?.banner?.icon || bannerProperties.icon;
	var icon = formIcon ? formIcon : null;

	const additionalMessages = [];

	if(action.ownerNames){
		additionalMessages.push(`${action.ownerNames.slice(0, 25)}${action.ownerNames.length > 25 ? "..." : ""}`);
	}

	return (
		<Banner
			id={"workflowActionBanner"}
			time={formatDate(action.created_at)}
			message_line1={message_line1}
			message_line2={message_line2}
			topMessages={additionalMessages}
			onPress={onPress}
			color={color}
			textColor={textColor}
			iconColor={iconColor}
			icon={icon}
			border
		/>
	);
}

WorkflowActionBanner.contextTypes = {
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	dispatch: PropTypes.func,
	formatDate: PropTypes.func,
	config: PropTypes.object
};

import PropTypes from "prop-types";
import React  from "react";

import Banner from "./Banner";

import Style from "ae-style";

export default function IndicatorSteps(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;

	var banner = props.banner;
	var time = banner.updated_at;
	var date = banner.formatted_date;
	var from = banner.from;

	var from_id = from._id;
	var color = Style.colors.red;
	var banner_type = banner.banner_type;
	var status = banner.content.status;
	var group = banner.group;

	if(status === 1)
		color = Style.colors.yellow;

	var message_line1 = localize("banner.indicator.steps." + status, from);

	var clear = dispatch("banner:trigger:clear", {
		from: from_id,
		type: banner_type,
		group: group
	});

	return (
		<Banner time={time} date={date} message_line1={message_line1} color={color} onPress={clear} icon="alert" border />
	);
}

IndicatorSteps.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};


/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	TouchableOpacity,
	StyleSheet,
} from "react-native";
import xtend from "xtend";

import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";

var DEFAULT_PERSON = {
	fname: "", lname: ""
};

var styles = StyleSheet.create({
	container: {
		marginLeft: Style.layout.marginContentSmall,
		flexDirection: "column",
		flex: 1,
	},
	nameText: {
		textAlignVertical: "center"
	}
});

export default function ContactName(props){
	var person = xtend(DEFAULT_PERSON, props.person);
	var contrast = props.contrast;
	var teal = props.teal;
	var title = props.title;
	var subtitle = props.subtitle;
	var onPress = props.onPress;
	const inactiveUser = person.inactive;

	var actionable = !!onPress;

	var icon = actionable ? "\u203A" : "";

	var renderedTitle = title ? (
		<StyledText contrast={contrast} small>{title}</StyledText>
	) : null;

	var renderedSubTitle = subtitle ? (
		<StyledText black={inactiveUser} contract={contrast} small>{subtitle}</StyledText>
	) : null;

	const name = !person.lname ? person.fname : person.lname + ", " + person.fname;

	var Container = null;
	var containerProp = null;

	actionable
		?	(
			Container = TouchableOpacity,
			containerProp = {
				style: styles.container,
				onPress: onPress
			}
		)
		: (
			Container = View,
			containerProp = {
				style: styles.container
			}
		);

	// a non-breaking space is included after the name to allow sensible page rendering
	return (
		<Container {...containerProp}>
			{renderedTitle}
			<StyledText style={styles.nameText} black={inactiveUser} contrast={contrast} teal={teal}  emphasized>
				{name + "\u00A0" + icon}
			</StyledText>
			{renderedSubTitle}
		</Container>
	);
}

import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";

import Breakpoints from "../../../Breakpoints";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import StyledText from "ae-base/Base/StyledText";
import BackButton from "ae-base/Base/BackButton";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import VerticalButton from "ae-base/Base/VerticalButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";

import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		mainContent: {
			backgroundColor: Style.colors.primary,
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			padding: Style.layout.paddingSmall
		},
		flexTwo: {
			flex: 2
		},
		buttonSpacing: {
			marginLeft: 8
		},
		text: {},
		iconStyles: {
			padding: Style.layout.paddingSmall
		},
		controls: {
			justifyContent: "flex-end",
			flexDirection: "row",
			flex: 1
		},
		backButtonPadding: {
			marginBottom: Style.layout.paddingSmall,
			paddingLeft: Style.layout.paddingMedium,
			paddingRight: Style.layout.paddingMedium,
		}
	},
	512: {
		mainContent: {
			flexDirection: "row",
		},
		text: {
			flex: 1
		},
		container: {
			backgroundColor: Style.colors.primary,
			paddingLeft: Style.layout.paddingMedium,
			paddingRight: Style.layout.paddingMedium,
			margin: 0,
		}
	}
});

export default function GroupMessagingHeader(props, context){
	var group = props.group;

	var localize = context.localize;
	var dispatch = context.dispatch;
	var exitMsgTitle = localize("messaging.exitmessaging");
	var isMobile = Breakpoints.smallerThan("mobile");
	var headingSize = Breakpoints.smallerThan("mobile") ? "emphasized" : "largeHeading";

	var name = group.name;

	var editGroupTitle = localize("groupchat.editGroup");
	var membersTitle = localize("groupchat.members");

	var editGroup = dispatch("desktop:trigger:page", {
		page: "groupadd"
	});
	var showMembers = dispatch("desktop:trigger:page", {
		page: "groupchat_members"
	});
	var groupId = group._id;

	const onPressBack = () => {
		dispatch("desktop:trigger:page:back")();
		dispatch("persiststorage:delete")({
			dataId: groupId
		})
	}

	if(isMobile) return (
		<View style={styles.mainContent}>
			<StyledIconButton clear contrast smallHeading icon="left" style={styles.iconStyles} onPress={onPressBack} />
			<View style={styles.flexTwo}>
				<StyledText id={"groupChat_name"} size={headingSize} inline bold contrast>{name}</StyledText>
			</View>
			<View style={styles.controls}>
				<VerticalButton id={"groupChat_edit"} title={editGroupTitle} icon="aeto-i" contrast onPress={editGroup} />
				<VerticalButton id={"groupChat_edit"} style={styles.buttonSpacing} title={membersTitle} icon="aeto-team" contrast onPress={showMembers} />
			</View>
		</View>
	);

	else return (
		<View style={styles.container}>
			<BackButton title={exitMsgTitle} onPress={onPressBack} />
			<View style={styles.mainContent}>
				<View style={styles.text}>
					<StyledText id={"groupChat_name"} size={headingSize} bold inline contrast>{name}</StyledText>
				</View>
				<VerticalButton  id={"groupChat_edit"} title={editGroupTitle} icon="aeto-i" contrast onPress={editGroup} />
				<ButtonSpacer />
				<VerticalButton id={"groupChat_members"} title={membersTitle} icon="aeto-team" contrast onPress={showMembers} />
			</View>
		</View>
	);
}

GroupMessagingHeader.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import React, {useState} from "react";
import {
	StyleSheet,
	View,
} from "react-native";
import PropTypes from "prop-types";

import StyledButton from "ae-base/Base/StyledButton";
import ManagedSection from "../ManagedSection";
import Submission from "./Submission";
import ListAdd from "ae-base/List/ListAdd";
import Loader from "ae-base/Base/Loader";
import ManagedActiveGroupFormSubmission from "./ManagedActiveGroupFormSubmission";

import Style from "ae-style";

var styles = StyleSheet.create({
	more: {
		marginVertical: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center"
	}
});

export default function ManagedGroupFormSubmissions(props, context) {
	var model = props.model;
	var toast = model.toast;
	var dispatch = context.dispatch;
	var manage = model.manage;
	var localizeFrom = context.localizeFrom;
	var groupformPermissions = manage.permissions.groupforms;
	var managedgroupformsubmissions = manage.managedgroupformsubmissions;
	var submissions = managedgroupformsubmissions.submissions || [];
	var managedgroupformlist = manage.managedgroupformlist;
	var formId = managedgroupformsubmissions.form;
	var schema = managedgroupformsubmissions.schema;

	var currentform = managedgroupformlist[formId];
	if(!currentform) return <Loader />;

	var formType = currentform.formType;
	var contact = manage.account;
	var group = manage.group;
	var localize = context.localize;
	var localization = currentform.localization;

	var title = localizeFrom(localization, ".") || currentform.name || currentform._id;
	const [formsLoaded, setFormsLoaded] = useState(0);
	var loadmore = localize("groupforms.loadmore");
	var addSubmission = localize("groupforms.addnew");
	const PAGE_LIMIT = 32;

	function getMore() {
		dispatch("managedgroupformsubmissions:trigger:loadMore")();
		setFormsLoaded(submissions.length);
	}
	
	if(formType === "single") {
		if(submissions.length === 1) {
			return <ManagedActiveGroupFormSubmission model={model} submission={submissions[0]} singleForm />;
		}
	}

	var renderedSubmissions = submissions.map(function(submission){
		var id = submission._id;
		return (
			<Submission schema={schema} submission={submission} key={id} contact={contact} permission={(typeof groupformPermissions === "object" ? groupformPermissions[formId] : 2)} />
		);
	});

	var onAddSubmission = dispatch("managedactivegroupformsubmission:trigger:creating", {
		id: formId,
		owner: contact._id,
		group,
	});

	const permission = groupformPermissions[formId];
	const canWrite = permission > 1 ||  permission < -1;
	var renderMore =
	submissions.length >= formsLoaded + PAGE_LIMIT ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="dropdown" onPress={getMore} />
		</View>
	) : null;

	var renderAdd = (formType === "multi" || submissions.length < 1) ? (
		<ListAdd title={addSubmission} onPress={onAddSubmission} />
	) : null;

	if(typeof groupformPermissions === "object" && !canWrite)
		renderAdd = null;

	return (
		<ManagedSection model={model} online={model.online} title={title} contact={contact} toast={toast}>
			{renderAdd}
			{renderedSubmissions}
			{renderMore}
		</ManagedSection>
	);
}

ManagedGroupFormSubmissions.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	StyleSheet,
	TouchableOpacity,
	View
} from "react-native";
import Style from "ae-style";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Style.colors.black,
	}
});

export default class HiddenScreen extends PureComponent {
	render(){
		var context = this.context;
		var dispatch = context.dispatch;
		var onShowUp = dispatch("screenhider:trigger:show");

		return (
			<TouchableOpacity style={styles.container} onPress={onShowUp}>
				<View />
			</TouchableOpacity>
		);
	}
}

HiddenScreen.contextTypes = {
	dispatch: PropTypes.func,
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";
import AtaContainer from "./AtaContainer";
import Card from "../Layout/Card";
import MainScroll from "../Layout/MainScroll";
import Style from "ae-style";

var styles = StyleSheet.create({
	centerText: {
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-around"
	},
	margin: {
		marginHorizontal: Style.layout.marginContentSmall
	},
	column: {
		flexDirection: "column"
	}
});

export default class TrialExpired extends PureComponent {
	render(){
		var context = this.context;
		var dispatch = context.dispatch;
		var onLogout = dispatch("logout:trigger:logout");

		var localize = context.localize;
		var youratouchawaytrialperiodhasexpired = localize("trialexpired.youratouchawaytrialperiodhasexpired");
		var logout = localize("trialexpired.logout");

		return (
			<AtaContainer center>
				<MainScroll>
					<Card style={styles.column}>
						<View style={styles.centerText}>
							<StyledIcon smallHeading orange name="alert" />
							<StyledText bold primary smallHeading style={styles.margin}>{youratouchawaytrialperiodhasexpired}</StyledText>
							<StyledIcon smallHeading orange name="alert" />
						</View>
						<View style={styles.centerText}>
							<StyledButton title={logout} bold contrast large onPress={onLogout} />
						</View>
					</Card>
				</MainScroll>
			</AtaContainer>
		);
	}
}
TrialExpired.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import React, { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import StyledText from "ae-base/Base/StyledText";

import PropTypes from "prop-types";
import Style from "ae-style";
import PowerButtonNew from "./PowerButtonNew";
import { Dimensions } from "react-native";
import ATouchAwayConnectedHeader from "./ATouchAwayConnectedHeader";
import WorkflowContinueButton from "./WorkflowContinueButton";

var styles = StyleSheet.create({
	page: {},
	layout: {
		flexDirection: "row",
		alignSelf: "baseline",
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		backgroundColor: "rgb(57, 66, 69)"
	},
	smallLayout: {
		flexDirection: "row",
		alignSelf: "baseline",
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		backgroundColor: "rgb(57, 66, 69)"
	},
	textContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		flexGrow: 1,
		alignSelf: "stretch",
		alignItems: "stretch"
	},
	dateText: {
		flex: 1,
		padding: 12,
		alignSelf: "flex-end"
	},
	smallTextContainer: {
		height: "100%",
		flexDirection: "row",
		justifyContent: "center",
		flexGrow: 1,
		alignSelf: "stretch",
		alignItems: "center"
	},
	textEnd: {
		alignSelf: "flex-end"
	},
	rightLabel: {
		width: 32,
		backgroundColor: Style.colors.primary
	},
	timeText: {
		marginRight: 12
	},
	absoluteCenterHiMessage: {
		position: "absolute",
		height: "100%",
		width: "100%",
		alignItems: "center",
		justifyContent: "center"
	},
	smallHiMessage: {
		height: "100%",
		flexDirection: "row",
		justifyContent: "center",
		flexGrow: 1,
		alignSelf: "stretch",
		alignItems: "center"
	},
	hidePowerButton: {
		width: 0.01,
		overflow: "hidden"
	},
	smallHidePowerButton: {
		width: 0.01,
		overflow: "hidden",
		alignSelf: "flex-start"
	}
});

const MIN_WIDTH = 500;
export default class ATouchAwayInfoBar extends PureComponent {
	render() {
		var dispatch = this.context.dispatch;
		var localize = this.context.localize;
		var props = this.props;
		var model = props.model;
		var footer = model.footer;
		var headerRight = [];

		var calling =  model.calling;
		var settings = model.settings || {};

		var isCalling = calling.state === "connected";

		var personal = model.personal;
		var name = personal.fname;
		var hi = localize("atouchawayheader.hi");
		var sidebarWidth = props.sidebarWidth;
		var powerButtonWidth = sidebarWidth || Dimensions.get("window").width * 0.15;
		var hidePowerButton = settings.power_button === "none";
		var smallTablet = Dimensions.get("window").width - (hidePowerButton ? 0 : sidebarWidth) < MIN_WIDTH;
		var textSize =
			smallTablet
				? {
					largeHeading: true
				  }
				: {
					smallHeading: true
				  };

		if (model.workflowAction && model.workflowAction.collapsed) {
			headerRight = <WorkflowContinueButton action={model.workflowAction} size={textSize} style={{flex: 1, justifyContent: "center"}} />;
		} else {
			headerRight = (
				<View style={{justifyContent: "center"}} >
					<StyledText onPress={dispatch("about:trigger:open")} contrast bold emphasized style={styles.timeText}>
						{footer.time}
					</StyledText>
				</View>
			);
		}

		var powerButtonContainerStyles = [{width: powerButtonWidth}];
		if(hidePowerButton){
			powerButtonContainerStyles.push(smallTablet ? styles.smallHidePowerButton : styles.hidePowerButton);
		}
		var renderPowerButton =
			(<View style={powerButtonContainerStyles}>
				<PowerButtonNew settings={settings} />
			</View>);

		var hiMessagePadding = {
			paddingLeft: smallTablet ? 0 : sidebarWidth || 0
		};
		var hiMessageStyle = smallTablet ? [styles.smallHiMessage] : [styles.absoluteCenterHiMessage];

		if(!hidePowerButton){
			hiMessageStyle.push(hiMessagePadding);
		}
		var renderHiMessage = (
			<View style={hiMessageStyle}>
				<StyledText contrast bold smallHeading>
					{hi}{name}
					{"!"}
				</StyledText>
			</View>
		);

		if(isCalling) return (<ATouchAwayConnectedHeader incall={props.incall} calling={calling} />);

		return smallTablet ?
			(
				<View style={styles.smallLayout}>
					{renderPowerButton}
					{renderHiMessage}
					<View style={styles.smallTextContainer}>
						{headerRight}
					</View>
				</View>
			) : (
				<View style={styles.layout}>
					{renderHiMessage}
					{renderPowerButton}
					<View style={styles.textContainer}>
						<View />
						{headerRight}
					</View>
				</View>
			);
	}
}

ATouchAwayInfoBar.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

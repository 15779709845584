import PropTypes from "prop-types";
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Style from "@aetonix/styles";
import StyledText from "ae-base/Base/StyledText";
import ChangeValue from "./ChangeValue";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		styledTextFlex: {
			paddingVertical: Style.layout.paddingSmall / 2,
			paddingHorizontal: Style.layout.paddingSmall / 3,
		},
		cardItemBackground: {
			paddingHorizontal: Style.layout.paddingSmall,
			backgroundColor: Style.colors.itemLightGray,
		},
	},
	512: {
		styledTextFlex: {
			paddingVertical: Style.layout.paddingSmall * 2,
			paddingHorizontal: Style.layout.paddingSmall * 3,
		}
	}
});

export default function Path(props, context){
	var change = props.change;
	var path = change.path;
	var value = change.value;
	var op = change.op;
	var careplan = props.careplan;
	var simplePath = change.simplePath;

	var localize = context.localize;
	var addednewitemto = localize("proposal.addednewitemto");
	var set = localize("proposal.set");
	var removed = localize("proposal.removed");
	var changes_key = localize("proposal.careplan_changes." + simplePath);
	var careplan_changes = localize(changes_key);

	if(!careplan.flattened[path] && (typeof value === "object")){
		return (
			<View style={styles.cardItemBackground}>
				<View style={styles.styledTextFlex}>
					<StyledText bold>{addednewitemto}</StyledText>
				</View>
				<ChangeValue>{careplan_changes}</ChangeValue>
			</View>
		);
	} else {
		if(op === "replace" || op === "add"){
			return (
				<View style={styles.cardItemBackground}>
					<View style={styles.styledTextFlex}>
						<StyledText bold>{set}</StyledText>
					</View>
					<ChangeValue>{careplan_changes}</ChangeValue>
				</View>
			);
		}
		if(op === "remove"){
			var pathArray = path.split("/");
			var pathNumber = parseInt(pathArray.pop(), 10);
			var normalNumber = pathNumber + 1;
			var normalStr = normalNumber.toString();
			pathArray.push(normalStr);
			var normalPath = pathArray.join("/");
			return (
				<View style={styles.cardItemBackground}>
					<View style={styles.styledTextFlex}>
						<StyledText bold>{removed}</StyledText>
					</View>
					<ChangeValue>{normalPath}</ChangeValue>
				</View>
			);
		}
	}
}

Path.contextTypes = {
	localize: PropTypes.func
};

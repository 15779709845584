import PropTypes from "prop-types";
import React, {PureComponent} from "react";

import CareplanSection from "./Sections/Careplan";
import FormsSection from "./Sections/Forms";
import GeneralSection from "./Sections/General";
import HealthSection from "./Sections/Health";
import LegacySection from "./Sections/Legacy";
import UtilitiesSection from "./Sections/Utilities";

import ManagedSection from "../ManagedSection";

export default class aTouchAwaySettings extends PureComponent {
	constructor(props){
		super(props);

		this.state = {
			careplan: false,
			forms: false,
			general: true,
			utilities: false,
			health: false,
			legacy: false
		};

		this.toggleGeneral = toggle.bind(this, "general");
		this.toggleUtilities = toggle.bind(this, "utilities");
		this.toggleCareplan = toggle.bind(this, "careplan");
		this.toggleHealth = toggle.bind(this, "health");
		this.toggleForms = toggle.bind(this, "forms");
		this.toggleLegacy = toggle.bind(this, "legacy");
	}

	render(){
		var props = this.props;
		var context = this.context;
		var model = props.model;
		var atouchaway = model.manage.atouchaway || {};
		var contact = model.manage.account;
		var toast = model.toast;

		var localize = context.localize;
		var atouchawaysettings = localize("atouchawaysettings.atouchawaysettings");

		var loadedKey = Object.keys(atouchaway || {}).length;

		var hasCareplan = Object.keys(model.manage.allSections || {}).some(function(key) {
			return key.indexOf("careplan_") !== -1;
		}) || atouchaway.careplan;

		var renderCareplan = hasCareplan ? (
			<CareplanSection model={model} onToggle={this.toggleCareplan} display={this.state.careplan} />
		) : null;

		var renderLegacy = "legacy" in atouchaway ? (
			<LegacySection model={model} onToggle={this.toggleLegacy} display={this.state.legacy} />
		) : null;

		return (
			<ManagedSection online={model.online} key={loadedKey} contact={contact} title={atouchawaysettings} toast={toast}>
				<GeneralSection model={model} onToggle={this.toggleGeneral} display={this.state.general} />
				<UtilitiesSection model={model} onToggle={this.toggleUtilities} display={this.state.utilities} />
				<HealthSection model={model} onToggle={this.toggleHealth} display={this.state.health} />
				<FormsSection model={model} onToggle={this.toggleForms} display={this.state.forms} />
				{renderCareplan}
				{renderLegacy}
			</ManagedSection>
		);
	}
}

function toggle(key){
	var current = this.state[key];
	var update = {};
	update[key] = !current;
	this.setState(update);
}

aTouchAwaySettings.contextTypes = {
	localize: PropTypes.func
};
import React, {
	PureComponent
} from "react";
import {
	View,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";
import PropTypes from "prop-types";
import ListItem from "ae-base/List/ListItem";
import ListIcon from "ae-base/List/ListIcon";
import ListContent from "ae-base/List/ListContent";
import Style from "ae-style";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import { DATE_TIME_FORMAT } from "../../../utils/date-utils";

const styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		content: {
			flex: 1,
			flexDirection: "column",
			maxWidth: Style.layout.maxContent / 3,
		},
		icon: {
			marginVertical: Style.layout.marginSlim,
			marginHorizontal: Style.layout.marginSlim
		}
	},
	512: {
		content: {
			maxWidth: Style.layout.maxContent
		}
	}
});

export default class Submission extends PureComponent {
	render(){
		const props = this.props;
		const context = this.context;
		const dispatch = context.dispatch;
		const localize = context.localize;
		const localizeFrom = context.localizeFrom;
		const formatDate = context.formatDate;
		const submission = props.submission;
		const permission = props.permission;
		const type = props.type;
		const id = submission._id;
		const editor = submission.editHistory || [];
		const editorName = editor.length ?  editor[editor.length - 1 ] : null;
		let title;
		const schema = props.schema || {};
		if(editorName) {
			title = localize("groupforms.edit_by", editorName);
		}
		const locked = submission.locked;
		const updated_at = submission.updated_at;
		const format_date = formatDate(updated_at, DATE_TIME_FORMAT);

		const edittitle = localize("groupforms.edit");
		const viewtitle = localize("groupforms.view");
		let submissionTitle = "";
		if(schema.label)
			submissionTitle = parseTitle(submission.data, schema, localizeFrom);

		const renderSubmissionTitle = submissionTitle ? (
			<StyledText bold>{submissionTitle}</StyledText>
		) : null;

		const edit = type === "group" ? dispatch("activegroupformsubmission:trigger:update:start", {
			id: id
		}) : dispatch("activeownformsubmission:trigger:update:start", {
			id: id
		});

		const view = type === "group" ? dispatch("activegroupformsubmission:trigger:view", {
			id: id,
		}) : dispatch("activeownformsubmission:trigger:view", {
			id: id
		});

		const targetType = props.targetType;

		const canEdit = permission > 1 || targetType === "group" || targetType === "staff";
		let renderEditButton = (!canEdit || locked) ? null : (
			<StyledButton title={edittitle} icon="compose" onPress={edit} />
		);

		return(
			<ListItem >
				<ListContent>
					<View style={styles.icon}>
						<ListIcon name="notebook" />
					</View>
					<View style={styles.content}>
						{renderSubmissionTitle}
						<StyledText>{format_date}</StyledText>
						<StyledText bold>{title}</StyledText>
					</View>
				</ListContent>
				{renderEditButton}
				<ButtonSpacer />
				<StyledButton title={viewtitle} contrast onPress={view} />
			</ListItem>
		);
	}
}

function parseTitle(submissionData, schema, localizeFrom){
	const label = schema.label;
	const properties = schema.properties || {};

	const labelData = submissionData[label] || "";
	const labelSchema = properties[label] || {};
	const valueLocalization = getValueLocalization(labelSchema);

	if(labelSchema.type === "array" && Array.isArray(labelData))
		return labelData.reduce((acc, data, index) => {
			const terminator = index === 0 ? "" : " / ";
			acc += terminator + normalizeTitle(localizeFrom, valueLocalization, data);
			return acc;
		}, "");
	else return normalizeTitle(localizeFrom, valueLocalization, labelData);
}

function normalizeTitle(localizeFrom, value_localization, data){
	return localizeFrom(value_localization, data) || data || "";
}

function getValueLocalization(schema){
	if(schema.type === "array")
		return (schema.items || {}).value_localization || (schema.items || {}).valueLocalization || {};
	else
		return schema.value_localization || schema.valueLocalization || {};
}

Submission.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	formatDate: PropTypes.func,
	localizeFrom: PropTypes.func
};

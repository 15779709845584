import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "ae-style";
import ManagedSection from "../ManagedSection";
import StyledText from "ae-base/Base/StyledText";
import ListAdd from "ae-base/List/ListAdd";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";
import StyledButton from "ae-base/Base/StyledButton";
import ListItem from "ae-base/List/ListItem";
import ListContent from "ae-base/List/ListContent";

var styles = StyleSheet.create({
	more: {
		marginVertical: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center"
	}
});

export default function Workflow(props, context) {
	var model = props.model;
	var dispatch = context.dispatch;
	var localize = context.localize;

	var toast = model.toast;
	var manage = model.manage;
	var saving = model.saving;
	var group = model.activemanagegroup;
	var contact = manage.account;
	var workflow = manage.workflow;
	var ongoing = workflow.ongoing;
	var history = workflow.history.history;
	var managee = manage.account._id;
	var noMoreResults = workflow.history.noMoreResults;

	var workflowTitle = localize("workflow.workflow");

	var createButton = localize("workflow.create");
	var createTrigger = dispatch("managedcreateworkflow:trigger:create", {
		managee: managee,
		group: group
	});

	var renderCreateButton = (
		<ListAdd title={createButton} onPress={createTrigger} />
	);

	return (
		<ManagedSection online={model.online} contact={contact} title={workflowTitle} saving={saving} toast={toast}>
			<OngoingWorkflow ongoing={ongoing} group={group} />
			<ButtonSpacer />
			{renderCreateButton}
			<ButtonSpacer />
			<HistoryWorkflow history={history} group={group} noMoreResults={noMoreResults} />
		</ManagedSection>
	);
}

function OngoingWorkflow(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;
	var ongoingList = props.ongoing || [];
	var group = props.group;
	var ongoingTitle = localize("workflow.ongoingtitle");
	var viewButtonTitle = localize("workflow.viewbuttontitle");

	var renderOngoingTitle = ongoingList.length === 0 ? null : (
		<StyledText bold smallHeading>{ongoingTitle}</StyledText>
	);
	var renderOngoingList = ongoingList.map(function(data) {
		var label = data.label;
		var timeObj = new Date(data.updated_at);
		var workflowId = data._id;
		var month = timeObj.getMonth() + 1;
		var minutes =((timeObj.getMinutes() < 10 ? '0' : '') + timeObj.getMinutes());
		var readableTime = timeObj.getDate() + "/" + month + " @ " + timeObj.getHours() + ":" + minutes
		var onPress = dispatch("managedworkflow:trigger:details", {
			workflow: workflowId,
			group: group,
		});

		var action = (
			<StyledButton title={viewButtonTitle} onPress={onPress} />
		);

		var labelText = label ? (
			<StyledText bold>{label}</StyledText>
		) : null;

		return (
			<ListItem key={data._id}>
				<ListContent vertical>
					{labelText}
					<StyledText>{readableTime}</StyledText>
				</ListContent>
				{action}
			</ListItem>
		);
	});

	return (
		<View>
			{renderOngoingTitle}
			{renderOngoingList}
		</View>
	);
}

function HistoryWorkflow(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;
	var history = props.history || [];
	var group = props.group;
	var noMoreResults = props.noMoreResults;
	var historyTitle = localize("workflow.historytitle");
	var viewButtonTitle = localize("workflow.viewbuttontitle");
	var loadMoreTitle = localize("workflow.loadmore");

	var renderOngoingTitle = history.length === 0 ? null : (
		<StyledText bold smallHeading>{historyTitle}</StyledText>
	);

	var renderOngoingList = history.map(function(data) {
		var label = data.label;
		var workflowId = data._id;
		var timeObj = new Date(data.updated_at);
		var month = timeObj.getMonth() + 1;
		var minutes =((timeObj.getMinutes() < 10 ? '0' : '') + timeObj.getMinutes());
		var readableTime = timeObj.getDate() + "/" + month + " @ " + timeObj.getHours() + ":" + minutes;
		

		var onPress = dispatch("managedworkflow:trigger:details", {
			workflow: workflowId,
			history: true,
			group: group,
		});

		var action = (
			<StyledButton title={viewButtonTitle} onPress={onPress} />
		);

		const labelText = label ?
			(
				<StyledText bold>{label}</StyledText>
			)
			: null;

		return (
			<ListItem key={workflowId}>
				<ListContent vertical>
					{labelText}
					<StyledText>{readableTime}</StyledText>
				</ListContent>
				{action}
			</ListItem>
		);
	});

	// If there could be more results, pop the last one.
	// This is so that there will always be something to show when the user presses "Load More", even if they are at the end
	if (!noMoreResults && renderOngoingList.length > 1) {
		renderOngoingList.pop();
	}
	var loadMoreHistory = dispatch("managedhistoryworkflow:trigger:more");
	var renderLoadMoreButton = (!noMoreResults && renderOngoingList.length > 0) ? (
		<View style={styles.more}>
			<StyledButton title={loadMoreTitle} icon="dropdown" onPress={loadMoreHistory} />
		</View>
	) : null;

	return (
		<View>
			{renderOngoingTitle}
			{renderOngoingList}
			{renderLoadMoreButton}
		</View>
	);
}

Workflow.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

Workflow.propTypes = {
	model: PropTypes.object,
};

OngoingWorkflow.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

HistoryWorkflow.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";

import BasicState from "./BasicState";
import CallButton from "../CallButton";

export default class Outgoing extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;

		var localize = context.localize;

		var calling = props.calling;
		var who = calling.who;
		var message = localize("outgoing.messageCalling", who);
		var button = "endCall";

		var callgroup = props.callgroup;
		if(callgroup){
			message = localize("outgoing.callgroupCalling", callgroup);
			button = "callgroupEndCall";
		}

		return (
			<BasicState message={message}>
				<CallButton button={button} callgroup={callgroup} />
			</BasicState>
		);
	}
}

Outgoing.contextTypes = {
	localize: PropTypes.func
};

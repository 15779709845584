import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";

import Form from "ae-forms";

import LegacySchema from "../../../../../schemas/ATouchAwayLegacy";

import SettingSection from "../SettingSection";

export default function Legacy(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;
	var display = props.display;
	var model = props.model;
	var toggle = props.onToggle;

	var atouchaway = model.manage.atouchaway || {};

	var title = localize("atasettings.legacy") || "Legacy";

	var renderBody = display ? (
		<Form schema={LegacySchema} defaultData={atouchaway} onSubmit={dispatch("managedatouchaway:trigger:update")} />
	) : null;

	return (
		<View>
			<SettingSection section="legacy" title={title} display={display} onToggle={toggle} />
			{renderBody}
		</View>
	);
}

Legacy.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
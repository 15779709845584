/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import ListItem from "ae-base/List/ListItem";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import DialogContainer from "ae-base/Base/DialogContainer";

import Avatar from "../../Contacts/Avatar";
import Contact from "../../Contacts/Contact";
import Breakpoints from "../../../Breakpoints";
import MainScroll from "../../Layout/MainScroll";
import MainContent from "../../Layout/MainContent";
import PageContainer from "../../Layout/PageContainer";
import GroupFormHeader from "../GroupForms/GroupFormHeader";
import ManageGroups from "../Homepage/ManageGroups";

import UserCreationSearchSchema from "../../../schemas/UsersCreationSearch";

import Form from "ae-forms";

import Style from "ae-style";

import debounce from "debounce";
import BlockView from "ae-base/Base/BlockView";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";

var styles = StyleSheet.create({
	dialogItem: {
		backgroundColor: Style.colors.white,
		padding: Style.layout.paddingButton - 4,
		borderWidth: 1,
		borderColor: "rgba(0,0,0,0.1)",
		width: 250,
		justifyContent: "center",
		zIndex: 2
	},
	closeButton: {
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	moreButton: {
		alignItems: "center",
		flex: 1,
		margin: Style.layout.marginSmall
	},
	inviteContainer: {
		marginLeft: Style.layout.marginContentSmall,
		flexDirection: "column",
		flex: 1,
	},
	centerTextStyle: {
		justifyContent: "center"
	},
	button: {
		marginLeft: 5
	},
	confirmationEmphasis: {padding: 10,  margin: 15, marginTop: 0},
	confirmationContainer: {
		maxWidth: 800,
		padding: 24,
		backgroundColor: "white",
		alignItems: "center",
		marginHorizontal: 10
	},
	confirmationEmphasisContainer: {marginTop: 15},
	emphasisContainer: {marginVertical: 10, backgroundColor: Style.colors.tertlight}
});

function ConfirmationEmpahsis (props){
	const {title, content} = props;
	return (<View style={styles.confirmationEmphasisContainer}>
		<StyledText centered>{title}</StyledText>
		<View style={styles.confirmationEmphasis}>
			<StyledText centered bold>{content}</StyledText>
		</View>
	</View>);
}
export default class UsersList extends PureComponent {
	constructor(props){
		super(props);
		var model = props.model;
		var orgUsers = model.orgusers;
		var query = orgUsers.query;

		this.state = {
			search: query
		};
		this.onChange = debounce(onChange.bind(this), 100);
	}

	render(){
		var context = this.context;
		var props = this.props;
		var dispatch = context.dispatch;
		var localize = context.localize;
		var model = props.model;
		var toast = model.toast;
		var orgUsers = model.orgusers;
		var orgUserInvites = model.orguserinvites;
		var activeGroup = model.activegroupmanagegroup;
		var groups = model.groupmanagegroups;
		var groupSettings = model.groupsettings || {};
		var userList = orgUsers.list;
		var token = typeof orgUsers.token === "boolean" ? localize("userlist.loading") : orgUsers.token;
		var endOfList = orgUsers.end;

		var title = localize("userlist.title");

		var tokenText = localize("userlist.usertoken", {
			token: token
		});

		var renderTokenDialog = token ? (
			<DialogContainer>
				<DialogItem>
					<StyledText selectable>{tokenText}</StyledText>
					<View style={styles.closeButton}>
						<StyledButton title={localize("userlist.close")} clear onPress={dispatch("orgusers:trigger:closetoken")} />
					</View>
				</DialogItem>
			</DialogContainer>
		) : null;

		var renderMoreButton = endOfList ? null : (
			<View style={styles.moreButton}>
				<StyledButton title={localize("userlist.loadmore")} contrast onPress={dispatch("orgusers:trigger:more")} />
			</View>
		);

		var renderInvites = this.state.search ? null : (
			<InviteList users={orgUserInvites} />
		);

		var renderCreateButton = (
			<StyledButton title={localize("userlist.create")} icon={"compose"} onPress={dispatch("usercreation:trigger:view")} fullwidth style={styles.centerTextStyle} />
		);

		var renderControlConfirmation = null;

		if(orgUsers.initiateControlTarget){
			const confirmControl = dispatch("accountswitcher:trigger:switchAccount", {
				who: orgUsers.initiateControlTarget
			});
			const cancelControl = dispatch("orgusers:trigger:initiateControl", null);

			const targetUser = orgUsers.list.find((user)=>{
				return user._id  === orgUsers.initiateControlTarget;
			});

			let  userType = "Unknown";

			if (targetUser.type.indexOf("user:mobile") !== -1) {
				userType = "Mobile";
			} else if (targetUser.type.indexOf("atouchaway") !== -1) {
				userType = "aTouchAway";
			} else if (targetUser.type.indexOf("simplified") !== -1) {
				userType = "Simplified";
			}

			if(targetUser){
				renderControlConfirmation  = (<ConfirmationModal
					open={orgUsers.initiateControlTarget}
					title={localize("userlist.controlconfirmation.title")}
					titleIcon="warning"
					confirmText={localize("userlist.controlconfirmation.confirm")}
					cancelText={localize("userlist.controlconfirmation.cancel")}
					onConfirm={confirmControl}
					onCancel={cancelControl}
				>
					<View style={styles.confirmationContainer}>
						<StyledText bold centered>{
							localize("userlist.controlconfirmation.warningpassword")}</StyledText>
						<View style={styles.emphasisContainer}>
							<ConfirmationEmpahsis title={localize("userlist.controlconfirmation.loginas")} content={`${targetUser.lname}, ${targetUser.fname}`} />
							<ConfirmationEmpahsis title={localize("userlist.controlconfirmation.usertype")} content={localize(`usertypeenum.${userType}`)} />
						</View>
						<StyledText centered>{
							localize("userlist.controlconfirmation.guidechangetype")}
						</StyledText>
					</View>
				</ConfirmationModal>);
			}

		}
		return (
			<PageContainer toast={toast}>
				{renderTokenDialog}
				<MainScroll>
					<GroupFormHeader title={title} />
					<MainContent>
						{renderCreateButton}
						<ManageGroups title="managegroups.orgusers" placeholder="managegroups.all" trigger="activegroupmanagegroup:trigger:load" groups={groups} active={activeGroup} />
						<Form schema={UserCreationSearchSchema} defaultData={this.state} autoSubmit onSubmit={this.onChange} />
						{renderInvites}
						<ContactList users={userList} groupSettings={groupSettings} />
						{renderMoreButton}
					</MainContent>
				</MainScroll>
				{renderControlConfirmation}
			</PageContainer>
		);
	}
}

function ContactList(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;
	var contacts = props.users;
	var groupSettings = props.groupSettings;
	if (!contacts.length) return null;

	var renderedContacts = contacts.map(function(contact){
		var renderGetToken = null;
		var renderControl = null;
		var switchAccount = dispatch("orgusers:trigger:initiateControl", contact._id);
		const inactive = contact.inactive;

		if (contact.type.indexOf("user:mobile") === -1 || contact.type.indexOf("login:none") !== -1){
			renderGetToken = (
				<StyledButton title={localize("userlist.token")} onPress={dispatch("orgusers:trigger:token", contact._id)} />
			);
			renderControl = groupSettings.allowControl ? (<StyledButton title={localize("userlist.control")} onPress={switchAccount} style={styles.button} />) : null;
		}
		return (
			<Contact key={contact._id} subtitle={contact.patientNumber} contact={contact} inactiveUser={inactive} onPress={dispatch("usercreation:trigger:edit", contact)}>
				{!inactive && renderGetToken}
				{!inactive && renderControl}
			</Contact>
		);
	});
	return (
		<BlockView>
			{renderedContacts}
		</BlockView>
	);
}

function InviteList(props){
	var invites = props.users;
	if (!invites.length) return null;
	var renderedInvites = invites.map(function(invite){
		return (
			<Invite key={invite._id} invite={invite} />
		);
	});
	return (
		<BlockView>
			{renderedInvites}
		</BlockView>
	);
}

function DialogItem(props){
	var children = props.children;
	return (
		<View style={styles.dialogItem}>
			{children}
		</View>
	);
}

function Invite(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;
	var invite = props.invite || {};
	var email = invite.email;

	var sizeAvatar = "mini";
	if(Breakpoints.smallerThan("mobile"))
		sizeAvatar = "tiny";

	return (
		<ListItem>
			<Avatar size={sizeAvatar} />
			<View style={styles.inviteContainer}>
				<StyledText emphasized>{email}</StyledText>
			</View>
			<StyledButton alert title={localize("userlist.cancel")} onPress={dispatch("orguserinvites:trigger:cancel", invite._id)} />
		</ListItem>
	);
}

function onChange(data){
	var text = data.search;
	this.setState({
		search: text
	});
	this.context.dispatch("orgusers:trigger:search")(text);
}

UsersList.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

ContactList.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

Invite.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import React from "react";
import {
	StyleSheet
} from "react-native";
import PropTypes from "prop-types";

import Form from "ae-forms";

const styles = StyleSheet.create({
	text: {
		color: "#FFFFFF"
	}
});

export default function ManageGroups(props, context) {
	const {groups, active} = props;
	const {id_prefix, dispatch, localize} = context;

	const all = props.placeholder ? localize(props.placeholder) : localize("managegroups.uncategorized");

	if(!groups || !groups.length) return null;

	const enumLabel = groups.map(({name}) => name);
	const enumValue = groups.map(({_id})	=> _id);

	enumLabel.unshift(all);
	enumValue.unshift("all");

	const ManageGroupsSchema = {
		title: "ManageGroups",
		type: "object",
		properties: {
			group: {
				description: props.title,
				type: "string",
				enum: enumValue,
				enumLabel
			}
		}
	};

	const defaults = {
		group: active || "all"
	};

	const onSubmit = dispatch(props.trigger);

	var extraProps = {};
	if(props.contrast)
		extraProps.labelStyle = styles.text;

	return (
		<Form {...extraProps} contrast={props.contrast} id_prefix={id_prefix + "manageGroupsForm"} key={active} schema={ManageGroupsSchema} defaultData={defaults} autoSubmit onSubmit={onSubmit} />
	);
}

ManageGroups.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};


/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, April 25, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../../Breakpoints";
import PropTypes from "prop-types";
import NotificationIcon from "ae-base/Base/NotificationIcon";
import SquareButton from "ae-base/Base/SquareButton";
import Heading from "ae-base/Base/Heading";
import Style from "ae-style";

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignSelf: "center"
	},
	notification: {
		position: "absolute",
		top: 0,
		right: 0
	},
	green: {
		borderWidth: 2,
		borderColor: Style.colors.green
	},
	red: {
		borderWidth: 2,
		borderColor: Style.colors.red
	},
	yellow: {
		borderWidth: 2,
		borderColor: Style.colors.yellow
	}
});

var MAX_BUTTONS = 6;

var responsiveStyles = makeIncrements(MAX_BUTTONS);

export default function HealthSection(props, context){
	var dispatch = context.dispatch;
	const localize = context.localize;
	const localizeFrom = context.localizeFrom;
	var status = props.status || {};

	var title = props.title;
	var buttons = props.buttons || [];
	var person = props.person || {};
	var screen = props.screen;
	var updates = props.updates;
	var health_sections = props.health_sections || {};
	const customIndicatorsButtons = props.customIndicatorsButtons || [];

	var atouchawaysettings = props.atouchawaysettings || {};
	if(!buttons.length) return null;

	if (customIndicatorsButtons.length > 0) {
		buttons = [...buttons, ...customIndicatorsButtons];
	}

	let validButtons = buttons.filter(function (button) {
		var checkButton = button.type;
		var ignorePermission = button.ignorePermission;
		if (ignorePermission) return true;
		if (health_sections[checkButton]) {
			return health_sections[checkButton];
		}
		if (atouchawaysettings[checkButton]) {
			return atouchawaysettings[checkButton];
		}
	});

	if(!validButtons.length) return null;

	var renderedTitle = title  ? (
		<Heading title={title} screen={screen} />
	) : null;

	var renderedButtons = validButtons.map(function(button){
		var permissionName = button.permission;
		var trigger = button.trigger;
		var indicator = button.indicator;
		const isCustomIndicator = button.isCustomIndicator;

		var triggerPayload = {
			who: person._id
		};

		var buttonTitle = localize(button.title) || permissionName;
		var icon = button.icon;
		var action = dispatch(trigger, triggerPayload);
		let key = buttonTitle;

		if (isCustomIndicator) {
			buttonTitle = localizeFrom(button.title, ".") || localize("myhealth.indicator");
			action = dispatch(trigger, {
				indicatorId: indicator,
				indicatorUnit: button.unit
			});
			key = `${buttonTitle}_${indicator}`;
		}

		var textSize = 12;
		var iconSize = Style.imageSize.tiny;

		if(Breakpoints.smallerThan("small")) {
			iconSize = Style.imageSize.icon;
		}

		var notification = updates[permissionName] ? (
			<NotificationIcon style={styles.notification} title="!" />
		) : null;

		var buttonStatus = (status[indicator] || {}).status;

		if(indicator === "bloodoxygen"){
			var oxygenStatus = (status.oxygensaturation || {}).status;
			var heartStatus = (status.heartrate || {}).status;
			buttonStatus = oxygenStatus >= heartStatus ? oxygenStatus : heartStatus;
		}
		if(indicator === "bloodpressure"){
			var bpStatus = (status.bloodpressure || {}).status;
			var bpPulserateStatus = (status.bpPulserate || {}).status ?? -1;
			buttonStatus = Math.max(bpStatus, bpPulserateStatus);
		}

		var buttonStyle = [];
		if(buttonStatus === 0)
			buttonStyle = styles.green;
		if(buttonStatus === 1)
			buttonStyle = styles.yellow;
		if(buttonStatus === 2)
			buttonStyle = styles.red;

		if (buttonTitle.length >= 25 && context.language === "ta") textSize = 9;

		return (
			<SquareButton
				key={key}
				title={buttonTitle}
				textSize={textSize}
				style={buttonStyle}
				icon={icon}
				iconSize={iconSize}
				onPress={action}>
				{notification}
			</SquareButton>
		);
	});
	return (
		<View style={responsiveStyles.container}>
			{renderedTitle}
			<View style={styles.buttons}>
				{renderedButtons}
			</View>
		</View>
	);
}

HealthSection.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	language: PropTypes.string
};

function makeIncrements(buttons) {
	var i = buttons;
	var increment = Style.layout.squareButton + Style.layout.marginSmall;
	var definition = {};

	while(i){
		var size = increment * i;

		definition[size] = {
			container: {
				width: size
			}
		};

		i--;
	}

	return ResponsiveStylesheet.createSized("min-width", definition);
}


/* © 2021 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, September 17, 2021
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import PropTypes from "prop-types";
import HealthIndicator from "./HealthIndicator";

export default function MyCustomIndicatorMetrics(props, context) {
	const localize = context.localize;
	const localizeFrom = context.localizeFrom;

	const model = props.model;
	const myhealth = model.myhealth;
	const customIndicatorsList = model.customindicatorslist || [];
	const customIndicator = myhealth.customindicator || [];
	const currentViewingCustomIndicatorId = customIndicator.indicatorId;
	const currentViewingCustomIndicator = customIndicatorsList.find(indicator => indicator._id === currentViewingCustomIndicatorId) || {};
	const readings = customIndicator.cumulative || [];
	const reverseReadings = customIndicator.reverse || []; // needed for chart

	let today = null;
	const section = model.page;
	const pagetitle = localizeFrom(currentViewingCustomIndicator.localization, ".") || localize("myhealth.indicator");

	const unit = currentViewingCustomIndicator.unit || "";

	const textformat = "{value}";

	// Sets up the format to the 'today' button
	let graphData;
	if (readings.length) {
		graphData = adjust_time(reverseReadings, null).slice(-10); // for chart
		today = readings[0]?.history?.value;
	}

	return (
		<HealthIndicator
			model={model}
			section={section}
			textformat={textformat}
			units={unit}
			pagetitle={pagetitle}
			latestReading={today}
			reverseEntries={graphData}
			activeModel={customIndicator}
			xaxis={"x"}
			yaxis={"y"}
			managed
		/>
	);
}

function adjust_time(data, forBoxGraphic) {
	const adjusted = data.map((item) => {
		const time = time_label(item.updated_at, forBoxGraphic);
		const itemValue = item?.history?.value;
		const value = (typeof itemValue !== "number")
			? (parseFloat(itemValue) || 0)
			: itemValue;
		return {
			x: time,
			y: value
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return (parsed.month + "-" + parsed.day + "  " + parsed.time);
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}

MyCustomIndicatorMetrics.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};

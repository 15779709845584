import React from "react";
import PropTypes from "prop-types";

import ManagedSection from "../ManagedSection";
import DeviceAdd from "../../../Devices/DeviceAdd";

import StyledButton from "ae-base/Base/StyledButton";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import StyledText from "ae-base/Base/StyledText";

export default function DevicesUpdate(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;

	var model = props.model;
	var manage = model.manage;
	var account = manage.account;
	var permissions = manage.permissions;
	var updating = manage.devices.updating;
	var removing = manage.devices.removing;
	var toast = model.toast;

	if(!updating) return null;
	var canDelete = permissions.devices ? permissions.devices >= 2 : false;

	var onSubmit = dispatch("manageddevices:trigger:update:submit");
	var devicesTitle = localize("devices.edittitle");
	var defaultData = updating;

	var removeDevice = dispatch("manageddevices:trigger:remove:start", {
		device: updating._id
	});

	var deleteButton = canDelete ? (
		<StyledButton title={localize("devices.removebutton")} icon="delete" onPress={removeDevice} clear contrast />
	) : null;

	var backTitle = localize("devices.backTitle");

	var renderContent = (
		<ManagedSection online={model.online} title={devicesTitle} contact={account} toast={toast} back={backTitle} edit={deleteButton} >
			<DeviceAdd enumValue={[updating.model]} defaultData={defaultData} onSubmit={onSubmit} managed />
		</ManagedSection>
	);

	var confirmRemove = dispatch("manageddevices:trigger:remove:submit");
	var cancelRemove = dispatch("manageddevices:trigger:remove:cancel");

	if(removing)
		renderContent = (
			<ConfirmationModal
				open={removing}
				title={localize("devices.confirmremovetitle")}
				titleIcon="warning"
				confirmText={localize("devices.yesremove")}
				cancelText={localize("devices.cancelremove")}
				onConfirm={confirmRemove}
				onCancel={cancelRemove} >
				<StyledText contrast>{localize("devices.areyousure")}</StyledText>
			</ConfirmationModal>
		);

	return renderContent;
}

DevicesUpdate.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Style from "ae-style";
import StyledButton from "ae-base/Base/StyledButton";
import ManagedSection from "./ManagedSection";
import Heading from "ae-base/Base/Heading";
import Contact from "../../Contacts/Contact";

var styles = StyleSheet.create({
	contacts: {
		marginTop: Style.layout.marginLarge,
		marginBottom: Style.layout.marginContentVertical
	}
});

export default function BraceletContactPotentials(props, context) {
	var dispatch = context.dispatch;
	var model = props.model;
	var manage = model.manage || {};
	var toast = model.toast;
	var account = manage.account;
	var contactpotentials = model.manage.contacts || [];
	var braceletContact = model.manage.bracelet.contact;

	var localize = context.localize;
	var availablecontacts = localize("braceletpresscontact.availablecontacts");
	var set = localize("braceletpresscontact.set");
	var selected = localize("braceletpresscontact.selected");
	var nopotentialcontacts = localize("braceletpresscontact.nopotentialcontact");
	var addcontact = localize("braceletpresscontact.addcontact");

	var screen = model.size;

	var renderContact = (
		contactpotentials.map(function(contact){
			var id = contact._id;
			var addContact = dispatch("managedbracelet:trigger:contact", {
				who: id
			});
			if(id === braceletContact){
				return (
					<Contact contact={contact} key={id} screen={screen}>
						<StyledButton icon="pin" title={selected} white />
					</Contact>
				);
			}
			return (
				<Contact contact={contact} key={id} screen={screen}>
					<StyledButton icon="plus" title={set} secondary onPress={addContact} />
				</Contact>
			);
		})
	);

	var renderPotentials = contactpotentials.length ? (
		<View>
			<Heading title={availablecontacts} screen={screen} />
			<View style={styles.contacts}>
				{renderContact}
			</View>
		</View>
	) : (
		<Heading title={nopotentialcontacts} screen={screen} />
	);

	return (
		<ManagedSection online={model.online} contact={account} title={addcontact} toast={toast}>
			{renderPotentials}
		</ManagedSection>
	);
}

BraceletContactPotentials.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../Breakpoints";
import Style from "ae-style";

import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";
import Avatar from "../../Contacts/Avatar";
import BackButton from "ae-base/Base/BackButton";
import StyledButton from "ae-base/Base/StyledButton";
import CircleButton from "ae-base/Base/CircleButton";
import HeaderImage from "../../Effects/HeaderImage";
import ImageUrl from "ae-base/Base/ImageUrl";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		center: {
			alignItems: "center",
			justifyContent: "center"
		},
		avatar: {
			alignSelf: "center",
			marginVertical: Style.layout.marginContentVertical
		},
		pictureEdit: {
			position: "absolute",
			justifyContent: "center",
			top: 0,
			right: -(88 / 2),
			bottom: 0
		},
		backbutton: {
			flex: 1,
			marginLeft: Style.layout.paddingMedium
		},
		logoutbutton: {
			marginRight: 5,
			marginTop: 5,
		},
		buttoncontainer: {
			flex: 1,
			flexDirection: "row",
			justifyContent: "space-between",
			marginTop: 5,
			marginLeft: 5,
			marginRight: 5,
			alignItems: "center"
		},
		guide: {
			marginBottom: 10
		},
	},
	512: {
		buttoncontainer: {
			flex: 1,
			flexDirection: "row",
			justifyContent: "space-between",
			marginLeft: 0,
			marginTop: 0,
			marginRight: 0
		},
		avatar: {
			marginVertical: Style.layout.marginContentVertical
		},
		pictureEdit: {
			right: -(138 / 2)
		},
		backbutton: {
			paddingLeft: Style.layout.paddingMedium
		},
		logoutbutton: {
			marginRight: 0,
			marginTop: 0,
		}
	}
});

export default function ProfileHeader(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;
	var config = context.config;

	var preferences = props.preferences;

	var sizeAvatar = "large";
	var nameSize = "largeHeading";
	var guide = "emphasized";
	var backbuttonSize;
	var controlText;
	var controlSize;
	var iconSize;

	if (Breakpoints.smallerThan("mobileSmall")) {
		sizeAvatar = "small";
		controlSize = Style.layout.controlButtons;
		controlText = "verySmall";
		nameSize = "small";
		guide = "verySmall";
		backbuttonSize = "small";
		iconSize = "smallHeading";
	}
	if (Breakpoints.smallerThan("smallTablet")) {
		sizeAvatar = "medium";
		controlSize = Style.layout.controlButtons;
		controlText = "verySmall";
		nameSize = "smallHeading";
		guide = "small";
		backbuttonSize = "small";
		iconSize = "smallHeading";
	}

	var name = preferences.lname + ", " + preferences.fname;
	var backbutton = <StyledText contrast size={backbuttonSize}>{localize("profileheader.backbutton")}</StyledText>;
	var editpicture = <StyledText bolder black size={controlText}>{localize("profileheader.editpicture")}</StyledText>;
	var subtitle = localize("profileheader.subtitle");

	var openPictureEdit = dispatch("desktop:trigger:page", {
		page: "profile_picture"
	});

	var logoutButton = props.hasParent ? (
		<ReturnLogoutButton />
	) : (
		<LogoutButton />
	);

	return (
		<HeaderImage source={ImageUrl(preferences.image_medium, config)}>
			<View style={styles.buttoncontainer}>
				<View style={styles.backbutton}>
					<BackButton id={context.id_prefix + "backButton"} title={backbutton} />
				</View>
				<BlockView style={styles.logoutbutton}>
					{logoutButton}
				</BlockView>
			</View>
			<View style={[styles.center, styles.avatar]}>
				<Avatar person={preferences} size={sizeAvatar} />
				<View id={"profilepicchangebutton"} style={styles.pictureEdit}>
					<CircleButton title={editpicture}
						id={context.id_prefix + "openPicture"}
						icon="camera"
						onPress={openPictureEdit}
						diameter={controlSize}
						iconSize={iconSize} />
				</View>
			</View>
			<View style={styles.center}>
				<StyledText centered size={nameSize} bold contrast>{name}</StyledText>
				<BlockView style={styles.guide}>
					<StyledText size={guide} contrast centered>{subtitle}</StyledText>
				</BlockView>
			</View>
		</HeaderImage>
	);
}

function LogoutButton(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;
	var logout = dispatch("logoutconfirmmodal:trigger:show");

	var logoutTitle = localize("profileheader.logout");

	return (
		<BlockView>
			<StyledButton id={context.id_prefix + "logout"} title={logoutTitle} icon="close" alert onPress={logout} />
		</BlockView>
	);
}

function ReturnLogoutButton(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;

	var logoutTitle = localize("logoutreturn.logoutreturn");

	return (
		<BlockView>
			<StyledButton id={context.id_prefix + "returnLogout"} title={logoutTitle} icon="close" alert onPress={dispatch("accountswitcher:trigger:returnAccount")} />
		</BlockView>
	);
}

ProfileHeader.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func,
};

LogoutButton.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

ReturnLogoutButton.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
	Image,
} from "react-native";
import SquareGrid from "react-native-square-grid";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

var styles = StyleSheet.create({
	contactsContainer: {
		flex: 1,
		flexDirection: "row"
	},
	contact: {
		flex: 1,
		margin: Style.layout.marginSmall,
	},
	contactOffline: {
		borderWidth: 2,
		borderColor: "#FF0000",
	},
	contactImage: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	contactName: {
		alignItems: "center",
		backgroundColor: "rgba(0,0,0,0.77)",
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		padding: Style.layout.paddingSlim,
	},
	contactMessage: {
		backgroundColor: "rgba(0,0,0,0.77)",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		padding: Style.layout.paddingSlim,
	},
	pagingButton: {
		justifyContent: "center",
	},
	pagingButtonCircle: {
		margin: Style.layout.paddingSlim,
		width: Style.imageSize.mini,
		height: Style.imageSize.mini,
		borderRadius: Style.imageSize.mini / 2,
		backgroundColor: "#FFFFFF",
		justifyContent: "center",
		alignItems: "center",
		padding: 4,
	},
	pagingButtonDisabled: {
		opacity: 0.3,
	}
});

export default function Contacts(props) {
	var contacts = props.contacts;
	var column = contacts.columns || 1;
	var row = contacts.rows || 1;
	var list = contacts.list || [];

	if(row === "scroll")
		row = null;

	return (
		<View style={styles.contactsContainer}>
			<LoadPrevious contacts={contacts} />
			<SquareGrid columns={column} rows={row} items={list} renderItem={renderContact} />
			<LoadNext contacts={contacts} />
		</View>
	);
}

function LoadNext(props, context) {
	var dispatch = context.dispatch;
	var contacts = props.contacts;

	var loadNext = dispatch("contactspage:trigger:next");

	return (
		<PagingButton icon="navigateright" onPress={loadNext} disabled={!contacts.hasMore} />
	);
}

function LoadPrevious(props, context) {
	var dispatch = context.dispatch;
	var contacts = props.contacts;

	var loadPrevious = dispatch("contactspage:trigger:previous");

	return (
		<PagingButton icon="navigateleft" onPress={loadPrevious} disabled={!contacts.hasLess} />
	);
}

function PagingButton(props) {
	var onPress = props.onPress;
	var icon = props.icon;
	var disabled = props.disabled;

	var containerStyles = [
		styles.pagingButton
	];
	if(disabled) {
		containerStyles.push(styles.pagingButtonDisabled);
		onPress = null;
	}

	return (
		<TouchableOpacity style={containerStyles} onPress={onPress}>
			<View style={styles.pagingButtonCircle}>
				<StyledIcon name={icon} black largeHeading />
			</View>
		</TouchableOpacity>
	);
}

function renderContact(contact) {
	return (
		<Contact contact={contact} />
	);
}

function Contact(props, context) {
	var config = context.config;
	var dispatch = context.dispatch;

	var contact = props.contact;
	var id = contact._id;

	var name = contact.lname + ", " + contact.fname;
	var profileImage = (contact.image_medium || contact.image);

	var isAvailable = contact.isAvailable;
	var hasMessage = contact.hasMessage || {};
	var textMessage = hasMessage.content;
	var messageRead = hasMessage.read;

	var makeCall = isAvailable
		? dispatch("calling:trigger:call", {
			who: id,
			reason: "screen"
		})
		: dispatch("calling:trigger:silent", {
			who: id,
			reason: "screen"
		});

	var contactStyles = [styles.contact];
	var imageStyles = [styles.contactImage];
	if(!isAvailable) {
		contactStyles.push(styles.contactOffline);
		imageStyles.push({
			filter: "grayscale(100%)",
			WebkitFilter: "grayscale(100%)",
			opacity: 0.7,
		});
	}

	var renderMessage = messageRead ? (
		null
	) : (
		<View style={styles.contactMessage}>
			<StyledText emphasized contrast>{textMessage}</StyledText>
		</View>
	);

	return (
		<TouchableOpacity style={contactStyles} onPress={makeCall}>
			<Image source={ImageUrl(profileImage, config)} style={imageStyles} />
			<View style={styles.contactName}>
				<StyledText emphasized contrast>{name}</StyledText>
			</View>
			{renderMessage}
		</TouchableOpacity>
	);
}

LoadNext.contextTypes = {
	dispatch: PropTypes.func,
};

LoadPrevious.contextTypes = {
	dispatch: PropTypes.func,
};

Contact.contextTypes = {
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import React from "react";
import PropTypes from "prop-types";
import MyHealthComponent from "./MyHealthComponent";

export default function CustomIndicator(props, context) {
	var model = props.model;
	const localize = context.localize;
	const localizeFrom = context.localizeFrom;
	const customIndicatorsList = model.customindicatorslist;
	var customindicator = model.customindicator;

	const indicatorDefinition = customIndicatorsList?.find(indicator => indicator._id === customindicator?.indicatorId);

	var cumulative = customindicator.cumulative;
	var reverse_cumulative = customindicator.reverse; // needed for chart

	var today = null;
	var section = model.page;
	// Note: There is actually a invisble space at the end for formatting purposes
	var textformat = "{value}";

	// Sets up the format to the 'today' button
	if (cumulative.length) {
		var graphdata = adjust_time(reverse_cumulative).slice(-10); // for chart
		today = cumulative[0].history.value;
	}

	const indicatorUnit = indicatorDefinition?.unit;

	return (
		<MyHealthComponent
			model={model}
			section={section}
			textformat={textformat}
			latestReading={today}
			reverseEntries={graphdata}
			activeModel={customindicator}
			xaxis={"x"}
			yaxis={"y"}
			title={localizeFrom(indicatorDefinition?.localization ?? {}, ".") || localize("myhealth.indicator")}
			units={indicatorUnit}
		/>
	);
}

function adjust_time(data) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, false);
		var value = typeof item.history.value === "string" ? parseFloat(item.history.value) : item.history.value;
		return {
			x: time,
			y: value,
			width: 5
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return parsed.month + "-" + parsed.day + "  " + parsed.time;
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = data < 10 ? "0" + data : data;
	return item;
}

CustomIndicator.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};
import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet
} from "react-native";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

import Style from "ae-style";

var styles = StyleSheet.create({
	text_style: {
		color: Style.colors.white,
		paddingRight: Style.layout.paddingSmall,
		justifyContent: "center",
	},
	button_container: {
		marginLeft: Style.layout.marginSmall,
		justifyContent: "center"
	},
	text_container: {
		flex: 1
	}
});

export default class Unverified extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var dispatch = context.dispatch;
		var personal = props.personal;
		var reverify = props.reverify;
		var email = personal.email;
		var reverifySent = reverify.sent;
		var color;

		var localize = context.localize;

		color = Style.colors.primary;
		var resend = localize("unverified.resend");
		var content = localize("unverified.content") + email;

		var onReverify = dispatch("desktop:trigger:reverify");

		var sendButton = !reverifySent ? <StyledButton id={"resend"} white title={resend} onPress={onReverify} /> : null ;

		return (
			<Notification
				color={color}
				iconColor={"white"}>
				<View style={styles.text_container}>
					<StyledText style={styles.text_style} emphasized>
						{content}
					</StyledText>
				</View>
				<View style={styles.button_container}>
					{sendButton}
				</View>
			</Notification>
		);
	}
}

Unverified.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

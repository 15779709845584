/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet
} from "react-native";
import PropTypes from "prop-types";

import GroupFormHeader from "./GroupFormHeader";
import OutputForm from "ae-forms/src/Outputs/OutputForm";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import MainContent from "../../Layout/MainContent";
import StyledButton from "ae-base/Base/StyledButton";
import { DATE_TIME_FORMAT } from "../../../utils/date-utils";

const styles = StyleSheet.create({
	editButton: {
		alignSelf: "flex-end",
		marginTop: 4
	}
});

export default function ActiveStaffFormSubmission(props, context){
	const model = props.model;
	const submission = props.submission;
	const singleForm = props.singleForm;
	const dispatch = context.dispatch;
	const localize = context.localize;
	const formatDate = context.formatDate;

	const activeownformsubmission = model.activeownformsubmission || {};
	const forms_sections = (model.atouchawaysettings || {}).forms_sections || {};
	const ownform = model.ownformsubmissions.form;
	const ownformlist = model.ownformlist;

	const currentform = ownformlist[ownform];
	const localizeFrom = context.localizeFrom;
	const localization = currentform.localization;
	const title = localizeFrom(localization, ".") || currentform.name || currentform._id;

	const currentSubmission = submission || activeownformsubmission;

	const locked = currentSubmission.locked;
	const defaultData = currentSubmission.data;

	const editedHistory = currentSubmission.editHistory || [];
	const lastEdit = editedHistory.length ? editedHistory[editedHistory.length - 1] : null;
	const editedBy = singleForm ? (lastEdit ? localize("groupforms.edit_by", lastEdit) : null) : null;

	const date = singleForm && lastEdit ? formatDate(lastEdit.updated_at, DATE_TIME_FORMAT) : null;

	const schema = ownformlist[ownform].schema;

	const edittitle = localize("groupforms.edit");

	const edit = dispatch("activeownformsubmission:trigger:update:start", {
		id: currentSubmission._id
	});

	const renderEditButton = (singleForm && !locked) ? (
		<StyledButton title={edittitle} icon="compose" onPress={edit} clear contrast style={styles.editButton} />
	) : null;

	const permission = typeof forms_sections[ownform] === "boolean" ? (forms_sections[ownform] ? 2 : 0) : forms_sections[ownform];
	const canWrite = permission > 1 ||  permission < -1;
	if (!canWrite)
		renderEditButton = null;

	return (
		<PageContainer>
			<MainScroll>
				<GroupFormHeader title={title} edit={renderEditButton} subTitle={editedBy} additionalTitles={[date]} />
				<MainContent>
					<OutputForm schema={schema} data={defaultData} />
				</MainContent>
			</MainScroll>
		</PageContainer>
	);
}

ActiveStaffFormSubmission.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	formatDate: PropTypes.func
};

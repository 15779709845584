/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import TabBar from "../../Layout/TabBar";
import PropTypes from "prop-types";

var HOME = {
	id: "home",
	action: "desktop:trigger:page:home",
	icon: "home",
	label: "Home"
};

var GROUP_FORMS = {
	id: "group_forms",
	action: "desktop:trigger:page",
	actionData: {
		page: "group_forms"
	},
	icon: "briefcase",
	label: "Organization"
};

var MY_HEALTH = {
	id: "my_health",
	action: "desktop:trigger:page",
	actionData: {
		page: "my_health"
	},
	icon: "heartbeat",
	label: "Health"
};

var CREATION_TOKEN = {
	id: "creation_token",
	action: "orgusers:trigger:view",
	actionData: {},
	icon: "briefcase",
	label: "Creation/Token"
};

var TAB_DATA = [HOME];

export default function HomeTabs(props, context){
	var localize = context.localize;
	var ownforms = props.ownforms || {};
	var groupforms = props.groupforms || {};
	var groupsettings = props.groupsettings || {};
	var hideCreationToken = groupsettings?.hideCreationToken || false;

	GROUP_FORMS.label = localize("homepagetabs.group_forms");
	MY_HEALTH.label = localize("homepagetabs.my_health");
	HOME.label = localize("homepagetabs.home");
	CREATION_TOKEN.label = localize("userlist.title");

	var page = props.page;
	var userType = props.userType;

	var mobileUser = userType.indexOf("user:mobile") !== -1;
	var staffUser = userType.indexOf("staff") !== -1;

	var ownKeys = Object.keys(ownforms).length;
	var groupKeys = Object.keys(groupforms).length;

	var isOnlyOne = ownKeys === 0 && groupKeys === 0 && staffUser;
	var organizationTab = isOnlyOne ? CREATION_TOKEN : GROUP_FORMS;

	if(staffUser && mobileUser) {
		TAB_DATA =  isOnlyOne && hideCreationToken ? [HOME, MY_HEALTH] : [HOME, organizationTab, MY_HEALTH];
	} else if(staffUser) {
		TAB_DATA =  isOnlyOne && hideCreationToken ? [HOME] : [HOME, organizationTab];
	} else if(mobileUser) {
		TAB_DATA = [HOME, MY_HEALTH];
	}

	if(TAB_DATA.length < 2) return null;

	return (
		<TabBar active={page} tabs={TAB_DATA} />
	);
}

HomeTabs.contextTypes = {
	localize: PropTypes.func
};
import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet
} from "react-native";

import AutoReplySchema from "../../../../schemas/AutoReply";
import Form from "ae-forms";
import StyledButton from "ae-base/Base/StyledButton";
import InputLabel from "ae-forms/src/Inputs/Layout/InputLabel";
import Divider from "../../../Layout/Divider";
import Style from "ae-style";
import BlockView from "ae-base/Base/BlockView";

var styles = StyleSheet.create({
	divider: {
		marginTop: Style.layout.marginContentSmall
	}
});

export default function AutoReply(props, context){
	var dispatch = context.dispatch;
	var autoReply = props.autoreply;
	var localize = context.localize;

	var buttonclear = localize("autoreply.buttonclear");

	var autoReplyData = {
		autoreply: autoReply || ""
	};

	var title = autoReply ? (
		<StyledButton contrast id={context.id_prefix + "clearButton"} title={buttonclear} onPress={dispatch("autoreply:trigger:clear")} />
	) : null;

	return (
		<BlockView>
			<InputLabel schema={AutoReplySchema} />
			{title}
			<Form id_prefix={context.id_prefix + "autoReplyForm"} key={autoReply} schema={AutoReplySchema} defaultData={autoReplyData} onSubmit={dispatch("autoreply:trigger:update")} />
			<BlockView style={styles.divider}>
				<Divider />
			</BlockView>
		</BlockView>
	);
}

AutoReply.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";

import CardPage from "../../../Layout/CardPage";
import Form from "ae-forms";
import StyledText from "ae-base/Base/StyledText";
import ScheduleOnOff from "../../../../schemas/ScheduleOnOff";

var DEFAULT_SETTINGS = {
	time: {
		hours: "0",
		minutes: "0",
	},
	action: "offline",
	day: 0,
	months: new Date().toJSON()
};

export default class AddSchedule extends PureComponent {
	render(){
		var context = this.context;
		var dispatch = context.dispatch;
		var localize = context.localize;

		var screen = this.props.screen;

		var createSchedule = dispatch("schedulemaker:trigger:add");
		var goBack = dispatch("schedules:trigger:modify");
		var discardchanges = localize("addschedule.discardchanges");
		var scheduleTitle = localize("addschedule.scheduleTitle");

		return (
			<CardPage screen={screen} online={this.props.online}>
				<StyledText bold large>{scheduleTitle}</StyledText>
				<Form id_prefix={context.id_prefix + "addSchedule"} schema={ScheduleOnOff} defaultData={DEFAULT_SETTINGS} onSubmit={createSchedule} onCancel={goBack} cancelText={discardchanges} />
			</CardPage>
		);
	}
}

AddSchedule.contextTypes = {
	id_prefix: PropTypes.string,
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

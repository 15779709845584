import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

var getName = prop("Name");
var getRole = prop("Role");
var getOrganizationName = prop("OrganizationName");
var getPhone = prop("Phone");
var getIsRegularCareTeamMember = prop("IsRegularCareTeamMember");
var getIsLeadCoordinator = prop("IsLeadCoordinator");
var getIsHomeCaregiver = prop("IsHomeCaregiver");
var getFeeling = prop("HomeCaregiverStatus.Name");

export default function Careteam(props, context) {
	var careplan = props.careplan;

	var localize = context.localize;
	var careteammemberinformation = localize("careteam.careteammemberinformation");
	var careteam = localize("careteam.careteam");
	var thepeopleirelyonathomearefeeling = localize("careteam.thepeopleirelyonathomearefeeling");

	var careTeamMember = careplan.CareTeamMember || [];

	var renderTeamMember = careTeamMember.map(function(member, index){
		return (
			<CareteamMember key={index} member={member} index={index} />
		);
	});

	return (
		<Section title={careteam}>
			<Title title={careteammemberinformation} />
			{renderTeamMember}
			<Item title={thepeopleirelyonathomearefeeling} value={getFeeling(careplan)} />
		</Section>
	);
}

class CareteamMember extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var member = props.member;

		var localize = context.localize;
		var name = localize("careteam.name");
		var roleorrelationship = localize("careteam.roleorrelationship");
		var organizationname = localize("careteam.organizationname");
		var telephonenumber = localize("careteam.telephonenumber");
		var regularcareteammember = localize("careteam.regularcareteammember");
		var leadcarecoordinator = localize("careteam.leadcarecoordinator");
		var irelyonmostathome = localize("careteam.irelyonmostathome");

		return (
			<Group>
				<Item title={name} value={getName(member)} />

				<Item title={roleorrelationship} value={getRole(member)} />

				<Item title={organizationname} value={getOrganizationName(member)} />

				<Item title={telephonenumber} value={getPhone(member)} />

				<Item title={regularcareteammember} value={getIsRegularCareTeamMember(member)} />

				<Item title={leadcarecoordinator} value={getIsLeadCoordinator(member)} />

				<Item title={irelyonmostathome} value={getIsHomeCaregiver(member)} />
			</Group>
		);
	}
}

Careteam.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

CareteamMember.contextTypes = {
	localize: PropTypes.func
};

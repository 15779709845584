import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";
import prop from "prop";

import Link from "ae-base/Base/Link";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";

var getGivenNames = prop("GivenNames");
var getPreferredName = prop("PreferredName");
var getSurname = prop("Surname");
var getDateOfBirth = prop("DateOfBirth");
var getEmail = prop("Email");
var getDependants = prop("Dependants");
var getReligion = prop("Religion");
var getGender = prop("Gender.Name");
var getHealthLink = prop("HealthLink.Name");
var getChrisClientGuid = prop("ChrisClientGuid");
var getChrisClientNumber = prop("ChrisClientNumber");
var getAddress = prop("Address.AddressFreeLine");
var getCity = prop("Address.City");
var getProvince = prop("Address.Province.Name");
var getPostal = prop("Address.PostalCode");
var getPrimaryPhone = prop("PrimaryPhone.Number");
var getAlternatePhone = prop("AlternatePhone.Number");
var getPreferredContactBy = prop("PreferredContactBy.Name");
var getHealthCard = prop("HealthCard.Hcn");
var getHealthCardIssuedBy = prop("IssuedBy");
var getHcnVersion = prop("HealthCard.HcnVersion");
var getHealthCardReason = prop("HealthCard.Reason.Name");
var getFirstLanguage = prop("FirstLanguage.Name");
var getPreferredLanguage = prop("OfficialLanguage");
var getEthnicity = prop("Ethnicity.Name");
var getMaritalStatus = prop("MaritalStatus.Name");
var getResidenceType = prop("ResidenceType.Name");
var getLivingArrangement = prop("LivingArrangement.Name");
var getPrimaryContactFirstName = prop("PrimaryContact.FirstName");
var getPrimaryContactSurname = prop("PrimaryContact.Surname");
var getPrimaryContactRelationship = prop("PrimaryContact.Relationship");
var getPrimaryContactPhone = prop("PrimaryContact.Phone.Number");
var getEmergencyContactFirstName = prop("EmergencyContact.FirstName");
var getEmergencyContactSurname = prop("EmergencyContact.Surname");
var getEmergencyContactRelationship = prop("EmergencyContact.Relationship");
var getEmergencyContactPhone = prop("EmergencyContact.Phone.Number");
var getSubstituteDecisionMakerFirstName = prop("SubstituteDecisionMaker.FirstName");
var getSubstituteDecisionMakerSurname = prop("SubstituteDecisionMaker.Surname");
var getSubstituteDecisionMakerRelationship = prop("SubstituteDecisionMaker.Relationship");
var getSubstituteDecisionMakerPhone = prop("SubstituteDecisionMaker.Phone.Number");

export default function Client(props, context) {
	var careplan = props.careplan;
	var permissions = props.permissions;
	var changes = props.changes;

	var client = careplan || [];
	var hasPermission = permissions >= 2;

	var localize = context.localize;
	var givenname = localize("client.givenname");
	var preferredname = localize("client.preferredname");
	var surname = localize("client.surname");
	var gender = localize("client.gender");
	var dateofbirth = localize("client.dateofbirth");
	var healthlink = localize("client.healthlink");
	var chrisclientguid = localize("client.chrisclientguid");
	var chrisclientnumber = localize("client.chrisclientnumber");
	var address = localize("client.address");
	var city = localize("client.city");
	var province = localize("client.province");
	var postalcode = localize("client.postalcode");
	var telephone = localize("client.telephone");
	var alternatetelephone = localize("client.alternatetelephone");
	var emailaddress = localize("client.emailaddress");
	var preferredcontactby = localize("client.preferredcontactby");
	var healthcardversion = localize("client.healthcardversion");
	var healthcardnumber = localize("client.healthcardnumber");
	var healthcardissuedby = localize("client.healthcardissuedby");
	var healthcardreason = localize("client.healthcardreason");
	var mothertougue = localize("client.mothertougue");
	var preferredlanguage = localize("client.preferredlanguage");
	var ethnicity = localize("client.ethnicity");
	var religion = localize("client.religion");
	var maritalstatus = localize("client.maritalstatus");
	var whereicurrentlylive = localize("client.whereicurrentlylive");
	var peoplewholivewithme = localize("client.peoplewholivewithme");
	var peoplewhodependonme = localize("client.peoplewhodependonme");
	var primarycontact = localize("client.primarycontact");
	var emergencycontact = localize("client.emergencycontact");
	var substitutedecisionmaker = localize("client.substitutedecisionmaker");

	return (
		<View>
			<Items hasChanged={hasPermission && changes.GivenNames} data={"GivenNames"}>
				<Item>
					<Label>{givenname}</Label>
					<Value>{getGivenNames(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.PreferredName} data={"PreferredName"}>
				<Item alternate>
					<Label>{preferredname}</Label>
					<Value>{getPreferredName(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.Surname} data={"Surname"}>
				<Item>
					<Label>{surname}</Label>
					<Value>{getSurname(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.Gender} data={"Gender.Name"}>
				<Item alternate>
					<Label>{gender}</Label>
					<Value>{getGender(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.DateOfBirth} data={"DateOfBirth"}>
				<Item>
					<Label>{dateofbirth}</Label>
					<Value>{getDateOfBirth(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.HealthLink} data={"HealthLink.Name"}>
				<Item alternate>
					<Label>{healthlink}</Label>
					<Value>{getHealthLink(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.ChrisClientGuid} data={"ChrisClientGuid"}>
				<Item>
					<Label>{chrisclientguid}</Label>
					<Value>{getChrisClientGuid(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.ChrisClientNumber} data={"ChrisClientNumber"}>
				<Item alternate>
					<Label>{chrisclientnumber}</Label>
					<Value>{getChrisClientNumber(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["Address.AddressFreeLine"]} data={"Address.AddressFreeLine"}>
				<Item>
					<Label>{address}</Label>
					<Value>{getAddress(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["Address.City"]} data={"Address.City"}>
				<Item alternate>
					<Label>{city}</Label>
					<Value>{getCity(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["Address.Province.Name"]} data={"Address.Province.Name"}>
				<Item>
					<Label>{province}</Label>
					<Value>{getProvince(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["Address.PostalCode"]} data={"Address.PostalCode"}>
				<Item alternate>
					<Label>{postalcode}</Label>
					<Value>{getPostal(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["PrimaryPhone.Number"]} data={"PrimaryPhone.Number"}>
				<Item>
					<Label>{telephone}</Label>
					<Value>
						<Link title={getPrimaryPhone(client)} uri={"tel:" + getPrimaryPhone(client)} />
					</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["AlternatePhone.Number"]} data={"AlternatePhone.Number"}>
				<Item alternate>
					<Label>{alternatetelephone}</Label>
					<Value>
						<Link title={getAlternatePhone(client)} uri={"tel:" + getAlternatePhone(client)} />
					</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.Email} data={"Email"}>
				<Item>
					<Label>{emailaddress}</Label>
					<Value>
						<Link title={getEmail(client)} uri={"mailto:" + getEmail(client)} />
					</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["PreferredContactBy.Name"]} data={"PreferredContactBy.Name"}>
				<Item alternate>
					<Label>{preferredcontactby}</Label>
					<Value>{getPreferredContactBy(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["HealthCard.HcnVersion"]} data={"HealthCard.HcnVersion"}>
				<Item>
					<Label>{healthcardversion}</Label>
					<Value>{getHcnVersion(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["HealthCard.Hcn"]} data={"HealthCard.Hcn"}>
				<Item alternate>
					<Label>{healthcardnumber}</Label>
					<Value>{getHealthCard(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["HealthCard.Reason.Name"]} data={"HealthCard.Reason.Name"}>
				<Item>
					<Label>{healthcardreason}</Label>
					<Value>{getHealthCardReason(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["IssuedBy"]} data={"IssuedBy"}>
				<Item alternate>
					<Label>{healthcardissuedby}</Label>
					<Value>{getHealthCardIssuedBy(client)}</Value>
				</Item>
			</Items>
			<Items hasChanged={hasPermission && changes["FirstLanguage.Name"]} data={"FirstLanguage.Name"}>
				<Item>
					<Label>{mothertougue}</Label>
					<Value>{getFirstLanguage(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["OfficialLanguage"]} data={"OfficialLanguage"}>
				<Item alternate>
					<Label>{preferredlanguage}</Label>
					<Value>{getPreferredLanguage(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["Ethnicity.Name"]} data={"Ethnicity.Name"}>
				<Item>
					<Label>{ethnicity}</Label>
					<Value>{getEthnicity(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.Religion} data={"Religion"}>
				<Item alternate>
					<Label>{religion}</Label>
					<Value>{getReligion(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["MaritalStatus.Name"] || ""} data={"MaritalStatus.Name"}>
				<Item>
					<Label>{maritalstatus}</Label>
					<Value>{getMaritalStatus(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["ResidenceType.Name"] || ""} data={"ResidenceType.Name"}>
				<Item alternate>
					<Label>{whereicurrentlylive}</Label>
					<Value>{getResidenceType(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes["LivingArrangement.Name"] || ""} data={"LivingArrangement.Name"}>
				<Item>
					<Label>{peoplewholivewithme}</Label>
					<Value>{getLivingArrangement(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.Dependants} data={"Dependants"}>
				<Item alternate>
					<Label>{peoplewhodependonme}</Label>
					<Value>{getDependants(client)}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.PrimaryContact} data={"PrimaryContact"}>
				<Item>
					<Label>{primarycontact}</Label>
					<Value parallel>{getPrimaryContactFirstName(client)}  {getPrimaryContactSurname(client)}</Value>
					<Value parallel>{getPrimaryContactRelationship(client)}</Value>
					<Value parallel>
						<Link title={getPrimaryContactPhone(client)} uri={"tel:" + getPrimaryContactPhone(client)} />
					</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.EmergencyContact} data={"EmergencyContact"}>
				<Item alternate>
					<Label>{emergencycontact}</Label>
					<Value parallel>{getEmergencyContactFirstName(client)} {getEmergencyContactSurname(client)}</Value>
					<Value parallel>{getEmergencyContactRelationship(client)}</Value>
					<Value parallel>
						<Link title={getEmergencyContactPhone(client)} uri={"tel:" + getEmergencyContactPhone(client)} />
					</Value>
				</Item>
			</Items>

			<Items hasChanged={hasPermission && changes.SubstituteDecisionMaker} data={"SubstituteDecisionMaker"}>
				<Item>
					<Label>{substitutedecisionmaker}</Label>
					<Value parallel>{getSubstituteDecisionMakerFirstName(client)} {getSubstituteDecisionMakerSurname(client)}</Value>
					<Value parallel>{getSubstituteDecisionMakerRelationship(client)}</Value>
					<Value parallel>
						<Link title={getSubstituteDecisionMakerPhone(client)} uri={"tel:" + getSubstituteDecisionMakerPhone(client)} />
					</Value>
				</Item>
			</Items>
		</View>
	);

}

Client.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

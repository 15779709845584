import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	View,
	Dimensions,
} from "react-native";

import SimpleState from "../SimpleState";
import SquareButton from "../../SquareButton";
import AtaContainer from "../../AtaContainer";
import StyledText from "ae-base/Base/StyledText";

var styles = StyleSheet.create({
	outer: {
		padding: 24,
		backgroundColor: "white",
		borderRadius: 24,
		alignSelf: "center",
	},
	controls: {
		flexDirection: "row",
		justifyContent: "center"
	},
	callActionContainer: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "stretch"
	}
});

export default function Outgoing(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;
	var who = props.who;
	var urgency = props.urgency;
	var callgroup = props.callgroup;
	var isUrgencyActive = urgency.active;
	var isUrgencyCancelling = urgency.cancelling;
	var cancel = localize("atouchawaycalling.cancel");

	if(isUrgencyCancelling){
		var acceptCancel = dispatch("urgencycalls:trigger:cancel:accept");
		var denyCancel = dispatch("urgencycalls:trigger:cancel:deny");
		var acceptCancelText = localize("atouchawaycalling.acceptCancelText");
		var denyCancelText = localize("atouchawaycalling.denyCancelText");
		var cancelText = localize("atouchawaycalling.cancelText");

		var dimensions = Dimensions.get("window");

		var outerStyles = [styles.outer, {
			maxWidth: dimensions.width / 10 * 8,
		}];

		var buttonSize =
			(dimensions.width < 400 || dimensions.height < 400) ? {tiny: true} :
				(dimensions.width < 700 || dimensions.height < 600) ? {tiny: true} :
					null;

		return (
			<AtaContainer center>
				<View style={outerStyles}>
					<StyledText bold primary large>{cancelText}</StyledText>
					<View style={styles.controls}>
						<SquareButton title={acceptCancelText} icon="like" onPress={acceptCancel} contrast secondary {...buttonSize} />
						<SquareButton title={denyCancelText} icon="dislike" onPress={denyCancel} contrast highlight {...buttonSize} />
					</View>
				</View>
			</AtaContainer>
		);
	}

	var message = localize("atouchawaycalling.calling", who);

	var cancelCall = isUrgencyActive ? (
		dispatch("urgencycalls:trigger:cancel:start")
	) : (
		dispatch("calling:trigger:hangup", {
			reason: "Cancelled"
		})
	);

	var icon = isUrgencyActive ? "plus" : "aeto-hang_up-2";

	if(callgroup){
		who = callgroup;
		message = localize("callgroups.calling", callgroup);
		cancelCall = dispatch("callgroupscalling:trigger:cancel");
	}

	return (
		<SimpleState who={who} message={message}>
			<View style={styles.callActionContainer}>
				<SquareButton title={cancel} icon={icon} onPress={cancelCall} small contrast highlight />
			</View>
		</SimpleState>
	);
}

Outgoing.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

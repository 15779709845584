import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Link from "ae-base/Base/Link";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import SectionTitle from "../SectionTitle";
import GroupItems from "../GroupItems";

var getProvidedServices = prop("ProvidedServices");
var getContactName = prop("ContactName");
var getPhone = prop("Phone");
var getEmail = prop("Email");
var getStartDate = prop("StartDate");
var getOrganization = prop("Organization.Name");

export default function Supports(props, context) {
	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var localize = context.localize;
	var supportsandservicesinformation = localize("supports.supportsandservicesinformation");

	var communitySupport = careplan.CommunitySupport || [];

	var renderCommunitySupport = communitySupport.map(function(support, index) {
		var isChanged = changes["CommunitySupport." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<Communitysupport key={index} support={support} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	}
	);

	return (
		<View>
			<SectionTitle name={supportsandservicesinformation} section="CommunitySupport" permissions={permissions} changes={changes} />
			{renderCommunitySupport}
		</View>
	);
}

class Communitysupport extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var support = props.support;
		var index = props.index;
		var currentSection = props.currentSection;
		var hasChanged = props.hasChanged;
		var showCareplanDetail = props.showCareplanDetail;
		var ownCareplan = props.ownCareplan;

		var localize = context.localize;
		var organizationname = localize("supports.organizationname");
		var servicesprovided = localize("supports.servicesprovided");
		var contactname = localize("supports.contactname");
		var telephonenumber = localize("supports.telephonenumber");
		var emailaddress = localize("supports.emailaddress");
		var startdate = localize("supports.startdate");

		return (
			<GroupItems>
				<Items hasChanged={hasChanged} data={"CommunitySupport." + index}>
					<Item>
						<Label>{organizationname}</Label>
						<Value>{getOrganization(support)}</Value>
					</Item>

					<Item alternate>
						<Label>{servicesprovided}</Label>
						<Value>{getProvidedServices(support)}</Value>
					</Item>

					<Summarized currentSection={currentSection} section={"CommunitySupport." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
						<Item>
							<Label>{contactname}</Label>
							<Value>{getContactName(support)}</Value>
						</Item>

						<Item alternate>
							<Label>{telephonenumber}</Label>
							<Value>
								<Link title={getPhone(support)} uri={"tel:" + getPhone(support)} />
							</Value>
						</Item>

						<Item>
							<Label>{emailaddress}</Label>
							<Value>
								<Link title={getEmail(support)} uri={"mailto:" + getEmail(support)} />
							</Value>
						</Item>

						<Item alternate>
							<Label>{startdate}</Label>
							<Value>
								<Link title={getStartDate(support)} uri={"mailto:" + getStartDate(support)} />
							</Value>
						</Item>
					</Summarized>
				</Items>
			</GroupItems>
		);
	}
}

Supports.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

Communitysupport.contextTypes = {
	localize: PropTypes.func
};

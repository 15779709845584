import React, {
	Component
} from "react";
import {
	View,
	StyleSheet,
	Dimensions,
} from "react-native";
import AtaContainer from "../AtaContainer";
import ATouchAwaySidebar from "../ATouchAwaySidebar";
import ATouchAwayInfoBar from "../ATouchAwayInfoBar";
import About from "../About";
import Reminder from "../Reminder";
import ActionList from "./ActionList";
import PropTypes from "prop-types";

import BraceletIcon from "../BraceletIconNew";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import Style from "ae-style";

var styles = StyleSheet.create({
	content: {
		flex: 1,
		flexDirection: "row",
	}
});

const MIN_DATE_WIDTH = 1000;

export default class ATouchAwayWorkflow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sidebarWidth: 0,
		};
	}

	onSidebarLayoutChange = (event) => {
		let width = event.nativeEvent.layout.width;
		this.setState({
			sidebarWidth: width
		});
	};

	render() {
		var context = this.context;
		var localize = context.localize;
		var model = this.props.model;
		var settings = model.settings;
		var options = model.options;
		var trunk = options.trunk;
		var reminder = model.reminders || {};
		var missed = model.missed;
		var pending = model.pendingactions;
		var resources = model.resources;
		var status = model.status || {};
		var forms = model.forms;
		var allowedForms = settings.forms_sections || {};

		var renderTrunk = trunk ? <About options={options} /> : null;
		var renderReminder = reminder.active && !reminder.answered ? <Reminder reminder={reminder} /> : null;

		var formsViewable = checkPermissions(forms, allowedForms);

		var renderInfoBar = <ATouchAwayInfoBar model={model} sidebarWidth={this.state.sidebarWidth} />;

		var personal = model.personal;
		var testModeEnabled = personal.testModeEnabled;

		var screenWidth = Dimensions.get("window").width;
		var footer = model.footer;
		var temperatureAndDate = [
			<View style={{ justifyContent: "center" }}>
				<StyledText bold contrast emphasized maxSize="smallHeading">
					{screenWidth < MIN_DATE_WIDTH ? null : footer.temperature}
				</StyledText>
			</View>,
			<View style={{ justifyContent: "center" }}>
				<StyledText bold contrast emphasized maxSize="smallHeading">
					{screenWidth < MIN_DATE_WIDTH ? footer.shortDate : footer.date}
				</StyledText>
			</View>];
		var testModeBanner = testModeEnabled ? (
			<View style={{ justifyContent: "center", flexGrow: 1, alignItems: "center"  }}>
				<StyledText bold emphasized maxSize="smallHeading" color={Style.colors.orange}>
					<StyledIcon color={Style.colors.orange} name={"fa-bug"} />
					{" "}{localize("testmode.title")}
				</StyledText>
			</View>)
			: null;
		var footerRight = testModeBanner || temperatureAndDate;
		var renderBraceletBar = (
			<View style={{ justifyContent: "space-between", flexDirection: "row", paddingRight: 12 }}>
				<BraceletIcon bracelet={model.bracelet} icon={"aeto-watch"} />
				{footerRight}
			</View>
		);

		return (
			<AtaContainer>
				<View style={{height: "100%"}}>
					{renderInfoBar}
					<View style={styles.content}>
						<View onLayout={this.onSidebarLayoutChange}>
							<ATouchAwaySidebar
								formsViewable={formsViewable}
								workflowActions={pending}
								missed={missed}
								settings={settings}
								page={"workflow"}
								resources={resources}
								status={status}
								model={model}
							/>
						</View>
						<View style={{flex: 1}}>
							<ActionList actions={pending} />
						</View>
					</View>
					{renderTrunk}
					{renderReminder}
					{renderBraceletBar}
				</View>
			</AtaContainer>
		);
	}
}

function checkPermissions(list, permissions){
	return Object.keys(list).reduce(function(acc, element){
		if(permissions[element])
			acc[element] = list[element];
		return acc;
	}, {});
}

ATouchAwayWorkflow.contextTypes = {
	localizeFrom: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";

import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";
import Contact from "../../Contacts/Contact";
import CardPage from "../../Layout/CardPage";
import ListAdd from "ae-base/List/ListAdd";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import StyledText from "ae-base/Base/StyledText";
import sortNames from "../../../utils/SortNames";

export default function GroupInviteContact(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;
	var model = props.model;
	var groupchat = model.groupchat;
	var group = groupchat.group;
	var members = group.members;
	var personal = model.personal;
	var toast = model.toast;
	var removing = groupchat.removingMember;
	var leaving = groupchat.leaving;

	var screen = model.size;

	var areyousure;
	if(removing){
		areyousure = localize("groupchat.areyousuremember", removing);
	}

	var membersTitle = localize("groupchat.members");
	var goback = localize("groupchat.back");
	var confirmText = localize("groupchat.confirmRemoveMember");
	var cancelText = localize("groupchat.cancelRemoveMember");
	var removeMemberTitle = localize("groupchat.removeMember");
	var leaveGroupTitle = localize("groupchat.leaveGroup");

	var addContactTitle = localize("groupchat.addContact");

	var leaveGroup = dispatch("groupchat:trigger:leave:start");

	var addContact = dispatch("groupchat:trigger:add:open");

	var confirmDelete = dispatch("groupchat:trigger:remove:member:accept");
	var cancelDelete = dispatch("groupchat:trigger:remove:member:cancel");

	var renderMembers = sortNames(members).map(function(member, index){
		if(!member) return null;
		var isSelf = personal._id === member._id;
		var removeMember = dispatch("groupchat:trigger:remove:member:start", {
			member: member
		});
		var buttonTitle = isSelf ? leaveGroupTitle : removeMemberTitle;
		var onPress = isSelf ? leaveGroup : removeMember;
		var id = isSelf ? "groupChat_leave" : "groupChat_removeMember_" + member._id;
		return (
			<Contact contact={member} key={index} screen={screen} >
				<BlockView>
					<StyledButton id={id} clear onPress={onPress} title={buttonTitle} />
				</BlockView>
			</Contact>
		);
	});

	var confirmationTitle = removeMemberTitle;

	if(leaving){
		confirmationTitle = leaveGroupTitle;
		confirmText = localize("groupchat.confirmLeave");
		cancelText = localize("groupchat.cancelLeave");
		confirmDelete = dispatch("groupchat:trigger:leave:accept");
		cancelDelete = dispatch("groupchat:trigger:leave:cancel");
		areyousure = localize("groupchat.areyousureleave");
	}

	var openConfirmation = leaving || removing;

	var confirmation = (
		<ConfirmationModal
			id={"groupChat_removeMember"}
			open={openConfirmation}
			title={confirmationTitle}
			titleIcon="warning"
			confirmText={confirmText}
			cancelText={cancelText}
			onConfirm={confirmDelete}
			onCancel={cancelDelete}
			toast={toast}>
			<StyledText contrast>{areyousure}</StyledText>
		</ConfirmationModal>
	);

	return (
		<CardPage title={membersTitle} titleIcon={"plus-circle"} backTitle={goback} external={confirmation} toast={toast} screen={screen}>
			{renderMembers}
			<ListAdd id={"groupChat_addMember"} title={addContactTitle} onPress={addContact} />
		</CardPage>
	);
}

GroupInviteContact.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";

var styles = StyleSheet.create({
	position: {
		flexDirection: "row",
		justifyContent: "flex-end",
	},
});

export default function Summarized(props){
	var section = props.section;
	var currentSection = props.currentSection;
	var styledPosition = styles.position;
	var ownCareplan = props.ownCareplan;
	var showCareplanDetail = props.showCareplanDetail;

	if(ownCareplan){
		var displaying = section === showCareplanDetail;
		var button = displaying ? (
			<HideButton ownCareplan={ownCareplan} />
		) : (
			<ShowButton section={section} ownCareplan={ownCareplan} />
		);
	}else{
		displaying = section === currentSection;
		button = displaying ? (
			<HideButton />
		) : (
			<ShowButton section={section} />
		);
	}

	return (
		<View>
			{displaying ? props.children : null}
			<View style={styledPosition}>
				{button}
			</View>
		</View>
	);
}

function ShowButton(props, context){
	var dispatch = context.dispatch;
	var section = props.section;
	var ownCareplan = props.ownCareplan;

	if(ownCareplan){
		var action = dispatch("careplan:trigger:showdetail", {
			section: section
		});
	}else{
		action = dispatch("managedcareplanexpander:trigger:expand", {
			section: section
		});
	}

	var localize = context.localize;
	var showmore = localize("summarized.showmore");

	return (
		<StyledButton clear small icon="dropdown" title={showmore} onPress={action}  />
	);
}

function HideButton(props, context){
	var dispatch = context.dispatch;
	var ownCareplan = props.ownCareplan;

	if(ownCareplan){
		var action = dispatch("careplan:trigger:hidedetail");
	}else{
		action = dispatch("managedcareplanexpander:trigger:contract");
	}

	var localize = context.localize;
	var showless = localize("summarized.showless");

	return (
		<StyledButton clear small icon="directup" title={showless} onPress={action} />
	);
}

ShowButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

HideButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Style from "ae-style";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";
import ManagedSection from "./ManagedSection";
import Heading from "ae-base/Base/Heading";
import Contact from "../../Contacts/Contact";
import ListAdd from "ae-base/List/ListAdd";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";

import Form from "ae-forms";

import UrgencyPreferencesSchema from "../../../schemas/UrgencyPreferences";
import sortNames from "../../../utils/SortNames";

var styles = StyleSheet.create({
	addBox: {
		marginLeft: Style.layout.marginSmall
	},
	contacts: {
		marginTop: Style.layout.marginLarge,
		marginBottom: Style.layout.marginContentVertical
	},
	container: {
		flex: 1
	}
});

export default function Managedurgency(props, context) {
	var dispatch = context.dispatch;
	var model = props.model;
	var toast = model.toast;
	var manage = model.manage || {};
	var account = manage.account;
	var urgencycontacts = manage.urgencycontacts || [];
	var allcontacts = model.contacts;
	var contactprofile = model.contactprofile;
	var contactId = contactprofile._id;
	var urgencyPreferences = manage.urgencyPreferences || {};

	var screen = model.size;

	var localize = context.localize;
	var urgencycontact_s = localize("managedurgency.urgencycontact_s");
	var removecontact = localize("managedurgency.removecontact");
	var addanewurgencycontact = localize("managedurgency.addanewurgencycontact");

	var isRemoving = allcontacts && (allcontacts.removing === contactId);
	if (isRemoving) {
		var cancelRemove = dispatch("allcontacts:trigger:remove:contact:cancel");
		var confirmRemove = dispatch("allcontacts:trigger:remove:contact:confirm", {
			who: contactId
		});
	}

	var renderUrgencyContacts = urgencycontacts.length ? (
		<View>
			<Heading title={urgencycontact_s} screen={screen} />
			<Form schema={UrgencyPreferencesSchema} onSubmit={dispatch("managedurgencypreferences:trigger:update")} defaultData={urgencyPreferences} autoSubmit />
			<View style={styles.contacts}>
				{sortNames(urgencycontacts).map(function(contact){
					var id = contact._id;
					var removeContact = dispatch("managedurgencycontacts:trigger:remove", {
						who: id
					});
					return (
						<Contact contact={contact} key={id} screen={screen} >
							<StyledButton icon="delete" title={removecontact} alert onPress={removeContact} />
						</Contact>
					);
				})}
			</View>
		</View>
	) : null;

	var openRequest = dispatch("desktop:trigger:page", {
		page: "managed_urgency_potentials"
	});

	var renderAdd = (
		<BlockView style={styles.addBox}>
			<ListAdd title={addanewurgencycontact} onPress={openRequest} spaced />
		</BlockView>
	);

	return (
		<View style={styles.container}>
			<ManagedSection online={model.online} contact={account} title={urgencycontact_s} toast={toast}>
				{renderUrgencyContacts}
				{renderAdd}
			</ManagedSection>
			<ConfirmationModal open={isRemoving} title={localize("contactprofile.sure")} titleIcon="warning" confirmText={localize("contactprofile.confirmremove")} cancelText={localize("contactprofile.cancelremove")} onConfirm={confirmRemove} onCancel={cancelRemove}>
				<StyledText contrast>{localize("contactprofile.detailremove", {
					fname: contactprofile.fname,
					lname: contactprofile.lname
				})}
				</StyledText>
			</ConfirmationModal>
		</View>
	);
}

Managedurgency.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

Managedurgency.propTypes = {
	model: PropTypes.object,
};

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet
} from "react-native";

import SimpleState from "./aTouchAwayCalling/SimpleState";

var styles = StyleSheet.create({
	container: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	}
});

export default function CallGroupBusy(props, context){
	var group = props.group;
	var message = context.localize("callgroups.busy", group);
	return (
		<View style={styles.container}>
			<SimpleState who={group} message={message} />
		</View>
	);
}

CallGroupBusy.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet
} from "react-native";

import RingtoneSchema from "../../../../schemas/Ringtone";
import Form from "ae-forms";
import StyledButton from "ae-base/Base/StyledButton";
import Divider from "../../../Layout/Divider";
import Style from "ae-style";
import BlockView from "ae-base/Base/BlockView";

var styles = StyleSheet.create({
	divider: {
		marginTop: Style.layout.marginContentSmall
	}
});

export default function Ringtone(props, context){
	var dispatch = context.dispatch;
	var ringtone = props.ringtone;
	var localize = context.localize;
	var testringtone = localize("ringtone.testringtone");
	var defaultData = {
		ringtone: ringtone
	};

	return (
		<BlockView>
			<Form id_prefix={context.id_prefix + "ringtoneForm"} key={ringtone} schema={RingtoneSchema} defaultData={defaultData} autoSubmit onSubmit={dispatch("preferences:trigger:preferences:update")} />
			<StyledButton contrast id={context.id_prefix + "testRingtone"} title={testringtone} onPress={dispatch("soundspreferences:trigger:test")} />
			<BlockView style={styles.divider}>
				<Divider />
			</BlockView>
		</BlockView>
	);

}

Ringtone.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

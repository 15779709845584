/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Aetonix, January 01, 2017
* For information or permission request, email info@aetonixsystems.com
*/

import PropTypes from "prop-types";
import React from "react";
import {
	Image,
	StyleSheet,
} from "react-native";
import footerTexture from "../../../Resources/img/header_texture_01SC.png";

var styles = StyleSheet.create({
	footer: {
		position: "absolute",
		width: 264,
		height: 119,
		bottom: -28,
		left: -54,
		opacity: 0.8,
	},
});

import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import Form from "ae-forms";
import HomeHeader from "./HomeHeader";
import Unverified from "./Unverified";
import ContactList from "./ContactList";
import GroupList from "./GroupList";
import ManagedContactList from "./ManagedContactList";
import WanderValidation from "./WanderValidation";
import FallValidation from "./FallValidation";
import Banners from "../../Banners";
import PermissionBanners from "./PermissionBanners";
import AppearOfflineBanner from "./AppearOfflineBanner";
import OfflineBanner from "./OfflineBanner";
import UpdateBanner from "./UpdateBanner";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import StyledText from "ae-base/Base/StyledText";
import InviteDenySchema from "../../../schemas/InviteDeny";
import WorkflowActionBanners from "../../Workflow/WorkflowActionBanners";
import HomeTabs from "./HomeTabs";
import ReminderBanners from "./ReminderBanners";
import LiveChatBubble from "./LiveChatBubble";
import TestModeBanner from "./TestModeBanner";

export default function Homepage(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;

	var hidden = props.hidden;
	var model = props.model;
	var toast = model.toast;
	var homepage = model.homepage;
	var contacts = homepage.contacts;
	var personal = model.personal;
	var reverify = model.reverify;
	var callgroups = model.callgroups;
	var contactsettings = model.contactsettings;
	var callgroupsMembers = model.callgroupsmembers;
	var unreadnotifications = model.usernotifications.unread;
	var contact_count = homepage.contacts_count + callgroups.length;
	var managed = homepage.managed;
	var managed_count = homepage.managed_count;
	var managed_total_count = homepage.managed_users_count;
	var incoming = homepage.incoming;
	var outgoing = homepage.outgoing;
	var hideOffline = homepage.hideOffline || {};
	var groups = homepage.groups;
	var group_count = homepage.groups_count;

	var displaying = homepage.displaying || {};
	var displaying_contacts = displaying.contacts;
	var displaying_managed = displaying.managed;
	var displaying_groups = displaying.groups;

	var availability = model.availability;
	var missed = model.missed;
	var isDenying = model.requests.denying;
	var request, who;
	if(isDenying){
		request = isDenying.request;
		who = isDenying.who;
	}
	var manage = model.manage;
	var requests = manage.requests || {};
	var manageDenying = requests.denying;
	var type = personal.type || [];
	var screen = model.size;
	var managedStatuses = manage.managedstatuses;

	var patientGroups = model.groupmanagegroups;
	var managegroups = model.managegroups || [];
	var activemanagegroup  = model.activemanagegroup;
	var managedEnd = manage.managedEnd || {};

	var confirmDeny, cancelDeny;
	if(isDenying){
		confirmDeny = dispatch("requests:trigger:deny:confirm", {
			who: who,
			request: request,
		});
		cancelDeny = dispatch("requests:trigger:deny:cancel");
		var setBlocking = dispatch("requests:trigger:block:set");
	}

	var confirmManageDeny, cancelManageDeny;
	if(manageDenying){
		confirmManageDeny = dispatch("managementrequests:trigger:deny:confirm", {
			request: manageDenying.request,
		});
		cancelManageDeny = dispatch("managementrequests:trigger:deny:cancel");
	}

	var areyousure = localize("homepage.areyousure");
	var confirmtext = localize("homepage.confirmtext");
	var canceltext = localize("homepage.canceltext");
	var requestdeny = localize("homepage.requestdeny");
	var managedeny = localize("homepage.managedeny");
	var online = model.online;
	var unavailable;
	var unverified = personal.unverified;
	var available = model.personal.available;
	if (!available && online) {
		unavailable =	<AppearOfflineBanner />;
	}

	var offline = model.online ? null : (
		<OfflineBanner />
	);

	var updateBanner = model.outdated ? <UpdateBanner /> : null;

	var unverifiedBanner = unverified ? (
		<Unverified  personal={personal} reverify={reverify} />
	) : null;

	var testModeBanner = personal.testModeEnabled ?  <TestModeBanner /> : null;
	var workflowactions = model.workflowactions;

	var reminders = model.myreminders.ownpendingreminders ? (
		<ReminderBanners reminders={model.myreminders.ownpendingreminders} />
	) : null;

	var liveChatGroups = model.livechatgroups;
	var renderLiveChatBubble = liveChatGroups.length ? (
		<LiveChatBubble missed={model.livechatmissedmessage} groups={liveChatGroups} personal={personal} screen={screen} />
	) : null;

	// Check if managegroups is empty
	// If it is, render managedusers
	// If it isn't render managegroups

	return (
		<PageContainer saving={model.saving} toast={toast} hidden={hidden}>
			{renderLiveChatBubble}
			<HomeHeader unreadnotifications={unreadnotifications} personal={model.personal} screen={screen} missed={missed} />
			<Image resizeMethod="resize" style={styles.footer} source={footerTexture} />
			<MainScroll>
				{updateBanner}
				{unverifiedBanner}
				{testModeBanner}
				{offline}
				{unavailable}
				{reminders}
				<PermissionBanners
					permissions={model.devicepermissions}
				/>
				<Banners
					max={10}
					banner={model.banner}
					fallinvalidator={model.fallinvalidator}
					wanderinvalidator={model.wanderinvalidator}
				/>
				<WorkflowActionBanners actions={workflowactions} />
				<GroupList
					list={groups}
					count={group_count}
					missed={missed}
					screen={screen}
					displaying={displaying_groups} />
				<ContactList
					callgroupsMembers={callgroupsMembers}
					callgroups={callgroups}
					contactsettings={contactsettings}
					list={contacts}
					incoming={incoming}
					outgoing={outgoing}
					availability={availability}
					hiding={hideOffline.contacts}
					count={contact_count}
					manageCount={managed_count}
					displaying={displaying_contacts}
					missed={missed}
					screen={screen} />
				<ManagedContactList
					managegroups={managegroups}
					patientGroups={patientGroups}
					activemanagegroup={activemanagegroup}
					loading={model.loading}
					list={managed}
					listEnd={managedEnd.end}
					contactsettings={contactsettings}
					managedStatuses={managedStatuses}
					availability={availability}
					hiding={hideOffline.managed}
					count={managed_count}
					totalCount={managed_total_count}
					missed={missed}
					displaying={displaying_managed}
					screen={screen}
					isSearching={managedEnd.isSearching}
					padBottom={!!renderLiveChatBubble} />
			</MainScroll>
			<HomeTabs
				ownforms={model.ownformlist}
				groupforms={model.groupformlist}
				page={model.page}
				userType={type}
				groupsettings={model.groupsettings}
			/>
			<ConfirmationModal
				open={isDenying}
				title={areyousure}
				titleIcon="warning"
				confirmText={confirmtext}
				cancelText={canceltext}
				onConfirm={confirmDeny}
				onCancel={cancelDeny}>
				<StyledText contrast>{requestdeny}</StyledText>
				<Form schema={InviteDenySchema} contrast autoSubmit onSubmit={setBlocking} />
			</ConfirmationModal>
			<ConfirmationModal
				open={manageDenying}
				title={areyousure}
				titleIcon="warning"
				confirmText={confirmtext}
				cancelText={canceltext}
				onConfirm={confirmManageDeny}
				onCancel={cancelManageDeny}>
				<StyledText contrast>{managedeny}</StyledText>
			</ConfirmationModal>

			<WanderValidation
				wanderinvalidator={model.wanderinvalidator}
				from={model.personal._id} />

			<FallValidation
				fallinvalidator={model.fallinvalidator}
				from={model.personal._id} />
		</PageContainer>
	);
}

Homepage.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

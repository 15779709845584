import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	View
} from "react-native";

import Style from "@aetonix/styles";
import StyledText from "ae-base/Base/StyledText";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: Style.layout.marginContent
	},
	title: {
		marginTop: Style.layout.marginHuge,
		marginBottom: Style.layout.marginLarge,
		alignItems: "center"
	}
});

export default function CareplanWelcome(props, context){
	var localize = context.localize;
	var title = localize("mobileatacareplan.overview");
	var welcome = localize("mobileatacareplan.welcome");

	return (
		<View style={styles.container}>
			<View style={styles.title}>
				<StyledText tertiary large>{title}</StyledText>
			</View>
			<StyledText contrast emphasized>{welcome}</StyledText>
		</View>
	);
}

CareplanWelcome.contextTypes = {
	localize: PropTypes.func
};

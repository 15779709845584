import PropTypes from "prop-types";
import React, { useMemo } from "react";

import SimplifiedHome from "./SimplifiedHome";
import ScreenSaver from "../aTouchAway/ScreenSaver";
import FeedbackModal from "../aTouchAway/FeedbackModal";
import Disconnected from "../aTouchAway/Disconnected";
import AtaContainer from "../aTouchAway/AtaContainer";
import About from "../aTouchAway/About";
import Setting from "./Setting";
import License from "../Pages/Login/License";
import Reminder from "./Reminder";
import Reading from "./SimplifiedReading";
import ReadingModal from "./SimplifiedNewReadingModal";

import useActiveMeetingManager from '../../Calling/hooks/useActiveMeetingManager/useActiveMeetingManager';
import IncomingCallModal from '../../Calling/components/Modals/IncomingCall';
import Call from '../../Calling/Call';
import MeetingContext from "../../Calling/context/MeetingContext";
import MeetingMinimize from "../../Calling/components/MeetingMinimize";
import useEnabledFeatures from "../../hooks/useEnabledFeatures/useEnabledFeatures";
import PrivacyPolicy from "../Pages/Login/PrivacyPolicy";

export default function Simplified(props, context){
	var model = props.model;
	var currentPage = model.page;
	var offline = model.offline;
	var screensaver = model.screensaver;
	var options = model.options;
	var aboutbox = model.aboutbox;

	const { dispatch, listen, unlisten, config, localize, api } = context;
	const events = { dispatch, listen, unlisten, api };

	const meetingManager = useActiveMeetingManager(events, config, model);

	const { meeting, isIncomingCallOpen } = meetingManager;

	const isNotInMeeting = !isIncomingCallOpen && currentPage !== "meeting";

	if (screensaver && currentPage !== 'meeting') {
		return <ScreenSaver type={screensaver} />;
	}

	var feedback = model.feedbackmodal ? (
		<FeedbackModal model={model} />
	) : null;

	if (isNotInMeeting && aboutbox.open) {
		return <About options={options} aboutbox={aboutbox} />;
	}

	var renderLicense = model.license ? (
		<License close={context.dispatch("license:trigger:hide")} />
	) : null;

	var renderPrivacyPolicy = model.privacypolicy ? (
		<PrivacyPolicy close={dispatch("privacypolicy:trigger:hide")} />
	) : null;

	var renderReminder = model.reminders ? (
		<Reminder reminders={model.reminders} size={model.size} />
	) : null;

	var renderReading = model.reading ? (
		<Reading model={model.reading} />
	) : null;

	var mainContent = null;

	if (currentPage === "meeting") {
		mainContent = <Call mode="simplified"/> ;
	} else if (currentPage === "home") {
		mainContent = <SimplifiedHome model={model}/>;
	} else if (currentPage === "setting") {
		mainContent = <Setting model={model} />;
	}

	if (offline) {
		return <Disconnected />;
	}

	return (
		<AtaContainer>
			<MeetingContext.Provider value={meetingManager}>
				{mainContent}
				{isNotInMeeting && renderReading}
				{isNotInMeeting && <ReadingModal model={model} />}
				{isNotInMeeting && feedback}
				{isNotInMeeting && renderLicense}
				{isNotInMeeting && renderPrivacyPolicy}
				{isNotInMeeting && renderReminder}
				{ isIncomingCallOpen && <IncomingCallModal/> }
				{ meetingManager?.isMinimized && (
					<MeetingMinimize title={meeting?.title} onClick={() => meetingManager?.goToMeeting?.()}/>
				)}
			</MeetingContext.Provider>
		</AtaContainer>
	);
}

Simplified.contextTypes = {
	dispatch: PropTypes.func,
	listen: PropTypes.func,
	unlisten: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func,
	api: PropTypes.object,
};

/* eslint-disable no-undef */
/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: MIT-0
 */

export const SERVER_URL = "https://hrk7l21nf6.execute-api.ca-central-1.amazonaws.com/Prod/";
export const SERVER_REGION = "ca-central-1";

export function createMeetingRequest(meetingName, attendeeName) {
	let url = encodeURI(SERVER_URL + "/join?" + `title=${meetingName}&name=${attendeeName}&region=${SERVER_REGION}`);

	return fetch(url, { method: "POST" }).then((j) => j.json());
}
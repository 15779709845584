import PropTypes from "prop-types";
import React from "react";

import ManagedHealthIndicator from "../../HealthIndicators/ManagedHealthIndicator";

export default function ActivityMetrics (props, context){
	var model = props.model;
	var manage = model.manage;
	var activity = manage.managedactivity;

	var localize = context.localize;
	var steps = activity.cumulative;
	var reverse_steps = activity.reverse; // needed for chart
	var today = null;
	var section = model.page;
	var pagetitle = localize("activity.status");
	var  units = localize("activity.rightLabel");
	var textFormat = "{cumulative}";

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = reverse_steps.slice(-24); // for chart
		today = steps[steps.length - 1].cumulative;
	}

	return (
		<ManagedHealthIndicator model={model} section={section} textformat={textFormat} pagetitle={pagetitle} latestReading={today} units={units} reverseEntries={graphdata} activeModel={activity} xaxis={"updated_at"} yaxis={"cumulative"} bar managed />
	);
}
ActivityMetrics.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};


import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function BatteryChanged(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var devicebatterychanged = localize("banner.batterychanged", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={devicebatterychanged} iconName="info"  notification={notification} screen={screen} />
	);

}

BatteryChanged.contextTypes = {
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";
import Style from "ae-style";
import noop from "noop3";

export default function ConferenceCallMissed(props, context){
	var dispatch = context.dispatch;
	var notification = props.notification;
	var iconColor = Style.colors.alert;
	var localize = context.localize;
	var screen = props.screen;
	var voiceOnly = notification.voiceOnly;
	var callmissed = null;
	var icon = null;
	var  contacts = props.contacts || [];
	if (voiceOnly){
		callmissed = localize("callnotification.voiceCallMissed");
		icon = "phone";
	} else {
		callmissed = localize("callnotification.callmissed");
		icon = "aeto-video_call";
	}
	var goToNotification = dispatch("calling:trigger:call", {
		who: notification.from._id,
		reason: "screen"
	});

	var isContact = contacts.find((contact) => (contact._id ===  notification.from._id));

	var openNotification = (isContact) ? goToNotification : noop ;

	return (
		<TimelineItem content={callmissed} iconName={icon} iconColor={iconColor} onPress={openNotification} clearable notification={notification} screen={screen} />
	);

}

ConferenceCallMissed.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

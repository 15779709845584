import PropTypes from "prop-types";
import React from "react";
import {View, StyleSheet} from "react-native";

import ButtonGridGeneric from "./ButtonGridGeneric";

const styles = StyleSheet.create({
	sections: {
		alignItems: "center"
	}
});

const BUTTONS = [
	{title: "contactsettings.settings", trigger: "owncontactsettings:trigger:view", icon: "edit"}
];

export default function ContactSettings(props, context){
	const localize = context.localize;
	const contactSettingsTitle = localize("contactsettings.title");
	return (
		<View style={styles.sections}>
			<ButtonGridGeneric buttons={BUTTONS} title={contactSettingsTitle} contact={props.contact} />
		</View>
	);
}

ContactSettings.contextTypes = {
	localize: PropTypes.func
};
import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useState, useEffect } from "react";
import {
	View, TextInput, StyleSheet
} from "react-native";

import ManagedSection from "../ManagedSection";
import Note from "./Note";
import ListAdd from "ae-base/List/ListAdd";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

const styles = StyleSheet.create({
	searchInput: {
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 5,
		padding: 10,
		marginBottom: 10,
	},
});
export default function Managedforms(props, context) {
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredSubmissions, setFilteredSubmissions] = useState([]);
	var dispatch = context.dispatch;
	var model = props.model;
	var screen = model.size;

	var toast = model.toast;
	var manage = model.manage;
	var forms = manage.forms || {};
	var submissions = forms.submissions || [];
	var contact = manage.account;
	var localize = context.localize;

	const searchBar = (
		<View>
			<TextInput
				style={styles.searchInput}
				placeholder={localize("managedforms.search_notes")}
				value={searchQuery}
				onChangeText={setSearchQuery}
			/>
		</View>
	);



	var title = localize("managedforms.title");
	var addnewnote = localize("managedforms.addnewnote");


	useEffect(() => {
		handleSearch(searchQuery);
	}, [searchQuery]);

	const handleSearch = (query) => {
		const lowerCaseQuery = query.toLowerCase();
		const filtered = submissions.filter((note) => {
			const content = note.data.body || ""; 
			const title = note.data.title.toLowerCase();
	
			return content.toLowerCase().includes(lowerCaseQuery) || title.includes(lowerCaseQuery);
		});

		setFilteredSubmissions(filtered);
	};



	var renderNote = submissions.map(function (note) {
		return (
			<Note submission={note} key={note._id} screen={screen} />
		);
	});

	const renderFilteredNote = filteredSubmissions.map(function (note) {
		return <Note submission={note} key={note._id} screen={screen} />;
	});

	var onAddNewNote = dispatch("managedforms:trigger:start", {
		form: "note"
	});
	var nextPage = dispatch("managedforms:trigger:more");

	var moreButton = forms.submissionEnd ? null : (
		<StyledButton title={localize("userlist.loadmore")} contrast onPress={nextPage} />
	);
	return (
		<ManagedSection online={model.online} contact={contact} title={title} toast={toast}>
			{searchBar}
			<ListAdd title={addnewnote} onPress={onAddNewNote} />
			{searchQuery.length > 0 ? renderFilteredNote : renderNote}
			<View style={{ padding: 10, alignItems: "center" }}>
				{moreButton}
			</View>
		</ManagedSection>
	);
}

Managedforms.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet
} from "react-native";
import prop from "prop";

import Form from "ae-forms";
import InputLabel from "ae-forms/src/Inputs/Layout/InputLabel";
import Style from "ae-style";
import BlockView from "ae-base/Base/BlockView";

var getAudio = prop("input.sources.audio");
var getVideo = prop("input.sources.video");

var styles = StyleSheet.create({
	container: {
		marginTop: Style.layout.marginContentSmall,
		marginBottom: Style.layout.marginContentSmall
	}
});

export default function MediaSettings(props, context){
	var dispatch = context.dispatch;
	var options = props.options;

	var audioItems = getAudio(options) || [];
	var videoItems = getVideo(options) || [];

	if(!audioItems && !videoItems) return(null);

	var enumAudio = audioItems.map(function(item){
		return item.value;
	});

	var enumVideo = videoItems.map(function(item){
		return item.value;
	});

	var enumAudioOption = audioItems.map(function(item){
		return item.name;
	});

	var enumVideoOption = videoItems.map(function(item){
		return item.name;
	});

	var MediaSettingsSchema = {
		title: "MediaSettings",
		description: "mediasettings.title",
		type: "object",
		properties: {
			audio: {
				description: "mediasettings.audio",
				value_description: "audio_input",
				enumLabel: enumAudioOption,
				type: "string",
				enum: enumAudio
			},
			video: {
				description: "mediasettings.video",
				value_description: "video_input",
				enumLabel: enumVideoOption,
				type: "string",
				enum: enumVideo
			}
		}
	};
	var loadedKey = videoItems.length + " " + audioItems.length;

	return (
		<BlockView style={styles.container}>
			<InputLabel schema={MediaSettingsSchema} />
			<Form id_prefix={context.id_prefix + "mediaSettingForm"} key={loadedKey} schema={MediaSettingsSchema} defaultData={options.input} autoSubmit onSubmit={dispatch("inputoptions:trigger:update")} />
		</BlockView>
	);

}

MediaSettings.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};
import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet
} from "react-native";

import BloodOxygen from "../../../schemas/BloodOxygenFollowUp";
import Modal from "../../Layout/Modal";
import Card from "../../Layout/Card";
import MainScroll from "../../Layout/MainScroll";

import Form from "ae-forms";
import Heading from "ae-base/Base/Heading";

var SCHEMA_MAP = {
	"bloodoxygen": BloodOxygen
};


export default function DeviceFollowUp(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;
	var followup = props.followup;
	var reading = followup.reading;
	var type = followup.type;

	var defaultData = {
		reading: reading
	};

	var schema = SCHEMA_MAP[type];

	var confirmText = localize("common.save");
	var cancelText = localize("common.cancel");

	var title = localize(schema.description);

	return (
		<Modal open>
			<Card atouchaway scroll style={{maxHeight:"90%"}}>
					<Heading title={title} />
					<Form
						schema={schema}
						defaultData={defaultData}
						onSubmit={dispatch("devicefollowup:trigger:submit")}
						submitText={confirmText}
						cancelText={cancelText}
						onCancel={dispatch("devicefollowup:trigger:cancel")}
						style={{flex: 1}}
						isATouchAway
					/>
			</Card>
		</Modal>
	);
}

DeviceFollowUp.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};
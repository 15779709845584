import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";
import {
	StyleSheet,
	Image
} from "react-native";

import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

var imageSizes = Object.keys(Style.imageSize);

var imageStyles = StyleSheet.create(imageSizes.reduce(function(style, name){
	var size = Style.imageSize[name];

	var borderWidth = (size > 100) ? 4 : 2;

	style[name] = {
		width: size,
		height: size,
		borderRadius: (size + borderWidth) / 2,
		borderWidth: borderWidth
	};

	return style;
}, {}));

var styles = StyleSheet.create({
	image: {
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		opacity: 0.999,
		alignSelf: "center"
	},
	inactive: {
		opacity: 0.5
	}
});

export default class Avatar extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var config = context.config;

		var person = props.person || {};
		var inactive = props.inactive;
		var children = props.children;
		var size = props.size ? props.size : "large";
		var imageSize = imageStyles[size];
		var borderColor = props.borderColor ? props.borderColor : Style.look.outline;
		var imageBorder = {
			borderColor: borderColor
		};

		var imageStyle =  [styles.image, imageSize, imageBorder];

		if(inactive)
			imageStyle.push(styles.inactive);

		return (
			<Image resizeMethod="resize" source={ImageUrl(person.image, config)} style={imageStyle} key={person._id + Date.now()}>
				{children}
			</Image>
		);
	}
}

Avatar.contextTypes = {
	config: PropTypes.object,
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, Augest 28, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import StyledText from "ae-base/Base/StyledText";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Style from "@aetonix/styles";

import Heading from "ae-base/Base/Heading";
import Output from "ae-forms/src/Outputs/Output";

import GenericStatus from "../../../schemas/GenericStatus";

var MAX_BUTTONS = 6;

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		alignSelf: "center"
	},
	title: {
		alignSelf: "center"
	},
	outputContainer: {
		padding: 5,
		borderWidth: 2,
		borderColor: Style.colors.light,
		textAlign: "center",
		margin: Style.layout.marginSmall / 2,
		paddingTop: 10,
	},
});

export default function WorkflowStatus(props, context) {
	var status = props.status || {};
	var atouchaway = props.atouchaway;
	var localize = context.localize;
	var statusTitle = localize("workflowstatus.title");
	var title = props.title || statusTitle;
	var status_history = status.status || {};
	var indicator_status = status.indicator_status || {};
	var screen = props.screen;
	var workflowDefinitions = props.workflowDefinitions;
	var filteredIndicators = atouchaway ? Object.keys(indicator_status).filter(function (key) {
		var item = indicator_status[key];
		return item.status !== -1;
	}) : [];

	var overallStatusSchema = {
		"displayType": "status",
		"enum": [0, 1, 2],
		"description": localize("workflowstatus.overall"),
		"type": "number",
	}

	var overallStatus = status?.current_status

	var large = props.large;
	var extraText = props.extraText;
	var renderedTitle = Object.keys(status_history).concat(filteredIndicators).length && title ? large ? (
		<View style={styles.title}>
			<StyledText tertiary large>{title}</StyledText>
		</View>
	) : (
		<Heading title={title} screen={screen}>
			{extraText}
		</Heading>
	) : null;

	const parseStatus = (status) => {
		if (status === 2) {
			return localize("workflowstatus.highRisk");
		}
		if (status === 1) {
			return localize("workflowstatus.lowRisk");
		}
		if (status === 0) {
			return localize("workflowstatus.normal");
		}
	};

	var renderedButtons = Object.keys(status_history).map(function (key) {
		var item = status_history[key];
		var splitedKey = key.split(":");
		var workflowId = splitedKey[0];
		var statusName = splitedKey[1];

		var workflowDefinition = isArray(workflowDefinitions) ?
			workflowDefinitions.reduce(function (result, definition) {
				return definition._id === workflowId ? definition : result;
			}, null) : workflowDefinitions[workflowId];
		if (!workflowDefinition) return null;
		var schema = workflowDefinition.schema && workflowDefinition.schema.statuses ? workflowDefinition.schema.statuses[statusName] : {};

		return (
			<View key={workflowDefinition._id} style={styles.outputContainer}>
				<Output style={styles.output} key={workflowDefinition._id} schema={schema} data={item.status} large={large} />
				<StyledText>{parseStatus(item.status)}</StyledText>
			</View>
		);
	});


	let renderOverallStatus = null;

	if (status_history) {
		renderOverallStatus = (
			<View style={styles.outputContainer}>
				<Output style={styles.output} schema={overallStatusSchema} data={overallStatus} large={large} />
				<StyledText>{parseStatus(overallStatus)}</StyledText>
			</View>
		)
	}
	
	var renderedHealth = null;
	if (atouchaway)
		renderedHealth = Object.keys(indicator_status).filter(function (key) {
			var item = indicator_status[key];
			return item.status !== -1;
		}).map(function (key) {
			var item = indicator_status[key];
			var data = item.status;
			var schema = JSON.parse(JSON.stringify(GenericStatus));
			schema.description = "statuses." + key;

			return (
				<View style={styles.outputContainer}>
					<Output style={styles.output} key={key} schema={schema} data={data} large={large} />
				</View>
			);
		});

	var responsiveStyles = makeIncrements(MAX_BUTTONS);

	function makeIncrements(buttons) {
		var i = buttons;
		var increment = Style.layout.squareButton + Style.layout.marginSmall;
		var definition = {};

		while (i) {
			var size = increment * i;

			definition[size] = {
				container: {
					width: size
				}
			};

			i--;
		}

		return ResponsiveStylesheet.createSized("min-width", definition);
	}

	return (
		<View style={responsiveStyles.container}>
			{renderedTitle}
			<View style={styles.buttons}>
				{renderedButtons?.length > 0 ? renderOverallStatus : null}
				{renderedButtons}
				{renderedHealth}
			</View>
		</View>
	);
}

WorkflowStatus.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

function isArray(value) {
	return value && typeof value === "object" && value.constructor === Array;
}
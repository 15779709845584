import PropTypes from "prop-types";
import React from "react";
import {
	View,
	Platform,
	Dimensions,	
} from "react-native";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import ResponsiveStyleSheet from "react-native-responsive-stylesheet";
import {
	VictoryLine,
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryLabel,
	VictoryContainer
} from "victory-native";

const { width, height } = Dimensions.get('window');	

var styles = ResponsiveStyleSheet.createSized("min-direction", {
	0: {
		container: {
			alignSelf: "center",
		},
		textY: {
			paddingTop: 20,
			marginLeft: width < 450 ? 20 : 0
		},
		textX: {
			paddingBottom: 20
		},
		viewForScrolling: {
			width: "100%",
			height: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 1
		}
	},
	512: {
		container: {
			width: 530,
			height: 300,
			marginBottom: 80
		}
	}
});

const responsiveStyles = ResponsiveStyleSheet.createOriented({
	landscape: {
		container: {
			alignSelf: "center",
			paddingLeft: 30
		}
	},
	portrait: {
		container: {
			alignSelf: "center",
		}
	}
});

export default function VictoryGraph(props, context){
	var data = props.data;
	var xaxis = props.xaxis;
	var yaxis = props.yaxis;
	var managed = props.managed;
	var bar = props.bar;
	var trend = props.trend;
	var textSize = context.textsize;

	var fontSize = Style.text.verySmall;

	if(textSize === 1)
		fontSize = Style.text.small;
	else if (textSize === 2)
		fontSize = Style.text.standard;

	var deviceWidth = Dimensions.get("window").width;
	var containerStyle = [styles.container, responsiveStyles.container];

	var nativeProps = {};

	if (Platform.OS !== "web") {
		var chartWidth = deviceWidth / 1.7;
		if (!props.isATA)
			chartWidth = deviceWidth * 0.95;
		containerStyle.push({
			width: chartWidth
		});

		if (deviceWidth <= 600) {
			nativeProps.width = chartWidth;
		} else {
			nativeProps.width = chartWidth * 0.70;
		}
	}

	var xAxisLabel = props.xAxisLabel;
	var yAxisLabel = props.yAxisLabel;

	var managedColors = {
		tickLabels: Style.colors.black,
		grid: Style.colors.semiLightGrey,
		axis: Style.colors.grey
	};

	var atouchawayColors = {
		tickLabels: Style.colors.white,
		grid: Style.colors.semiDarkGrey,
		axis: Style.colors.semiLightGrey
	};

	var colors = managed ? managedColors : atouchawayColors;

	var xAxisStyle = {
		tickLabels: {
			fill: colors.tickLabels,
			padding: 5,
			fontSize: fontSize
		},
		ticks: {
			padding: 0
		},
		axis: {
			stroke: colors.axis
		},
		grid: {
			stroke: colors.grid
		},
		axisLabel: {
			fill: colors.tickLabels
		}
	};

	var yAxisStyle = {
		tickLabels: {
			fill: colors.tickLabels,
			padding: width < 450 ? 1 : 5,
			fontSize: fontSize
		},
		ticks: xAxisStyle.ticks,
		axis: xAxisStyle.axis,
		grid: xAxisStyle.grid,
		axisLabel: {
			fill: xAxisStyle.axisLabel.fill,
			angle: -90
		}
	};

	var chartPadding = {
		left: 45,
		bottom: 30,
		top: 0,
		right: 20
	};

	var barStyle = {
		data: {
			fill: Style.colors.secondary
		}
	};

	var lineStyle = {
		data: {
			stroke: Style.colors.secondary
		}
	};

	var trendLineStyle = {
		data: {
			stroke: Style.colors.red
		}
	};

	var trendedData = trend ? data.map(trendData) : null;

	var trendLine = trend ? (
		<VictoryLine data={trendedData} style={trendLineStyle} x={xaxis} y={yaxis} interpolation="natural" />
	) : null;

	var plot_type = bar ? (
		<VictoryBar barRatio={0.7} data={data} style={barStyle} x={xaxis} y={yaxis} interpolation="natural" />
	) : (data?.length > 5 ?
		(<VictoryLine data={data} style={lineStyle} x={xaxis} y={yaxis} interpolation="natural" />) : null
	);

	return (
		<View style={containerStyle}>
			<View style={styles.viewForScrolling} />
			<StyledText style={styles.textY} contrast={!managed} small black={managed}>{yAxisLabel}</StyledText>
			<VictoryChart padding={chartPadding} domainPadding={30}  containerComponent={<VictoryContainer portalZIndex={0} />} {...nativeProps}>
				<VictoryAxis fixLabelOverlap style={yAxisStyle} dependentAxis />
				<VictoryAxis fixLabelOverlap style={xAxisStyle} tickLabelComponent={<VictoryLabel textAnchor="middle" />} />
				{plot_type}
				{trendLine}
			</VictoryChart>
			<StyledText style={styles.textX} contrast={!managed} centered small black={managed}>{xAxisLabel}</StyledText>
		</View>
	);
}

function trendData(dataPoint){
	return {
		x: dataPoint.x,
		y: dataPoint.y0 ? Math.round((dataPoint.y + dataPoint.y0) / 2) : dataPoint.y
	};
}

VictoryGraph.contextTypes = {
	textsize: PropTypes.number
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
	View,
	Linking,
	TouchableOpacity,
	Text
} from "react-native";
import UserStatusSchema from "../../../schemas/UserStatus";

import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import MainContent from "../../Layout/MainContent";
import Divider from "../../Layout/Divider";

import Form from "ae-forms";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

import OfflineBanner from "../Homepage/OfflineBanner";
import ProfileHeader from "./ProfileHeader";
import Trialwizard from "./Trialwizard";
import VersionNumber from "./VersionNumber";
import ApplicationSettings from "./Sections/ApplicationSettings";
import PrivacySettings from "./Sections/PrivacySettings";
import AccountSettings from "./Sections/AccountSettings";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import DefaultContactSettings from "./Sections/DefaultContactSettings";

import Style from "ae-style";
import AccountDeletion from "./AccountDeletion";

var styles = StyleSheet.create({
	license: {
		marginVertical: Style.layout.marginContentSmall
	}
});

export default function Myprofile(props, context) {
	var dispatch = context.dispatch;
	var config = context.config;

	var localize = context.localize;
	var model = props.model;
	var preferences = model.preferences;
	var options = model.options;
	var trialwizard = model.trialwizard;
	var profilesection = model.profilesection;
	var toast = model.toast;
	var saving = model.saving;
	var islogout = model.logoutconfirmmodal;
	var personal = model.personal;
	var autologout = model.orgautologout;
	var multifactor = model.orgmultifactor;
	var accountSwitch = model.accountswitch || {};
	var type = personal.type || [];
	var hasNoLogin = type.indexOf("login:none") === -1 ? false : true;
	var hide = dispatch("logoutconfirmmodal:trigger:hide");
	var logout = dispatch("logout:trigger:logout");

	var statusOptions = {
		available: options.available
	};

	var offline = model.online ? null : (
		<OfflineBanner />
	);

	var privacyError = localize("myprofile.privacyerror");
	var renderPrivacyError = preferences.preferences_info_update_error ? (
		<StyledText>{privacyError}</StyledText>
	) : null;

	var renderNonMobile = hasNoLogin ? null : [
		(<PrivacySettings preferences={preferences} profilesection={profilesection} autologout={autologout} multifactor={multifactor} />),
		renderPrivacyError,
		(<Divider />)
	];

	var renderMyprofilePage = trialwizard.active ? (
		<Trialwizard trialwizard={trialwizard} />
	) : (
		<PageContainer saving={saving} toast={toast}>
			<MainScroll>
				<ProfileHeader preferences={preferences} hasParent={accountSwitch.hasParent} />
				{offline}
				<MainContent>
					<Form schema={UserStatusSchema}
						id_prefix={context.id_prefix + "UserStatus"}
						defaultData={statusOptions}
						autoSubmit
						onSubmit={dispatch("options:trigger:available")} />
					<ApplicationSettings model={model} profilesection={profilesection} />
					<AccountSettings preferences={preferences} profilesection={profilesection} />
					<DefaultContactSettings preferences={preferences} profilesection={profilesection} />
					<Divider />
					{renderNonMobile}
						<View style={styles.license}>
							<StyledButton contrast title={localize("register.licenseagreement")} onPress={dispatch("license:trigger:show")} icon="notebook" />
						</View>
						<View style={styles.license}>
							<StyledButton contrast title={localize("privacy_policy.privacy_policy_agreement")} onPress={dispatch("privacypolicy:trigger:show")} icon="notebook" />
						</View>
						<View style={styles.license}>
							<StyledButton contrast title={localize("accountdeletion.button")} onPress={dispatch("accountdeletion:trigger:open")} icon="ban" />
						</View>
					<VersionNumber black={true} model={model} config={config} />
				</MainContent>
			</MainScroll>
			<ConfirmationModal  open={islogout} title={localize("myprofile.sure")} titleIcon="warning" confirmText={localize("myprofile.confirmlogout")} cancelText={localize("myprofile.cancellogout")} onConfirm={logout} onCancel={hide}>
				<StyledText contrast>{localize("myprofile.detaillogout")}
				</StyledText>
			</ConfirmationModal>
			<AccountDeletion open={model.accountdeletion?.open} />
		</PageContainer>
	);

	return renderMyprofilePage;
}

Myprofile.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

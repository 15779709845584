import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import Breakpoints from "../../../../Breakpoints";

import CardPage from "../../../Layout/CardPage";
import Avatar from "../../../Contacts/Avatar";

import Form from "ae-forms";
import ChangePicSchema from "../../../../schemas/ChangePic";

import Style from "ae-style";

var styles = StyleSheet.create({
	image: {
		flexDirection: "row",
		marginVertical: Style.layout.marginLarge,
		justifyContent: "center"
	}
});

export default function ProfilePictureChoose(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;

	var model = props.model;
	var preferences = model.personal;
	var saving = model.saving;
	var toast = model.toast;
	var openCropper = dispatch("personalprofilepicture:trigger:start");

	var screen = model.size;

	var changepictitle = localize("profilepicturechoose.changepictitle");
	var backtitle = localize("profilepicturechoose.backtitle");

	var sizeAvatar = "large";
	if (Breakpoints.smallerThan("mobile")) {
		sizeAvatar = "medium";
	}
	if (Breakpoints.smallerThan("small")) {
		sizeAvatar = "verysmall";
	}
	return (
		<CardPage title={changepictitle} icon="camera" backTitle={backtitle} saving={saving} toast={toast} screen={screen}>
			<View style={styles.image}>
				<Avatar person={preferences} size={sizeAvatar} />
			</View>
			<Form schema={ChangePicSchema} autoSubmit onSubmit={openCropper} />
		</CardPage>
	);
}

ProfilePictureChoose.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

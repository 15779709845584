import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import BackButton from "ae-base/Base/BackButton";
import HeaderImage from "../../../Effects/HeaderImage";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

var styles = StyleSheet.create({
	text: {
		alignItems: "center",
		marginVertical: Style.layout.marginBordered,
	},
	backbuttonMargin: {
		margin: Style.layout.paddingMedium
	}
});

export default function ManagedManagerHeader(props, context){
	var contact = props.contact;
	var config = context.config;

	var title = props.title;
	var edit = props.edit;

	var localize = context.localize;
	var gobackusersprofile = localize("managedmangerspermissions.gobackcareteam");

	var name = contact.lname + ", " + contact.fname;

	return (
		<HeaderImage source={ImageUrl(contact.image_medium, config)} style={styles.container}>
			<View style={styles.backbuttonMargin}>
				<BackButton title={gobackusersprofile}>{edit}</BackButton>
			</View>
			<View style={styles.text}>
				<StyledText smallHeading bold contrast>{title}</StyledText>
				<StyledText emphasized contrast>{name}</StyledText>
			</View>
		</HeaderImage>
	);
}

ManagedManagerHeader.contextTypes = {
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet
} from "react-native";

import StyledButton from "ae-base/Base/StyledButton";
import Divider from "../../../Layout/Divider";
import Style from "@aetonix/styles";
import InputLabel from "ae-forms/src/Inputs/Layout/InputLabel";
import ScheduleSchema from "../../../../schemas/ScheduleOnOff";
import BlockView from "ae-base/Base/BlockView";

var styles = StyleSheet.create({
	divider: {
		marginTop: Style.layout.marginContentSmall
	},
	container: {
		marginTop: Style.layout.marginContentSmall,
		marginBottom: Style.layout.marginContentSmall
	}
});

export default function ScheduleOnline(props, context){
	var dispatch = context.dispatch;

	var localize = context.localize;
	var buttontitle = localize("scheduleonline.buttontitle");

	return (
		<BlockView style={styles.container}>
			<InputLabel schema={ScheduleSchema} />
			<StyledButton contrast id={context.id_prefix + "scheduleOnlineButton"} title={buttontitle} color="grey" onPress={dispatch("desktop:trigger:page", {
				page: "schedule"
			})} />
			<BlockView style={styles.divider}>
				<Divider />
			</BlockView>
		</BlockView>
	);

}

ScheduleOnline.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

import MainScroll from "../../Layout/MainScroll";
import PropTypes from "prop-types";
import Style from "@aetonix/styles";
import { DATE_TIME_FORMAT } from "../../../utils/date-utils";

var styles = StyleSheet.create({
	container: {
		backgroundColor: "#131618",
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 235,
		padding: Style.layout.paddingButton,
		marginRight: Style.layout.marginMedium
	},
	button: {
		marginBottom: Style.layout.marginLarge,
		paddingLeft: Style.layout.paddingSmall,
		alignItems: "center",
		flexDirection: "row"
	},
	scroll: {
		flexDirection: "column"
	},
	textStyle: {
		flex: 1,
		flexWrap: "wrap"
	},
	back: {
		padding: Style.layout.marginContentSmall,
		backgroundColor: Style.colors.secondary,
		borderRadius: 10,
		alignSelf: "stretch"
	}
});

export default function SubmissionsList(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;
	var submissions = props.submissions;
	var activeSubmission = props.active;

	var backTitle = localize("myhealth.back");
	var goBack = dispatch("ownformsubmissions:trigger:clear");
	var renderSubmissions = submissions.map(function(submission){
		var active = submission._id === activeSubmission;
		return (
			<Submission submission={submission} active={active} />
		);
	});

	if(!renderSubmissions.length)
		renderSubmissions = <StyledText style={{color:'#4A959B'}}>{localize("atouchawayforms.nosubmissions")}</StyledText>

	return (
		<View style={styles.container}>
			<MainScroll atouchaway style={styles.scroll}>
				{renderSubmissions}
			</MainScroll>
			<StyledButton icon={"chevron-left"} style={styles.back} centered contrast tertiary title={backTitle} onPress={goBack} />
		</View>
	);
}

function Submission(props, context) {
	var dispatch = context.dispatch;
	var formatDate = context.formatDate;
	var submission = props.submission;
	var active = props.active;

	var updated_at = submission.updated_at;

	var format_date = formatDate(updated_at, DATE_TIME_FORMAT);

	var openSection = dispatch("activeownformsubmission:trigger:view", {
		id: submission._id
	});

	return (
		<TouchableOpacity onPress={openSection} style={styles.button}>
			<StyledText style={{color:'#4A959B'}} tertiary={!active} contrast={active}>{format_date}</StyledText>
		</TouchableOpacity>
	);
}

Submission.contextTypes = {
	formatDate: PropTypes.func,
	dispatch: PropTypes.func
};

SubmissionsList.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

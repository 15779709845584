import "./bootstrap.js";
import {
	AppRegistry,
} from "react-native";
import global from "global";
import prop from "prop";

import mockingbird from "./mockingbird";
import Multibird from "./MultiBird";

var CORDOVA_FAIL_DELAY = 5 * 1000;

import fontAwesome from "react-native-vector-icons/Fonts/FontAwesome.ttf";
var fontAwesomeStyles = "@font-face { src:url(" + fontAwesome + ") format(\"truetype\"); font-family: FontAwesome; }";

import nunito from "./Resources/fonts/Nunito/Nunito-Regular.ttf";
var nunitoStyles = "@font-face { src:url(" + nunito + ") format(\"truetype\"); font-family: Nunito; }";

import aetonixIcons from "./Resources/fonts/aetonix-icons/icomoon.ttf";
var aetonixIconStyles = "@font-face { src:url(" + aetonixIcons + ") format(\"truetype\"); font-family: icomoon; }";

import genomicons from "./Resources/fonts/genomicons/SSGeomiconsSquared.ttf";
var genomiconsStyles = "@font-face { src:url(" + genomicons + ") format(\"truetype\"); font-family: SSGeomiconsSquared; }";

import symbola from "./Resources/fonts/Symbola/Symbola.ttf";
var symbolaStyles = "@font-face { src:url(" + symbola + ") format(\"truetype\"); font-family: Symbola; }";

var document = global.document;

var allStyles = fontAwesomeStyles + nunitoStyles + aetonixIconStyles + genomiconsStyles + symbolaStyles;

// create stylesheet
var style = document.createElement("style");
style.type = "text/css";
if (style.styleSheet){
	style.styleSheet.cssText = allStyles;
} else {
	style.appendChild(document.createTextNode(allStyles));
}

global.onerror = function(msg, url, lineNo, columnNo, error){
	console.error((error || {}).stack || msg);
};

// inject stylesheet
document.head.appendChild(style);

// Only apply this fix if using iOS with
if(prop("cordova.plugins.phonertc")(global)) {
	var scroll = 0;
	document.addEventListener("touchstart", function () {
		scroll = document.body.scrollTop;
	});

	document.addEventListener("touchend", function (e) {
		if (scroll === document.body.scrollTop) {
			var node = e.target.nodeName.toString().toUpperCase();
			if (node === "INPUT" || node === "TEXTAREA" || node === "SELECT") {
				e.preventDefault();
				e.target.focus();
				if(node !== "SELECT") {
					var textLength = e.target.value.length;
					e.target.setSelectionRange(textLength, textLength);
				}
			}
		}
	});
}

AppRegistry.registerComponent("mockingbird", function () {
	if(global.localStorage.getItem("multibird")) {
		return Multibird;
	} else {
		return mockingbird;
	}
});

global.addEventListener("beforeunload", destroyApp, false);

var running = false;

if(global.cordova)
	runCordova();
else run();

function runCordova() {
	document.addEventListener("deviceready", run);
	setTimeout(run, CORDOVA_FAIL_DELAY);
}

function run() {
	if(running) return;
	running = true;
	AppRegistry.runApplication("mockingbird", {
		rootTag: document.getElementById("root")
	});
}

function destroyApp() {
	if(global.$app)
		global.$app.destroy();
}

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";

import ProfileSection from "../ProfileSection";
import BlockView from "ae-base/Base/BlockView";
import ContactSettings from "../../../../schemas/ContactSettings";

import Form from "ae-forms";

export default class DefaultContactSettings extends PureComponent {
	render(){
		const props = this.props;
		const context = this.context;
		const dispatch = context.dispatch;
		const localize = context.localize;
		const preferences = props.preferences;
		const display = props.profilesection.displaying.contactSettings;

		const title = localize("contactsettings.default");

		const defaultData = preferences.defaultContactSettings;

		const renderBody = display ? (
			<Form schema={ContactSettings} defaultData={defaultData} onSubmit={dispatch("preferences:trigger:preferences:update:contactSettings")} />
		) : null;

		return (
			<BlockView>
				<ProfileSection title={title} section="contactSettings" display={display} />
				{renderBody}
			</BlockView>
		);
	}
}

DefaultContactSettings.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";
import memoize from "memoizee";
import xtend from "xtend";

import Form from "ae-forms";

import CareplanSectionsSchema from "../../../../../schemas/CareplanSections";

import SettingSection from "../SettingSection";

var combineSchemas = memoize(combineSchemasRaw);

var ATOUCHAWAYCAREPLANSETTING = {
	type: "object",
	properties: {
		careplan: {
			description: "atouchawaysettings.careplan",
			format: "switch",
			inputType: "switch",
			type: "boolean"
		},
	}
};

export default function Careplan(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;
	var display = props.display;
	var model = props.model;
	var toggle = props.onToggle;

	var atouchaway = model.manage.atouchaway || {};

	var title = localize("atasettings.careplan") || "Careplan";

	var schema = combineSchemas([ATOUCHAWAYCAREPLANSETTING, CareplanSectionsSchema]);

	var renderBody = display ? (
		<Form key={"careplan"} schema={schema} defaultData={atouchaway} onSubmit={dispatch("managedatouchaway:trigger:update")} />
	) : null;

	return (
		<View>
			<SettingSection section="careplan" title={title} display={display} onToggle={toggle} />
			{renderBody}
		</View>
	);
}

function combineSchemasRaw(schemas) {
	return schemas.reduce(function(prev, current){
		var prevProperties = prev.properties;
		var currentProperties = current.properties;

		var mergedProperties = xtend(prevProperties, currentProperties);

		return xtend(prev, {
			properties: mergedProperties
		});
	});
}

Careplan.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
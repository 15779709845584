import React, { useMemo } from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";
import PropTypes from "prop-types";
import MainScroll from "../../Layout/MainScroll";

var MYHEALTH_BUTTONS = [{
	title: "myhealth.overview", section: "overview", "icon": "list"
}, {
	title: "myhealth.activity", section: "activity", "icon": "stock"
}, {
	title: "myhealth.bloodsugar", section: "bloodsugar", "icon": "rocket"
},  {
	title: "myhealth.weight", section: "weight", "icon": "user"
}, {
	title: "myhealth.bloodpressure", section: "bloodpressure", "icon": "heartbeat"
}, {
	title: "myhealth.oxygen", section: "bloodoxygen", "icon": "stethoscope"
}, {
	title: "myhealth.bodytemp", section: "bodytemperature", "icon": "thermometer"
}];

import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	container: {
		backgroundColor: "#131618",
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 215,
		padding: Style.layout.paddingButton,
		marginRight: Style.layout.marginSmall,
	},
	button: {
		marginBottom: Style.layout.paddingButton,
		marginTop: Style.layout.paddingButton,
		justifyContent: "center",
		flexDirection: "row",
		alignItems: "center",
		flex: 4
	},
	scroll: {
		flexDirection: "column",
	},
	textStyle: {
		flex: 3,
		marginHorizontal: Style.layout.paddingSmall,
		flexWrap: "wrap",
	},
	back: {
		marginTop: Style.layout.marginSmall,
		padding: Style.layout.marginContentSmall,
		backgroundColor: Style.colors.secondary,
		borderRadius: 10,
		alignSelf: "stretch"
	}
});

export default function MyHealthList(props, context) {

	const { customIndicatorsList, customIndicator } = props;

	var localize = context.localize;
	const localizeFrom = context.localizeFrom;
	var dispatch = context.dispatch;
	var section = props.section;
	var settings = props.settings;
	var sectionPermissions = settings.myhealth_sections || {};

	var back_title = localize("myhealth.back");
	var go_back = dispatch("atouchawaymobile:trigger:page", {
		page: "contacts"
	});

	var buttons = MYHEALTH_BUTTONS.reduce(function(acc, button){
		var currentSection = button.section;
		var isActive = button.section === section;
		if(sectionPermissions[button.section] || !(button.section in sectionPermissions))
			acc = acc.concat(<MyHealthSection section={button} key={currentSection} active={isActive} />);
		return acc;
	}, []);

	const customIndicatorButtons = customIndicatorsList?.reduce?.((accum, indicator) => {
		const button = {
			title: "myhealth.indicator",
			section: "customindicator",
			icon: "medkit",
		};

		var isActive = section === "customindicator" && (customIndicator.indicatorId === indicator._id);

		if(sectionPermissions[button.section] || !(button.section in sectionPermissions)) {
			accum = [
				...accum,
				<MyHealthSection
					section={button}
					indicator={indicator}
					key={`${button.section}_${indicator?.["_id"]}`}
					active={isActive}
					title={localizeFrom(indicator?.localization ?? {}, ".")}
				/>
			];
		}

		return accum;
	}, []);

	const healthButtons = [...buttons, ...customIndicatorButtons];

	return (
		<View style={styles.container}>
			<MainScroll atouchaway style={styles.scroll}>
				{healthButtons}
			</MainScroll>
			<StyledButton
				icon={"chevron-left"}
				style={styles.back}
				centered
				contrast
				tertiary
				title={back_title}
				onPress={go_back}
			/>
		</View>
	);
}

function MyHealthSection(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;
	var section = props.section;
	var active = props.active;

	var icon = section.icon;
	var title = props.title ? props.title : localize(section.title);
	var style = StyleSheet.create({ 
		icon: {
			flex: 1,
			alignItems: "flex-start",
			color: active ? '#FFFFFF' : '#4A959B'
		},
	})

	let openSection;

	if(section.section === "customindicator")
		openSection = dispatch("customindicator:trigger:view", {
			indicatorId: props?.indicator?._id,
			indicatorUnit: props?.indicator?.unit
		});
	else
		openSection = (section.section === "overview" || section.section === "contacts") ? dispatch("atouchawaymobile:trigger:page", {
			page: section.section
		}) : dispatch(section.section + ":trigger:view", {
			who: section.section
		});

	return (
		<TouchableOpacity onPress={openSection} style={styles.button}>
			<StyledIcon tertiary={!active} large name={icon} contrast={active} style={style.icon} size={Style.text.largeHeading} />
			<StyledText style={styles.textStyle} light={!active} size={18} contrast={active}>{title}</StyledText>
		</TouchableOpacity>
	);
}

MyHealthList.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func,
	localizeFrom: PropTypes.func,
};

MyHealthSection.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func,
};


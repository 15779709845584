import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

var getAssessmentName = prop("AssessmentName");
var getCompleted = prop("Completed");
var getCompletedDate = prop("CompletedDate");
var getAssessmentScore = prop("AssessmentScore");
var getActionsTaken = prop("ActionsTaken");
var getAssessmentType = prop("AssessmentType.Name");

export default function Healthassessments(props, context) {
	var careplan = props.careplan;

	var localize = context.localize;
	var healthassessmentsTitle = localize("healthassessments.healthassessment_s");
	var assssments = localize("healthassessments.assessments");

	var healthassessments = careplan.HealthAssessment || [];

	var renderHealthAssessment = healthassessments.map(function(healthassessment, index){
		return (
			<HealthAssessment key={index} healthassessment={healthassessment} index={index} />
		);
	});

	return (
		<Section title={healthassessmentsTitle}>
			<Title title={assssments} />
			{renderHealthAssessment}
		</Section>
	);
}

class HealthAssessment extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var healthassessment = props.healthassessment;

		var localize = context.localize;
		var assessmenttype = localize("healthassessments.assessmenttype");
		var assessmentname = localize("healthassessments.assessmentname");
		var completed = localize("healthassessments.completed");
		var completeddate = localize("healthassessments.completeddate");
		var score = localize("healthassessments.score");
		var actionstaken = localize("healthassessments.actionstaken");

		return (
			<Group>
				<Item title={assessmenttype} value={getAssessmentType(healthassessment)} />

				<Item title={assessmentname} value={getAssessmentName(healthassessment)} />

				<Item title={completed} value={getCompleted(healthassessment)} />

				<Item title={completeddate} value={getCompletedDate(healthassessment)} />

				<Item title={score} value={getAssessmentScore(healthassessment)} />

				<Item title={actionstaken} value={getActionsTaken(healthassessment)} />
			</Group>
		);
	}
}

Healthassessments.contextTypes = {
	config: PropTypes.object,
	localize: PropTypes.func
};

HealthAssessment.contextTypes = {
	localize: PropTypes.func
};

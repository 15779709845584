import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";

import AtaContainer from "./AtaContainer";
import StyledButton from "ae-base/Base/StyledButton";
import ScreenSaver from "./ScreenSaver";

export default class PowerButton extends PureComponent {
	render(){
		var model = this.props.model;
		var context = this.context;
		var dispatch = context.dispatch;
		var handlePowerButton = dispatch("app:trigger:power");

		var screensaver = model.screensaver;
		var localize = context.localize;
		var powerbutton = localize("powerbutton.powerbutton");

		if(screensaver)
			return <ScreenSaver type={screensaver} />;

		return (
			<AtaContainer>
				<StyledButton secondary contrast title={powerbutton} icon="power-off" onPress={handlePowerButton} largeHeading />
			</AtaContainer>
		);
	}
}

PowerButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

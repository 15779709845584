import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";
import noop from "noop3";

export default function CallCompleted(props, context){
	var dispatch = context.dispatch;
	var notification = props.notification;
	var localize = context.localize;
	var screen = props.screen;
	var  contacts = props.contacts || [];
	var callcompleted = localize("callnotification.callcompleted", {
		timestamp: notification.updated_at
	});

	var goToNotification = dispatch("calling:trigger:call", {
		who: notification.from._id,
		reason: "screen"
	});

	var isContact = contacts.find((contact) => (contact._id ===  notification.from._id));

	var openNotification = (isContact) ? goToNotification : noop ;
	return (
		<TimelineItem content={callcompleted} iconName="phone" onPress={openNotification} clearable notification={notification} screen={screen} />
	);

}

CallCompleted.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

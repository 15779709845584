import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View
} from "react-native";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

var getSocialInteractionInLast7DaysOther = prop("SocialInteractionInLast7DaysOther");
var getOtherConsiderations = prop("OtherConsiderations");
var getEmploymentName = prop("Employment.Name");
var getFollowRecommendedDietName = prop("FollowRecommendedDiet.Name");
var getFoodAdequateName = prop("FoodAdequate.Name");
var getHousingAdequateName = prop("HousingAdequate.Name");
var getHowDifficultUnderstandHealthInfoName = prop("HowDifficultUnderstandHealthInfo.Name");
var getIncomeAdequacyName = prop("IncomeAdequacy.Name");
var getPhysicalActivity5TimesPerWeekName = prop("PhysicalActivity5TimesPerWeek.Name");
var getSocialInteractionInLast7DaysName = prop("SocialInteractionInLast7Days.Name");
var getTravelDifficultyName = prop("TravelDifficulty.Name");
var getTravelMethodName = prop("TravelMethod.Name");
var getUsedOtherSubstancesName = prop("UsedOtherSubstances.Name");
var getSubstanceName = prop("Substance.Name");
var getSubstanceFrequently = prop("HowFrequently.Name");
var getSubstanceRecently = prop("HowRecently.Name");
var getSmokesTobaccoName = prop("SmokesTobacco.Name");
var getDrinksAlcoholName = prop("DrinksAlcohol.Name");
var getGambleResponsiblyName = prop("GambleResponsibly.Name");

export default function Situations(props, context) {
	var careplan = props.careplan;

	var localize = context.localize;
	var situationlifestyle = localize("situation.situationlifestyle");
	var supplementarybenefitsirecieve = localize("situation.supplementarybenefitsirecieve");
	var howiwork = localize("situation.howiwork");
	var howadequateismyincomeisformyhealth = localize("situation.howadequateismyincomeisformyhealth");
	var ifollowmyrecommendeddiet = localize("situation.ifollowmyrecommendeddiet");
	var howadequatemyfoodisformyhealth = localize("situation.howadequatemyfoodisformyhealth");
	var howitravel = localize("situation.howitravel");
	var howdifficultitistotravel = localize("situation.howdifficultitistotravel");
	var howdifficultitistoreadandunderstandinformationaboutmyhealth = localize("situation.howdifficultitistoreadandunderstandinformationaboutmyhealth");
	var howadequatemyhousingisformyhealth = localize("situation.howadequatemyhousingisformyhealth");
	var ihaveusedothersubstances = localize("situation.ihaveusedothersubstances");
	var igetminutesofphysicalactivity = localize("situation.igetminutesofphysicalactivity");
	var ihavehadsocialinteractioninthelastsevendays = localize("situation.ihavehadsocialinteractioninthelastsevendays");
	var otherconsiderations = localize("situation.otherconsiderations");

	var SocialInteractionInLast7Days = getSocialInteractionInLast7DaysOther(careplan) || getSocialInteractionInLast7DaysName(careplan);

	var supplementaryBenefits = careplan.SupplementaryBenefit || [];
	var renderSupplementaryBenefits = supplementaryBenefits.map((supplementarybenefit, index) =>
		<SupplementaryBenefits key={index} supplementarybenefit={supplementarybenefit} />
	);

	var renderTobacco = (
		<Tobacco careplan={careplan} />
	);

	var renderAlcohol = (
		<Alcohol careplan={careplan} />
	);

	var renderGambleResponsibly = (
		<Gamble careplan={careplan} />
	);

	var subStances = careplan.UsedSubstance || [];
	var renderSubstances = subStances.map((substance, index) =>
		<Substances key={index} substance={substance} />
	);

	return (
		<Section title={situationlifestyle}>
			<Group>
				<Title title={supplementarybenefitsirecieve} />
				{renderSupplementaryBenefits}
			</Group>

			<Item title={howiwork} value={getEmploymentName(careplan)} />

			<Item title={howadequateismyincomeisformyhealth} value={getIncomeAdequacyName(careplan)} />

			<Item title={ifollowmyrecommendeddiet} value={getFollowRecommendedDietName(careplan)} />

			<Item title={howadequatemyfoodisformyhealth} value={getFoodAdequateName(careplan)} />

			<Item title={howitravel} value={getTravelMethodName(careplan)} />

			<Item title={howdifficultitistotravel} value={getTravelDifficultyName(careplan)} />

			<Item title={howdifficultitistoreadandunderstandinformationaboutmyhealth} value={getHowDifficultUnderstandHealthInfoName(careplan)} />

			<Item title={howadequatemyhousingisformyhealth} value={getHousingAdequateName(careplan)} />

			{renderTobacco}

			{renderAlcohol}

			<Group>
				<Item title={ihaveusedothersubstances} value={getUsedOtherSubstancesName(careplan)} />
				{renderSubstances}
			</Group>

			{renderGambleResponsibly}

			<Item title={igetminutesofphysicalactivity} value={getPhysicalActivity5TimesPerWeekName(careplan)} />

			<Item title={ihavehadsocialinteractioninthelastsevendays} value={SocialInteractionInLast7Days} />

			<Item title={otherconsiderations} value={getOtherConsiderations(careplan)} />
		</Section>
	);
}

class SupplementaryBenefits extends PureComponent {
	render(){
		var props = this.props;
		var supplementarybenefit = props.supplementarybenefit;
		return (
			<Item title={null} value={supplementarybenefit.Name} />
		);
	}
}

function Tobacco(props, context){
	var careplan = props.careplan;
	var smoke = getSmokesTobaccoName(careplan);
	var perDay = careplan.NumberOfCigarettesPerDay;
	var perYear = careplan.NumberOfPackYears;
	var quit = careplan.QuitSmokingDate;

	var localize = context.localize;
	var ismoketobacco = localize("situation.ismoketobacco");
	var cigarettesday = localize("situation.cigarettesday");
	var packsyear = localize("situation.packsyear");
	var quitsmoking = localize("situation.quitsmoking");

	return (
		<Group>
			<Item title={ismoketobacco} value={smoke} />
			{
				smoke === "Yes" ? (
					<View>
						<Item title={cigarettesday} value={perDay} />
						<Item title={packsyear} value={perYear} />
						{
							quit ? (
								<Item title={quitsmoking} value={quit} />
							) : null
						}
					</View>
				) : null
			}
		</Group>
	);
}

function Alcohol(props, context){
	var careplan = props.careplan;
	var drink = getDrinksAlcoholName(careplan);
	var perSitting = careplan.NumberOfDrinksOneSitting;
	var perWeek = careplan.NumberOfDrinksPerWeek;

	var localize = context.localize;
	var idrinkalcohol = localize("situation.idrinkalcohol");
	var drinksinonesitting = localize("situation.drinksinonesitting");
	var drinksweek = localize("situation.drinksweek");

	return (
		<Group>
			<Item title={idrinkalcohol} value={drink} />
			{
				drink === "Yes" ? (
					<View>
						<Item title={drinksinonesitting} value={perSitting} />
						<Item title={drinksweek} value={perWeek} />
					</View>
				) : null
			}
		</Group>
	);
}

class Substances extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var substance = props.substance;

		var localize = context.localize;
		var howrecently = localize("situation.howrecently");
		var howfrequently = localize("situation.howfrequently");

		return (
			<View>
				<Title title={getSubstanceName(substance)} />
				<Item title={howrecently} value={getSubstanceRecently(substance)} />
				<Item title={howfrequently} value={getSubstanceFrequently(substance)} />
			</View>
		);
	}
}

function Gamble(props, context){
	var careplan = props.careplan;
	var gamble = getGambleResponsiblyName(careplan);
	var recent = careplan.MostRecentDateGambled;
	var number = careplan.NumberOfDaysGambledIn90Days;

	var localize = context.localize;
	var igambleresponsibly = localize("situation.igambleresponsibly");
	var mostrecentdateigambled = localize("situation.mostrecentdateigambled");
	var numberofdaysinlast90days = localize("situation.numberofdaysinlast90days");

	return (
		<Group>
			<Item title={igambleresponsibly} value={gamble} />
			<Item title={mostrecentdateigambled} value={recent} />
			<Item title={numberofdaysinlast90days} value={number} />
		</Group>
	);
}

Situations.contextTypes = {
	config: PropTypes.object,
	localize: PropTypes.func
};

Tobacco.contextTypes = {
	localize: PropTypes.func
};

Alcohol.contextTypes = {
	localize: PropTypes.func
};

Gamble.contextTypes = {
	localize: PropTypes.func
};

Situations.propTypes = {
	model: PropTypes.object,
};

SupplementaryBenefits.propTypes = {
	supplementarybenefit: PropTypes.object,
};

Substances.propTypes = {
	substance: PropTypes.object,
};

Substances.contextTypes = {
	localize: PropTypes.func
};

import React from "react";
import PropTypes from "prop-types";
import MyHealthComponent from "./MyHealthComponent";

export default function AtouchAwayActivityMetrics (props){
	var model = props.model;
	var activity = model.activity;

	var steps = activity.cumulative;
	var reverse_steps = activity.reverse; // needed for chart
	var today = null;
	var section = model.page;
	var textFormat = "{cumulative}";

	// If the bracelet is a metawear and the model
	// is 'meta' ie. auto read, then hide the 'read from device' button
	var automatic = model.metawearauto;
	var readFromDevice = automatic ? null : props.readFromDevice ;

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = reverse_steps.slice(-24); // for chart
		today = steps[steps.length - 1].cumulative;
	}

	return (
		<MyHealthComponent model={model}
			section={section}
			textformat={textFormat}
			latestReading={today}
			reverseEntries={graphdata}
			activeModel={activity}
			xaxis={"updated_at"}
			yaxis={"cumulative"}
			bar
			readFromDevice={readFromDevice} />
	);
}

AtouchAwayActivityMetrics.contextTypes = {
	dispatch: PropTypes.func
};

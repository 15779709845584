import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
	Dimensions
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";
import AtaContainer from "./AtaContainer";

import Contact from "./Contact";

import MainScroll from "../Layout/MainScroll";

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		padding: 40
	},
	outer: {
		flex: 1,
		flexDirection: "column"
	},
	center: {
		alignSelf: "center",
		padding: 32
	},
	contacts: {
		flex: 1,
	},
	contactBox: {
		margin: 8,
	},
	padding: {
		padding: 32
	}
});

export default function ConferenceInviter(props, context){
	var dispatch = context.dispatch;
	var list = props.list || [];
	var calling = props.calling;
	var availability = props.availability;
	var voiceOnly = calling.streams.voiceOnly;
	var onBack = dispatch("conferenceinviter:trigger:close");
	var screen = props.screen;

	var localize = context.localize;
	var whodoyouwishtoinvite = localize("conferenceinviter.whodoyouwishtoinvite");
	var gobackwithoutinvitinganyone = localize("conferenceinviter.gobackwithoutinvitinganyone");

	return (
		<AtaContainer>
			<View style={styles.outer}>
				<View style={styles.center}>
					<StyledText bold contrast largeHeading>{whodoyouwishtoinvite}</StyledText>
				</View>
				<MainScroll style={styles.contacts}>
					<ContactList list={list} availability={availability} voiceOnly={voiceOnly} screen={screen} />
				</MainScroll>
				<BlockView style={styles.padding}>
					<StyledButton icon={"navigateleft"} title={gobackwithoutinvitinganyone} bold contrast clear large onPress={onBack} />
				</BlockView>
			</View>
		</AtaContainer>
	);
}

function ContactList(props){
	var list = props.list || [];
	var availability = props.availability;
	var voiceOnly = props.voiceOnly;

	if(!list || !list.length) return (
		null
	);

	var screenWidth = Dimensions.get("window").width;

	var contactBoxStyles = [styles.contactBox, {
		width: screenWidth / 4,
		height: screenWidth / 4
	}];

	var renderedButtons = list.map(function(contact, index){
		var id = contact._id;
		var status = availability[id];
		return (
			<View key={index} style={contactBoxStyles}>
				<Contact key={id} contact={contact} status={status} conference voiceOnly={voiceOnly} />
			</View>
		);
	});

	return (
		<View style={styles.buttons}>
			{renderedButtons}
		</View>
	);
}

ConferenceInviter.contextTypes = {
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import React, { useContext } from "react";
import PropTypes from "prop-types";
export const LocalizeContext = React.createContext(() => {});

export const LocalizeProvider = (props, context) => {
    return <LocalizeContext.Provider {...props} value={context.localize} />;
};

export const LocalizeConsumer = LocalizeContext.Consumer;

export const useLocalize = () => {
    return useContext(LocalizeContext);
};

LocalizeProvider.contextTypes = {
    formatDate: PropTypes.func,
    localize: PropTypes.func,
    localizeFrom: PropTypes.func,
    dispatch: PropTypes.func
};

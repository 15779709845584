import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import MainScroll from "../../Layout/MainScroll";
import StyledText from "ae-base/Base/StyledText";
import Markdown from "ae-base/Base/Markdown";

import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		marginHorizontal: Style.layout.marginMedium,
		flexDirection: "column"
	},
	content: {
		flex: 1,
		padding: Style.layout.paddingButton,
		borderWidth: 2,
		borderColor: Style.colors.tertiary,
	},
	scroll: {
		flex: 1,
		paddingHorizontal: Style.layout.paddingButton,
	}
});

export default function ResourcesView(props, context){
	var localizeFrom = context.localizeFrom;
	var resources = props.resources;
	var viewingResource = resources.viewingResource;
	var resourcesList = resources.resources;
	var resourceViewed = resourcesList.find(function(resource) {
		return resource._id === viewingResource;
	});
	var title = resourceViewed.name;
	var resourceData = resourceViewed.data;

	if(typeof resourceViewed.name === "object" && typeof resourceViewed.data === "object"){
		title = localizeFrom(resourceViewed.name, ".");
		resourceData = localizeFrom(resourceViewed.data, ".");
	}

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<MainScroll atouchaway style={styles.scroll}>
					<View>
						<StyledText light bold smallHeading>{title}</StyledText>
					</View>
					<Markdown contrast>
						{resourceData}
					</Markdown>
				</MainScroll>
			</View>
		</View>
	);
}

ResourcesView.contextTypes = {
	localizeFrom: PropTypes.func
};

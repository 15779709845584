import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function ManagedNew(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var message = localize("banner.managedusernew", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={message} iconName="info"  clearable notification={notification} screen={screen} />
	);

}

ManagedNew.contextTypes = {
	localize: PropTypes.func,
};

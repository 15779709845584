import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";

import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import MainContent from "../../Layout/MainContent";
import HealthHeader from "./HealthHeader";
import OfflineBanner from "../Homepage/OfflineBanner";

export default class MyHealthSection extends PureComponent {
	render(){
		var props = this.props;
		var children = props.children;
		var title = props.title;
		var external = props.external;
		var editButton = props.edit;
		var saving = props.saving;
		var toast = props.toast;

		var offline = props.online ? null : (
			<OfflineBanner />
		);

		return (
			<PageContainer saving={saving} toast={toast}>
				<MainScroll>
					<HealthHeader title={title} edit={editButton} back={props.back} />
					{offline}
					<MainContent>
						{children}
					</MainContent>
				</MainScroll>
				{external}
			</PageContainer>
		);
	}
}

MyHealthSection.propTypes = {
	name: PropTypes.string,
};

MyHealthSection.contextTypes = {
	dispatch: PropTypes.func,
};

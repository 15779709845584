import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";

import MainScroll from "../../Layout/MainScroll";
import PropTypes from "prop-types";
import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	container: {
		backgroundColor: "#131618",
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 235,
		padding: Style.layout.paddingButton
	},
	button: {
		marginBottom: Style.layout.marginLarge,
		paddingHorizontal: Style.layout.paddingSlim,
		alignItems: "center",
		flexDirection: "row"
	},
	scroll: {
		flexDirection: "column"
	},
	textStyle: {
		flex: 1,
		flexWrap: "wrap",
	},
	back: {
		marginTop: Style.layout.marginSmall,
		padding: Style.layout.marginContentSmall,
		backgroundColor: Style.colors.secondary,
		borderRadius: 10,
		alignSelf: "stretch"
	}
});

export default function ResourcesList(props, context) {
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;
	var dispatch = context.dispatch;
	var resources = props.resources;
	var resourcesList = resources.resources || [];
	var viewingResource = resources.viewingResource;
	var active = false;
	var back_title = localize("myhealth.back");
	var go_back = dispatch("ownresources:trigger:close")

	function by_name(x, y) {
		x = x.name;
		y = y.name;
		if (typeof x === "object")
			x = localizeFrom(x, ".");
		if (typeof y === "object")
			y = localizeFrom(y, ".");
		let locale = props.model.personal.language;
		if (locale === "cn_s" || locale === "cn_t")
			locale = (locale === "cn_s") ? "zh-CN" : "zh-HK";
		return x.localeCompare(y, locale);
	}

	var renderForms = resourcesList.sort(by_name).map(function(resource){
		active = resource._id === viewingResource;
		return (
			<Resource key={resource._id} resource={resource} active={active} />
		);
	});

	var renderEmptyResources = !resourcesList.length ? <StyledText centered contrast>{localize("resources.empty")}</StyledText> : null;

	return (
		<View style={styles.container}>
			<MainScroll atouchaway style={styles.scroll}>
				{renderForms}
				{renderEmptyResources}
			</MainScroll>
			<StyledButton icon={"chevron-left"} style={styles.back} centered contrast tertiary title={back_title} onPress={go_back} />
		</View>
	);
}

function Resource(props, context) {
	var localizeFrom = context.localizeFrom;
	var dispatch = context.dispatch;
	var resource = props.resource;
	var title = resource.name;
	var active = props.active;

	if(typeof resource.name === "object")
		title = localizeFrom(resource.name, ".");

	var openSection = dispatch("ownresources:trigger:viewresource", {
		id: resource._id
	});

	return (
		<TouchableOpacity onPress={openSection} style={styles.button}>
			<StyledText style={styles.textStyle} light={!active} contrast={active}>{title}</StyledText>
		</TouchableOpacity>
	);
}

ResourcesList.contextTypes = {
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	dispatch: PropTypes.func
};

Resource.contextTypes = {
	localizeFrom: PropTypes.func,
	dispatch: PropTypes.func
};

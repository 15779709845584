import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Heading from "ae-base/Base/Heading";
import Avatar from "../../../Contacts/Avatar";

import Form from "ae-forms";
import ChangePicSchema from "../../../../schemas/ChangePic";
import Breakpoints from "../../../../Breakpoints";

import Style from "ae-style";

var styles = StyleSheet.create({
	image: {
		flexDirection: "row",
		marginVertical: Style.layout.marginLarge,
		justifyContent: "center"
	}
});

export default class ProfilePictureChoose extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;

		var dispatch = context.dispatch;
		var account = props.account;

		var screen = props.screen;

		var localize = context.localize;
		var changeyourprofilepicture = localize("profilepicturechoose.changepictitle");

		var openCropper = dispatch("managedprofilepicture:trigger:start");

		var sizeAvatar = "large";
		if (Breakpoints.smallerThan("mobile")) {
			sizeAvatar = "medium";
		}
		if (Breakpoints.smallerThan("small")) {
			sizeAvatar = "verysmall";
		}

		return (
			<View>
				<Heading title={changeyourprofilepicture} icon="camera" screen={screen} />
				<View style={styles.image}>
					<Avatar person={account} size={sizeAvatar} />
				</View>
				<Form schema={ChangePicSchema} autoSubmit onSubmit={openCropper} />
			</View>
		);
	}
}

ProfilePictureChoose.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

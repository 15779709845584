import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";

import ProfileSection from "../ProfileSection";
import PersonalInfo from "./PersonalInfo";
import BlockView from "ae-base/Base/BlockView";

export default class AccountSettings extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var preferences = props.preferences;
		var display = props.profilesection.displaying.account;
		var localize = context.localize;

		var title = localize("accountsettings.title");
		var renderBody = null;

		if(display)
			renderBody = (
				<BlockView>
					<PersonalInfo preferences={preferences} />
				</BlockView>
			);

		return (
			<BlockView>
				<ProfileSection title={title} section="account" display={display} />
				{renderBody}
			</BlockView>);
	}
}

AccountSettings.contextTypes = {
	localize: PropTypes.func
};

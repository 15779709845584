import PropTypes from "prop-types";
import React, { useContext } from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import ImageUrl from "ae-base/Base/ImageUrl";
import Style from "ae-style";

import getVersion, { NewCallingVersion } from "../../utils/GetVersion";
import MeetingContext from "../../Calling/context/MeetingContext";
import useEnabledFeatures from "../../hooks/useEnabledFeatures/useEnabledFeatures";

var styles = StyleSheet.create({
	name: {
		flex: 1,
		justifyContent: "center",
		flexDirection: "column",
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		padding: 8,
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20
	},
	message: {
		flex: 1,
		justifyContent: "center",
		flexDirection: "column",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		padding: 8,
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20
	},
	nameOffline: {
		flex: 1,
		justifyContent: "center",
		flexDirection: "column",
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		padding: 8,
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20
	},
	item: {
		flex: 1,
		alignSelf: "stretch",
	},
	itemBorderRadius: {
		borderRadius: 20
	},
	itemOffline: {
		borderWidth: 2,
		borderRadius: 20,
		borderColor: Style.colors.alert
	},
	contentColor: {
		backgroundColor: Style.colors.alert
	},
	content: {
		width: null,
		height: null,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		borderRadius: 20
	},
	contentOffline: {
		width: null,
		height: null,
		flex: 1,
		borderRadius: 20,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		opacity: 0.2,
	},
	outer: {
		flex: 1,
		overflow: "hidden",
		borderRadius: 20
	},
});

export default function Contact(props, context){
		var config = context.config;
		var contact = props.contact;
		var message = props.message || {};
		var complex = props.complex;
		var messaging = props.messaging;
		var disableMessagePreview = props.disableMessagePreview;
		var disableName = props.disableName;
		var dispatch = context.dispatch;
		var id = contact._id;
		var name = contact.lname + ", " + contact.fname;
		var conference = props.conference;
		var status = props.status;
		var voiceOnly = props.voiceOnly;

		var localize = context.localize;
		var emergency = localize("atouchawaycontact.emergency");
		const { 
			isConfirming = false,
			confirmCall,
		} = props;

		const { isCallingEnabled } = useEnabledFeatures();
		const version = getVersion(contact.versionInfo || {});
		const meetingContext = useContext(MeetingContext);

		if(contact.name){
			name = contact.name;
			status = 1;
		}

		var viewMessage = complex ?
			dispatch("atouchawaymessaging:trigger:view:popup", {
				who: contact
			}) :
			dispatch("atouchawaymessaging:trigger:clear", {
				who: id
			});

		var dispatchCallFn = conference
			? (voiceOnly
				? dispatch("conferenceinviter:trigger:add", {
					who: id,
					voiceOnly: "true"
				})
				: dispatch("conferenceinviter:trigger:add", {
					who: id
				})
			)
			: (status
				? dispatch("calling:trigger:call", {
					who: id,
					reason: "screen"
				})
				: dispatch("calling:trigger:silent", {
					who: id,
					reason: "screen"
				}));
		
		let onCall = () => {
			if (isConfirming) {
				confirmCall(name, dispatchCallFn);
				return;
			}
			dispatchCallFn();
		}

		var displayMessage = !!message && !!message.content && !disableMessagePreview;
		var displayContent = (displayMessage && messaging === "intermediate") ? message.content.slice(0, 32) + "..." : message.content;

		var renderMessage = (displayMessage && message.content !== " " && (messaging === "basic" || messaging === "intermediate")) ? (
			<TouchableOpacity style={styles.message} onPress={viewMessage}>
				<StyledText black bold size={props.textSize} >{displayContent}</StyledText>
			</TouchableOpacity>
		) : null;

		const isNewCallingSupported = version && version.majorVersion >= NewCallingVersion;
		if (isNewCallingSupported && isCallingEnabled) {
			const onCreateMeeting = () => meetingContext.onCreateMeeting(config.id, `${config.lname}, ${config.fname}`, [], [contact._id]);
			const onSilentTrigger = dispatch("calling:trigger:silent", { who: id, reason: "screen" })

			onCall = status ? onCreateMeeting : onSilentTrigger;
		}

		var renderContact = status ? (
			<View style={styles.item}>
				<TouchableOpacity accessibilityComponentType="button" style={styles.outer} onPress={onCall}>
					<View style={styles.outer}>
						<Image resizeMethod="resize" source={ImageUrl(contact.image, config)} style={styles.content} />
						{renderMessage}
						{!disableName ? <View style={styles.name}>
							<StyledText black bold centered size={props.textSize} >{name}</StyledText>
						</View> : null}
					</View>
				</TouchableOpacity>
			</View>
		) : (
			<View style={[styles.item, styles.itemOffline]}>
				<TouchableOpacity accessibilityComponentType="button" style={styles.outer} onPress={onCall}>
					<View style={styles.outer}>
						<Image resizeMethod="resize" source={ImageUrl(contact.image, config)} style={styles.contentOffline} />
						{renderMessage}
						{!disableName ? <View style={styles.nameOffline}>
							<StyledText contrast bold centered size={props.textSize} >{name}</StyledText>
						</View> : null}
					</View>
				</TouchableOpacity>
			</View>
		);

		var urgencycall = dispatch("urgencycalls:trigger:call", {
			reason: "screen"
		});

		const onUrgencyCall = () => {
			if (isConfirming) {
				confirmCall(name, urgencycall);
				return;
			}
			urgencycall();
		}

		if(contact.listbutton)
			renderContact = (
				<View style={[styles.item, styles.contentColor, styles.itemBorderRadius]}>
					<TouchableOpacity accessibilityComponentType="button" style={styles.outer} onPress={onUrgencyCall}>
						<View style={styles.content}>
							<StyledIcon name="plus-circle" contrast hugeIcon />
							{!disableName && !disableMessagePreview ? <StyledText maxSize="emphasized" size={props.textSize} centered contrast>{emergency}</StyledText> : null}
						</View>
					</TouchableOpacity>
				</View>
			);

		return renderContact;
}

Contact.contextTypes = {
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Style from "ae-style";
import Breakpoints from "../../../../Breakpoints";
import BackButton from "ae-base/Base/BackButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		mainContent: {
			backgroundColor: Style.colors.primary,
			flexDirection: "row",
			alignItems: "center",
			padding: Style.layout.paddingSmall
		},
		text: {
			alignItems: "center",
			flex: 2,
		},
		iconStyle: {
			justifyContent: "flex-end",
			flexDirection: "row",
			flex: 1,
		},
		backButton: {
			padding: Style.layout.paddingSmall
		},
		container: {
			backgroundColor: Style.colors.primary,
			paddingTop: Style.layout.paddingSlim,
			flex: 2,
		},
		leftAligned: {
			backgroundColor: Style.colors.primary,
			paddingTop: Style.layout.paddingSlim,
			flex: 2
		},
		mainContainer: {
			backgroundColor: Style.colors.primary,
			paddingTop: Style.layout.paddingSlim,
			paddingHorizontal: Style.layout.paddingMedium,
			flex: 2,
		},
		controls: {
			justifyContent: "flex-end",
			flexDirection: "row",
			flex: 1
		},
		editContainer: {
			flexDirection: "row",
			padding: Style.layout.paddingSmall,
			paddingLeft: Style.layout.paddingMedium
		}
	}
});

export default function ManagedHeader(props, context){
	var contact = props.contact;

	var title = props.title;
	var edit = props.edit;
	var finish = props.finish;
	var subTitle = props.subTitle;
	var additionalTitles = props.additionalTitles;

	var localize = context.localize;
	var isMobile = Breakpoints.smallerThan("mobile");

	var backButtonTitle = props.back || localize("managedheader.gobackusersprofile");
	var renderSubtitle = subTitle ? (
		<StyledText centered={!isMobile} emphasized contrast>{subTitle}</StyledText>
	) : null;

	var renderAdditionalTitles = additionalTitles && Array.isArray(additionalTitles) ? additionalTitles.map(e => (
		<StyledText key={e} centered={!isMobile} standard contrast>{e}</StyledText>
	)) : null;


	let backPress = () => {
		if (!props.actionId) {
			context.dispatch("desktop:trigger:page:back")();
			return;
		}
		context.dispatch("activeworkflowaction:trigger:view", {
			action: props.actionId,
		})();
		context.dispatch("desktop:trigger:page:back", {
			action: props.actionId,
		})();
	};

	var name = contact.lname + ", " + contact.fname;

	if (isMobile) return (
		<View style={styles.mainContent}>
			<StyledIconButton clear contrast smallHeading icon="left" style={styles.backButton} onPress={backPress} />
			<View style={styles.leftAligned}>
				<StyledText smallHeading bold contrast>{title}</StyledText>
				<StyledText emphasized contrast>{name}</StyledText>
				{renderSubtitle}
				{renderAdditionalTitles}
			</View>
			<View style={styles.iconStyle}>
				{edit}
				<ButtonSpacer />
				{finish}
			</View>
		</View>
	);
	else return (
		<View style={styles.mainContainer}>
			<BackButton title={backButtonTitle} onPress={backPress}>
				<View style={styles.editContainer}>
					{edit}
					<ButtonSpacer />
					{finish}
				</View>
			</BackButton>
			<View style={styles.mainContent}>
				<View style={styles.container}>
					<StyledText centered smallHeading bold contrast>{title}</StyledText>
					<StyledText centered emphasized contrast>{name}</StyledText>
					{renderSubtitle}
					{renderAdditionalTitles}
				</View>
			</View>
		</View>
	);
}

ManagedHeader.contextTypes = {
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

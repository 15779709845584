import PropTypes from "prop-types";
import React, {
	Component
} from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity,
	Dimensions,
	Animated,
	Easing
} from "react-native";

import StyledText from "ae-base/Base/StyledText";

const CHANGE_INTERVAL = 1000;

var styles = StyleSheet.create({
	container: {
		backgroundColor: "#000000",
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0
	},
	view: {
		flex: 1,
		alignSelf: "stretch"
	}
});

// Message moves every 10 seconds

export default class ScreenSaver extends Component {

	constructor(props) {
		super(props);
		this._language = props.language || "en";
		this.state = {
			x: new Animated.Value(0),
			y: new Animated.Value(0),
			curTime: new Date()
		};

		this._dimensions = null;

		this._endX = false;
		this._endY = false;
		this.onLayout = onLayout.bind(this);
		this.cycleAnimationX = cycleAnimationX.bind(this);
		this.cycleAnimationY = cycleAnimationY.bind(this);
		this.finishX = finishX.bind(this);
		this.finishY = finishY.bind(this);
	}

	componentDidMount(){
		this.loadInterval = setInterval(() => {
			this.setState({
				curTime: new Date()
			});
		}, CHANGE_INTERVAL);
	}

	componentWillUnmount(){
		this.loadInterval && clearInterval(this.loadInterval);
		this.loadInterval = false;
	}

	render(){
		var context = this.context;
		var dispatch = context.dispatch;
		var clearScreensaver = dispatch("screensaver:trigger:clear");

		var type = this.props.type;

		if(type === "hide")
			return (
				<TouchableOpacity style={styles.container} onPress={clearScreensaver} />
			);

		var textStyle = {
			position: "absolute",
			left: 0,
			top: 0,
			transform: [
				{translateX: this.state.x},
				{translateY: this.state.y}
			]
		};

		var localize = context.localize;
		var message1 = localize("screensaver.touch");
		var message2 = localize("screensaver.atouchaway");

		return (
			<TouchableOpacity style={styles.container} onPress={clearScreensaver}>
				<Animated.View style={textStyle}>
					<View style={styles.view} onLayout={this.onLayout}>
						<StyledText contrast bold centered largeHeading>{formatDate(this.state.curTime)}</StyledText>
						<StyledText contrast bold centered largeHeading>{message2}</StyledText>
						<StyledText contrast bold centered largeHeading>{message1}</StyledText>
					</View>
				</Animated.View>
			</TouchableOpacity>
		);
	}
}

ScreenSaver.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

ScreenSaver.propTypes = {
	language: PropTypes.string
};

function onLayout(event){
	if(this._dimensions) return;
	let {width, height} = event.nativeEvent.layout;
	this._dimensions = {width, height};
	this.cycleAnimationX();
	this.cycleAnimationY();
}

function cycleAnimationX(){
	if(!this._dimensions) return;
	const viewWidth = (this._dimensions || {}).width;
	const screenWidth = Dimensions.get("window").width;
	let usableWidth = screenWidth - viewWidth - 15;
	if(this._endX)
		usableWidth = 0;

	Animated.timing(this.state.x, {
		toValue: usableWidth,
		duration: 15000,
		easing: Easing.linear,
		useNativeDriver: true
	}).start(this.finishX);
}

function cycleAnimationY(){
	if(!this._dimensions) return;
	const viewHeight = (this._dimensions || {}).height;
	const screenHeight = Dimensions.get("window").height;
	let usableHeight = screenHeight - viewHeight - 15;
	if(this._endY)
		usableHeight = 0;

	Animated.timing(this.state.y, {
		toValue: usableHeight,
		duration: 13000,
		easing: Easing.linear,
		useNativeDriver: true
	}).start(this.finishY);
}

function finishX(event){
	if(event.finished){
		this._endX = !this._endX;
		this.cycleAnimationX();
	}
}

function finishY(event){
	if(event.finished){
		this._endY = !this._endY;
		this.cycleAnimationY();
	}
}

function formatDate(date){
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	const year = date.getFullYear();

	const hours = date.getHours().toString().padStart(2, "0");
	const minutes = date.getMinutes().toString().padStart(2, "0");
	const seconds = date.getSeconds().toString().padStart(2, "0");

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

var getHeightInch = prop("BaselineVitals.HeightInch");
var getWeightLb = prop("BaselineVitals.WeightLb");
var getHbA1cPercent = prop("BaselineVitals.HbA1cPercent");
var getHeightCm = prop("BaselineVitals.HeightCm");
var getWeightKg = prop("BaselineVitals.WeightKg");
var getHbA1cMmolPerMol = prop("BaselineVitals.HbA1cMmolPerMol");

var getType = prop("IssueType.Name");
var getDescription = prop("HealthCondition.Description");
var getClinical = prop("HealthCondition.Condition");
var getOnset = prop("HealthCondition.OnsetNote");
var getStability = prop("HealthCondition.Stability.Name");
var getNotes = prop("HealthCondition.Notes");

export default function Healthconditions(props, context) {
	var careplan = props.careplan;

	var localize = context.localize;
	var healthIssuesTitle = localize("healthconditions.healthissues");
	var issues = localize("healthconditions.issues");
	var baselinevitals = localize("healthconditions.baselinevitals");
	var height = localize("healthconditions.height");
	var weight = localize("healthconditions.weight");
	var hba1c = localize("healthconditions.hba1c");

	var healthIssues = careplan.HealthIssue || [];

	var renderHealthIssue = healthIssues.map(function(issue, index){
		return (
			<Issue key={index} defaultData={issue} index={index} />
		);
	}
	);

	var getHeightUnit = getHeightCm(careplan) ? " cm" : " in";
	var getWeightUnit = getWeightKg(careplan) ? " kg" : " lb";
	var getHbA1cUnit = getHbA1cPercent(careplan) ? " %" : " mmol/mol";

	var heightValue = (getHeightCm(careplan) || getHeightInch(careplan) || "") + getHeightUnit;
	var weightValue = (getWeightKg(careplan) || getWeightLb(careplan) || "") + getWeightUnit;
	var hba1cValue = (getHbA1cPercent(careplan) || getHbA1cMmolPerMol(careplan) || "") + getHbA1cUnit;

	return (
		<Section title={healthIssuesTitle}>
			<Title title={issues} />
			{renderHealthIssue}

			<Title title={baselinevitals} />
			<Item title={height} value={heightValue} />

			<Item title={weight} value={weightValue} />

			<Item title={hba1c} value={hba1cValue} />
		</Section>
	);

}

class Issue extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var defaultData = props.defaultData;
		var localize = context.localize;
		var issuetype = localize("healthconditions.issuetype");
		var description = localize("healthconditions.description");
		var clinicaldescription = localize("healthconditions.clinicaldescription");
		var onset = localize("healthconditions.onset");
		var stability = localize("healthconditions.stability");
		var notes = localize("healthconditions.notes");

		return(
			<Group>
				<Item title={issuetype} value={getType(defaultData)} />

				<Item title={description} value={getDescription(defaultData)} />

				<Item title={clinicaldescription} value={getClinical(defaultData)} />

				<Item title={onset} value={getOnset(defaultData)} />

				<Item title={stability} value={getStability(defaultData)} />

				<Item title={notes} value={getNotes(defaultData)} />
			</Group>
		);

	}
}

Healthconditions.contextTypes = {
	config: PropTypes.object,
	localize: PropTypes.func
};

Issue.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	TouchableOpacity,
} from "react-native";

var styles = StyleSheet.create({
	main: {
		flex: 1,
		backgroundColor: "#000000",
	},
});

export default function Hide(props, context) {
	var dispatch = context.dispatch;

	return (
		<TouchableOpacity style={styles.main} onPress={dispatch("screenhider:trigger:show")} />
	);
}

Hide.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import CardPage from "../../../Layout/CardPage";
import Form from "ae-forms";
import SearchSchema from "../../../../schemas/Search";
import EmailInviteSchema from "../../../../schemas/EmailInvitemanager";
import StyledText from "ae-base/Base/StyledText";
import ToRequest from "../../../Contacts/ToRequest";
import Style from "ae-style";

var styles = StyleSheet.create({
	searchText: {
		marginVertical: Style.layout.marginLarge
	},
	searchBox: {
		marginBottom: Style.layout.marginLarge
	}
});

export default function Request(props, context) {
	var dispatch = context.dispatch;
	var model = props.model;

	var screen = model.size;

	var localize = context.localize;
	var goback = localize("request.gobackcontact");
	var text1 = localize("managedcontactsrequest.text1");
	var text2 = localize("managedcontactsrequest.text2");
	var addacontact = localize("managedcontactsrequest.addacontact");

	var doSearch = dispatch("managedcontactrequests:trigger:search");

	var manage = model.manage;
	var account = manage.account;
	var requests = manage.contact_requests;
	var contacts = manage.contacts || [];
	var contactList = contacts.map(function(obj){
		return obj._id;
	});
	var searchResults = requests.search;
	var currentQuery = requests.query;
	var isEmail = requests.isEmail;
	var incoming = manage.contact_requests.incoming || [];
	var incomingList = incoming.map(function(obj){
		return obj.from._id;
	});
	var outgoing = manage.contact_requests.outgoing || [];
	var outgoingList = outgoing.map(function(obj){
		return obj.to._id;
	});

	var renderedResults = searchResults.filter(function(result){
		return !(result.qualifier);
	}).map(function(result){
		var id = result?._id;
		var action = dispatch("managedcontactrequests:trigger:request", {
			who: id
		});
		var message = localize("request.sendrequest");
		var icon = "plus";
		if(result && result.qualifier === "blocked"){
			action = dispatch("blocking:trigger:unblock", {
				who: id
			});
			message = localize("request.unblock");
			icon = "unlock";
		}
		if(contactList && contactList.indexOf(id) !== -1){
			action = null;
			message = localize("request.alreadycontact");
			icon = "user";
		}
		if((incomingList && incomingList.indexOf(id) !== -1) || (outgoingList && outgoingList.indexOf(id) !== -1)){
			action = null;
			message = localize("request.inprogress");
			icon = "sync";
		}

		return (
			<ToRequest key={id}
				who={result}
				action={action}
				icon={icon}
				message={message}
				screen={screen}
			/>
		);
	});

	var noResults = currentQuery && !searchResults.length;

	var searchForm = (
		<View>
			<StyledText style={styles.searchText}>{text1}</StyledText>
			<View style={styles.searchBox}>
				<Form schema={SearchSchema} autoSubmit onSubmit={doSearch} />
			</View>
		</View>
	);

	if(noResults && isEmail){
		var sendinvite = localize("managedmanagerrequest.sendinvite");
		var sendEmailInvite = dispatch("managedaccountinvites:trigger:send");
		var cancelInvite = dispatch("desktop:trigger:page:back");
		var defaultInvite = {
			query: currentQuery
		};
		searchForm = (
			<View>
				<StyledText style={styles.searchText}>{text1}</StyledText>
				<View style={styles.searchBox}>
					<Form
						schema={EmailInviteSchema}
						defaultData={defaultInvite}
						submitText={sendinvite}
						cancelText={goback}
						onSubmit={sendEmailInvite}
						onCancel={cancelInvite} />
				</View>
			</View>
		);
	}

	var notFound = (noResults && !isEmail) ? (
		<StyledText>{text2}</StyledText>
	) : null;

	return (
		<CardPage backTitle={goback} contact={account} title={addacontact}>
			{searchForm}
			{notFound}
			{renderedResults}
		</CardPage>
	);
}

Request.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";
import {
	View
} from "react-native";
import prop from "prop";

import Link from "ae-base/Base/Link";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import SectionTitle from "../SectionTitle";
import Summarized from "../Summarized";
import GroupItems from "../GroupItems";

var getName = prop("Name");
var getRole = prop("Role");
var getOrganizationName = prop("OrganizationName");
var getPhone = prop("Phone");
var getIsRegularCareTeamMember = prop("IsRegularCareTeamMember");
var getIsLeadCoordinator = prop("IsLeadCoordinator");
var getIsHomeCaregiver = prop("IsHomeCaregiver");
var getFeeling = prop("HomeCaregiverStatus.Name");

export default function Careteam(props, context) {
	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes || [];
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var localize = context.localize;
	var careteammemberinformation = localize("careteam.careteammemberinformation");
	var thepeopleirelyonathomearefeeling = localize("careteam.thepeopleirelyonathomearefeeling");

	var careTeamMember = careplan.CareTeamMember || [];

	var renderTeamMember = careTeamMember.map(function(member, index){
		var isChanged = changes["CareTeamMember." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<CareteamMember key={index} member={member} index={index} currentSection={currentSection} hasChanged={hasChanged} showCareplanDetail={showCareplanDetail} ownCareplan={ownCareplan} />
		);
	});

	var hasHomeCaregiverStatusPermission = permissions >= 2 && changes.HomeCaregiverStatus;
	return (
		<View>
			<SectionTitle name={careteammemberinformation} section="CareTeamMember" permissions={permissions} changes={changes} />
			{renderTeamMember}
			<Items hasChanged={hasHomeCaregiverStatusPermission} data={"HomeCaregiverStatus.Name"}>
				<Item>
					<Label>{thepeopleirelyonathomearefeeling}</Label>
					<Value>{getFeeling(careplan)}</Value>
				</Item>
			</Items>
		</View>
	);
}

class CareteamMember extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var member = props.member;
		var currentSection = props.currentSection;
		var hasChanged = props.hasChanged;
		var index = props.index;
		var showCareplanDetail = props.showCareplanDetail;
		var ownCareplan = props.ownCareplan;

		var localize = context.localize;
		var name = localize("careteam.name");
		var roleorrelationship = localize("careteam.roleorrelationship");
		var organizationname = localize("careteam.organizationname");
		var telephonenumber = localize("careteam.telephonenumber");
		var regularcareteammember = localize("careteam.regularcareteammember");
		var leadcarecoordinator = localize("careteam.leadcarecoordinator");
		var irelyonmostathome = localize("careteam.irelyonmostathome");

		return (
			<GroupItems>
				<Items hasChanged={hasChanged} data={"CareTeamMember." + index}>
					<Item>
						<Label>{name}</Label>
						<Value>{getName(member)}</Value>
					</Item>

					<Item alternate>
						<Label>{roleorrelationship}</Label>
						<Value>{getRole(member)}</Value>
					</Item>

					<Summarized currentSection={currentSection} section={"CareTeamMember." + index} showCareplanDetail={showCareplanDetail} ownCareplan={ownCareplan} >
						<Item>
							<Label>{organizationname}</Label>
							<Value>{getOrganizationName(member)}</Value>
						</Item>

						<Item alternate>
							<Label>{telephonenumber}</Label>
							<Value>
								<Link title={getPhone(member)} uri={"tel:" + getPhone(member)} />
							</Value>
						</Item>

						<Item>
							<Label>{regularcareteammember}</Label>
							<Value>{getIsRegularCareTeamMember(member)}</Value>
						</Item>

						<Item alternate>
							<Label>{leadcarecoordinator}</Label>
							<Value>{getIsLeadCoordinator(member)}</Value>
						</Item>

						<Item>
							<Label>{irelyonmostathome}</Label>
							<Value>{getIsHomeCaregiver(member)}</Value>
						</Item>
					</Summarized>
				</Items>
			</GroupItems>
		);
	}
}

Careteam.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

CareteamMember.contextTypes = {
	localize: PropTypes.func
};

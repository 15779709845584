import React from "react";
import PropTypes from "prop-types";

import {
	View,
	StyleSheet,
	TouchableOpacity,
	Text,
	Linking
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import DialogContainer from "ae-base/Base/DialogContainer";


import Style from "ae-style";


const styles = StyleSheet.create({
	item: {
		backgroundColor: Style.colors.white,
		alignItems: "center",
		justifyContent: "center",
		zIndex: 2,
		maxWidth: "70%"
	},
	text: {
		padding: 15
	},
	container: {
		flexShrink: 1,
		justifyContent: "space-around"
	},
	closeButton: {
		marginTop: 5,
		borderTopWidth: 1,
		borderColor: "#e8e8e8",
		alignItems: "center"
	},
	button: {
		paddingVertical: 10, 
		alignItems: "center", 
		width: "100%"
	},
	linkText: {
		color: Style.colors.orange
	}
});

export default function AccountDeletion(props, context){
	const {localize, dispatch} = context;
	if(!props.open) return null;

	return (
		<DialogContainer>
			<View style={styles.item}>
				<View style={styles.container}>
					<View style={styles.text}>
						<StyledText selectable>{localize("accountdeletion.message")}</StyledText>
					</View>
					<View style={styles.closeButton}>
						<TouchableOpacity style={styles.button} onPress={dispatch("accountdeletion:trigger:close")}>
							<StyledText>{localize("userlist.close")}</StyledText>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</DialogContainer>
	)
}

AccountDeletion.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};
import React from "react";
import PropTypes from "prop-types";
import { View, StyleSheet, Platform, Image } from "react-native";

import loadingImg from "../../Resources/img/secondaryspinner.gif";
import StyledText from "ae-base/Base/StyledText";
import Card from "../Layout/Card";
import Modal from "../Layout/Modal";

const styles = StyleSheet.create({
	card: {
		flexShrink: 1,
		flexWrap: "wrap",
	},
	container: {
		...Platform.select({
			ios: {},
			android: {},
			default: {
				flexShrink: 1,
				flexWrap: "wrap",
			},
		}),
	},
	canvas: {
		width: 50,
		height: 50
	},
	spinnerContainer: {
		alignItems: "center"
	}
});

export default function Loading(props, context) {
	const { open } = props;

	return (
		<Modal open={open} style={{backgroundColor: "rgba(6, 91, 97, 0.5)"}}>
			<Card rounded style={styles.card}>
				<View style={styles.container}>
					<StyledText emphasized>
						{context.localize("loader.loading")}
					</StyledText>
					<View style={styles.spinnerContainer}>
						<Image resizeMethod="resize" resizeMode="contain" style={styles.canvas} source={loadingImg} />
					</View>
				</View>
			</Card>
		</Modal>
	);
}

Loading.contextTypes = {
	localize: PropTypes.func
}

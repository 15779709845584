import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import Heading from "ae-base/Base/Heading";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";
import BlockView from "ae-base/Base/BlockView";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";

import Contact from "../../../Contacts/Contact";
import ListAdd from "ae-base/List/ListAdd";

import ManagedSection from "../ManagedSection";
import Style from "ae-style";
import sortNames from "../../../../utils/SortNames";

var styles = StyleSheet.create({
	addBox: {
		marginLeft: Style.layout.marginSmall,
		marginTop: Style.layout.marginSmall
	},
	contacts: {
		marginTop: Style.layout.marginSmall,
		marginBottom: Style.layout.marginSmall
	},
	buttonContainer: {
		flexDirection: "column"
	}
});

export default function Managedcontacts(props, context) {
	var dispatch = context.dispatch;
	var model = props.model;
	var toast = model.toast;
	var manage = model.manage || {};
	var contact_requests = manage.contact_requests || {};
	var contacts = manage.contacts || [];
	var incoming = contact_requests.incoming || [];
	var outgoing = contact_requests.outgoing || [];
	var denying = contact_requests.denying;
	var contact_invites = manage.contact_invites;
	var removing = manage.removing;
	var account = manage.account;

	var screen = model.size;

	var localize = context.localize;
	var accept = localize("managedcontacts.accept");
	var adduser = localize("managedcontacts.adduser");
	var ascontact = localize("managedcontacts.ascontact");
	var cancel = localize("managedcontacts.cancel");
	var canceltext1 = localize("managedcontacts.canceltext1");
	var canceltext2 = localize("managedcontacts.canceltext2");
	var confirmtext1 = localize("managedcontacts.confirmtext1");
	var confirmtext2 = localize("managedcontacts.confirmtext2");
	var contact_s = localize("managedcontacts.contact_s");
	var deny = localize("managedcontacts.deny");
	var inviteanewcontact = localize("managedcontacts.inviteanewcontact");
	var managedcontacts = localize("managedcontacts.managedcontacts");
	var pendingcontactrequests = localize("managedcontacts.pendingcontactrequests");
	var pendingoutgoingrequests = localize("managedcontacts.pendingoutgoingrequests");
	var removecontact = localize("managedcontacts.removecontact");
	var text2 = localize("managedcontacts.text2");
	var title = localize("managedcontacts.title");
	var youareaboutdenythisrequest = localize("managedcontacts.youareaboutdenythisrequest");

	var renderedContacts = sortNames(contacts).map(function(contact){
		var id = contact._id;
		var removeContact = dispatch("management:trigger:remove:contact:start", {
			who: id
		});
		var onPress = dispatch("contactprofile:trigger:profile:open", {
			who: id,
			back: "home",
			message: true,
			patientId: account._id
		});
		return (
			<Contact contact={contact} key={id} screen={screen} onPress={onPress}>
				<StyledButton icon="delete" title={removecontact} alert onPress={removeContact} />
			</Contact>
		);
	});

	var renderedIncoming = (incoming.length) ? (
		<View>
			<Heading title={pendingcontactrequests} screen={screen} />
			<StyledText>{adduser} {account.fname} {ascontact}</StyledText>
			<View style={styles.contacts}>
				{incoming.map(function(request){
					var id = request._id;
					var acceptRequest = dispatch("managedcontactrequests:trigger:accept", {
						request: id
					});

					var denyRequest = dispatch("managedcontactrequests:trigger:deny", {
						request: id
					});

					return (
						<Contact contact={request.from} key={id} screen={screen} >
							<View style={styles.buttonContainer}>
								<StyledButton title={accept} icon="check" contrast onPress={acceptRequest} />
								<ButtonSpacer />
								<StyledButton title={deny} icon="delete" alert centered stretch onPress={denyRequest} />
							</View>
						</Contact>
					);
				})}
			</View>
		</View>
	) : null;

	var renderOutgoingRequests = outgoing.map(function(request){
		var id = request._id;
		var cancelRequest = dispatch("managedcontactrequests:trigger:cancel", {
			request: id
		});

		return (
			<Contact contact={request.to} key={id} screen={screen} >
				<StyledButton title={cancel} icon="delete" alert onPress={cancelRequest} />
			</Contact>
		);
	});

	var renderOutgoingEmail = contact_invites.map(function(invite){
		var id = invite._id;
		var email = invite.email;
		var cancelEmail = dispatch("managedaccountinvites:trigger:cancel", invite);

		var contact = {
			fname: email
		};

		return (
			<Contact contact={contact} key={id} screen={screen}>
				<StyledButton title={cancel} icon="delete" alert onPress={cancelEmail} />
			</Contact>
		);
	});

	var renderOutgoing = outgoing.length || contact_invites.length ? (
		<View>
			<Heading title={pendingoutgoingrequests} screen={screen} />
			<StyledText>{text2}</StyledText>
			<View style={styles.contacts}>
				{renderOutgoingEmail}
				{renderOutgoingRequests}
			</View>
		</View>
	) : null;

	var confirmRemove, cancelRemove;
	if(removing){
		confirmRemove = dispatch("management:trigger:remove:contact:confirm", {
			who: removing
		});
		cancelRemove = dispatch("management:trigger:remove:contact:cancel");
	}

	var confirmDeny, cancelDeny;
	if(denying){
		confirmDeny = dispatch("managedcontactrequests:trigger:deny:confirm", {
			request: denying
		});
		cancelDeny = dispatch("managedcontactrequests:trigger:deny:clear");
	}

	var confirmation1 = (
		<ConfirmationModal
			open={removing}
			title={title}
			titleIcon="warning"
			confirmText={confirmtext1}
			cancelText={canceltext1}
			onConfirm={confirmRemove}
			onCancel={cancelRemove} />
	);

	var confirmation2 = (
		<ConfirmationModal
			open={denying}
			title={title}
			titleIcon="warning"
			confirmText={confirmtext2}
			cancelText={canceltext2}
			onConfirm={confirmDeny}
			onCancel={cancelDeny}>
			<StyledText contrast>{youareaboutdenythisrequest}</StyledText>
		</ConfirmationModal>
	);

	var modals = [confirmation1, confirmation2];

	var openRequest = dispatch("desktop:trigger:page", {
		page: "managed_contact_requests"
	});

	return (
		<ManagedSection online={model.online} contact={account} title={managedcontacts} external={modals} toast={toast}>
			{renderedIncoming}
			{renderOutgoing}
			<Heading title={contact_s} screen={screen} />
			<View style={styles.contacts}>
				{renderedContacts}
				<BlockView style={styles.addBox}>
					<ListAdd title={inviteanewcontact} onPress={openRequest} spaced />
				</BlockView>
			</View>
		</ManagedSection>
	);
}

Managedcontacts.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet
} from "react-native";

import AtaContainer from "../AtaContainer";
import	StyledButton from "ae-base/Base/StyledButton";

var styles = StyleSheet.create({
	button: {
		flex: 1,
		justifyContent: "flex-end",
		alignItems: "flex-end"
	}
});

export default class StandBy extends PureComponent {
	render(){
		var context = this.context;
		var dispatch = context.dispatch;
		var handlePowerButton = dispatch("app:trigger:power");

		var localize = context.localize;
		var powerbutton = localize("powerbutton.powerbutton");

		return (
			<AtaContainer>
				<View style={styles.button}>
					<StyledButton border clear title={powerbutton} icon="power-off" onPress={handlePowerButton} largeHeading />
				</View>
			</AtaContainer>
		);
	}
}

StandBy.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";

import MainScroll from "../../Layout/MainScroll";
import PropTypes from "prop-types";
import Style from "@aetonix/styles";

var styles = StyleSheet.create({
	container: {
		backgroundColor: "#131618",
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 235,
		padding: Style.layout.paddingButton,
		marginRight: Style.layout.marginMedium
	},
	button: {
		marginBottom: Style.layout.marginLarge,
		paddingLeft: Style.layout.paddingSmall,
		alignItems: "center",
		flexDirection: "row"
	},
	scroll: {
		flexDirection: "column"
	},
	textStyle: {
		flex: 1,
		flexWrap: "wrap",
	},
	back: {
		marginTop: Style.layout.marginSmall,
		padding: Style.layout.marginContentSmall,
		backgroundColor: Style.colors.secondary,
		borderRadius: 10,
		alignSelf: "stretch"
	}
});

export default function FormsList(props, context) {
	const { localize, dispatch } = context;
	const { forms, active: activeForm } = props;
	const backTitle = localize("myhealth.back");

	const goBack = context.dispatch("ownformsubmissions:trigger:close");

	var renderForms = Object.keys(forms).map(function(form){
		var active = form === activeForm;
		return (
			<Form form={forms[form]} active={active} isATouchAway />
		);
	});

	return (
		<View style={styles.container}>
			<MainScroll atouchaway style={styles.scroll}>
				{renderForms}
			</MainScroll>
			<StyledButton icon={"chevron-left"} style={styles.back} centered contrast tertiary title={backTitle} onPress={() => goBack()}/>
		</View>
	);
}

function Form(props, context) {
	var localizeFrom = context.localizeFrom;
	var dispatch = context.dispatch;
	var form = props.form;
	var active = props.active;

	var icon = form.icon;
	var title = localizeFrom(form.localization, ".");
	var style = StyleSheet.create({ 
		icon: {
			marginRight: Style.layout.marginMedium,
			color: active ? '#FFFFFF' : '#4A959B' 
		},
	})

	var openSection = dispatch("ownformsubmissions:trigger:search", {
		form: form._id
	});

	return (
		<TouchableOpacity onPress={openSection} style={styles.button}>
			<StyledIcon tertiary={!active} large name={icon} contrast={active} style={style.icon} />
			<StyledText style={styles.textStyle} light={!active} contrast={active}>{title}</StyledText>
		</TouchableOpacity>
	);
}

FormsList.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func,
};

Form.contextTypes = {
	localizeFrom: PropTypes.func,
	dispatch: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";

import ManagedSection from "../ManagedSection";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import ListItem from "ae-base/List/ListItem";
import OutputForm from "ae-forms/src/Outputs/OutputForm";
import VerticalButton from "ae-base/Base/VerticalButton";

import Style from "@aetonix/styles";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0: {
		info: {
			flexDirection: "row",
			justifyContent: "space-between",
			flex: 1,
			alignSelf: "stretch"
		},
		actionPending: {
			paddingBottom: Style.layout.paddingMedium
		},
		vButtons: {
			paddingHorizontal: Style.layout.paddingSmall
		},
		creationTimeContainer: {
			color: Style.colors.tertiary,
			width: "100%"
		},
		creationTimeText: {
			fontSize: Style.text.verySmall,
			fontWeight: "400",
			display: "flex",
			justifyContent: "flex-end",
			textAlign: "right"
		},
		loadMoreButton: {
			padding: 16,
			paddingHorizontal: 30,
			marginVertical: 16
		},
		loadMoreButtonContainer: {
			marginVertical: Style.layout.marginContentVertical,
			alignItems: "center",
			justifyContent: "center"
		}
	},
	512: {
		creationTimeText: { fontSize: Style.text.small }
	}
});

export default function WorkflowActions(props, context) {
	var model = props.model;
	var localize = context.localize;
	var dispatch = context.dispatch;

	var toast = model.toast;
	var manage = model.manage;
	var saving = model.saving;
	var group = model.activemanagegroup;
	var contact = manage.account;
	var workflow = manage.workflow;
	var details = workflow.details;
	var externals = workflow.externals || {};

	var content = null;
	var editButton = null;
	var editTitle = localize("workflow.editbuttontitle");
	var finishButton = null;
	var finishTitle = localize("workflow.finishbuttontitle");
	var finishAction = () => {};
	if (details) {
		content = <Actions details={details} externals={externals} />;
	}

	if (details && details.active) {
		finishButton = (
			<VerticalButton
				style={styles.vButtons}
				title={finishTitle}
				icon={"stop-circle"}
				contrast
				clear
				onPress={dispatch("managededitingworkflow:trigger:workflow:initiateFinish", true)}
			/>
		);

		finishAction = dispatch("managededitingworkflow:trigger:workflow:finish", {
			workflow: details._id,
			managee: contact._id,
			group: group
		});
		editButton = (
			<VerticalButton
				style={styles.vButtons}
				title={editTitle}
				icon={"pencil-square"}
				contrast
				clear
				onPress={dispatch("managededitingworkflow:trigger:edit", {
					workflow: details._id,
					managee: contact._id,
					group: group
				})}
			/>
		);
	}

	var workflowTitle = localize("workflow.workflow");

	var showFinishConfirmation = workflow.edit.initiateFinish;
	var external = null;

	external = (
		<ConfirmationModal
			open={showFinishConfirmation}
			title={localize("common.areyousure")}
			titleIcon="warning"
			confirmText={localize("workflow.finishConfirm")}
			cancelText={localize("workflow.finishCancel")}
			onConfirm={finishAction}
			onCancel={dispatch("managededitingworkflow:trigger:workflow:initiateFinish", false)}
		>
			<StyledText contrast>{localize("workflow.finishConfirmation")}</StyledText>
		</ConfirmationModal>
	);
	return (
		<ManagedSection
			online={model.online}
			contact={contact}
			title={workflowTitle}
			toast={toast}
			saving={saving}
			finish={finishButton}
			edit={editButton}
			external={external}
		>
			{content}
		</ManagedSection>
	);
}

function GetTimeString(date) {
	var timeObj = new Date(date);
	var minutes = timeObj.getMinutes();
	if (minutes < 10) {
		minutes = "0" + timeObj.getMinutes();
	}
	var month = timeObj.getMonth() + 1;
	return timeObj.getDate() + "/" + month + " @ " + timeObj.getHours() + ":" + minutes;
}

function Actions(props, context) {
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;
	var details = props.details;
	var actionList = details.actions.actions || [];
	var definition = details.definition;
	var externals = props.externals;
	var dispatch = context.dispatch;
	var hasMoreActions = details.actions.hasMore;

	var label = details.label || "";
	var actionTtial = localize("workflow.actiontital", {
		label: label
	});
	var loadMoreTitle = localize("workflow.loadmore");

	var renderOngoingTitle = (
		<StyledText bold smallHeading>
			{actionTtial}
		</StyledText>
	);

	var actionSchema = definition.actions;
	var actorsSchema = definition.actors;
	var renderOngoingList = actionList.map(function (data, index) {
		var actionLabel = data.action;
		var schema = actionSchema[actionLabel];
		var readableTime = GetTimeString(data.updated_at);
		var createdTime = GetTimeString(data.created_at);
		let clearedTime = new Date(data.updated_at);
		var renderForm = schema ? (
			<OutputForm key={index} schema={schema} data={data.data || []} externalForms={externals[details.workflow]} />
		) : null;

		var actorKey = data.actor;
		var actorSchema = actorsSchema[actorKey] || {};
		var actorLocalized = localizeFrom(actorSchema, ".") || Object.keys(actorSchema)[0] || actorKey;

		var owner = data.owner[0] || {};
		var name = `${owner.lname}, ${owner.fname} (${actorLocalized})`;
		if(owner.name){
			name = `${owner.name} [${localize("usercreation.managegroup")}] (${actorLocalized})`;
		}

		var actionPending = localize("workflow.actionpending");
		var pendingMessage = data.responded ? null : (
			<View style={styles.actionPending}>
				<StyledText>{actionPending}</StyledText>
			</View>
		);

		if(data.cleared){
			return (
				<ListItem fold>
					<View style={styles.creationTimeContainer}>
						<StyledText style={styles.creationTimeText}>
							{localize("workflow.createdAt")} {readableTime}
						</StyledText>
					</View>
					{renderForm}
					{pendingMessage}
					<View style={styles.info}>
						<StyledText bold>
							{localize("workflow.clearedAt")}: {`${clearedTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
						</StyledText>
					</View>
				</ListItem>
			);
		}


		return (
			<ListItem fold>
				<View style={styles.creationTimeContainer}>
					<StyledText style={styles.creationTimeText}>
						{localize("workflow.createdAt")} {createdTime}
					</StyledText>
				</View>
				{renderForm}
				{pendingMessage}
				<View style={styles.info}>
					<StyledText bold>
						{name} {readableTime}
					</StyledText>
				</View>
			</ListItem>
		);
	});

	var renderLoadMoreButton = (
		<View style={styles.loadMoreButtonContainer}>
			<StyledButton
				contrast
				icon="dropdown"
				title={loadMoreTitle}
				style={styles.loadMoreButton}
				onPress={dispatch("managedactionsworkflow:trigger:more")}
			/>
		</View>
	);

	return (
		<View>
			{renderOngoingTitle}
			{renderOngoingList}
			{hasMoreActions && renderLoadMoreButton}
		</View>
	);
}

WorkflowActions.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

WorkflowActions.propTypes = {
	model: PropTypes.object
};

Actions.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "ae-style";
import StyledButton from "ae-base/Base/StyledButton";

import BlockView from "ae-base/Base/BlockView";

var styles = StyleSheet.create({
	sectionContent: {
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		margin: Style.layout.marginSmall
	}
});

export default function ProfileSection(props, context){
	var title = props.title || "";
	var section = props.section;
	var display = props.display;
	var iconName = "directup";
	if(display)
		iconName = "dropdown";

	var onToggle = props.onToggle;

	return (
		<BlockView>
			<View style={styles.sectionContent}>
				<StyledButton id={context.id_prefix + section} icon={iconName} clear title={title} smallHeading onPress={onToggle} />
			</View>
		</BlockView>
	);

}

ProfileSection.contextTypes = {
	id_prefix: PropTypes.string,
};

import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function CareplanUpdate(props, context){
	var notification = props.notification;
	var fname = notification.from.fname;
	var lname = notification.from.lname;
	var localize = context.localize;
	var content = notification.content;
	var count = (content.sections || []).length;
	var screen = props.screen;
	var careplanupdates = localize("banner.careplan_hasUpdates", {
		fname: fname,
		lname: lname,
		count: count
	});

	return (
		<TimelineItem content={careplanupdates} iconName="info"  notification={notification} screen={screen} />
	);

}

CareplanUpdate.contextTypes = {
	localize: PropTypes.func,
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import Style from "ae-style";
import StyledButton from "ae-base/Base/StyledButton";

import BlockView from "ae-base/Base/BlockView";

var styles = StyleSheet.create({
	sectionContent: {
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		margin: Style.layout.marginSmall
	}
});

export default function ProfileSection(props, context){
	var title = props.title || "";
	var section = props.section;
	var display = props.display;
	var iconName = "directup";
	if(display)
		iconName = "dropdown";

	var dispatch = context.dispatch;

	var onToggle = dispatch("profilesection:trigger:toggle:" + section);

	return (
		<BlockView>
			<View style={styles.sectionContent}>
				<StyledButton id={context.id_prefix + section} icon={iconName} clear title={title} smallHeading onPress={onToggle} />
			</View>
		</BlockView>
	);

}

ProfileSection.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
};

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../Breakpoints";
import PropTypes from "prop-types";
import SquareButton from "ae-base/Base/SquareButton";
import Heading from "ae-base/Base/Heading";
import Style from "ae-style";

const MAX_BUTTONS = 6;

const styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignSelf: "center"
	}
});

const responsiveStyles = makeIncrements(MAX_BUTTONS);

export default function ButtonGridContactSettings(props, context){
	const dispatch = context.dispatch;
	const localize = context.localize;

	const title = props.title;
	const buttons = props.buttons || [];

	const contact = props.contact || {};
	const who = contact._id;

	const screen = props.screen;

	if(!who) return (
		null
	);

	const renderedTitle = title  ? (
		<Heading title={title} screen={screen} />
	) : null;

	const renderedButtons = buttons.map(function(button){
		const trigger = button.trigger;

		const triggerPayload = {
			who: who
		};

		const buttonTitle = localize(button.title);
		const icon = button.icon;
		const action = dispatch(trigger, triggerPayload);

		const textSize = 12;
		let iconSize = Style.imageSize.tiny;

		if(Breakpoints.smallerThan("small")) {
			iconSize = Style.imageSize.icon;
		}

		return (
			<SquareButton
				key={button.title}
				title={buttonTitle}
				textSize={textSize}
				icon={icon}
				iconSize={iconSize}
				onPress={action} />
		);
	});

	return (
		<View style={responsiveStyles.container}>
			{renderedTitle}
			<View style={styles.buttons}>
				{renderedButtons}
			</View>
		</View>
	);
}

ButtonGridContactSettings.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

function makeIncrements(buttons) {
	let i = buttons;
	const increment = Style.layout.squareButton + Style.layout.marginSmall;
	const definition = {};

	while(i){
		const size = increment * i;

		definition[size] = {
			container: {
				width: size
			}
		};

		i--;
	}

	return ResponsiveStylesheet.createSized("min-width", definition);
}

import PropTypes from "prop-types";
import React from "react";

import BannerSoundSchema from "../../../../schemas/BannerSound";
import Form from "ae-forms";
import Divider from "../../../Layout/Divider";
import BlockView from "ae-base/Base/BlockView";

export default function BannerSound(props, context){
	var dispatch = context.dispatch;
	var options = props.options;

	var soundOptions = {
		enabled: options.bannernotice
	};

	return (
		<BlockView>
			<Form id_prefix={context.id_prefix + "soundForm"} schema={BannerSoundSchema} defaultData={soundOptions} autoSubmit onSubmit={dispatch("bannernotice:trigger:set")} />
			<Divider />
		</BlockView>
	);

}

BannerSound.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
};

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { Fragment } from "react";
import { StyleSheet } from "react-native";
import Form from "ae-forms";
import CardPage from "../../../Layout/CardPage";
import StyledButton from "ae-base/Base/StyledButton";
import Loader from "ae-base/Base/Loader";
import StyledText from "ae-base/Base/StyledText";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

var styles = StyleSheet.create({
	deleteBtn: {
		alignSelf: "flex-end",
		flex: 1,
	},
	lock: {
		alignSelf: "flex-end",
		marginTop: 4,
	},
});

export default function ManagedGroupFormSubmissionUpdate(props, context) {
	var model = props.model;
	var dispatch = context.dispatch;
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;
	var manage = model.manage;
	var screen = model.screen;
	var managedactivegroupformsubmission = manage.managedactivegroupformsubmission || {};
	var groupform = manage.managedgroupformsubmissions.form;
	var saving = model.saving;
	var managedgroupformlist = manage.managedgroupformlist;
	var confirmView = managedactivegroupformsubmission.confirmation;
	var deleteView = managedactivegroupformsubmission.delete;

	var currentForm = managedgroupformlist[groupform];
	var schema = currentForm.schema;
	const isStaff = model.personal?.type?.includes("staff") || false;
	var hasSchema = schema && schema.properties;

	var groupformPermissions = manage.permissions.groupforms;
	const permission = groupformPermissions[currentForm._id];

	const canDelLock = permission > 1;

	if (!hasSchema) return <Loader />;
	var disableLock = schema.disableLock;

	var localization = currentForm.localization;
	var schemaTitle = localizeFrom(localization, ".") || currentForm.name || currentForm._id;

	var deleteSubmission = dispatch("managedactivegroupformsubmission:trigger:delete");
	var lockSubmission = dispatch("managedactivegroupformsubmission:trigger:lock");

	var delText = localize("groupforms.delete");
	var editText = `${schemaTitle} - ${localize("groupforms.editsub")}`;
	var discardchanges = localize("groupforms.discardchanges");
	var lockText = localize("groupforms.lock");

	const dataId = managedactivegroupformsubmission._id;
	const defaultData = { ...managedactivegroupformsubmission.data, ...managedactivegroupformsubmission.persistStorage };
	var confirmLock = dispatch("managedactivegroupformsubmission:trigger:confirmlock");
	var cancelLock = dispatch("managedactivegroupformsubmission:trigger:cancellock");
	var cancelDelete = dispatch("managedactivegroupformsubmission:trigger:canceldelete");

	const clearPersistStorage = () => {
		dispatch("persiststorage:delete")({
			dataId,
			targetId: manage.account._id,
		});
		manage.managedactivegroupformsubmission.persistStorage = undefined;
	};

	const saveEncryptStorage = (data) => {
		managedactivegroupformsubmission.persistStorage = data;
		dispatch("persiststorage:save")({
			dataId,
			data,
			targetId: manage.account._id,
		});
	};

	const onSubmit = (data) => {
		clearPersistStorage();
		dispatch("managedactivegroupformsubmission:trigger:update:submit")(data);
	};

	const handleDeletion = (data) => {
		clearPersistStorage();
		dispatch("managedactivegroupformsubmission:trigger:confirmdelete")(data);
	};

	const goBack = () => {
		clearPersistStorage();
		dispatch("managedactivegroupformsubmission:trigger:cancel")();
	};

	var renderContent = null;
	if (confirmView) {
		renderContent = (
			<ConfirmationModal
				open={confirmView}
				title={localize("groupform.confirmTitle")}
				titleIcon="warning"
				confirmText={localize("groupform.yesLock")}
				cancelText={localize("groupform.cancelLock")}
				onConfirm={confirmLock}
				onCancel={cancelLock}
			>
				<StyledText contrast>{localize("groupform.confirmLock")}</StyledText>
			</ConfirmationModal>
		);
	} else if (deleteView) {
		renderContent = (
			<ConfirmationModal
				open={deleteView}
				title={localize("groupform.confirmDelete")}
				titleIcon="warning"
				confirmText={localize("groupform.yesDelete")}
				cancelText={localize("groupform.cancelDelete")}
				onConfirm={handleDeletion}
				onCancel={cancelDelete}
			>
				<StyledText contrast>{localize("groupform.confirmDelete")}</StyledText>
			</ConfirmationModal>
		);
	} else {
		renderContent = (
			<CardPage
				title={editText}
				icon="notebook"
				saving={saving}
				screen={screen}
				toast={model.toast}
				online={model.online}
			>
				{canDelLock && (
					<Fragment>
						<StyledButton alert title={delText} icon="delete" onPress={deleteSubmission} style={styles.deleteBtn} />
						{disableLock ? null : (
							<StyledButton contrast title={lockText} icon="check" onPress={lockSubmission} style={styles.lock} />
						)}
					</Fragment>
				)}
				<Form
					saveEncryptStorage={debounce((data) => saveEncryptStorage(data), 500)}
					dataId={dataId}
					schema={schema}
					defaultData={defaultData}
					onSubmit={onSubmit}
					onCancel={goBack}
					cancelText={discardchanges}
					showCustomAddButton={isStaff}
				/>
			</CardPage>
		);
	}

	return renderContent;
}

ManagedGroupFormSubmissionUpdate.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
};

import PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {
	View,
	TouchableWithoutFeedback
} from "react-native";

import StyledIconButton from "ae-base/Base/StyledIconButton";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import NotificationIcon from "ae-base/Base/NotificationIcon";

import StyledButton from "ae-base/Base/StyledButton";

import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		badge: {
			position: "absolute",
			left: 0,
			top: 0,
		},
		badgeGroup: {
			position: "absolute",
			left: -4,
			top: 6
		},
		touchable: {
			position: "absolute",
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			zIndex: 11,
			backgroundColor: Style.colors.shadow,
			flex: 1
		},
		container: {
			position: "absolute",
			left: 10,
			bottom: 40,
		},
		buttonContainer: {
			width: 50,
			height: 50,
			zIndex: 12,
			backgroundColor: Style.colors.secondary,
			borderRadius: 50,
			alignItems: "center",
			justifyContent: "center",
			marginTop: Style.layout.marginSmall
		},
		groupButtonContainer: {
			position: "absolute",
			left: 70,
			bottom: 40
		},
		groupButtonStyle: {
			borderRadius: 20,
			marginTop: Style.layout.marginSmall
		},
		buttonStyle: {
			padding: Style.layout.paddingSlim
		}
	},
	512: {
		badge: {
			position: "absolute",
			top: -3,
			left: -3
		},
		buttonContainer: {
			width: 60,
			height: 60
		},
		container: {
			bottom: 50
		},
		groupButtonContainer: {
			left: 80,
			bottom: 50
		},
		buttonStyle: {
			padding: Style.layout.paddingSmall
		}
	}
});

export default class LiveChatBubble extends PureComponent {
	constructor(props){
		super(props);

		this.state = {
			clicked: false
		};

		this.toggleClicked = toggleClicked.bind(this);
		this.handleClick = handleClick.bind(this);
	}

	render(){
		var props = this.props;
		var groups = props.groups;
		var personal = props.personal;
		var state = this.state;
		var clicked = state.clicked;
		var missed = props.missed || {};

		var isMember = false;

		var renderGroups = groups.map((group) => {
			isMember = group.members.indexOf(personal._id) !== -1;
			return (
				<GroupBubble key={group._id} missed={missed[group._id]} group={group} isMember={isMember} onPress={this.handleClick} />
			);
		});

		var containerStyle = [styles.container, styles.buttonContainer];

		var content = null;

		var renderMissedMessage = missed && missed.total ? (
			<NotificationIcon style={styles.badge} title={missed.total} />
		) : null;

		if(clicked)
			content = (
				<TouchableWithoutFeedback onPress={this.toggleClicked}>
					<View style={styles.touchable}>
						<View style={containerStyle}>
							{renderMissedMessage}
							<StyledIconButton icon="comments" style={styles.buttonStyle} onPress={this.toggleClicked} contrast />
						</View>
						<View style={styles.groupButtonContainer}>
							{renderGroups}
						</View>
					</View>
				</TouchableWithoutFeedback>
			);
		else
			content = (
				<View style={containerStyle}>
					{renderMissedMessage}
					<StyledIconButton icon="comments" style={styles.buttonStyle} onPress={this.toggleClicked} contrast />
				</View>
			);

		return content;
	}
}

function GroupBubble(props){
	var group = props.group;
	var name = group.name;
	var missed = props.missed;

	var data = {
		group: group._id
	};

	var onPress = props.onPress("activelivechatgroup:trigger:view", data);

	var renderMissed = missed ? (
		<NotificationIcon style={styles.badgeGroup} title={missed} />
	) : null;

	return (
		<View>
			{renderMissed}
			<StyledButton style={styles.groupButtonStyle} title={name} onPress={onPress} contrast />
		</View>
	);

}

function toggleClicked(){
	var dispatch = this.context.dispatch;
	var props = this.props;
	var groups = props.groups;
	if(groups.length === 1){
		var group = groups[0];
		dispatch("activelivechatgroup:trigger:view", {
			group: group._id
		})();
		return;
	}

	var clicked = this.state.clicked;
	this.setState({
		clicked: !clicked
	});
}

function handleClick(event, data){
	return function(){
		this.context.dispatch(event, data)();
		this.toggleClicked();
	}.bind(this);
}

LiveChatBubble.contextTypes = {
	dispatch: PropTypes.func
};

GroupBubble.contextTypes = {
	dispatch: PropTypes.func
};
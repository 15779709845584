import PropTypes from "prop-types";
import React from "react";
import { Platform, StyleSheet, View, Dimensions, ScrollView, TouchableWithoutFeedback } from "react-native";

import VerticalButton from "ae-base/Base/VerticalButton";
import NotificationIcon from "ae-base/Base/NotificationIcon";

import Style from "@aetonix/styles";
import PowerButtonNew from "./PowerButtonNew";

import ScrollIndicator from "ae-base/Base/ScrollIndicator";

var styles = StyleSheet.create({
	sidebar: {
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center"
	},
	bottomPadded: {
		marginBottom: Style.layout.marginContentVertical
	},
	badge: {
		position: "absolute",
		right: 30,
		top: 0
	},
	scroll: {
		paddingTop: Style.layout.paddingMedium,
		paddingHorizontal: Style.layout.paddingMedium,
		flexGrow: 1,
		flex: 0,
		flexShrink: 1,
		marginTop: "auto",
		marginBottom: "auto"
	}
});

var HEALTH_KEYS = ["overview", "activity", "bloodsugar", "weight", "bloodpressure", "bloodoxygen", "bodytemperature"];

export default function ATouchAwaySidebar(props, context) {
	var dispatch = context.dispatch;
	var settings = props.settings || {};
	var page = props.page;
	var localize = context.localize;
	var status = props.status || {};
	var formsViewable = props.formsViewable;

	var myhealthSettings = settings.myhealth_sections || {};

	var firstHealth = null;

	for (var i = 0; i < HEALTH_KEYS.length; i++) {
		var setting = HEALTH_KEYS[i];
		if (myhealthSettings[setting] && !firstHealth) {
			firstHealth = setting;
			break;
		}
	}

	firstHealth = firstHealth || "overview";

	// if (!settings.careplan && !settings.myhealth && !settings.messaging && page === "contacts")
	// 	return null;
	var missed = props.missed;
	var squish = props.squish;
	var pendingActions = props.workflowActions;

	var height = {
		height: Dimensions.get("window").height / 1.51
	};

	var openCareplan = dispatch("atouchawaymobile:trigger:page", {
		page: "careplan"
	});

	var openContacts = dispatch("atouchawaymobile:trigger:page", {
		page: "contacts"
	});

	var openMessaging = dispatch("atouchawaymobile:trigger:page", {
		page: "messaging"
	});

	var openMyHealth = dispatch("atouchawaymobile:trigger:page", {
		page: firstHealth
	});

	var openForms = dispatch("atouchawaymobile:trigger:page", {
		page: "forms"
	});

	var openWorkflow = dispatch("atouchawaymobile:trigger:page", {
		page: "workflow"
	});

	var openStatus = dispatch("atouchawaymobile:trigger:page", {
		page: "status"
	});

	if (pendingActions && pendingActions.length === 1)
		openWorkflow = dispatch("activeworkflowaction:trigger:view", {
			action: pendingActions[0]._id
		});

	var careplanTitle = localize("atouchawaysidebar.careplan");
	var contactsTitle = localize("atouchawaysidebar.contacts");
	var messagingTitle = localize("atouchawaysidebar.messaging");
	var myHealthTitle = localize("atouchawaysidebar.myhealth");
	var formsTitle = localize("atouchawaysidebar.forms");
	var workflowTitle = localize("atouchawaysidebar.actions");
	var statusTitle = localize("workflowstatus.titleshort");

	var resourcesTitle = localize("resources.title");
	var openResources = dispatch("ownresources:trigger:open");

	var isEmpty = (obj) => Object.keys(obj).length === 0;

	var isFormsEmpty = !formsViewable || isEmpty(formsViewable);

	var buttons = [];

	var renderMissedMessage = null;
	if (missed && missed.count && missed.count.totalmessages)
		renderMissedMessage = <NotificationIcon style={styles.badge} title={missed.count.totalmessages} />;

	if (settings.careplan)
		buttons.push(
			<VerticalButton
				key={careplanTitle}
				buttonColor={true}
				title={careplanTitle}
				icon="map"
				contrast={page === "careplan"}
				style={styles.bottomPadded}
				onPress={openCareplan}
			/>
		);

	if (settings.forms === true && !isFormsEmpty)
		buttons.push(
			<VerticalButton
				key={formsTitle}
				buttonColor={true}
				title={formsTitle}
				icon="map"
				contrast={page === "forms"}
				style={styles.bottomPadded}
				onPress={openForms}
			/>
		);

	if (settings.messaging === "advanced")
		buttons.push(
			<VerticalButton
				key={messagingTitle}
				buttonColor={true}
				title={messagingTitle}
				icon="envelope"
				contrast={page === "messaging"}
				style={styles.bottomPadded}
				onPress={openMessaging}
			>
				{renderMissedMessage}
			</VerticalButton>
		);

	if (settings.myhealth === true)
		buttons.push(
			<VerticalButton
				key={myHealthTitle}
				buttonColor={true}
				title={myHealthTitle}
				icon="heartbeat"
				contrast={page === "activity"}
				style={styles.bottomPadded}
				onPress={openMyHealth}
			/>
		);

	if (pendingActions && pendingActions.length)
		buttons.push(
			<VerticalButton
				key={workflowTitle}
				buttonColor={true}
				title={workflowTitle}
				icon="files"
				contrast={page === "workflow"}
				style={styles.bottomPadded}
				onPress={openWorkflow}
			>
				<NotificationIcon style={styles.badge} title={pendingActions.length} />
			</VerticalButton>
		);

	if (settings.status && status.current_status > -1)
		buttons.push(
			<VerticalButton
				key={statusTitle}
				buttonColor={true}
				title={statusTitle}
				icon="clock"
				contrast={page === "status"}
				style={styles.bottomPadded}
				onPress={openStatus}
			/>
		);

	if (settings.resources)
		buttons.push(
			<VerticalButton
				key={resourcesTitle}
				title={resourcesTitle}
				icon="newspaper-o"
				contrast={page === "resources"}
				style={styles.bottomPadded}
				buttonColor={true}
				onPress={openResources}
			/>
		);

	if (buttons.length)
		buttons.unshift(
			<VerticalButton
				key={contactsTitle}
				title={contactsTitle}
				icon="users"
				buttonColor={true}
				contrast={page === "contacts"}
				style={styles.bottomPadded}
				onPress={openContacts}
			/>
		);

	var containerStyle = [styles.sidebar];
	if (squish) containerStyle.push(height);

	const ScrollComponent = Platform.OS === "android" ? ScrollIndicator : ScrollView;

	if (buttons.length)
		return (
			<View style={{flex: 1}}>
				{props.showPowerButton && <PowerButtonNew settings={settings} />}
				<ScrollComponent style={styles.scroll}>
					<TouchableWithoutFeedback>
						<View>{buttons}</View>
					</TouchableWithoutFeedback>
				</ScrollComponent>
			</View>
		);
	else return <View />;
}

ATouchAwaySidebar.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import React, { useContext, useCallback, useState } from 'react';
import { createStyled } from '@emotion/primitives-core';
import { variant } from 'styled-system';
import { StyleSheet, TouchableOpacity, View, ScrollView } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPhone, faPhoneSlash, faStar, faUsers, faCaretDown, faCaretUp, faMedal, faSpinner } from '@fortawesome/free-solid-svg-icons';
import AvatarGroup from '../Avatar/AvatarGroup';
import { sortNow } from '../Tray/trayMembers.library';
import Avatar from '../Avatar/Avatar';
import { asInitials } from '../../libraries/tile.library';
import ResponsiveText from '../Text/ResponsiveText';
import Style from "@aetonix/styles";
import MeetingContext from '../../context/MeetingContext';
import { useLocalize } from '../../../hooks/useLocalize/LocalizeContext';

const styled = createStyled(StyleSheet);

const BackgroundOverlay = styled(View)({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(10, 10, 10, 0.6)',
	overflow: 'hidden',
	flexDirection: 'column',
	justifyContent: 'flex-end',
});

const Container = styled(View)({
	borderWidth: 3,
	borderRightWidth: 0,
	borderLeftWidth: 0,
	borderRadius: 20,
	borderColor: "#e8e8e8",
	backgroundColor: "white",
	alignItems: "flex-start",
	borderBottomRightRadius: 0,
	borderBottomLeftRadius: 0,
	height: 'auto',
	maxHeight: '95%',
	overflow: 'hidden',
	width: "100%",
	paddingLeft: 15,
	paddingTop: 15,
	paddingRight: 5,
	paddingBottom: 10,
	flexDirection: 'column',
	flex: 0,
	flexShrink: 1,
	flexGrow: 1,
});

const CallText = styled(ResponsiveText)({
	color: "#141823",
	fontWeight: 'bold',
	maxWidth: "100%",
}, variant({
	variants: {
		title: { color: 'white', marginLeft: 15, padding: 1 },
		sender: { lineHeight: 65 },
		description: { color: '#596174', marginTop: 10 },
		open: { color: '#596174', marginLeft:1 },
	},
}));

const CallButtonContainer = styled(View)({
	width: '100%',
	minWidth: 300,
	maxWidth: 800,
	paddingVertical: Style.layout.paddingSlim,
	flexDirection: "row",
	alignItems: "flex-end",
	justifyContent: 'flex-start',
});

const CallActionButton = styled(TouchableOpacity)({
	flex: 1,
	alignItems: "center",
	justifyContent: 'center',
	padding: 10,
	borderWidth: 4,
	borderRadius: 15,
	borderColor: "#f5564a",
	height: 'auto',
	maxHeight: 80,
	maxWidth: 500,
}, variant({
	variants: {
		accept: {
			borderColor: "#065B61",
			backgroundColor: "#065B61",
			marginRight: 10
		},
		decline: {
			borderColor: "#C55050",
			backgroundColor: "#C55050",
		}
	}
}), variant({
	prop: 'disabled',
	variants: {
		true: { opacity: 0.5 },
		false: { opacity: 1 },
	}
}))

const Sheet = styled(View)({
	width: '100%',
	maxHeight: '95%',
	overflow: 'hidden',
	borderRadius: 20,
	backgroundColor: '#065B61',
	borderBottomRightRadius: 0,
	borderBottomLeftRadius: 0,
	shadowColor: "#000",
	shadowOffset: { width: 0, height: 12 },
	shadowOpacity: 1,
	shadowRadius: 16.00,
});

const ParticipantContainer = styled(View)({
	position: 'relative',
	justifyContent:'flex-start',
    flexDirection:'row',
	alignItems: 'center',
	paddingVertical: 3,
	marginBottom: 5,
})

const ItemTitle = styled(ResponsiveText) ({
    fontSize: 25,
	flex: 1,
    fontFamily: 'Avenir',
    fontWeight: '500',
    color: "#2c3e50",
	marginLeft: 10,
	alignSelf: 'center'
});

const iconsProps = {
	'patient': { icon: faStar, color: 'gold' },
	'care-team': { icon: faUsers, color: '#596174' },
	'administrator': { icon : faMedal, color:'silver', style: { 'borderRadius': 250 } }
};

const ParticipantSection = styled(View)({
	paddingVertical: 20,
	maxHeight: 'auto',
});

const ParticipantSectionHeader = styled(View)({
	flexDirection: 'row',
	justifyContent: 'flex-start',
	alignItems: 'center',
	width: 'auto',
	height: 'auto'
});

const PartcipantToggleButton = styled(TouchableOpacity)({
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-start',
	borderWidth: 4,
	borderColor: '#e8e8e8',
	borderRadius: 30,
	paddingHorizontal: 7,
	marginLeft: 5,
});

const BadgeContainer = styled(View)({
	position: 'absolute',
	right: -4,
	bottom: -4,
	zIndex: 20,
	backgroundColor: 'white',
	borderRadius: 1000,
	padding: 3,
});

const ScrollableContent = styled(ScrollView)({ width: '100%' });

const Participant = ({ name, type, image }) => {
	const icon = iconsProps?.[type] ?? null; 

	const badgeComponent = icon && (
		<BadgeContainer>
			<FontAwesomeIcon size={25} {...icon} />
		</BadgeContainer>
	);

	return (
		<ParticipantContainer accessibilityLabel={name}>
			<Avatar size="list" text={asInitials(name)} image={image} colour="#065B61" badge={badgeComponent}/>
			<ItemTitle size={Style.text.standard}>{name}</ItemTitle>
		</ParticipantContainer>
	);
}

const CollapsibleParticipants = ({ setIsParticipantsOpen, isParticipantsOpen, participants }) => {
	const localize = useLocalize();
	return (
		<ParticipantSection>
			<ParticipantSectionHeader>
				<CallText size={Style.text.smallHeading} adjustsFontSizeToFit numberOfLines={1}>{localize("meeting.roles.participants")}</CallText>
				<PartcipantToggleButton onPress={() => setIsParticipantsOpen(prevValue => !prevValue)}>
					<CallText
						variant="open"
						adjustsFontSizeToFit
						numberOfLines={1}
						size={Style.text.emphasized} 
					>
						{`Tap to ${isParticipantsOpen ? 'Close' : 'Open'}`}
					</CallText>
					<FontAwesomeIcon
						icon={isParticipantsOpen ? faCaretUp : faCaretDown}
						style={{ marginLeft: 1 }}
						color="#596174"
						size={40}
					/>
				</PartcipantToggleButton>
			</ParticipantSectionHeader>
			{ isParticipantsOpen && participants?.sort?.(sortNow)?.map?.(({ name, userType, image }, index) => (
				<Participant name={name} type={userType} image={image} key={index}/>
			))}
		</ParticipantSection>
	)
}

export default () => {

	const [isParticipantsOpen, setIsParticipantsOpen] = useState(false);

	const localize = useLocalize();
	const meetingManager = useContext(MeetingContext);

	const {
		meeting,
		isJoining,
		onJoinMeeting,
		onDeclineMeeting,
		members: participants
	} = meetingManager || {};

	const onAcceptMeeting = useCallback(() => onJoinMeeting(meeting?._id), [onJoinMeeting, meeting?._id]);

	const hasDataToRenderIncomingModal = !meetingManager || !meetingManager?.meeting;
	if (hasDataToRenderIncomingModal) {
		return null;
	}

	const { title: sender, description } = meeting || {};

	return (
		<BackgroundOverlay>
			<Sheet>
				<CallText size={Style.text.largeHeading} variant="title" adjustsFontSizeToFit numberOfLines={1}>{localize("meeting.incomingcalltitle")}</CallText>
				<Container>
					<ScrollableContent>
						<CallText size={60} variant="sender" title={sender} adjustsFontSizeToFit numberOfLines={2}>{sender}</CallText>
						<CallText size={Style.text.emphasized} variant="description" adjustsFontSizeToFit numberOfLines={3}>{description}</CallText>

						{ participants && participants?.length > 0 && (
							<AvatarGroup items={participants}/>
						)}

						{ participants && participants?.length > 0 && (
							<CollapsibleParticipants
								participants={participants}
								setIsParticipantsOpen={setIsParticipantsOpen}
								isParticipantsOpen={isParticipantsOpen}
							/>
						)}
					</ScrollableContent>
							
					<CallButtonContainer>
						<CallActionButton onPress={onAcceptMeeting} variant="accept" disabled={isJoining}>
							<FontAwesomeIcon icon={!isJoining ? faPhone : faSpinner} color="white" size={60}/>
						</CallActionButton >
						<CallActionButton onPress={onDeclineMeeting} variant="decline" disabled={isJoining}>
							<FontAwesomeIcon icon={faPhoneSlash} color="white" size={70}/>
						</CallActionButton>
					</CallButtonContainer>
				</Container> 
			</Sheet>
		</BackgroundOverlay>
	);
};
import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	StyleSheet,
	View,
	TouchableOpacity,
	Text,
	Linking
} from "react-native";

import Loader from "ae-base/Base/Loader";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";

import LoginSection from "./LoginSection";
import LoginLogo from "./LoginLogo";
import LoginBack from "./LoginBack";
import LoginSpacer from "./LoginSpacer";
import License from "./License";
import PrivacyPolicy from "./PrivacyPolicy";

import Form from "ae-forms";
import RegisterSchema from "../../../schemas/Register";
import LicenseCheckBoxSchema from "../../../schemas/LicenseCheckBoxSchema";
import PrivacyPolicyCheckBoxSchema from "../../../schemas/PrivacyPolicyCheckBoxSchema";


var styles = StyleSheet.create({
	submitAlign: {
		justifyContent: "flex-start"
	},
	backButton: {
		zIndex: 1,
		position: "absolute",
		top: -10,
		left: 0,
		right: 0,
		bottom: 0
	}
});

export default function Register(props, context){
	var dispatch = context.dispatch;
	var model = props.model;
	var toast = model.toast;
	var saving = model.save;
	var loading = model.authenticating;
	var localize = context.localize;

	var enteryourregistrationinformation = localize("register.enteryourregistrationinformation");
	var registeratouchaway = localize("register.registeratouchaway");
	var licenseagreement = localize("register.licenseagreement");
	const privacyPolicyAgreement = localize("privacy_policy.privacy_policy_agreement");

	if(loading) return (
		<Loader />
	);

	const renderLicense = model.license_show ? <License close={dispatch("register:trigger:license:hide")} /> : null;
	const renderPrivacyPolicy = model.privacy_policy_show ? <PrivacyPolicy close={dispatch("register:trigger:privacy_policy:show")} /> : null;

	return (
		<PageContainer dark saving={saving} toast={toast}>
			<MainScroll>
				<View style={styles.backButton}>
					<LoginBack onPress={dispatch("app:trigger:login")} />
				</View>

				<LoginSection header>
					<LoginLogo />
				</LoginSection>

				<LoginSection contrast >
					<LoginSpacer>
						<StyledText bold large>{enteryourregistrationinformation}</StyledText>
					</LoginSpacer>

					<LoginSpacer>
						<View style={styles.heading}>
							<StyledText bold emphasized>{localize("passwordrules.header")}</StyledText>
						</View>
						<StyledText>{"\u2022 " + localize("passwordrules.1")}</StyledText>
						<StyledText>{"\u2022 " + localize("passwordrules.2")}</StyledText>
						<StyledText>{"\u2022 " + localize("passwordrules.3")}</StyledText>
						<StyledText>{"\u2022 " + localize("passwordrules.4")}</StyledText>
						<StyledText>{"\u2022 " + localize("passwordrules.5")}</StyledText>
						<StyledText>{"\u2022 " + localize("passwordrules.7")}</StyledText>
						<StyledText>{"\u2022 " + localize("passwordrules.8")}</StyledText>
						<StyledText>{"\u2022 " + localize("passwordrules.9")}</StyledText>
					</LoginSpacer>

					<LoginSpacer>
						<Form key={registeratouchaway} schema={RegisterSchema} submitText={registeratouchaway} onSubmit={dispatch("register:trigger:register")} submitStyle={styles.submitAlign}>
							{<LoginSpacer>
								<StyledButton id="viewlicense" fullwidth title={licenseagreement} icon="notebook" onPress={dispatch("register:trigger:license:show")} />
							</LoginSpacer>}
							<Form schema={LicenseCheckBoxSchema} autoSubmit={true} onSubmit={dispatch("register:trigger:checked")}>
							</Form>
							{<LoginSpacer>
								<StyledButton id="viewlicense" fullwidth title={privacyPolicyAgreement} icon="notebook" onPress={dispatch("register:trigger:privacy_policy:show")} />
							</LoginSpacer>}
							<Form schema={PrivacyPolicyCheckBoxSchema} autoSubmit={true} onSubmit={dispatch("register:trigger:checked")}>
							</Form>
						</Form>

					</LoginSpacer>

				</LoginSection>
			</MainScroll>
			{renderPrivacyPolicy}
			{renderLicense}
		</PageContainer>
	);
}

Register.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

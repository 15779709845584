import PropTypes from "prop-types";
import React from "react";

import Heading from "ae-base/Base/Heading";
import StyledButton from "ae-base/Base/StyledButton";

import { View } from "react-native";

const styles = {
	sectionContent: {
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	}
}

export default function ContactHeading(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;
	var contrast = props.contrast;
	var count = props.count;
	var section = props.section;
	var displaying = props.displaying;
	var screen = props.screen;
	var patientGroups = props.patientGroups || [];

	var headerYou = localize("contactheading.headerYou");
	var headerContact = localize("contactheading.headerContact", {
		count: count
	});
	var headerManagedContact = localize("contactheading.headerManagedContact", {
		count: count
	});
	var incoming = localize("contactheading.incoming");
	var outgoing = localize("contactheading.outgoing");
	var minimize = localize("contactheading.minimize");
	var	maximize = localize("contactheading.maximize");
	var nocontacts = localize("contactheading.nocontacts");

	var headerMessage = headerYou + headerContact ;
	if(section === "managed")
		headerMessage = headerYou + headerManagedContact ;
	if(section === "incoming")
		headerMessage = incoming;
	if(section === "outgoing")
		headerMessage = outgoing;
	var icon = displaying ? "directup" : "dropdown";
	var title = displaying ? minimize : maximize;
	var toggleDisplaying = dispatch("homepage:trigger:toggle:" + section);

	if(section === "nocontacts") {
		headerMessage = nocontacts;
		icon = null;
		title = null;
		toggleDisplaying = null;
	}

	return (
			<View style={styles.sectionContent}>
					<StyledButton 
						id={context.id_prefix + section} 
						icon={count || patientGroups.length ? icon : undefined} 
						clear 
						title={headerMessage} 
						smallHeading 
						contrast={contrast} 
						onPress={count || patientGroups.length ? toggleDisplaying : undefined} 
					/>
			</View>
	);
}

ContactHeading.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React  from "react";

import Banner from "./Banner";

import Style from "ae-style";

export default function ManagedNewBanner(props, context){
	var localize = context.localize;
	var dispatch = context.dispatch;

	var banner = props.banner;
	var time = banner.updated_at;
	var date = banner.formatted_date;
	var from = banner.from;

	var from_id = from._id;
	var color = Style.colors.white;
	var banner_type = banner.banner_type;

	var message_line1 = localize("banner.managedusernew", {
		lname: from.lname,
		fname: from.fname
	});

	var clear = dispatch("banner:trigger:clear", {
		from: from_id,
		type: banner_type
	});

	return (
		<Banner time={time} date={date} message_line1={message_line1} color={color} onPress={clear} icon={"info"} border />
	);
}

ManagedNewBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Component
} from "react";
import {
	View,
	StyleSheet
} from "react-native";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";

var styles = StyleSheet.create({
	textContainer: {
		flex: 1
	},
	textStyle: {
		color: Style.colors.white
	}
});

export default class OfflineBanner extends Component {
	render(){
		var context = this.context;
		var color;
		var content;

		var localize = context.localize;

		color = "red";
		content = localize("banner.offline");

		return (
			<Notification
				color={color}
				iconColor={"white"}
				icon={"sync"}>
				<View style={styles.textContainer}>
					<StyledText style={styles.textStyle} bold emphasized>
						{content}
					</StyledText>
				</View>
			</Notification>
		);
	}
}

OfflineBanner.contextTypes = {
	localize: PropTypes.func,
	config: PropTypes.object
};

import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function BraceletDisconnect(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var devicedisconnected = localize("banner.braceletdisconnect", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={devicedisconnected} iconName="info"  notification={notification} screen={screen} />
	);

}

BraceletDisconnect.contextTypes = {
	localize: PropTypes.func,
};

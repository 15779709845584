import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Form from "ae-forms";

import ManagedSection from "../Pages/Managed/ManagedSection";

export default function SectionEdit(props, context){
	var model = props.model;
	var manage = model.manage;
	var account = manage.account;

	var careplan = manage.careplan;
	var edit = careplan.edit;
	var schema = edit.schema;
	var original = edit.original;
	var section = careplan.section.section;

	var sectionTitle = context.localize("careplanedit." + section);
	var onSubmit = context.dispatch("managedcareplansectioneditor:trigger:finish");

	var loadedKey = Object.keys(original || {}).length;

	return (
		<ManagedSection online={model.online} contact={account} title={sectionTitle}>
			<Form key={loadedKey} schema={schema} onSubmit={onSubmit} defaultData={original} />
		</ManagedSection>
	);
}

SectionEdit.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function UrgencyCall(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var urgencycallfrom = localize("banner.urgency_from", notification.from);
	var screen = props.screen;
	return (
		<TimelineItem content={urgencycallfrom} iconName="info"  notification={notification} screen={screen} />
	);

}

UrgencyCall.contextTypes = {
	localize: PropTypes.func,
};

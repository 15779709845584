import PropTypes from "prop-types";
import React from "react";
import {
	View,
	Image,
	StyleSheet,
	NativeModules
} from "react-native";

import AtaContainer from "./AtaContainer";
import LogoImg from "../../Resources/img/Aetonix_logo.png";
import SquareButton from "./SquareButton";
import StyledButton from "ae-base/Base/StyledButton";
import Card from "../Layout/Card";
import MediaSettings from "../Pages/Myprofile/Sections/MediaSettings";
import MainScroll from "../Layout/MainScroll";
const { AndroidSettings } = NativeModules;

import Style from "ae-style";

var styles = StyleSheet.create({
	logoImg: {
		width: 180,
		height: 120,
		resizeMode: "contain"
	},
	logoView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginHorizontal: Style.layout.marginContent
	},
	squreButtonView: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginVertical: Style.layout.marginMedium
	},
	inlineSqureButtonView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		marginVertical: Style.layout.marginMedium
	}
});

export default function Setting(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;

	var about = props.model.aboutbox;
	var options = props.model.options;
	var mediaSettingOpen = options.settings;
	var androidSetting = about.showSettings;

	var accountSwitch = props.model.accountswitch || {};
	var hasParent = accountSwitch.hasParent;

	var logoutButton = hasParent ? (
		<LogoutReturnButton />
	) : (
		<LogoutButton />
	);

	var renderSettingButtons = androidSetting ? (
		<View>
			<View style={styles.squreButtonView}>
				<MediaSettingsButton />
				<SettingsButton />
				<UpdateButton updateState={about.updateState} />
				{logoutButton}
			</View>
		</View>
	) : (
		<View style={styles.inlineSqureButtonView}>
			<MediaSettingsButton />
			<SquareButton small secondary contrast title={localize("contactsettings.settings")} icon={"settings"} onPress={() => AndroidSettings.wirelessSettings()} />
			{logoutButton}
		</View>
	);

	return mediaSettingOpen ? (
		<AtaContainer>
			<MainScroll>
				<Card>
					<Logo onBack={dispatch("options:trigger:settings")} />
					<MediaSettings options={options} />
				</Card>
			</MainScroll>
		</AtaContainer>
	) : (
		<AtaContainer>
			<Logo onBack={dispatch("about:trigger:exit")} />
			{renderSettingButtons}
		</AtaContainer>
	);
}

function Logo(props, context) {
	var onBack = props.onBack;
	var localize = context.localize;
	var goBack = localize("about.goback");

	return (
		<View style={styles.logoView}>
			<Image resizeMethod="resize" style={styles.logoImg} source={LogoImg} />
			<StyledButton alert title={goBack} onPress={onBack} />
		</View>
	);
}

function LogoutButton(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;

	var logoutText = localize("mobileatouchawaysettings.logoutbutton");

	return (
		<SquareButton small highlight contrast title={logoutText} icon={"delete"} onPress={dispatch("logout:trigger:logout")} />
	);
}

function LogoutReturnButton(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;

	var logoutText = localize("logoutreturn.logoutreturn");

	return (
		<SquareButton small highlight contrast title={logoutText} icon="delete" onPress={dispatch("accountswitcher:trigger:returnAccount")} />
	);
}

function UpdateButton(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;
	var updateState = props.updateState;

	var disabled = (!updateState) || (updateState === "none");
	var current = updateState === "current";

	if(disabled) return null;

	var action = current ? null : dispatch("contentupdate:trigger:update");

	if(updateState === "current" || updateState === "none"){
		var updateText = localize("mobileatouchawaysettings.updatebutton.noupdate");
		var downloadIcon = "check";
	}else if(updateState === "available"){
		updateText = localize("mobileatouchawaysettings.updatebutton.newupdate");
		downloadIcon = "download";
	}else if(updateState === "downloading" || updateState === "installing"){
		updateText = localize("mobileatouchawaysettings.updatebutton.updating");
		downloadIcon = "sync";
	}

	return (
		<SquareButton small secondary contrast title={updateText} icon={downloadIcon} onPress={action} />
	);
}

function SettingsButton(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;

	var androidText = localize("mobileatouchawaysettings.androidbutton");

	return (
		<SquareButton small secondary contrast title={androidText} icon={"smartphone"} onPress={dispatch("about:trigger:settings:android")} />
	);
}

function MediaSettingsButton(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;

	var mediaButtonText = localize("mobileatouchawaysettings.mediabutton");

	return (
		<SquareButton small secondary contrast title={mediaButtonText} icon={"settings"} onPress={dispatch("options:trigger:settings")} />
	);
}

LogoutReturnButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

UpdateButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

MediaSettingsButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

SettingsButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

LogoutButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

Setting.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

Logo.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, { Component, useMemo } from "react";
import { createStyled } from '@emotion/primitives-core'
import { View, StyleSheet } from 'react-native';

import ATouchAwayHome from "./ATouchAwayHome";
import ATouchAwayCalling from "./aTouchAwayCalling";
import ATouchAwaySetting from "./Setting";
import ATouchAwayMessaging from "./Messaging/ATouchAwayMessaging";
import HiddenScreen from "./HiddenScreen";
import ScreenSaver from "./ScreenSaver";
import FeedbackModal from "./FeedbackModal";
import Disconnected from "./Disconnected";
import TrialExpired from "./TrialExpired";
import ATouchAwayCareplan from "./ATouchAwayCareplan";
import ATouchAwayMyHealth from "./ATouchAwayMyHealth";
import ATouchAwayForms from "./ATouchAwayForms";
import ATouchAwayWorkflow from "./ATouchAwayWorkflow";
import ATouchAwayStatus from "./ATouchAwayStatus";
import Legacy from "./Legacy";
import About from "./About";
import Reminder from "./Reminder";
import AtaContainer from "./AtaContainer";
import WorkflowActionModal from "../Workflow/WorkflowActionModal";
import FileViewer from "../FileViewers/FileViewer";
import CallGroupBusy from "./CallGroupBusy";
import ATouchAwayResources from "./ATouchAwayResources/ATouchAwayResources";
import DeviceFollowUp from "./ATouchAwayMyHealth/DeviceFollowUp";
import License from "../Pages/Login/License";
import Relogin from "../AccountSwitch/Login";
import AtouchAwayNewReadingModal from "./ATouchAwayMyHealth/AtouchAwayNewReadingModal";
import StatusBar from "ae-base/Base/StatusBar";
import Loading from "./Loading";
import useActiveMeetingManager from '../../Calling/hooks/useActiveMeetingManager/useActiveMeetingManager';
import IncomingCallModal from '../../Calling/components/Modals/IncomingCall';
import Call from '../../Calling/Call';
import MeetingContext from "../../Calling/context/MeetingContext";
import MeetingMinimize from "../../Calling/components/MeetingMinimize";
import PrivacyPolicy from "../Pages/Login/PrivacyPolicy";

const styled = createStyled(StyleSheet);

const MeetingContainer = styled(View)({
	width: '100%',
	height: '100%',
	flex: 1,
	flexDirection: 'column',
});

function ATouchAwayFunctional(props, context) {
	var props = props;
	var context = context;
	var model = props.model;
	var settings = model.settings;
	var currentPage = model.page;

	var options = model.options;
	var aboutbox = model.aboutbox;
	var screensaver = model.screensaver;
	var fileViewer = model.fileviewer;
	var language = model.personal.language;

	var textsize = settings.textsize;

	const { dispatch, api, listen, unlisten, config, localize } = context;
	const events = { api, dispatch, listen, unlisten };

	const meetingManager = useActiveMeetingManager(events, config, model);

	const { meeting, isIncomingCallOpen } = meetingManager;
	const isNotInMeeting =  !isIncomingCallOpen && currentPage !== "meeting";

	const isInMeetingOrPending = currentPage === "meeting" || isIncomingCallOpen;
	if (screensaver && !isInMeetingOrPending) {
		return <ScreenSaver key={textsize+language} type={screensaver} language={language} />;
	}

	if (isNotInMeeting && aboutbox.open) {
		return <About options={options} aboutbox={aboutbox}/>;
	}

	if (isNotInMeeting && settings.legacy && currentPage !== "setting") {
		return <Legacy model={model} />;
	}	

	var hide = model.hide;
	var offline = model.offline;
	var trial = model.trial;
	var expired = trial.expired;

	var externalForms = model.workflowexternals;

	var returnLogin = model.accountswitch && !!model.accountswitch.email ? (
		<Relogin accountSwitch={model.accountswitch} />
	) : null;

	var renderLicense = model.license ? (
		<License close={dispatch("license:trigger:hide")} />
	) : null;

	var renderPrivacyPolicy = model.privacypolicy ? (
		<PrivacyPolicy close={dispatch("privacypolicy:trigger:hide")} />
	) : null;

	var feedback = model.feedbackmodal ? (
		<FeedbackModal model={model} scroll />
	) : null;

	const healthIndicatorNewReadingModal = ( model.page && currentPage !== "meeting") ? (<AtouchAwayNewReadingModal model={model} scroll />) : null;

	var callgroupBusy = model.callgroupscalling.busy ? (
		<CallGroupBusy group={model.callgroupscalling.group} toast={model.toast} />
	) : null;

	var workflowAction = model.workflowaction && (!model.workflowaction.collapsed) ? (
		<WorkflowActionModal atouchaway action={model.workflowaction} externalForms={externalForms} isATouchAway />
	) : null;

	var reminder = model.reminders ? (
		<Reminder reminder={model.reminders} />
	) : null;

	var deviceFollowUp = model.devicefollowup && model.devicefollowup.open ? (
		<DeviceFollowUp followup={model.devicefollowup} />
	) : null;

	var mainContent = null;

	if(expired)
		return <TrialExpired />;

	if(hide)
		return <HiddenScreen />;

	if (currentPage === "calling")
		return (
			<ATouchAwayCalling model={model} />
		);

	if(offline)
		return <Disconnected />;

	if (currentPage === "meeting") {
		mainContent = <Call mode="simplified" /> ;
	} else if (currentPage === "healthstatus") {
		mainContent = (
			<ATouchAwayMyHealth model={model} />
		);
	}
		
	if(currentPage === "overview")
		mainContent =  (
			<ATouchAwayMyHealth model={model} />
		);
	if(currentPage === "activity")
		mainContent =  (
			<ATouchAwayMyHealth model={model} />
		);

	if(currentPage === "bloodsugar")
		mainContent =  (
			<ATouchAwayMyHealth model={model} />
		);
	if(currentPage === "bloodpressure")
		mainContent =  (
			<ATouchAwayMyHealth model={model} />
		);
	if(currentPage === "bodytemperature")
		mainContent =  (
			<ATouchAwayMyHealth model={model} />
		);
	if(currentPage === "bloodoxygen")
		mainContent =  (
			<ATouchAwayMyHealth model={model} />
		);
	if (currentPage === "customindicator") {
		mainContent = (<ATouchAwayMyHealth model={model} />);
	}
	if(currentPage === "weight")
		mainContent =  (
			<ATouchAwayMyHealth model={model} />
		);

	if(currentPage === "setting")
		mainContent = (
			<ATouchAwaySetting model={model} />
		);
	if(currentPage === "contacts")
		mainContent = (
			<ATouchAwayHome key={textsize} model={model} />
		);
	if(currentPage.indexOf("careplan") !== -1)
		mainContent = (
			<ATouchAwayCareplan model={model} />
		);
	if(currentPage === "messaging")
		mainContent = (
			<ATouchAwayMessaging key={textsize} model={model} />
		);
	if(currentPage === "forms")
		mainContent = (
			<ATouchAwayForms model={model} />
		);
	if(currentPage === "workflow")
		mainContent = (
			<ATouchAwayWorkflow model={model} />
		);
	if(currentPage === "status")
		mainContent = (
			<ATouchAwayStatus model={model} />
		);

	if(currentPage === "resources")
		mainContent = (
			<ATouchAwayResources model={model} />
		);

	return (
		<AtaContainer>
			<MeetingContext.Provider value={meetingManager} >
				<MeetingContainer>
					<StatusBar barStyle="dark-content" />
					{mainContent}
					{isNotInMeeting && renderLicense}
					{isNotInMeeting && renderPrivacyPolicy}
					{isNotInMeeting && reminder}
					{isNotInMeeting && workflowAction}
					{isNotInMeeting && deviceFollowUp}
					{isNotInMeeting && feedback}
					{healthIndicatorNewReadingModal}
					{returnLogin}
					<FileViewer noDownload open={!!fileViewer.file} file={fileViewer.file} />
					{isNotInMeeting && callgroupBusy}
         			 <Loading open={model.saving} />
					{ isIncomingCallOpen && <IncomingCallModal/> }
				</MeetingContainer>
				
				{ meetingManager?.isMinimized && (
					<MeetingMinimize title={meeting?.title} onClick={() => meetingManager?.goToMeeting?.()}/>
				)}
			</MeetingContext.Provider>
		</AtaContainer>
	);
}

export default class aTouchAway extends Component {
	getChildContext(){
		var model = this.props.model;
		var settings = model.settings || {};
		var textsize = settings.textsize;
		return {
			textsize: textsize
		};
	}

	render(){
		return <ATouchAwayFunctional { ...this.props }/>
	}
}

aTouchAway.contextTypes = {
	dispatch: PropTypes.func,
	listen: PropTypes.func,
	unlisten: PropTypes.func,
	config: PropTypes.object,
};

aTouchAway.childContextTypes = {
	textsize: PropTypes.number
};

ATouchAwayFunctional.contextTypes = {
	dispatch: PropTypes.func,
	listen: PropTypes.func,
	unlisten: PropTypes.func,
	config: PropTypes.object,
	api: PropTypes.object,
	localize: PropTypes.func
};

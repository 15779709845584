
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, March 05, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Loader from "ae-base/Base/Loader";
import CardPage from "../../Layout/CardPage";
import PropTypes from "prop-types";
import Form from "ae-forms";

var DEFAULT_DATA = {};

export default function SubmissionCreation(props, context) {
	var model = props.model;
	var screen = model.size;
	var dispatch = context.dispatch;
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;

	var saving = model.saving;
	var groupformlist = model.groupformlist;
	var formId = model.groupformsubmissions.form;
	var currentForm = groupformlist[formId];

	var schema = currentForm.schema;
	var hasSchema = (schema && schema.properties);

	if(!hasSchema) return <Loader />;

	var localization = currentForm.localization;
	var schemaTitle = localizeFrom(localization, ".") || currentForm.name || currentForm._id;
	var title = `${schemaTitle} - ${localize("groupforms.addnew")}`;

	var onSubmit = dispatch("activegroupformsubmission:trigger:submit");
	var goBack = dispatch("activegroupformsubmission:trigger:cancel");
	var discardchanges = localize("groupforms.discardchanges");

	return (
		<CardPage title={title} icon="notebook" saving={saving} screen={screen} toast={model.toast} online={model.online}>
			<Form schema={schema} defaultData={DEFAULT_DATA} onSubmit={onSubmit} onCancel={goBack} cancelText={discardchanges} />
		</CardPage>
	);
}

SubmissionCreation.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func
};

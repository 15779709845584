import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Component
} from "react";
import {
	View,
	StyleSheet
} from "react-native";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import Permissions from "react-native-permissions";

import Style from "ae-style";

var PERMISSION_TYPES = [
	"camera",
	"microphone",
	"photo",
	"bluetooth",
	"notification",
	"backgroundRefresh",
	"mediaLibrary"
];

var styles = StyleSheet.create({
	text_style: {
		color: Style.colors.white,
		paddingRight: Style.layout.paddingSmall,
		justifyContent: "center",
	},
	button_container: {
		marginLeft: Style.layout.marginSmall,
		justifyContent: "center"
	},
	text_container: {
		flex: 1
	}
});

export default function PermissionBanners(props, context) {
	var permissionsData = props.permissions;
	var localize = context.localize;
	var permission = permissionsData.permission || {};
	var viewing = permissionsData.viewing || {};

	var banners = PERMISSION_TYPES.filter(function(type){
		var status = permission[type];
		var showing = viewing[type];
		return (showing && (status === "denied" || (type === "notification" && (status === "partial_authorized" || status === "undetermined"))));
	}).map(function(type){
		var text = localize("permissionbanners." + type);
		return (<PermissionBanner type={type} key={type} text={text} />);
	});

	return (
		<View >
			{banners}
		</View>
	);
}

class PermissionBanner extends Component {
	render(){
		var props = this.props;
		var context = this.context;

		var text = props.text;
		var type = props.type;

		var color =  Style.colors.primary;
		var localize = context.localize;
		var dispatch = context.dispatch;

		var openSettings = (<StyledButton
			id={"permissionBanner"}
			white
			title={localize("permissionbanners.settings")}
			onPress={Permissions.openSettings} />);

		var hideBanner = dispatch("devicepermissions:trigger:hide", type);

		return (<Notification
			color={color}
			iconColor={"white"} onPress={hideBanner}>
			<View style={styles.text_container}>
				<StyledText style={styles.text_style} emphasized>
					{text}
				</StyledText>
			</View>
			<View style={styles.button_container}>
				{openSettings}
			</View>
		</Notification>);
	}
}

PermissionBanners.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

PermissionBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {Component} from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	NativeModules
} from "react-native";
const { AndroidSettings } = NativeModules;

import Loader from "ae-base/Base/Loader";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";
import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import Divider from "../../Layout/Divider";
import Style from "@aetonix/styles";
import VersionNumber from "../Myprofile/VersionNumber";

import Form from "ae-forms";
import LoginSchema from "../../../schemas/Login";
import MultiFactorAuthSchema  from "../../../schemas/MultiFactorAuth";

import LoginSection from "./LoginSection";
import LoginLogo from "./LoginLogo";
import LoginBack from "./LoginBack";
import LoginLanguageBar from "./LoginLanguageBar";
import LoginSpacer from "./LoginSpacer";

var styles = StyleSheet.create({
	submitAlign: {
		justifyContent: "flex-start"
	},
	dividerSpacer: {
		marginBottom: Style.layout.marginMedium
	},
	backButton: {
		zIndex: 1,
		position: "absolute",
		top: -10,
		left: 0,
		right: 0,
		bottom: 0
	},
	buttonStyle: {
		paddingLeft: 0
	},
	settingsButton: {
		justifyContent: "center",
		alignSelf: "center",
		marginBottom: "0%"
	}
});

export default class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			time: 60,
			btnDisable: false,
			initialSend: false,
		};
	}

	render() {
		var props = this.props;
		var context = this.context;
		var dispatch = context.dispatch;
		var model = props.model;
		var saving = model.save;
		var localize = context.localize;
		var toast = model.toast;

		var already = localize("login.already");
		var haveanatouchawayaccount = localize("login.haveanatouchawayaccount");
		var signintoatouchaway = localize("login.signintoatouchaway");
		var areyou = localize("login.areyou");
		var newtoatouchaway = localize("login.newtoatouchaway");
		var createyourselfanaccount = localize("login.createyourselfanaccount");
		var ifyouvebeen = localize("login.ifyouvebeen");
		var givenanaccountkey = localize("login.givenanaccountkey");
		var enteranaccountkey = localize("login.enteranaccountkey");
		var authcoderequest = localize("multifactor.authcoderequest");
		var entercode = localize("multifactor.entercode");
		var codesubmit = localize("multifactor.submit");

		var signin = localize("login.signin");
		var signtoatouchaway = localize("login.signtoatouchaway");
		var forget = localize("login.forget");

		var loading = model.authenticating;

		if(loading) return (
			<Loader />
		);

		var asked = model.asked;

		var twofactorauth = model.id && model.email;
		var defaultData = {
			id: model.id
		};

		var maskedEmail = model.email ? emailMask(model.email) : "";

		var timeChange;
		var ti = this.state.time;
		const clock = () => {
			if (ti > 0) {
				ti = ti - 1;
				this.setState({
					time: ti,
					btnContent: authcoderequest + " (" + ti + "s)",
				});
			} else {
				clearInterval(timeChange);
				this.setState({
					btnDisable: false,
					time: 60,
					btnContent: authcoderequest,
				});
			}
		};

		const sendCode = () => {
			this.setState({
				btnDisable: true,
				btnContent: authcoderequest + " (60s)",
				initialSend: true
			});
			timeChange = setInterval(clock, 1000);
			dispatch("login:trigger:twofactorauth:request", {email: model.email, id: model.id})();
		};

		if(twofactorauth && !this.state.initialSend) {
			sendCode();
		}

		var content = asked && !twofactorauth ? (
			<LoginSection contrast >
				<StyledText large bold>{signin}</StyledText>
				<LoginSpacer>
					<Form id_prefix={"signinform_"} schema={LoginSchema} submitText={signtoatouchaway} onSubmit={dispatch("login:trigger:login")} submitStyle={styles.submitAlign} />
				</LoginSpacer>
				<LoginSpacer>
					<StyledButton id={"passwordReset"} title={forget} style={styles.buttonStyle} clear tertiary onPress={dispatch("app:trigger:passwordreset")} />
				</LoginSpacer>
			</LoginSection>
		) : twofactorauth ? (
			<LoginSection contrast >
				<StyledText large bold>{entercode}</StyledText>
				<LoginSpacer>
					<StyledText bold>{maskedEmail}</StyledText>
					<Form id_prefix={"multifactorauthform_"} schema={MultiFactorAuthSchema} submitText={codesubmit} defaultData={defaultData} onSubmit={dispatch("login:trigger:twofactorauth")} submitStyle={styles.submitAlign} />
					<BlockView style={styles.dividerSpacer}>
						<Divider />
					</BlockView>
					<StyledButton title={this.state.btnContent} id={"authcodeRequest"} onPress={sendCode} border white disabled={this.state.btnDisable} />
				</LoginSpacer>
			</LoginSection>
		) : (
			<LoginSection contrast>
				<Text>
					<StyledText>{already}</StyledText>
					<StyledText bold>{haveanatouchawayaccount}</StyledText>
				</Text>
				<LoginSpacer>
					<StyledButton contrast id={"signInButton"}  title={signintoatouchaway} onPress={dispatch("login:trigger:show")} />
				</LoginSpacer>
				<BlockView style={styles.dividerSpacer}>
					<Divider />
				</BlockView>
				<Text>
					<StyledText>{areyou}</StyledText>
					<StyledText bold>{newtoatouchaway}</StyledText>
				</Text>
				<LoginSpacer>
					<BlockView>
						<StyledButton title={createyourselfanaccount} id={"createNewAccount"} onPress={dispatch("app:trigger:register")} border white />
					</BlockView>
				</LoginSpacer>
			</LoginSection>
		);

		var headerContent = asked && !twofactorauth ? (
			<View style={styles.backButton}>
				<LoginBack id={"loginBack"} onPress={dispatch("login:trigger:asked")} />
			</View>
		) : twofactorauth ? (
			<View style={styles.backButton}>
				<LoginBack id={"loginBack"} onPress={dispatch("login:trigger:twofactorauth:cancel")} />
			</View>
		) : (
			<LoginLanguageBar language={model.language} />
		);

		return (
			<PageContainer dark saving={saving} toast={toast} >
				<MainScroll>
					{headerContent}

					<LoginSection header>
						<LoginLogo />
					</LoginSection>

					{content}

					<LoginSection>
						<Text>
							<StyledText contrast>{ifyouvebeen}</StyledText>
							<StyledText bold contrast>{givenanaccountkey}</StyledText>
						</Text>
						<LoginSpacer>
							<StyledButton title={enteranaccountkey} id={"enterAccountKey"} onPress={dispatch("app:trigger:tokenlogin")} />
						</LoginSpacer>
					</LoginSection>

					<VersionNumber config={model.config} model={model} showRegionChange />

					{ Platform.OS === 'android' &&
						<StyledButton medium clear title={localize("contactsettings.settings")}
							wrapperViewStyle={styles.settingsButton}
							onPress={() => AndroidSettings.wirelessSettings()}
						/>
					}

				</MainScroll>
			</PageContainer>
		);
	}
}

function emailMask(email) {
	var maskedEmail = email.replace(/([^@.])/g, "*").split("");
	var previous = "";
	for(var i = 0; i < maskedEmail.length; i++){
		if (i <= 1 || previous == "." || previous == "@"){
			maskedEmail[i] = email[i];
		}
		previous = email[i];
	}
	return maskedEmail.join("");
}

Login.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

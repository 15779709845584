import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import SectionTitle from "../SectionTitle";
import GroupItems from "../GroupItems";

var getProviderName = prop("ProviderName");
var getAppointmentDate = prop("AppointmentDate");
var getAppointmentTime = prop("AppointmentTime");
var getPurpose = prop("Purpose");
var getNotes = prop("Notes");

export default function Appointments(props, context) {
	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var localize = context.localize;
	var appointment_s = localize("appointments.appointment_s");

	var appointments = careplan.Appointment || [];

	var renderAppointment = appointments.map(function(appointment, index) {
		var isChanged = changes["Appointment." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<Appointment key={index} appointment={appointment} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	});

	return (
		<View>
			<SectionTitle name={appointment_s} section="Appointment" permissions={permissions} changes={changes} />
			{renderAppointment}
		</View>
	);
}

class Appointment extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var appointment = props.appointment;
		var index = props.index;
		var currentSection = props.currentSection;
		var hasChanged = props.hasChanged;
		var showCareplanDetail = props.showCareplanDetail;
		var ownCareplan = props.ownCareplan;

		var localize = context.localize;
		var providername = localize("appointments.providername");
		var datetime = localize("appointments.datetime");
		var purpose = localize("appointments.purpose");
		var notes = localize("appointments.notes");

		return (
			<GroupItems>
				<Items hasChanged={hasChanged} data={"Appointment." + index} >
					<Item>
						<Label>{providername}</Label>
						<Value>{getProviderName(appointment)}</Value>
					</Item>
					<Item alternate >
						<Label>{datetime}</Label>
						<Value>{getAppointmentDate(appointment)} {getAppointmentTime(appointment)}</Value>
					</Item>
					<Summarized currentSection={currentSection} section={"Appointments." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
						<Item>
							<Label>{purpose}</Label>
							<Value>{getPurpose(appointment)}</Value>
						</Item>
						<Item alternate>
							<Label>{notes}</Label>
							<Value>{getNotes(appointment)}</Value>
						</Item>
					</Summarized>
				</Items>
			</GroupItems>
		);
	}
}

Appointments.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

Appointment.contextTypes = {
	localize: PropTypes.func
};

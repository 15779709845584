import PropTypes from "prop-types";
import React from "react";
import ImageCrop from "../../../ImageCrop";
import Loader from "ae-base/Base/Loader";

export default function ProfilePictureCrop(props, context){
	var dispatch = context.dispatch;
	var model = props.model;
	var saving = model.saving;
	var personalpicture = model.personalpicture;
	var image = personalpicture.image;
	var width = personalpicture.width;
	var height = personalpicture.height;
	var orientation = personalpicture.orientation;

	if(!image || saving) return <Loader />;
	var goBack = dispatch("personalprofilepicture:trigger:cancel");

	var finishCrop = dispatch("personalprofilepicture:trigger:crop");

	return (
		<ImageCrop
			source={image.uri}
			width={width}
			height={height}
			orientation={orientation}
			onCancel={goBack}
			onConfirm={finishCrop}
		/>
	);
}

ProfilePictureCrop.contextTypes = {
	dispatch: PropTypes.func,
};

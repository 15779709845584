import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

var getProvidedServices = prop("ProvidedServices");
var getContactName = prop("ContactName");
var getPhone = prop("Phone");
var getEmail = prop("Email");
var getStartDate = prop("StartDate");
var getOrganization = prop("Organization.Name");

export default function Supports(props, context) {
	var careplan = props.careplan;

	var localize = context.localize;
	var supportTitle = localize("supports.supportsservices");
	var supportsandservicesinformation = localize("supports.supportsandservicesinformation");

	var communitySupport = careplan.CommunitySupport || [];

	var renderCommunitySupport = communitySupport.map(function(support, index) {
		return (
			<Communitysupport key={index} support={support} index={index} />
		);
	}
	);

	return (
		<Section title={supportTitle}>
			<Title title={supportsandservicesinformation} />
			{renderCommunitySupport}
		</Section>
	);
}

class Communitysupport extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var support = props.support;

		var localize = context.localize;
		var organizationname = localize("supports.organizationname");
		var servicesprovided = localize("supports.servicesprovided");
		var contactname = localize("supports.contactname");
		var telephonenumber = localize("supports.telephonenumber");
		var emailaddress = localize("supports.emailaddress");
		var startdate = localize("supports.startdate");

		return (
			<Group>
				<Item title={organizationname} value={getOrganization(support)} />

				<Item title={servicesprovided} value={getProvidedServices(support)} />

				<Item title={contactname} value={getContactName(support)} />

				<Item title={telephonenumber} value={getPhone(support)} />

				<Item title={emailaddress} value={getEmail(support)} />

				<Item title={startdate} value={getStartDate(support)} />
			</Group>
		);
	}
}

Supports.contextTypes = {
	config: PropTypes.object,
	localize: PropTypes.func
};

Communitysupport.contextTypes = {
	localize: PropTypes.func
};

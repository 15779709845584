import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function BraceletConnect(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var deviceconnected = localize("banner.braceletreconnect", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={deviceconnected} iconName="info"  notification={notification} screen={screen} />
	);

}

BraceletConnect.contextTypes = {
	localize: PropTypes.func,
};

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import PropTypes from "prop-types";

import StyledText from "ae-base/Base/StyledText";

import Header from "../HealthHeader";
import ResourceItem from "../../Resources/ResourceItem";
import MainScroll from "../../../Layout/MainScroll/MainScroll";
import MainContent from "../../../Layout/MainContent";

Resources.propTypes = {
	model: PropTypes.object
};

export default function Resources(props, context) {
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;
	var dispatch = context.dispatch;

	var model = props.model;
	var resourcesList = model.ownresources.resources || [];

	var resourcesTitle = localize("resources.title");

	function by_name(x, y) {
		x = x.name;
		y = y.name;
		if (typeof x === "object")
			x = localizeFrom(x, ".");
		if (typeof y === "object")
			y = localizeFrom(y, ".");
		let locale = model.personal.language || "en";
		if (locale === "cn_s" || locale === "cn_t")
			locale = (locale === "cn_s") ? "zh-CN" : "zh-HK";
		return x.localeCompare(y, locale);
	}

	var renderResourcesList = resourcesList.sort(by_name).map(function(resource) {
		var onClick = dispatch("ownresources:trigger:viewresource", {
			id: resource._id
		});
		return (
			<ResourceItem key={resource._id} resource={resource} onClick={onClick} />
		);
	});

	var renderEmptyResources = !resourcesList.length ? <StyledText centered>{localize("resources.empty")}</StyledText> : null;

	return (
		<MainScroll>
			<Header title={resourcesTitle} />
			<MainContent>
				{renderResourcesList}
				{renderEmptyResources}
			</MainContent>
		</MainScroll>
	);
}

Resources.contextTypes = {
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	dispatch: PropTypes.func
};

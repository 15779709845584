import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity
} from "react-native";

import ListItem from "ae-base/List/ListItem";
import ListAdd from "ae-base/List/ListAdd";
import NotificationIcon from "ae-base/Base/NotificationIcon";

import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";

import Avatar from "../../Contacts/Avatar";
import MainScroll from "../../Layout/MainScroll";

import Breakpoints from "../../../Breakpoints";

import StyledButton from "ae-base/Base/StyledButton";
import { DATE_TIME_FORMAT } from "../../../utils/date-utils";

const styles = StyleSheet.create({
	itemContainer: {
		padding: Style.layout.paddingSmall,
		flex: 1
	},
	container: {
		flex: 1
	},
	messageContainer: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between"
	},
	notification: {
		alignSelf: "flex-end",
		position: "relative"
	},
	loadMore: {
		padding: Style.layout.paddingSmall,
		justifyContent: "center",
		alignItems: "center"
	}
});

export default function SessionList(props, context){
	const localize = context.localize;
	const sessions = props.sessions || [];
	const create = props.create;
	const latestMessages = props.latestMessages || {};
	const missed = props.missed || {};
	const end = props.end;
	const loadMore = props.loadMore;

	const renderSessions = sessions.map((session) => {
		const sessionId = session._id;
		return (
			<SessionItem session={session} isMember={props.isMember} latest={latestMessages[sessionId]} missed={missed[sessionId]} pending={props.pending} />
		);
	});

	const renderCreate = create ? (
		<ListAdd title={localize("livechat.createsession")} onPress={create} />
	) : null;

	const loadmore = localize("timeline.loadmore");

	const renderLoadMore = end ? null : (
		<StyledButton id={"LoadMore"} title={loadmore} icon="chevron-circle-up" onPress={loadMore} />
	);

	return (
		<View style={styles.container}>
			<MainScroll>
				{renderCreate}
				{renderSessions}
				<View style={styles.loadMore}>
					{renderLoadMore}
				</View>
			</MainScroll>
		</View>
	);
}

function SessionItem(props, context){
	const localize = context.localize;
	const formatDate = context.formatDate;
	const dispatch = context.dispatch;
	const session = props.session || {};
	const latestMessage = props.latest;
	const missed = props.missed;
	let messageContent = "";
	let who = session.who || {};
	let date = new Date(session.created_at);

	if(latestMessage){
		if(latestMessage.type.indexOf("attachment") !== -1)
			messageContent = localize("livechat.attachment");
		else
			messageContent = latestMessage.content;
		date = new Date(latestMessage.created_at);
	}

	const isMember = props.isMember;

	if(isMember)
		who = session.owner || {};

	const name = who.fname ? who.lname + ", " + who.fname : localize("livechat.noagent");

	let viewSession = dispatch("activelivechatsession:trigger:view", {
		session: session._id
	});

	const pending = props.pending;
	if(pending)
		viewSession = dispatch("livechatsessions:trigger:accept", {
			session: session._id
		});

	let sizeAvatar = "mini";
	let sizeText = "small";
	if(Breakpoints.smallerThan("mobile")){
		sizeAvatar = "tiny";
		sizeText = "tiny";
	}

	const renderMissed = missed  && missed.messages ? (
		<NotificationIcon style={styles.notification} title={missed.messages} />
	) : null;

	const renderDate = date ? formatDate(date, DATE_TIME_FORMAT) : "";

	return (
		<TouchableOpacity onPress={viewSession}>
			<ListItem margined>
				<Avatar person={who} size={sizeAvatar} />
				<View style={styles.itemContainer}>
					<View style={styles.messageContainer}>
						<View>
							<StyledText>{name}</StyledText>
							<StyledText size={sizeText}>{messageContent}</StyledText>
						</View>
						<View>
							<StyledText size={sizeText}>{renderDate}</StyledText>
							{renderMissed}
						</View>
					</View>
				</View>
			</ListItem>
		</TouchableOpacity>
	);
}

SessionItem.contextTypes = {
	formatDate: PropTypes.func,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

SessionList.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
	View,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import Style from "@aetonix/styles";
import localization from "../../../Localization";

var styles = StyleSheet.create({
	version: {
		marginTop: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center"
	},
	region: {
		flexDirection: "row"
	}
});

export default function VersionNumber(props, context) {
	var config = context.config;
	var localize = context.localize;
	var dispatch = context.dispatch;
	var model = props.model;
	var selectedLanguage = model?.language ||  model?.personal?.language || 'en';
	var localization = config.localization
	var showRegionChange = props.showRegionChange;
	var black = props.black
	var changeregion = localize("versionnumber.changeregion");
	var localizedRegions = localization[selectedLanguage]?.hosts || {};
	var regionName = localizedRegions[config.name] || config.name;
	
	var renderRegionChange = null;
	if (showRegionChange) {
		renderRegionChange = (
			<StyledButton contrast small clear title={changeregion} onPress={dispatch("app:trigger:changeregion")} />
		);
		regionName = regionName + " - ";
	}
	if (black) {
		return (
			<View style={styles.version}>
				<StyledText black small>
					{"v" + config.version}{" - #"}{config.build}{" "}{config.type}
				</StyledText>
				<View style={styles.region}>
					<StyledText black small>{regionName}</StyledText>
					{renderRegionChange}
				</View>
			</View>
		);
	} else {
		return (
			<View style={styles.version}>
				<StyledText grey small>
					{"v" + config.version}{" - #"}{config.build}{" "}{config.type}
				</StyledText>
				<View style={styles.region}>
					<StyledText grey small>{regionName}</StyledText>
					{renderRegionChange}
				</View>
			</View>
		);
	}
}

VersionNumber.contextTypes = {
	config: PropTypes.object,
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";

import PageContainer from "../../../Layout/PageContainer";
import MainScroll from "../../../Layout/MainScroll/MainScroll";
import Card from "../../../Layout/Card";
import StyledText from "ae-base/Base/StyledText";
import Form from "ae-forms";

export default function WorkflowEdit(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;
	var model = props.model;
	var toast = model.toast;
	var workflow = model.manage.workflow;
	var details = workflow.details;
	var cancelTitle = localize("common.cancel");

	var cardTitle = localize("workflow.editCardTitle");
	var onCancel = dispatch("managededitingworkflow:trigger:reset");

	var definition = details && details.definition;
	var creationSchema = {
		properties: {}
	};
	var defaults = null;

	if(definition){
		var existingActors = details.actors;
		var actorSchemaProperties = Object.keys(definition.actors).reduce(function(result, actorLabel){
			if(actorLabel === "Patient") return result;
			var actorLocalization = definition.actors[actorLabel];

			if(actorLocalization?.type === "ManageGroup"){
				result[actorLabel] = {
					type: "object",
					properties: {
						label: {
							localization: actorLocalization,
							inputType: "output",
							displayType: "title"
						},
						patientGroups: {
							localization: localize("workflow.patientGroups"),
							type: "array",
							ignoreObjectStyle: true,
							fallbackPhrase: {
								"en": "Inaccessible Patient Group"
							},
							items: {
								type: "string",
								inputType: "dropdown",
								sources: ["manageGroups"],
								minLength: 1
							}
						},
						people: {
							localization: localize("workflow.managers"),
							type: "array",
							ignoreObjectStyle: true,
							fallbackPhrase: {
								"en": "Inaccessible User"
							},
							items: {
								type: "string",
								inputType: "dropdown",
								sources: ["managedContactsContacts", "managedContactsManagers", "managedManageGroupManagers"],
								minLength: 1
							}
						}
					}
				};
			} else {
				result[actorLabel] =  {
					localization: actorLocalization,
					fallbackPhrase: {
						"en": "Inaccessible User"
					},
					type: "array",
					items: {
						type: "string",
						inputType: "dropdown",
						sources: ["managedContactsContacts", "managedContactsManagers", "managedManageGroupManagers"],
						minLength: 1
					}
				}
			}
			return result;
		}, {});

		defaults = {
			actors: Object.keys(definition.actors).reduce(function(result, actorLabel){
				const actorLocalization = definition.actors[actorLabel];
				if(actorLocalization?.type === "ManageGroup"){
					result[actorLabel] = {
						patientGroups: existingActors.filter(actor => actor.actor === actorLabel && actor.type === "ManageGroup").map(e => e.who._id),
						people: existingActors.filter(actor => actor.actor === actorLabel && !actor.type).map(e => e.who._id)
					}
				} else {
					result[actorLabel] = getActorTypes(actorLabel, existingActors);
				}
				return result;
			}, {})
		};

		var edit = definition.edit;
		var editSchema = null;
		if(edit && edit.action)
			editSchema = definition.actions[edit.action];

		creationSchema = {
			"type": "object",
			"properties": {
				"actors": {
					"description": "Workflow Actors",
					"type": "object",
					"properties": actorSchemaProperties
				}
			}
		};

		if(editSchema){
			creationSchema.properties.edit = editSchema;
			defaults.edit = details.state || {};
		}
	}

	return (
		<PageContainer contrast toast={toast}>
			<MainScroll>
				<Card>
					<StyledText bold>{cardTitle}</StyledText>
					<Form
						schema={creationSchema}
						style={{flex: 1}}
						defaultData={defaults}
						sources={model.formsources}
						onSubmit={dispatch("managededitingworkflow:trigger:workflow:update")}
						onCancel={onCancel}
						cancelText={cancelTitle}
					/>
				</Card>
			</MainScroll>
		</PageContainer>
	);
}

function getActorTypes(type, list){
	return list.filter(function(actor){
		return actor.actor === type;
	}).map(function(actor){
		return actor.who._id;
	});
}

WorkflowEdit.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
import React from "react";
import PropTypes from "prop-types";
import ManagedHealthIndicator from "../../HealthIndicators/ManagedHealthIndicator";

export default function OxygenMetrics (props, context){
	var model = props.model;
	var manage = model.manage;
	var bloodoxygen = manage.managedbloodoxygen;

	var steps = bloodoxygen.cumulative;
	var reverse_steps = bloodoxygen.reverse; // needed for chart

	var localize = context.localize;
	var pulseratelabel = localize("bloodoxygen.pulseratelabel");
	var saturationlabel = localize("bloodoxygen.saturationlabel");
	var today = null;
	var today2 = null;
	var section = model.page;
	var pagetitle = localize("bloodoxygen.status");
	var textformat = "{oxygensaturation}%-{pulserate}";

	// Sets up the format to the 'today' button
	if (steps.length) {
		var graphdata = adjust_time(reverse_steps, "oxygensaturation").slice(-10); // for chart
		var pulsegraph = adjust_time(reverse_steps, "pulserate").slice(-10);
		today = steps[0].history.oxygensaturation + " " + saturationlabel;
		today2 = steps[0].history.pulserate;
	}

	return (
		<ManagedHealthIndicator model={model} section={section} textformat={textformat} units={saturationlabel + pulseratelabel} pagetitle={pagetitle} latestReading={today} latestSecondaryReading={today2} reverseEntries={graphdata} secondaryGraph={pulsegraph} activeModel={bloodoxygen} xaxis={"x"} yaxis={"y"} managed />
	);
}

function adjust_time(data, key, forBoxGraphic) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, forBoxGraphic);
		var value = (typeof item.history[key] === "string")
			? parseFloat(item.history[key])
			: item.history[key];
		return {
			x: time,
			y: value
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return (parsed.month + "-" + parsed.day + "  " + parsed.time);
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}

OxygenMetrics.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

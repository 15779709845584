import PropTypes from "prop-types";
import React from "react";

import UrgencyCall from "./UrgencyCall";
import UrgencyCancelled from "./UrgencyCancelled";
import UrgencyAccepted from "./UrgencyAccepted";
import CareplanUpdate from "./CareplanUpdate";
import BatteryLow from "./BatteryLow";
import BatteryChanged from "./BatteryChanged";
import BraceletDisconnect from "./BraceletDisconnect";
import BraceletConnect from "./BraceletConnect";
import ManagedNew from "./ManagedNew";
import Wandering from "./Wandering";
import IndicatorHeartRate from "./IndicatorHeartRate";
import IndicatorOxygenSaturation from "./IndicatorOxygenSaturation";
import IndicatorBloodSugar from "./IndicatorBloodSugar";
import IndicatorBloodPressure from "./IndicatorBloodPressure";
import IndicatorBodyTemperature from "./IndicatorBodyTemperature";
import IndicatorSteps from "./IndicatorSteps";
import IndicatorWeight from "./IndicatorWeight";

export default function BannerNotification(props) {
	var notification = props.notification;
	var notificationcontent = notification.content;
	var bannertype = notification.banner_type;
	var screen = props.screen;

	var Component = null;

	if (notificationcontent && bannertype) {
		if(bannertype === "info:indicator:bpPulserate")
			Component = IndicatorHeartRate;
		if(bannertype === "info:indicator:heartrate")
			Component = IndicatorHeartRate;
		if(bannertype === "info:indicator:oxygensaturation")
			Component = IndicatorOxygenSaturation;
		if(bannertype === "info:indicator:bloodsugar")
			Component = IndicatorBloodSugar;
		if(bannertype === "info:indicator:bloodpressure")
			Component = IndicatorBloodPressure;
		if(bannertype === "info:indicator:bodytemperature")
			Component = IndicatorBodyTemperature;
		if(bannertype === "info:indicator:steps")
			Component = IndicatorSteps;
		if(bannertype === "info:indicator:weight")
			Component = IndicatorWeight;
		if (bannertype === "info:careplan:change")
			Component = CareplanUpdate;
		if(bannertype === "info:manageduser:new")
			Component = ManagedNew;
		if (bannertype === "urgency:call")
			Component = UrgencyCall;
		if (bannertype === "urgency:cancelled")
			Component = UrgencyCancelled;
		if (bannertype === "urgency:accepted")
			Component = UrgencyAccepted;
		if (bannertype === "warning:device:battery")
			Component = BatteryLow;
		if (bannertype === "warning:device:batterychanged")
			Component = BatteryChanged;
		if (bannertype === "warning:device:disconnected")
			Component = BraceletDisconnect;
		if (bannertype === "warning:device:connected")
			Component = BraceletConnect;
		if (bannertype === "warning:device:wandering")
			Component = Wandering;

		if(Component)
			return (<Component notification={notification} screen={screen} />);
		else
			return null;
	}
	return null;
}

BannerNotification.contextTypes = {
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React, { useContext, Fragment } from "react";
import {
	View,
	StyleSheet,
	TouchableOpacity
} from "react-native";
import Breakpoints from "../../../Breakpoints";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import StyledText from "ae-base/Base/StyledText";
import BackButton from "ae-base/Base/BackButton";
import VerticalButton from "ae-base/Base/VerticalButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";
import HeaderImage from "../../Effects/HeaderImage";
import ImageUrl from "ae-base/Base/ImageUrl";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import Style from "ae-style";
import getVersion, { NewCallingVersion } from "../../../utils/GetVersion";
import MeetingContext from "../../../Calling/context/MeetingContext";
import useEnabledFeatures from "../../../hooks/useEnabledFeatures/useEnabledFeatures";
import { createStyled } from '@emotion/primitives-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faVideo, faVideoSlash, faTextHeight, faCommentAlt, faCommentSlash, faComment, faPhoneSlash, faPhone } from '@fortawesome/free-solid-svg-icons';

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		mainContent: {
			backgroundColor: Style.colors.primary,
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			padding: Style.layout.paddingSmall
		},
		text: {
			marginLeft: Style.layout.paddingSmall,
			flexDirection: "row"
		},
		flexTwo: {
			flex: 2
		},
		iconStyles: {
			padding: Style.layout.paddingSmall
		},
		container: {
			paddingLeft: Style.layout.paddingMedium,
			paddingRight: Style.layout.paddingMedium
		},
		controls: {
			justifyContent: "flex-end",
			flexDirection: "row",
			flex: 1
		}
	},
	512: {
		mainContent: {
			backgroundColor: Style.colors.clear,
			flexDirection: "row",
		},
		text: {
			paddingLeft: 0,
			paddingRight: 0,
			flex: 1
		},
	}
});

const styled = createStyled(StyleSheet);

const CallButton = styled(TouchableOpacity)({
	paddingLeft: 10
});
export default function MessagingHeader(props, context) {
	var dispatch = context.dispatch;
	var messages = props.messages;
	var config = context.config;
	var from = messages.from || {};
	var disabled = props.disabled;
	var isMobile = Breakpoints.smallerThan("mobile");
	var contactSettings = props.contactSettings;
	var userId = from._id;
	var localize = context.localize;
	var exitmessaging = localize("messaging.exitmessaging");
	var exitMsgTitle = exitmessaging;
	var video = localize("messaging.video");
	var voice = localize("messaging.voice");
	var headingSize = Breakpoints.smallerThan("mobile") ? "emphasized" : "largeHeading";

	var name = from.lname + ", " + from.fname;
	var callContact = dispatch("calling:trigger:call", {
		reason: "screen",
		who: from._id
	});

	var voiceCallContact = context.dispatch("calling:trigger:call", {
		who: from._id,
		reason: "screen",
		voiceOnly: true,
	});

	var disableVoice = contactSettings.voice || disabled;
	var disableVideo = contactSettings.video || disabled;

	const version = getVersion(from.versionInfo || {});
	const meetingContext = useContext(MeetingContext);
	const { isCallingEnabled } = useEnabledFeatures();
	let useOldCalling = true;

	const isNewCallingSupported = version && version.majorVersion >= NewCallingVersion;
	if (isNewCallingSupported && isCallingEnabled) {
		callContact = () => meetingContext.onCreateMeeting(from._id, `${config.fname} ${config.lname}`);
		useOldCalling = false;
		video = localize("workflow.call");
	}

	const onPressBack = () => {
		dispatch("desktop:trigger:page:back")();
		dispatch("persiststorage:delete")({
			dataId: userId
		})
	}
	
	if (isMobile) return (
		<View style={styles.mainContent}>
			<StyledIconButton id={"backButton"} clear contrast icon="left" smallHeading style={styles.iconStyles} onPress={onPressBack} />
			<View style={styles.flexTwo}>
				<StyledText size={headingSize} inline bold contrast>{name}</StyledText>
			</View>
			<View style={styles.controls}>
				<CallButton onPress={disableVideo ? null : callContact}>
					<FontAwesomeIcon icon={disableVideo ? faVideoSlash : faVideo} style={{ paddingRight: 55 }} color="#FFFFFF" size={30}></FontAwesomeIcon>
					<StyledText contrast centered size={20} >{localize("messaging.call")}</StyledText>
				</CallButton>
				{useOldCalling && <Fragment>
					<ButtonSpacer />
					<CallButton onPress={disableVoice ? null : voiceCallContact}>
						<FontAwesomeIcon icon={disableVoice ? faPhoneSlash : faPhone} style={{ paddingRight: 55 }} color="#FFFFFF" size={30}></FontAwesomeIcon>
						<StyledText contrast centered size={20} >{localize("messaging.voice")}</StyledText>
					</CallButton>
				</Fragment>}
			</View>
		</View>
	);

	else return (
		<HeaderImage source={ImageUrl(from.image_medium, config)}>
			<View style={styles.container}>
				<BackButton id={context.id_prefix + "backButton"} onPress={onPressBack} from={from} title={exitMsgTitle} />
				<View style={styles.mainContent}>
					<View style={styles.text}>
						<StyledText size={headingSize} bold contrast>{name}</StyledText>
					</View>
					<CallButton onPress={disableVideo ? null : callContact}>
						<FontAwesomeIcon icon={disableVideo ? faVideoSlash : faVideo} style={{ paddingRight: 5 }} color="#FFFFFF" size={32}></FontAwesomeIcon>
						<StyledText contrast centered size={25} >{localize("messaging.call")}</StyledText>
					</CallButton>
					{useOldCalling && <Fragment>
						<ButtonSpacer />
						<CallButton onPress={disableVoice ? null : voiceCallContact}>
							<FontAwesomeIcon icon={disableVoice ? faPhoneSlash : faPhone} style={{ paddingRight: 5 }} color="#FFFFFF" size={32}></FontAwesomeIcon>
							<StyledText contrast centered size={25} >{localize("messaging.voice")}</StyledText>
						</CallButton>
					</Fragment>}
				</View>
			</View>
		</HeaderImage>
	);
}

MessagingHeader.contextTypes = {
	id_prefix: PropTypes.string,
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import Modal from "../Layout/Modal";
import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import Form from "ae-forms";
import TimeoutLoginSchema from "../../schemas/TimeoutLogin";
import Toast from "ae-base/Base/Toast";

var styles = StyleSheet.create({
	title: {
		marginHorizontal: Style.layout.marginMedium
	},
	content: {
		marginVertical: Style.layout.marginLarge,
		alignItems: "center"
	},
	submitAlign: {
		justifyContent: "flex-start"
	},
	modal: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		backgroundColor: Style.colors.overlay,
	},
	end: {
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0
	},
	flex: {
		flex: 1
	}
});

export default class TimeoutModal extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var localize = context.localize;
		var dispatch = context.dispatch;

		var model = props.model;
		var toast = model.toast;
		var user = model.preferences;
		var defaultData = {
			email: user.email
		};

		var title = localize("passwordtimeout.enterpassword", {
			lname: user.lname,
			fname: user.fname
		});

		var confirmText = localize("passwordtimeout.login");
		var cancelText = localize("passwordtimeout.logout");

		var onCancel = dispatch("logout:trigger:logout");

		return (
			<Modal open>
				<View style={styles.modal}>
					<StyledText style={styles.title} smallHeading contrast>{title}</StyledText>
					<View style={styles.content}>
						<Form schema={TimeoutLoginSchema}
							submitText={confirmText}
							onSubmit={dispatch("passwordtimeout:trigger:login")}
							defaultData={defaultData}
							submitStyle={styles.submitAlign}
							onCancel={onCancel}
							cancelText={cancelText} />
					</View>
				</View>
				<View style={styles.end}>
					<Toast toast={toast} id={"timeout_toast"} />
				</View>
			</Modal>
		);
	}
}

TimeoutModal.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func,
};

import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function IndicatorBloodSugar(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var locale = "banner.indicator.bloodsugar." + notification.content.status;
	var message = localize(locale, notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={message} iconName="alert" clearable notification={notification} screen={screen} />
	);

}

IndicatorBloodSugar.contextTypes = {
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React from "react";

import CardPage from "../../Layout/CardPage";
import Form from "ae-forms";
import StyledButton from "ae-base/Base/StyledButton.js";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import StyledText from "ae-base/Base/StyledText";

import GroupSchema from "../../../schemas/GroupChat";

var DEFAULT_DATA = {
	name: "",
	description: ""
};

export default function GroupAdd(props, context){
	var model = props.model;
	var screen = model.size;
	var dispatch = context.dispatch;
	var localize = context.localize;
	var groupchat = model.groupchat;
	var group = groupchat.group;
	var saving = model.saving;
	var removing = groupchat.removing;
	var toast = model.toast;

	var title = localize("groupchat.creategroup");
	var discardchanges = localize("managedformscreation.discardchanges");
	var deleteTitle = localize("groupchat.deleteGroup");
	var confirmtext = localize("groupchat.confirmRemove");
	var canceltext = localize("groupchat.cancelRemove");
	var areyousure = localize("groupchat.areyousure");
	var defaultData = DEFAULT_DATA;

	var changeGroup = dispatch("groupchat:trigger:create");
	var deleteGroup = dispatch("groupchat:trigger:remove:group:start");
	var confirmDelete = dispatch("groupchat:trigger:remove:group:accept");
	var cancelDelete = dispatch("groupchat:trigger:remove:group:cancel");

	var renderDeleteButton = null;

	if(group) {
		defaultData = group;
		title = localize("groupchat.editGroup");
		changeGroup = dispatch("groupchat:trigger:edit");
		renderDeleteButton = (
			<StyledButton id={"groupChat_delete"} alert title={deleteTitle} icon="delete" onPress={deleteGroup} />
		);
	}

	var confirmation = (
		<ConfirmationModal
			id={"groupChat_delete"}
			open={removing}
			title={deleteTitle}
			titleIcon="warning"
			confirmText={confirmtext}
			cancelText={canceltext}
			onConfirm={confirmDelete}
			onCancel={cancelDelete}
			toast={toast}
		>
			<StyledText contrast>{areyousure}</StyledText>
		</ConfirmationModal>
	);

	var cancelEdit = dispatch("groupchat:trigger:edit:cancel");

	return (
		<CardPage title={title} icon="aeto-team" edit={renderDeleteButton} external={confirmation} toast={toast} saving={saving} screen={screen} online={model.online}>
			<Form id_prefix="createGroupForm_" schema={GroupSchema} defaultData={defaultData} onSubmit={changeGroup} onCancel={cancelEdit} cancelText={discardchanges} />
		</CardPage>
	);
}

GroupAdd.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

import React, { createRef } from "react";
import PropTypes from "prop-types";
import { View, StyleSheet, Dimensions } from "react-native";
import Jimp from "jimp/browser/lib/jimp";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";

import Style from "ae-style";
import ImageCropView from "./ImageCropView";
import getFileAsBase64 from "./getFileAsBase64";
import createUploadObj from "./createUploadObj";
import deferExpensiveCompute from "./deferExpensivecompute";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "black",
		width: "100%",
		paddingHorizontal: 10,
		alignItems: "center",
		justifyContent: "center"
	},
	image: {},
	controls: {
		flexDirection: "row",
		justifyContent: "center"
	},
	info: {
		alignItems: "center",
		justifyContent: "center",
		marginBottom: Style.layout.marginSmall
	},
	controlContainer: {
		padding: Style.layout.marginSmall,
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: Style.colors.toastBackground
	}
});

const MAX_VIEW_SIZE = 400;
const SCREEN_WIDTH = Dimensions.get("window").width * 0.9;
const VIEW_SIZE = SCREEN_WIDTH > MAX_VIEW_SIZE ? MAX_VIEW_SIZE : SCREEN_WIDTH;

class ImageCrop extends React.Component {
	viewerRef = createRef()
	constructor(props) {
		super(props);
	}
	onCrop = () => {
		let { x, y, scale } = this.viewerRef.current.getCurrentDim();

		deferExpensiveCompute(() => {
			getFileAsBase64(this.props.source).then(async (imgBase64) => {

				let jimpSource = Buffer.from(imgBase64, "base64");
				await Jimp.read(jimpSource)
					.then(async (img) => {

						//normalize image to views scale
						if (img.getHeight() < img.getWidth()) {
							img.scale(VIEW_SIZE / img.getHeight());
						} else {
							img.scale(VIEW_SIZE / img.getWidth());
						}
						// adjust zoom scale
						img.scale(scale);
						// crop to size
						img.crop(x, y, VIEW_SIZE, VIEW_SIZE);
						let resultB64 = await img.getBase64Async(Jimp.MIME_PNG);
						let uploadObj = await createUploadObj(resultB64, "avatar");
						this.props.onConfirm({
							crop: 0,
							image: uploadObj
						});
					})
					.catch((err) => {
						console.log("jimp err:", JSON.stringify(err));
						console.error(err);
					});
			}).catch((err) => {
				console.error("readfileasb64 err:", err);
			}).then(()=>{
				this.context.dispatch("save:trigger:finish")();
			});
		});

	}
	render() {
		var localize = this.context.localize;
		var drag = localize("imagecrop.drag");
		var confirm = localize("imagecrop.confirm");
		var cancel = localize("imagecrop.cancel");
		const { source, width, height, onCancel } = this.props;
		return (
			<View style={styles.container}>
				<View style={{ borderRadius: VIEW_SIZE / 2, borderColor: "white", borderWidth: 4, overflow: "hidden" }}>
					<ImageCropView ref={this.viewerRef} source={source} width={width} height={height} size={VIEW_SIZE} />
				</View>
				<View style={styles.controlContainer}>
					<View style={styles.info}>
						<StyledText contrast>{drag}</StyledText>
					</View>
					<View style={styles.controls}>
						<StyledButton fullwidth title={confirm} icon="check" contrast onPress={this.onCrop} />
						<ButtonSpacer />
						<StyledButton fullwidth title={cancel} icon="delete" alert onPress={onCancel} />
					</View>
				</View>
			</View>
		);
	}
}

ImageCrop.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};
export default ImageCrop;
import React from "react";
import {
	View
} from "react-native";

import Style from "ae-style";
import SquareButton from "ae-base/Base/SquareButton";
import StyledIcon from "ae-base/Base/StyledIcon";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import format from "string-template";
import PropTypes from "prop-types";
import StyledText from "ae-base/Base/StyledText";
import { parseStatus } from "../../../utils/ParseStatus";
import { LONG_LANGS } from "../../../utils/LongLangs";

var styles = ResponsiveStylesheet.createSized("min-width", {
	0: {
		square: {
			borderStyle: "solid",
			borderRadius: 10,
			borderWidth: Style.layout.marginSlim,
			backgroundColor: "white",
			alignSelf: "center",
			height: 145,
			width: Style.text.hugeIcon,
			paddingVertical: 4
		},
		precedingdaysContainer: {
			flexDirection: "row",
			marginBottom: Style.layout.marginMedium,
			flexWrap: "wrap",
			flex: 1,
			justifyContent: "center",
		},
		red: {
			borderColor: Style.colors.alert
		},
		green: {
			borderColor: Style.colors.primary,
		},
		info: {
			position: "absolute",
			top: 0,
			right: 0,
			paddingHorizontal: Style.layout.marginSlim
		},
		greenStatus: {
			borderColor: Style.colors.green
		},
		yellow: {
			borderColor: Style.colors.yellow
		},
		padded: {
			alignSelf: "center",
		}
	}
});

export default function PreceedingMeasure (props, context){
	var short = props.short;
	var section = props.section;
	var textFormat = props.textformat;
	var dispatch = context.dispatch;
	var localize = context.localize;
	const language = props.language;

	var fiveday = (short.length) ? (
		generate_buttons(short, section, textFormat, dispatch, localize, language)
	) : null;

	return (
		<View style={styles.precedingdaysContainer}>
			{fiveday}
		</View>
	);
}

function generate_buttons(short, section, textFormat, dispatch, localize, language) {
	if (!short) return;
	return short.map(function (item) {
		var id = item._id;
		var date = section === "activity" ? new Date(item.latest_time) : new Date(item.updated_at);
		var time = time_parse(date, true);
		var created = created_date_label(time.time, time.month, time.day);
		var itemx = item.cumulative  !== undefined && item.cumulative !== null ? item : item.history;
		var cumulative = format(textFormat, itemx);

		if(!cumulative && section === "bloodsugar") {
			textFormat = textFormat === "{mmol}" ? "{glucose}" : "{meanblood}";
			cumulative = format(textFormat, itemx);
		}

		var checkComment = section === "activity" ? item.comment : item.history.comment;
		if(section === "bloodoxygen") checkComment = item.history.comment || item.history.exertion || item.history.oxygen;
		var history = section === "activity" ? item : item.history;
		var showComment = dispatch("comment:trigger:show", {
			data: history,
			type: section
		});

		var style_squares = [styles.square];
		if (item.improved === true && section === "activity"){
			style_squares.push(styles.green);
		}
		else if (item.improved === false  && section === "activity") {
			style_squares.push(styles.red);
		}
		else {
			style_squares.push(styles.green);
		}

		var status = section === "activity" ? item.status : item.history.status;
		if(section === "bloodoxygen"){
			var heartRateStatus = item.history.heartRateStatus;
			var oxygenSaturationStatus = item.history.oxygenSaturationStatus;
			status = heartRateStatus;
			if(oxygenSaturationStatus > heartRateStatus)
				status = oxygenSaturationStatus;
		}
		if(section === "bloodpressure"){
			var bpPulserateStatus = item.history.bpPulserateStatus ?? -1;
			var bpStatus = item.history.status;
			status = Math.max(bpPulserateStatus, bpStatus);
		}

		if(status === 0)
			style_squares.push(styles.greenStatus);
		if(status === 1)
			style_squares.push(styles.yellow);
		if(status === 2)
			style_squares.push(styles.red);

		var onpress = checkComment ? showComment : null;
		var info = checkComment ? (
			<StyledIcon style={styles.info} name="info" size={Style.text.small} />
		) : null;

		var button = (
			<SquareButton title={created.time}
				secondaryTitle={created.date}
				text={cumulative + ""}
				iconSize={Style.text.emphasized}
				style={style_squares}
				key={id}
				onPress={onpress}
				displayCentered
			>
				<View style={{height: 16}}>
					{info}
					<StyledText style={styles.padded} size={LONG_LANGS.includes(language) ? Style.text.tiny : Style.text.small}>{parseStatus(status, localize)}</StyledText>
				</View>
			</SquareButton>
		);
		return (
			<View key={id + "button"}>
				{button}
			</View>
		);
	});
}

function created_date_label(time, month, day) {
	return {
		time: time,
		date: month + "-" + day
	};
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}

PreceedingMeasure.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

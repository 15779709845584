import PropTypes from "prop-types";
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import NavBanner from "ae-base/Base/NavBanner";

export default function CallingBackButton(props, context){
	var dispatch = context.dispatch;

	var localize = context.localize;

	var minimizecall = localize("callingbackbutton.minimizecall");

	var goBack = dispatch("desktop:trigger:page:back");

	return (
		<NavBanner id={"NavBanner"} title={minimizecall} icon="dropdown" onPress={goBack} />
	);
}

CallingBackButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

import PropTypes from "prop-types";
import React from "react";

import Form from "ae-forms";
import FullInfoSchema from "../../../schemas/FullInfo";
import ManagedSection from "./ManagedSection";
import ProfilePictureChoose from "./ProfilePicture/ProfilePictureChoose";

import Divider from "../../Layout/Divider";
export default function Managedprofile(props, context) {
	var model = props.model;
	var toast = model.toast;
	var dispatch = context.dispatch;
	var preferences = model.manage.preferences.data;
	var contact = model.manage.account;

	var screen = model.size;

	var localize = context.localize;
	var personalinformation = localize("managedprofile.personalinformation");

	var loadedKey = Object.keys(preferences || {}).length;

	return (
		<ManagedSection key={loadedKey}
			contact={contact}
			title={personalinformation}
			saving={model.saving}
			online={model.online}
			toast={toast}>
			<Form schema={FullInfoSchema} defaultData={preferences} onSubmit={dispatch("managedpreferences:trigger:update")} />
			<Divider style={{marginTop: 10}} />
			<ProfilePictureChoose account={contact} screen={screen} />
		</ManagedSection>
	);

}

Managedprofile.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

Managedprofile.propTypes = {
	model: PropTypes.object,
};

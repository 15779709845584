import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet
} from "react-native";

import Notification from "../../Layout/Notification";
import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";

var styles = StyleSheet.create({
	text_style: {
		color: Style.colors.white,
		paddingRight: Style.layout.paddingSmall,
		justifyContent: "center",
	},
	button_container: {
		marginLeft: Style.layout.marginSmall,
		justifyContent: "center"
	},
	text_container: {
		flex: 1
	}
});

export default class TestModeBanner extends PureComponent {
	render(){
		const context = this.context;

		const localize = context.localize;

		const text = localize("testmode.title");
		return (
			<Notification
				color={Style.colors.orange}
				iconColor={"white"}
				icon="fa-bug"
			>
				<View>
					<StyledText bold style={styles.text_style} emphasized>
						{text}
					</StyledText>
				</View>
			</Notification>
		);
	}
}

TestModeBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

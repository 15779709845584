import React from "react";
import PropTypes from "prop-types";
import memoize from "memoizee";

import ManagedSection from "../ManagedSection";
import DeviceAdd from "../../../Devices/DeviceAdd";

var ENUM_VALUES = {
	"weight": [
		"a&dweight",
		"ihweight"
	],
	"activity": [
		"metawear"
	],
	"bloodsugar": [
		"foratng"
	],
	"bodytemperature": [
		"forair20"
	],
	"bloodpressure": [
		"a&dbloodpressure",
		"ihbloodpressure",
	],
	"spo2": [
		"nonin",
		"foraspo2",
		"ihspo2"
	]
};

var TYPES = [
	"weight",
	"activity",
	"bloodsugar",
	"bodytemperature",
	"bloodpressure",
	"spo2"
];

var getEnum = memoize(getDeviceEnum);

export default function DevicesAdd(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;

	var model = props.model;
	var manage = model.manage;
	var account = manage.account;
	var toast = model.toast;

	var devices = manage.devices || {};
	var deviceList = devices.list || [];

	var devicesTitle = localize("devices.addtitle");
	var onSubmit = dispatch("manageddevices:trigger:add:submit");

	var backTitle = localize("devices.backTitle");
	return (
		<ManagedSection online={model.online} title={devicesTitle} contact={account} toast={toast} back={backTitle} >
			<DeviceAdd enumValue={getEnum(deviceList)} onSubmit={onSubmit} managed />
		</ManagedSection>
	);
}

function getDeviceEnum(devices){
	var defaultEnum = Object.assign({}, ENUM_VALUES);

	devices.forEach((device) => {
		var device_type = device.device_type || "";
		if(TYPES.indexOf(device_type) !== -1)
			delete defaultEnum[device_type];
	});

	return flattenMap(defaultEnum);
}

function flattenMap(obj){
	return Object.keys(obj).reduce((acc, e) => {
		return acc.concat(obj[e]);
	}, []);
}

DevicesAdd.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import CallPage from "./CallPage";
import CallButton from "./CallButton";

var styles = StyleSheet.create({
	onecallbutton: {
		flex: 1.5,
		justifyContent: "flex-end"
	},
});

export default function Connecting(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;
	var who = props.who;
	var hangUpText = localize("atouchawaycalling.endcall");
	var cancelCall = (
		dispatch("calling:trigger:hangup", {
			reason: "hangup"
		})
	);

	return (
		<CallPage who={who} state={"connecting"}>
			<View style={styles.onecallbutton}>
				<CallButton
					hangup
					icon={"aeto-hang_up-2"}
					buttonAction={cancelCall}
					buttonText={hangUpText}
				/>
			</View>
		</CallPage>
	);
}

Connecting.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {
	View,
	ScrollView
} from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";

import DialogContainer from "ae-base/Base/DialogContainer";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import LoginSection from "./Login/LoginSection";
import LoginLogo from "./Login/LoginLogo";

import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Config from "../../configs/config.json";

import localization from "../../Localization";
import localizer from "ae-localizer";

import PageContainer from "../Layout/PageContainer";
import MainScroll from "../Layout/MainScroll";
import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		dialogItem: {
			backgroundColor: Style.colors.white,
			padding: Style.layout.paddingButton - 4,
			borderWidth: 1,
			borderColor: "rgba(0,0,0,0.1)",
			width: 250,
			justifyContent: "center",
			zIndex: 2
		},
		closeButton: {
			flexDirection: "row",
			justifyContent: "center",
			paddingTop: Style.layout.paddingSmall
		},
		languageBar: {
			marginTop: Style.layout.marginSmall,
			flexWrap: "wrap",
			flexDirection: "row",
			justifyContent: "space-around"
		},
		page: {
			backgroundColor: Style.colors.primary,
			alignItems: "center",
			justifyContent: "center",
			flex: 1
		},
		container: {
			backgroundColor: Style.colors.white,
			alignItems: "center",
			justifyContent: "space-between",
			padding: Style.layout.paddingButton
		},
		buttonContainer: {
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "center",
			alignItems: "center",
			padding: Style.layout.paddingSmall
		},
		button: {
			marginRight: Style.layout.marginSmall,
			marginVertical: Style.layout.marginSmall
		}
	},
	512: {
		languageBar: {
			marginHorizontal: Style.layout.marginSmall,
		},
		dialogItem: {
			padding: Style.layout.paddingLarge,
			width: 350
		}
	}
});

var LANGUAGES = [
	"en",
	"fr",
	"cn_s",
	"cn_t",
	"es",
	"pt",
	"ta"
];


export default class RegionSelect extends PureComponent {
	constructor(props){
		super(props);
		this.state = {
			language: "en",
			selected: "Canada",
			showThankYou: false
		};

		this.buttonPress = buttonPress.bind(this);
		this.localize = localize.bind(this);
		this.setLanguage = setLanguage.bind(this);
		this.submitOkay = submitOkay.bind(this);
	}

	getChildContext(){
		return {
			localize: this.localize,
			dispatch: noop
		};
	}

	render(){
		var hosts = Config.hosts || [];
		var language = this.state.language;
		var localizedHosts = localization[language].hosts || {};

		var buttons = hosts.map(function(host){
			
			var localizedName = localizedHosts[host.name] || host.name;

			var onPress = function(){
				this.buttonPress(host.name);
			}.bind(this);
			return (
				<StyledButton style={styles.button} title={localizedName} onPress={onPress} />
			);
		}.bind(this));

		var selectString = this.localize("regionselect.selectregion");

		return (
			<PageContainer dark>
				<MainScroll>
					<LanguageBar language={this.state.language} changeLang={this.setLanguage} localize={this.localize} />
					<LoginSection header>
						<LoginLogo />
					</LoginSection>
					<LoginSection contrast>
						<StyledText>{selectString}</StyledText>
						<View style={styles.buttonContainer}>
							{buttons}
						</View>
					</LoginSection>
				</MainScroll>
				<ThankYouBox localize={this.localize} open={this.state.showThankYou} selected={this.state.selected} submit={this.submitOkay} />
			</PageContainer>
		);
	}
}

RegionSelect.childContextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

function LanguageBar(props){
	var changeLang = props.changeLang;
	var localizeString = props.localize;
	var activeLanguage = props.language;

	var languageButtons = LANGUAGES.map(function (language) {
		var contrast = "contrast";
		var title = localizeString("languages." + language);
		var set = changeLang(language);

		var styleList = {};
		if (language === activeLanguage) {
			styleList = {
				borderWidth: 1,
				borderColor: Style.colors.white,
				paddingVertical: 3,
				paddingHorizontal: 3
			};
		} else {
			styleList = {
				paddingVertical: 3
			};
		}

		return (
			<StyledButton id={"languageBar_" + language} key={language} style={styleList} clear contrast={contrast} title={title}  onPress={set} />
		);
	});

	return (
		<View style={styles.languageBar}>
			{languageButtons}
		</View>
	);
}

function DialogItem(props){
	var children = props.children;
	return (
		<View style={styles.dialogItem}>
			{children}
		</View>
	);
}

function ThankYouBox(props){
	var localizeString = props.localize;
	var open = props.open;
	var selected = props.selected;
	var submit = props.submit;

	if(!open) return null;

	var text = localizeString("regionselect.welcome" + selected);

	return (
		<DialogContainer>
			<DialogItem>
				<ScrollView>
					<View>
						<StyledText>{text}</StyledText>
						<View style={styles.closeButton}>
							<StyledButton title={localizeString("regionselect.ok")} onPress={submit} />
						</View>
					</View>
				</ScrollView>
			</DialogItem>
		</DialogContainer>
	);
}

function setLanguage(language){
	return function(){
		this.setState({
			language: language
		});
	}.bind(this);
}

function buttonPress(country){
	this.setState({
		selected: country,
		showThankYou: true
	});
}

function submitOkay(){
	var country = this.state.selected;
	var createRegion = this.props.createRegion;

	AsyncStorage.setItem("region", country).then(function(){
		createRegion(country);
	});
}

function localize(key, args){
	var language = this.state.language;
	var map = localization[language] || "en";
	return localizer(map, key, args);
}

function noop(){}
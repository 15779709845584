import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet
} from "react-native";

import BlockView from "ae-base/Base/BlockView";
import ListAdd from "ae-base/List/ListAdd";
import Style from "ae-style";
import Contact from "../../Contacts/Contact";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

var styles = StyleSheet.create({
	addBox: {
		marginLeft: Style.layout.marginSmall
	}
});

export default function BraceletPressContact(props, context) {
	var contactData = props.contactData;
	var screen = props.screen;

	if (props.isContact !== "contact"){
		return null;
	}

	var localize = context.localize;

	var addPressContact = localize("braceletpresscontact.addcontact");
	var changeContact = localize("braceletpresscontact.changecontact");
	var changeContactTitle = localize("braceletpresscontact.changecontacttitle");

	var openRequest = context.dispatch("desktop:trigger:page", {
		page: "managed_bracelet_contact_potentials"
	});

	var renderContactList = contactData._id ?  (
		<View>
			<StyledText>
				{changeContactTitle}
			</StyledText>
			<Contact contact={contactData} onPress={openRequest} screen={screen}>
				<StyledButton icon={"sync"} title={changeContact} clear onPress={openRequest} />
			</Contact>
		</View>
	) : (
		<ListAdd title={addPressContact} onPress={openRequest} spaced />
	);

	return (
		<BlockView style={styles.addBox}>
			{renderContactList}
		</BlockView>
	);
}

BraceletPressContact.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

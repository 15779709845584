import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";

import BasicState from "./BasicState";

export default class Busy extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;

		var localize = context.localize;

		var toast = props.toast;
		var calling = props.calling;
		var who = calling.who;
		var message = localize("outgoing.messageBusy", who);

		return (
			<BasicState message={message} toast={toast} />
		);
	}
}

Busy.contextTypes = {
	localize: PropTypes.func
};

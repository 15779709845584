import React from "react";
import PropTypes from "prop-types";
import {
	View,
	StyleSheet
} from "react-native";

import ListItem from "ae-base/List/ListItem";
import ListContent from "ae-base/List/ListContent";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import StyledIcon from "ae-base/Base/StyledIcon";

import Style from "ae-style";

var styles = StyleSheet.create({
	button: {
		marginLeft: Style.layout.marginSlim
	},
	info: {
		flexDirection: "row"
	},
	text: {
		marginHorizontal: Style.layout.marginSlim
	}
});

export default function DeviceItem(props, context){
	var localize = context.localize;
	var edit = localize("devices.edit");

	var editDevice = props.onClick;
	var device = props.device;
	var name = device.model;
	var mac = device.mac;
	var battery = device.battery_level;
	var batteryTitle = battery ? localize("devices.battery", {
		battery: battery
	}) : "";

	var icon = "highbattery";
	var color = '#008A28';

	if(battery <= 35){
		icon = "lowbattery";
		color = Style.colors.red;
	} else if(battery <= 60){
		icon = "mediumbattery";
		color = Style.colors.yellow;
	}

	var renderIcon = battery ? (
		<StyledIcon name={icon} color={color} />
	) : null;

	var displayName = localize("devices.models." + name) || name;

	var renderEdit = editDevice ? (
		<StyledButton style={styles.button} title={edit} onPress={editDevice} />
	) : null;

	return (
		<ListItem>
			<ListContent>
				<View>
					<StyledText bold>{displayName}</StyledText>
					<StyledText small>{mac}</StyledText>
				</View>
			</ListContent>
			<View style={styles.info} >
				{renderIcon}
				<View style={styles.text}>
					<StyledText>{batteryTitle}</StyledText>
				</View>
			</View>
			{renderEdit}
		</ListItem>
	);
}

DeviceItem.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	Component
} from "react";
import {
	StyleSheet,
	ScrollView,
	TouchableWithoutFeedback,
	View,
	Keyboard,
	Platform
} from "react-native";

// Based on http://stackoverflow.com/a/39563100

var styles = StyleSheet.create({
	main: {
		flex: 1
	},
});

export default class MessageScroll extends Component {
	constructor(props){
		super(props);

		this._scrollToBottom = this.scrollToBottom.bind(this);
		this._setScrollView = setScrollView.bind(this);
	}
	componentDidMount() {
		var context = this.context;
		var listen = context.listen;

		if(Keyboard)
			this.keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", this._scrollToBottom);

		listen("messages:scroll", this._scrollToBottom);
		listen("groupchat:scroll", this._scrollToBottom);
		listen("atouchawaymessaging:scroll", this._scrollToBottom);
		listen("livechatmessages:scroll", this._scrollToBottom);
	}
	componentWillUnmount() {
		var context = this.context;
		var unlisten = context.unlisten;

		if(Keyboard)
			this.keyboardDidShowListener.remove();

		unlisten("messages:scroll", this._scrollToBottom);
		unlisten("groupchat:scroll", this._scrollToBottom);
		unlisten("atouchawaymessaging:scroll", this._scrollToBottom);
		unlisten("livechatmessages:scroll", this._scrollToBottom);
	}
	scrollToBottom(){
		this.scrollView.scrollToEnd({
			animated: false
		});
	}
	render(){
		var scrollViewProps = {
			keyboardDismissMode: "none",
			ref: this._setScrollView,
			style: styles.main
		};

		if (Platform.OS === "web") {
			scrollViewProps.onScroll = this.context.timeoutReset;
		}

		return(
			<ScrollView {...scrollViewProps}>
				<TouchableWithoutFeedback>
					<View>
						{this.props.children}
					</View>
				</TouchableWithoutFeedback>
			</ScrollView>
		);
	}
}

MessageScroll.contextTypes = {
	listen: PropTypes.func,
	unlisten: PropTypes.func,
	timeoutReset: PropTypes.func
};

function setScrollView(ref) {
	this.scrollView = ref;
}

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	StyleSheet,
	ScrollView,
	TouchableWithoutFeedback,
	TouchableOpacity,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Style from "@aetonix/styles";
import { WEEKDAY_DATE_TIME_FORMAT } from "../../../utils/date-utils";

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#131618",
		marginRight: Style.layout.marginMedium,
		padding: Style.layout.paddingMedium,
		alignItems: "center"
	},
	actionContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignSelf: "stretch",
		padding: Style.layout.paddingButton,
		backgroundColor: "#2F2F2F",
		borderRadius: 20,
		marginVertical: Style.layout.marginSlim,
		flexWrap: "wrap",
	},
	placeholderText: {
		paddingTop: Style.layout.paddingMedium
	},
	scrollView: {
		flex: 0,
		flexGrow: 0,
		flexShrink: 1,
		alignSelf: "stretch"
	},
	header: {
		paddingBottom: Style.layout.paddingButton
	},
	dateActionText: {
		minWidth: 120
	}
});

export default function ActionList(props, context){
	const localize = context.localize;
	const actions = props.actions;

	const titleText = localize("workflow.pendingactionstitle");
	const noPendingText = localize("workflow.nopending");
	const tapText = localize("workflow.tapaction");

	const renderActions = actions.length === 0 ? (
		<StyledText style={styles.placeholderText} contrast smallHeading>{noPendingText}</StyledText>
	) : actions.map(function(action){
		return (<Action action={action} />);
	});

	return (
		<View style={styles.container}>
			<ScrollView style={styles.scrollView}>
				<StyledText largeHeading tertiary>{titleText}</StyledText>
				<StyledText style={styles.header} contrast emphasized>{tapText}</StyledText>
				<TouchableWithoutFeedback>
					<View>
						{renderActions}
					</View>
				</TouchableWithoutFeedback>
			</ScrollView>
		</View>
	);
}

function Action(props, context){
	const localizeFrom = context.localizeFrom;
	const formatDate = context.formatDate;
	const dispatch = context.dispatch;
	const action = props.action;
	const actionLabel = action.action;
	const workflow = action.ongoingWorkflow;
	const title = workflow.label;

	const date = formatDate(workflow.updated_at, WEEKDAY_DATE_TIME_FORMAT);

	const workflowSchema = workflow.workflow;
	const workflowName = localizeFrom(workflowSchema.description || {}, ".");

	const actionSchema = workflowSchema.actions[actionLabel] || {};
	const actionName = localizeFrom(actionSchema.localization || {}, ".");

	const bannerProperties = actionSchema.banner || {};
	const bannerDescription = bannerProperties.description;

	let displayText = workflowName;
	if(title) displayText = title + " - " + workflowName;
	let secondaryText = actionName;

	if(bannerDescription)
		secondaryText = localizeFrom(bannerDescription || {}, ".");

	const onPress = dispatch("activeworkflowaction:trigger:view", {
		action: action._id
	});

	return (
		<TouchableOpacity style={styles.actionContainer} onPress={onPress}>
			<View>
				<StyledText tertiary emphasized>{displayText}</StyledText>
				<StyledText tertiary>{secondaryText}</StyledText>
			</View>
			<StyledText style={styles.dateActionText} tertiary grey>{date}</StyledText>
		</TouchableOpacity>
	);
}

ActionList.contextTypes = {
	localize: PropTypes.func
};

Action.contextTypes = {
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	formatDate: PropTypes.func,
	dispatch: PropTypes.func
};

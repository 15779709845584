import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import prop from "prop";

import Item from "../Item";
import Group from "../Group";
import Section from "../Section";
import Title from "../Title";

var getEffect = prop("MedicationChangeSubjectiveEffect.Name");
var getMedicationAids = prop("MedicationAids.Name");
var getDrugName = prop("Drug.Name");
var getPharmacyName = prop("Pharmacy.Name");
var getNotes = prop("Notes");
var getMedicationReconciliationDate = prop("MedicationReconciliationDate");
var getMedicationReconciliationPerformedBy = prop("MedicationReconciliationPerformedBy");
var getMedicationChangeNote = prop("MedicationChangeNote");
var getMedicationChallengesNote = prop("MedicationChallengesNote");

var getSubstance = prop("Substance");
var getAllergy = prop("AllergyCategory.Name");
var getSeverity = prop("Severity.Name");
var getSymptoms = prop("Symptoms.Name");
var getRouteOther = prop("RouteOther");

var getStrength = prop("Strength");
var getFrequency = prop("Frequency");
var getReason = prop("Reason");
var getStartDate = prop("StartDate");
var getChangeDate = prop("ChangeDate");

export default function Treatments(props, context) {
	var careplan = props.careplan;

	var localize = context.localize;
	var treatmentsTitle = localize("treatments.allergiesmedications");
	var prescription_s = localize("treatments.prescription");
	var allergiesandintolerances = localize("treatments.allergiesandintolerances");
	var medications = localize("treatments.medications");
	var lastmedicationreconcilliation = localize("treatments.lastmedicationreconcilliation");
	var performedby = localize("treatments.performedby");
	var mylastmedicationchangewas = localize("treatments.mylastmedicationchangewas");
	var itmademefeel = localize("treatments.itmademefeel");
	var aidsiusetotakemymedications = localize("treatments.aidsiusetotakemymedications");
	var challengesihavetakingmedications = localize("treatments.challengesihavetakingmedications");
	var notes = localize("treatments.notes");
	var noknownallergies = localize("treatments.noknownallergies");

	var allergies = careplan.Allergy || [];
	var renderAllergy = allergies.map(function(allergy, index){
		return (
			<Allergy key={index} allergy={allergy} index={index} />
		);
	}
	);

	if(careplan.NoKnownAllergies === "true" || !allergies.length) {
		renderAllergy = (
			<Item title={null} value={noknownallergies} />
		);
	}

	var durg = careplan.Prescription || [];
	var renderDurg = durg.map(function(prescription, index){
		return (
			<Prescription key={index} prescription={prescription} index={index} />
		);
	}
	);

	return (
		<Section title={treatmentsTitle}>

			<Title title={allergiesandintolerances} />
			{renderAllergy}

			<Group>
				<Title title={medications} />

				<Item title={lastmedicationreconcilliation} value={getMedicationReconciliationDate(careplan)} />

				<Item title={performedby} value={getMedicationReconciliationPerformedBy(careplan)} />

				<Item title={mylastmedicationchangewas} value={getMedicationChangeNote(careplan)} />

				<Item title={itmademefeel} value={getEffect(careplan)} />

				<Item title={aidsiusetotakemymedications} value={getMedicationAids(careplan)} />

				<Item title={challengesihavetakingmedications} value={getMedicationChallengesNote(careplan)} />

			</Group>

			<Title title={prescription_s} />
			{renderDurg}

			<Item title={notes} value={getNotes(careplan)} />
		</Section>
	);
}

class Allergy extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var allergy = props.allergy;

		var symptoms = getSymptoms(allergy) || "";
		if(symptoms.indexOf("Other") !== -1)
			symptoms = allergy.SymptomsOther;

		var localize = context.localize;
		var substance = localize("treatments.substance");
		var allergyorintolerance = localize("treatments.allergyorintolerance");
		var symptom_s = localize("treatments.symptoms");
		var severity = localize("treatments.severity");

		return (
			<Group>
				<Item title={substance} value={getSubstance(allergy)} />

				<Item title={allergyorintolerance} value={getAllergy(allergy)} />

				<Item title={symptom_s} value={symptoms} />

				<Item title={severity} value={getSeverity(allergy)} />
			</Group>
		);
	}
}

class Prescription extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var prescription = props.prescription;

		var localize = context.localize;
		var drugname = localize("treatments.drugname");
		var dose = localize("treatments.dose");
		var route = localize("treatments.route");
		var direction = localize("treatments.direction");
		var reason = localize("treatments.reason");
		var pharmacy = localize("treatments.pharmacy");
		var startdate = localize("treatments.startdate");
		var changedate = localize("treatments.changedate");
		var prescriber = localize("treatments.prescriber");

		return (
			<Group>

				<Item title={drugname} value={getDrugName(prescription)} />

				<Item title={dose} value={getStrength(prescription)} />

				<Item title={route} value={getRouteOther(prescription)} />

				<Item title={direction} value={getFrequency(prescription)} />

				<Item title={reason} value={getReason(prescription)} />

				<Item title={pharmacy} value={getPharmacyName(prescription)} />

				<Item title={startdate} value={getStartDate(prescription)} />

				<Item title={changedate} value={getChangeDate(prescription)} />

				<Item title={prescriber} value={getChangeDate(prescription)} />
			</Group>
		);
	}
}

Treatments.contextTypes = {
	config: PropTypes.object,
	localize: PropTypes.func
};

Allergy.contextTypes = {
	localize: PropTypes.func
};

Prescription.contextTypes = {
	localize: PropTypes.func
};

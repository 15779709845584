export default {
	title: "ChangePic",
	description: "schemas_changepicture_title",
	type: "object",
	properties: {
		image: {
			description: "profilepicturechoose.choose",
			type: "object",
			inputType: "file",
			format: "file"
		}
	}
};

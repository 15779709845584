/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 * @flow
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform, SafeAreaView, View } from "react-native";
import xtend from "xtend";
import par from "par";
import StyledText from "ae-base/Base/StyledText";
import Loader from "ae-base/Base/Loader";

import make_login from "ae-models/factories/login";
import Login from "./Components/Pages/Login";

import make_desktop from "ae-models/factories/desktop";
import Desktop from "./Components/Desktop";

import make_register from "ae-models/factories/register";
import Register from "./Components/Pages/Login/Register";

import make_mobiletokenlogin from "ae-models/factories/mobiletokenlogin";
import Mobiletokenlogin from "./Components/Pages/Login/Mobiletokenlogin";

import make_simplified from "ae-models/factories/mobilesimplified";
import Simplified from "./Components/Simplified";

import make_passwordreset from "ae-models/factories/passwordreset";
import Passwordreset from "./Components/Pages/Login/Passwordreset";

import make_atouchaway from "ae-models/factories/mobileatouchaway";
import Atouchaway from "./Components/aTouchAway/aTouchAway";

import make_powerbutton from "ae-models/factories/powerbutton";
import PowerButton from "./Components/aTouchAway/PowerButton";

import PowerButtonLegacy from "./Components/aTouchAway/Legacy/StandBy";
import RegionSelect from "./Components/Pages/RegionSelect";
import AppComponent from "./AppComponent";
import Localization from "./Localization";
import Config from "./configs/config.json";
import API from "ae-api";
import global from "global";
import * as Sentry from "@sentry/react-native";
import { scrubBreadcrumbs, scrubEvents, getSentryEnvironment } from './sentry';
import RootErrorBoundary from "./RootErrorBoundary";

Sentry.init({
	dsn: "https://18328b84c5794409b6b42dcf82a3e666@o951867.ingest.sentry.io/5983304",
	enableNative: Platform.OS !== 'web',
	tracesSampleRate: 1.0,
	beforeBreadcrumb: scrubBreadcrumbs,
	beforeSend: scrubEvents,
	environment: getSentryEnvironment(Config.type),
});

var LoginAppComponent = AppComponent(make_login, Login);
var DesktopComponent = AppComponent(make_desktop, Desktop);
var RegisterComponent = AppComponent(make_register, Register);
var MobiletokenloginComponent = AppComponent(make_mobiletokenlogin, Mobiletokenlogin);
var PasswordresetComponent = AppComponent(make_passwordreset, Passwordreset);
var AtouchawayComponent = AppComponent(make_atouchaway, Atouchaway);
var PowerButtonComponent = AppComponent(make_powerbutton, PowerButton);
var PowerButtonLegacyComponent = AppComponent(make_powerbutton, PowerButtonLegacy);
var SimplifiedComponent = AppComponent(make_simplified, Simplified);

const withSentry = app => Platform.OS === 'web' ? Sentry.withProfiler(app) : Sentry.wrap(app);
class mockingbird extends Component {

	constructor(props) {
		super(props);

		this._current = null;
		this.state = {
			currentView: "loading",
			config: null,
			simpleConfig: null
		};

		this.handleDesktopLoad = handleDesktopLoad.bind(this);
		this.handleSimplifiedLoad = handleSimplifiedLoad.bind(this);

		this.handleLoginUpdate = handleLoginUpdate.bind(this);
		this.handleLoginLoad = handleLoginLoad.bind(this);

		this.handleRegisterLoad = handleRegisterLoad.bind(this);
		this.handleRegisterUpdate = handleRegisterUpdate.bind(this);

		this.handleMobiletokenloginLoad = handleMobiletokenloginLoad.bind(this);
		this.handleMobiletokenloginUpdate = handleMobiletokenloginUpdate.bind(this);

		this.handlePasswordresetLoad = handlePasswordresetLoad.bind(this);

		this.handleAtouchawayLoad = handleAtouchawayLoad.bind(this);
		this.handleAtouchawayPowerButton = handleAtouchawayPowerButton.bind(this);
		this.handleAtouchawayPowerButtonLegacy = handleAtouchawayPowerButtonLegacy.bind(this);

		this.handleAuthenticated = handleAuthenticated.bind(this);
		this.handleUnauthenticated = handleUnauthenticated.bind(this);
		this.attemptLogin = attemptLogin.bind(this);

		this.handleNoRegion = handleNoRegion.bind(this);
		this.createRegion = createRegion.bind(this);
		this.checkRegion = checkRegion.bind(this);
		this.handleLogout = handle_logout.bind(this);
	}

	getChildContext() {
		return {
			id_prefix: this.props.id_prefix || ""
		};
	}

	componentDidMount() {
		global.$app = {};
		this.checkRegion();
	}

	render() {
		const { config } = this.state;

		var currentView = this.state.currentView;
		var simpleConfig = this.state.simpleConfig;

		var comp = null;
		if (currentView === "regionselect")
			comp = (
				<RegionSelect createRegion={this.createRegion} />
			);
		if (currentView === "login")
			comp = (
				<LoginAppComponent config={simpleConfig} onUpdated={this.handleLoginUpdate} onLoaded={this.handleLoginLoad} />
			);
		if (currentView === "desktop")
			comp = (
				<DesktopComponent config={this.state.authedConfig} onLoaded={this.handleDesktopLoad} />
			);
		if (currentView === "simplified")
			comp = (
				<SimplifiedComponent config={this.state.authedConfig} onLoaded={this.handleSimplifiedLoad} />
			);
		if (currentView === "register")
			comp = (
				<RegisterComponent config={simpleConfig} onLoaded={this.handleRegisterLoad} onUpdated={this.handleRegisterUpdate} />
			);
		if (currentView === "mobiletokenlogin")
			comp = (
				<MobiletokenloginComponent config={simpleConfig} onUpdated={this.handleMobiletokenloginUpdate} onLoaded={this.handleMobiletokenloginLoad} />
			);
		if (currentView === "passwordreset")
			comp = (
				<PasswordresetComponent config={simpleConfig} onLoaded={this.handlePasswordresetLoad} />
			);
		if (currentView === "atouchaway")
			comp = (
				<AtouchawayComponent config={this.state.authedConfig} onLoaded={this.handleAtouchawayLoad} />
			);
		if (currentView === "power")
			comp = (
				<PowerButtonComponent config={simpleConfig} onLoaded={this.handleAtouchawayPowerButton} />
			);
		if (currentView === "power_legacy")
			comp = (
				<PowerButtonLegacyComponent config={simpleConfig} onLoaded={this.handleAtouchawayPowerButtonLegacy} />
			);
		if (currentView === "loading")
			comp = (
				<Loader />
			);

		if (!comp) {
			comp = <StyledText bold>{"Stuck in invalid state: " + currentView}</StyledText>;
		}

		return (
			<AESafeArea>
				<RootErrorBoundary config={config}>
					{comp}
				</RootErrorBoundary>	
			</AESafeArea>
		);
	}
}

mockingbird.childContextTypes = { id_prefix: PropTypes.string };

export default withSentry(mockingbird);

const AESafeArea = (props) => {
	if (Platform.OS === "ios") {
		return <SafeAreaView style={{ backgroundColor: "black", flexGrow: 1 }}>
			{/* wrapper so that anything that uses absolute positioning would respect safeareaview's padding */}
			<View style={{ position: "relative", flexGrow: 1, backgroundColor: "white" }}>
				{props.children}
			</View>
		</SafeAreaView>;
	}
	else return props.children;
};

function handleLoginUpdate(model) {
	var currentView = this.state.currentView;
	var config = this.state.config;
	if(currentView === "login" && model.token)
		this.setState({
			currentView: getView(model),
			authedConfig: xtend(config, {
				token: model.token,
				id: model._id || model.id,
				fname: model.fname,
				lname: model.lname,
				organization: model.organization,
				orgGroup: model.orgGroup || model.group,
				user_type: model.type,
			})
		});
}

function handleDesktopLoad(app) {
	app.dispatcher.on("app:trigger:logout", handle_logout.bind(this));
	app.dispatcher.on("app:trigger:refresh", handle_refresh.bind(this));
}

function handleLoginLoad(app) {
	app.dispatcher.on("app:trigger:register", handle_register.bind(this));
	app.dispatcher.on("app:trigger:tokenlogin", handle_tokenlogin.bind(this));
	app.dispatcher.on("app:trigger:passwordreset", handle_passwordreset.bind(this));
	if(!Config.host)
		app.dispatcher.on("app:trigger:changeregion", handleNoRegion.bind(this));
}

function handleRegisterLoad(app) {
	app.dispatcher.on("app:trigger:login", handle_logout.bind(this));
}

function handleRegisterUpdate(model) {
	if(!model.token) return;
	var config = this.state.config;
	var authData = model.authData;

	this.setState({
		currentView: getView(authData),
		authedConfig: xtend(config, {
			token: model.token,
			id: authData._id,
			fname: authData.fname,
			lname: authData.lname,
			organization: authData.organization,
			orgGroup: authData.orgGroup || authData.group,
			user_type: authData.type
		})
	});
}

function handleMobiletokenloginUpdate(model) {
	if(!model.token) return;
	var config = this.state.config;
	AsyncStorage.setItem("token", model.token).then(function(){
		var authData = model.data;
		this.setState({
			currentView: getView(authData),
			authedConfig: xtend(config, {
				token: model.token,
				id: authData._id,
				fname: authData.fname,
				lname: authData.lname,
				organization: authData.organization,
				orgGroup: authData.orgGroup || authData.group,
				user_type: authData.type
			})
		});
	}.bind(this));
}

function handle_refresh(){
	this.setState({
		currentView: "loading",
		authedConfig: null,
		config: null
	}, checkRegion.bind(this));
}

function handleMobiletokenloginLoad(app) {
	app.dispatcher.on("app:trigger:login", handle_logout.bind(this));
}

function handlePasswordresetLoad(app) {
	app.dispatcher.on("app:trigger:login", handle_logout.bind(this));
}

function handleAtouchawayLoad(app) {
	app.dispatcher.on("app:trigger:logout", handle_logout.bind(this));
	app.dispatcher.on("app:trigger:power", handle_power_button.bind(this));
	app.dispatcher.on("app:trigger:power:legacy", handle_power_button_legacy.bind(this));
	app.dispatcher.on("app:trigger:refresh", handle_refresh.bind(this));
}

function handleSimplifiedLoad(app){
	app.dispatcher.on("app:trigger:logout", handle_logout.bind(this));
	app.dispatcher.on("app:trigger:refresh", handle_refresh.bind(this));
}

function handleAtouchawayPowerButton(app) {
	app.dispatcher.on("app:trigger:power", handle_power_button_on.bind(this));
	app.dispatcher.on("app:trigger:refresh", handle_refresh.bind(this));
}

function handleAtouchawayPowerButtonLegacy(app) {
	app.dispatcher.on("app:trigger:power", handle_power_button_on.bind(this));
	app.dispatcher.on("app:trigger:refresh", handle_refresh.bind(this));
}

function handle_power_button() {
	this.setState({
		currentView: "power"
	});
}

function handle_power_button_legacy() {
	this.setState({
		currentView: "power_legacy"
	});
}

function handle_power_button_on() {
	this.setState({
		currentView: "atouchaway"
	});
}

async function handle_logout() {
  const simpleConfig = this.state.simpleConfig;
	const token = this.state.authedConfig?.token;

	if (token && simpleConfig) {
		var api = new API(simpleConfig);
		api.auth.logout(token);
	}
  
	await AsyncStorage.multiSet([
		["token", ""],
		["appDisplay", ""]
	]);

	this.setState({
		currentView: "login"
	});
}

function handle_register() {
	this.setState({
		currentView: "register"
	});
}

function handle_tokenlogin() {
	this.setState({
		currentView: "mobiletokenlogin"
	});
}

function handle_passwordreset() {
	this.setState({
		currentView: "passwordreset"
	});
}

function isATouchAway(authData) {
	return (authData.type || []).indexOf("atouchaway") !== -1;
}

function isSimplified(authData){
	return (authData.type || []).indexOf("simplified") !== -1;
}

function getView(authData) {
	if(isATouchAway(authData)) return "atouchaway";
	else if(isSimplified(authData)) return "simplified";
	else return "desktop";
}

function handleAuthenticated(token, config, authData) {
	config = config || this.state.config;
	this.setState({
		currentView: getView(authData),
		authedConfig: xtend(config, {
			token: token,
			id: authData._id,
			fname: authData.fname,
			lname: authData.lname,
			organization: authData.organization,
			orgGroup: authData.orgGroup || authData.group,
			user_type: authData.type
		})
	});
}

function handleUnauthenticated(){
	this.setState({
		currentView: "login"
	});
}

function attemptLogin(simpleConfig, config, token) {
	var api = new API(simpleConfig);

	return api.auth
		.auth(token)
		.then(par(this.handleAuthenticated, token, config))
		.catch(this.handleUnauthenticated);
}

function handleNoRegion(){
	this.setState({
		currentView: "regionselect"
	});
}

function createRegion(region, token){
	if(!Config.host && !region) return this.handleNoRegion();

	var localizedConfig = xtend(Config, {
		localization: Localization
	});

	var hosts = Config.hosts || [];
	var config = hosts.reduce(function(acc, host){
		if(host.name === region){
			acc = xtend(acc, host);
		}
		return acc;
	}, localizedConfig);

	var simpleConfig = xtend(config, {
		simple: true
	});

	this.setState({
		config: config,
		simpleConfig: xtend(config, {
			simple: true
		})
	});

	return this.attemptLogin(simpleConfig, config, token);
}

function checkRegion(){
	var token = this.props.token;
	var region = this.props.region;

	var storeKeys = [];
	if(!token)
		storeKeys.push("token");
	if(!region)
		storeKeys.push("region");

	return AsyncStorage.multiGet(storeKeys, function(err, stores){
		var values = flatten(stores);
		var storeToken = values["token"] || token;
		var storeRegion = values["region"] || region;

		if(storeToken && !storeRegion)
			return this.createRegion("Canada", token);
		else if(!storeToken && !storeRegion)
			return this.createRegion();
		else
			return this.createRegion(storeRegion, storeToken);
	}.bind(this));
}

function flatten(array){
	return array.reduce(function(acc, ele){
		acc[ele[0]] = ele[1];
		return acc;
	}, { });
}
import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";

import Breakpoints from "../../../Breakpoints";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import StyledText from "ae-base/Base/StyledText";
import BackButton from "ae-base/Base/BackButton";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import VerticalButton from "ae-base/Base/VerticalButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";

import Style from "ae-style";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		mainContent: {
			backgroundColor: Style.colors.primary,
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			padding: Style.layout.paddingSmall
		},
		flexTwo: {
			flex: 2
		},
		buttonSpacing: {
			marginLeft: 8
		},
		text: {},
		iconStyles: {
			padding: Style.layout.paddingSmall
		},
		controls: {
			justifyContent: "flex-end",
			flexDirection: "row",
			flex: 1
		},
		backButtonPadding: {
			marginBottom: Style.layout.paddingSmall,
			paddingLeft: Style.layout.paddingMedium,
			paddingRight: Style.layout.paddingMedium,
		}
	},
	512: {
		mainContent: {
			flexDirection: "row",
		},
		text: {
			flex: 1
		},
		container: {
			backgroundColor: Style.colors.primary,
			paddingLeft: Style.layout.paddingMedium,
			paddingRight: Style.layout.paddingMedium,
			margin: 0,
		}
	}
});

export default function LiveChatHeader(props, context){
	var group = props.group;
	var buttons = props.buttons;

	var exitMsgTitle = props.backText;
	var isMobile = Breakpoints.smallerThan("mobile");
	var headingSize = Breakpoints.smallerThan("mobile") ? "emphasized" : "largeHeading";

	var name = group.name;

	var renderButtons = buttons ? buttons.reduce(function(acc, button){
		acc.push(<VerticalButton id={button.id} title={button.title} icon={button.icon} contrast onPress={button.onPress} />);
		acc.push(<ButtonSpacer />);
		return acc;
	}, []) : null;

	if(isMobile) return (
		<View style={styles.mainContent}>
			<StyledIconButton clear contrast smallHeading icon="left" style={styles.iconStyles} onPress={context.dispatch("desktop:trigger:page:back")} />
			<View style={styles.flexTwo}>
				<StyledText id={"livechat_name"} size={headingSize} inline bold contrast>{name}</StyledText>
			</View>
			<View style={styles.controls}>
				{renderButtons}
			</View>
		</View>
	);

	else return (
		<View style={styles.container}>
			<BackButton title={exitMsgTitle} />
			<View style={styles.mainContent}>
				<View style={styles.text}>
					<StyledText id={"livechat_name"} size={headingSize} bold inline contrast>{name}</StyledText>
				</View>
				{renderButtons}
			</View>
		</View>
	);
}

LiveChatHeader.contextTypes = {
	dispatch: PropTypes.func
};
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import PropTypes from "prop-types";

import ManagedSection from "../ManagedSection";
import ResourceItem from "../../Resources/ResourceItem";

import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

Resources.propTypes = {
	model: PropTypes.object
};

export default function Resources(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;

	var model = props.model;
	var manage = model.manage;
	var account = manage.account;
	var permissions = manage.permissions;
	var resourcesList = manage.resources.resources || [];
	var toast = model.toast;
	var currentLanguage = model.personal.language || "en";

	var resourcesTitle = localize("resources.title");

	function by_name(x, y) {
		x = x.name[currentLanguage] || x.name.en || x.name;
		y = y.name[currentLanguage] || y.name.en || y.name;
		let locale = currentLanguage;
		if (locale === "cn_s" || locale === "cn_t")
			locale = (locale === "cn_s") ? "zh-CN" : "zh-HK";
		return x.localeCompare(y, locale);
	}

	var renderResourcesList = resourcesList.sort(by_name).map(function(resource) {
		var onClick = dispatch("managedresources:trigger:viewresource", {
			id: resource._id
		});
		return (
			<ResourceItem key={resource._id} resource={resource} onClick={onClick} />
		);
	});

	var renderEmptyResources = !resourcesList.length ? <StyledText centered>{localize("resources.emptyManaged")}</StyledText> : null;

	var canEdit = permissions.resources ? permissions.resources >= 2 : false;

	var editButton = canEdit ? (
		<StyledButton title={localize("groupforms.edit")} icon={"compose"} onPress={dispatch("managedresources:trigger:edit:start")} clear contrast />
	) : null;

	return (
		<ManagedSection online={model.online} title={resourcesTitle} contact={account} toast={toast} edit={editButton}>
			{renderResourcesList}
			{renderEmptyResources}
		</ManagedSection>
	);
}

Resources.contextTypes = {
	localize: PropTypes.func,
	dispatch: PropTypes.func
};

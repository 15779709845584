import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
} from "react-native";
import prop from "prop";

import Items from "../Items";
import Item from "../Item";
import Label from "../Label";
import Value from "../Value";
import Summarized from "../Summarized";
import SectionTitle from "../SectionTitle";
import GroupItems from "../GroupItems";

var getHeightInch = prop("BaselineVitals.HeightInch");
var getWeightLb = prop("BaselineVitals.WeightLb");
var getHbA1cPercent = prop("BaselineVitals.HbA1cPercent");
var getHeightCm = prop("BaselineVitals.HeightCm");
var getWeightKg = prop("BaselineVitals.WeightKg");
var getHbA1cMmolPerMol = prop("BaselineVitals.HbA1cMmolPerMol");

var getType = prop("IssueType.Name");
var getDescription = prop("HealthCondition.Description");
var getClinical = prop("HealthCondition.Condition");
var getOnset = prop("HealthCondition.OnsetNote");
var getStability = prop("HealthCondition.Stability.Name");
var getNotes = prop("HealthCondition.Notes");

export default function Healthconditions(props, context) {
	var careplan = props.careplan;
	var currentSection = props.currentSection;
	var permissions = props.permissions;
	var changes = props.changes;
	var showCareplanDetail = props.showCareplanDetail;
	var ownCareplan = props.ownCareplan;

	var localize = context.localize;
	var issues = localize("healthconditions.issues");
	var baselinevitals = localize("healthconditions.baselinevitals");
	var height = localize("healthconditions.height");
	var weight = localize("healthconditions.weight");
	var hba1c = localize("healthconditions.hba1c");

	var healthIssues = careplan.HealthIssue || [];

	var renderHealthIssue = healthIssues.map(function(issue, index){
		var isChanged = changes["HealthIssue." + index] || index === -1;
		var hasChanged = permissions >= 2 && isChanged;
		return (
			<Issue key={index} defaultData={issue} index={index} currentSection={currentSection} hasChanged={hasChanged} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail} />
		);
	}
	);

	var hasChanged = permissions >= 2;
	var getHeightUnit = getHeightCm(careplan) ? "cm" : "in";
	var getWeightUnit = getWeightKg(careplan) ? "kg" : "lb";
	var getHbA1cUnit = getHbA1cPercent(careplan) ? "%" : "mmol/mol";

	return (
		<View>
			<SectionTitle name={issues} section="HealthIssue" permissions={permissions} changes={changes} />
			{renderHealthIssue}
			<SectionTitle name={baselinevitals} />

			<Items hasChanged={hasChanged && (changes["BaselineVitals.HeightCm"] || changes["BaselineVitals.HeightInch"])} data={"BaselineVitals.HeightCm,BaselineVitals.HeightInch"}>
				<Item>
					<Label>{height}</Label>
					<Value>{getHeightCm(careplan) || getHeightInch(careplan)} {getHeightUnit} </Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && (changes["BaselineVitals.WeightKg"] || changes["BaselineVitals.WeightLb"])} data={"BaselineVitals.WeightKg,BaselineVitals.WeightLb"}>
				<Item alternate>
					<Label>{weight}</Label>
					<Value>{getWeightKg(careplan) || getWeightLb(careplan)} {getWeightUnit}</Value>
				</Item>
			</Items>

			<Items hasChanged={hasChanged && (changes["BaselineVitals.HbA1cMmolPerMol"] || changes["BaselineVitals.HbA1cPercent"])} data={"BaselineVitals.HbA1cPercent,BaselineVitals.HbA1cMmolPerMol"}>
				<Item>
					<Label>{hba1c}</Label>
					<Value>{getHbA1cPercent(careplan) || getHbA1cMmolPerMol(careplan)} {getHbA1cUnit}</Value>
				</Item>
			</Items>
		</View>
	);

}

class Issue extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var defaultData = props.defaultData;
		var index = props.index;
		var currentSection = props.currentSection;
		var hasChanged = props.hasChanged;
		var showCareplanDetail = props.showCareplanDetail;
		var ownCareplan = props.ownCareplan;

		var localize = context.localize;
		var issuetype = localize("healthconditions.issuetype");
		var description = localize("healthconditions.description");
		var clinicaldescription = localize("healthconditions.clinicaldescription");
		var onset = localize("healthconditions.onset");
		var stability = localize("healthconditions.stability");
		var notes = localize("healthconditions.notes");

		return(
			<GroupItems>
				<Items hasChanged={hasChanged} data={"HealthIssue." + index}>
					<Item>
						<Label>{issuetype}</Label>
						<Value>{getType(defaultData)}</Value>
					</Item>

					<Item alternate>
						<Label>{description}</Label>
						<Value>{getDescription(defaultData)}</Value>
					</Item>

					<Summarized currentSection={currentSection} section={"HealthIssue." + index} ownCareplan={ownCareplan} showCareplanDetail={showCareplanDetail}>
						<Item>
							<Label>{clinicaldescription}</Label>
							<Value>{getClinical(defaultData)}</Value>
						</Item>

						<Item alternate>
							<Label>{onset}</Label>
							<Value>{getOnset(defaultData)}</Value>
						</Item>

						<Item>
							<Label>{stability}</Label>
							<Value>{getStability(defaultData)}</Value>
						</Item>

						<Item alternate>
							<Label>{notes}</Label>
							<Value>{getNotes(defaultData)}</Value>
						</Item>
					</Summarized>
				</Items>
			</GroupItems>
		);

	}
}

Healthconditions.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func
};

Issue.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View
} from "react-native";

import GroupMessagingHeader from "./GroupMessagingHeader";
import MessagingInput from "./MessagingInput";
import Messages from "./Messages";
import Message from "./Message";
import MessageScroll from "../../Layout/MessageScroll";
import Divider from "../../Layout/Divider";
import PageContainer from "../../Layout/PageContainer";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import BlockView from "ae-base/Base/BlockView";
import FileUploadDialog from "./FileUploadDialog";
import OfflineBanner from "../Homepage/OfflineBanner";
import UploadConfirm from "./UploadConfirm";

import Style from "@aetonix/styles";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		messages: {
			marginHorizontal: Style.layout.marginContent / 2
		},
		messagesPending: {
			justifyContent: "center"
		},
		divider: {
			marginHorizontal: Style.layout.marginLarge,
		},
		more: {
			marginVertical: Style.layout.marginContentVertical / 2,
			alignItems: "center",
			justifyContent: "center"
		},
		messageRead: {
			alignItems: "flex-end",
			marginHorizontal: Style.layout.marginContent / 2,
			padding: Style.layout.paddingSlim
		},
		messageReadAlternate: {
			alignItems: "flex-start",
		}
	},
	512: {
		messages: {
			marginHorizontal: Style.layout.marginContent
		},
		messageRead: {
			marginHorizontal: Style.layout.marginContent,
		},
		more: {
			marginVertical: Style.layout.marginContentVertical,
			alignItems: "center",
			justifyContent: "center"
		}
	}
});

export default function GroupMessaging(props, context) {
	var model = props.model;
	var localize = context.localize;
	var dispatch = context.dispatch;
	var saving = model.saving;
	var groupchat = model.groupchat;
	var uploading = groupchat.uploading;
	var group = groupchat.group || {};
	var file = groupchat.file;
	var image = groupchat.fileImage;
	var personal = model.personal;
	var toast = model.toast;
	var groupedMsgList = model.groupgroupedmessages || [];
	var loadmore = localize("timeline.loadmore");
	var getMore = dispatch("groupchat:trigger:next");
	var loadMoreVal = groupchat.loadMore;
	var pendingMessages = groupchat.pendingList;
	var name = group.name;
	var groupId = group._id;
	var lastMessage;
	var isAlternate;

	var screen = model.size;

	var renderedGroupedMessages = groupedMsgList
		.map(function(list){
			var from = list[0].from;
			isAlternate = from._id !== personal._id;
			lastMessage = list[list.length - 1];

			return (
				<Messages person={from} alternate={isAlternate} messages={list} screen={screen} fullName />
			);
		});

	var renderedPendingMessages = null;
	if(!saving)
		renderedPendingMessages = pendingMessages.map(function(pendingItem){
			return (
				<Message file={pendingItem.file} content={pendingItem.content} pending={true} />
			);
		});

	var renderMore = loadMoreVal ? (
		<View style={styles.more}>
			<StyledButton title={loadmore} icon="chevron-circle-up" onPress={getMore} />
		</View>
	) : null;

	var sendMessage = function(content){
		dispatch("groupchat:trigger:send", {
			content: content
		})();
	};

	var onUpload = function(uploadfile){
		dispatch("groupchat:trigger:upload:confirm", {
			file: uploadfile
		})();
	};

	var cancelUpload = dispatch("groupchat:trigger:upload:cancel");
	var startUpload = dispatch("groupchat:trigger:upload:start");

	var renderDialog = null;
	if(uploading)
		renderDialog = (
			<FileUploadDialog onUpload={onUpload} cancelUpload={cancelUpload} />
		);

	var renderReadMessage = null;
	if(lastMessage){
		var membersRead = lastMessage.read.filter(function(person){
			return typeof person !== "string";
		}).map(function(person){
			return person.lname + ", " + person.fname.slice(0, 1);
		});
		var readMessage = localize("groupchat.readmessage", {
			people: membersRead.join(", ")
		});
		if(membersRead.length !== 0)
			renderReadMessage = (<StyledText black small>{readMessage}</StyledText>);
	}

	var offline = model.online ? null : (
		<OfflineBanner />
	);

	var messageReadStyle = [styles.messageRead];
	var defaultMessage = model?.messages?.defaultMessage;

	if(isAlternate)
		messageReadStyle.push(styles.messageReadAlternate);
	var onConfirm = dispatch("groupchat:trigger:upload:finish");
	var onCancel = dispatch("groupchat:trigger:upload:cancel");

	return (
		<PageContainer toast={toast} saving={saving}>
			<GroupMessagingHeader person={personal} group={group} />
			{offline}
			<MessageScroll>
				{renderMore}
				<View style={styles.messages} >
					{renderedGroupedMessages}
				</View>
				<View style={styles.messagesPending} >
					{renderedPendingMessages}
				</View>
				<View style={messageReadStyle} >
					{renderReadMessage}
				</View>
			</MessageScroll>
			<BlockView style={styles.divider}>
				<Divider />
			</BlockView>
			<MessagingInput startUpload={startUpload} messages={groupchat} name={name} defaultMessage={defaultMessage} id={groupId} sendMessage={sendMessage} />
			{renderDialog}
			<UploadConfirm open={!!file} file={file} image={image} toast={toast} onConfirm={onConfirm} onCancel={onCancel} screen={screen} />
		</PageContainer>
	);
}

GroupMessaging.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func,
};

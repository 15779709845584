import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet,
	Image,
	Dimensions
} from "react-native";

import CardPage from "../../Layout/CardPage";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";
import TrialBG from "../../../Resources/img/TrialBG.jpg";

var windowSize = Dimensions.get("window");
var imgHeight = windowSize.width > windowSize.height ? windowSize.height : windowSize.width;

var styles = StyleSheet.create({
	image: {
		flex: 1,
		width: null,
		height: imgHeight / 1.5,
	},
	textKey: {
		flexDirection: "row",
		alignItems: "center"
	},
	highlightToken: {
		backgroundColor: "#EDF2F3",
		padding: Style.layout.paddingSmall,
	},
	controls: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginTop: Style.layout.marginMedium,
	},
	subtitle: {
		marginVertical: Style.layout.marginSmall,
		alignSelf: "center"
	}
});

export default class Trialwizard extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var trialwizard = props.trialwizard;
		var localize = context.localize;

		var accountkey = localize("trialwizard.accountkey");
		var trialtitle = localize("trialwizard.trialtitle");
		var trialcontent = localize("trialwizard.trialcontent");
		var thanksbutton = localize("trialwizard.thanksbutton");
		var tryitbutton = localize("trialwizard.tryitbutton");
		var setuptitle = localize("trialwizard.setuptitle");
		var setupstepsline1 = localize("trialwizard.setupstepsline1");
		var setupstepsline2 = localize("trialwizard.setupstepsline2");
		var setupstepsline3 = localize("trialwizard.setupstepsline3");
		var cancel = localize("common.cancel");

		if(!trialwizard.active)
			return null;
		var renderContent = null;
		var renderButtons = null;
		var renderToken = trialwizard.token ? (
			<View style={styles.textKey}>
				<StyledText bold primary emphasized>{accountkey}</StyledText>
				<StyledText bold primary emphasized style={styles.highlightToken}>{trialwizard.token}</StyledText>
			</View>
		) : null;

		var onHide = context.dispatch("trialwizard:trigger:hide");
		var onTry = context.dispatch("trialwizard:trigger:try");
		var onCancel = context.dispatch("trialwizard:trigger:cancel");

		if(trialwizard.step === "intro"){
			renderContent = (
				<BlockView>
					<TrialBody title={trialtitle} />
					<StyledText bold primary standard>{trialcontent}</StyledText>
				</BlockView>
			);
			renderButtons = (
				<View style={styles.controls}>
					<StyledButton id={context.id_prefix + "thanksButton"} title={thanksbutton} bold alert emphasized onPress={onHide} />
					<StyledButton id={context.id_prefix + "tryButton"} title={tryitbutton} bold contrast emphasized onPress={onTry} />
				</View>
			);
		}else if(trialwizard.step === "token"){
			renderContent = (
				<BlockView>
					<TrialBody title={setuptitle} />
					<StyledText bold primary standard>{setupstepsline1}</StyledText>
					<StyledText bold primary standard>{setupstepsline2}</StyledText>
					<StyledText bold primary standard>{setupstepsline3}</StyledText>
				</BlockView>
			);
			renderButtons = (
				<View style={styles.controls}>
					<StyledButton id={context.id_prefix + "cancelButton"} title={cancel} bold alert emphasized onPress={onCancel} />
				</View>
			);
		}

		return (
			<CardPage>
				<Image resizeMethod="resize" style={styles.image} resizeMode="contain" source={TrialBG} />
				{renderContent}
				{renderToken}
				{renderButtons}
			</CardPage>
		);

	}
}

function TrialBody(props){
	var title = props.title;
	return (
		<BlockView>
			<View style={styles.subtitle}>
				<StyledText bold primary smallHeading>{title}</StyledText>
			</View>
		</BlockView>
	);
}

Trialwizard.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

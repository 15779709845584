import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Banner from "./Banner";

import Style from "ae-style";

export default function UrgencyCancelBanner(props, context) {
	var color;
	var message_line1;
	var message_line2;
	var validate;

	var localize = context.localize;
	var dispatch = context.dispatch;

	var banner = props.banner;
	var time = banner.updated_at;
	var date = banner.formatted_date;
	var from = banner.from;
	var banner_type = banner.banner_type;

	var content = banner.content;
	var reason = content.reason;
	var from_id = content.from;

	from_id = from._id;

	if (reason === "fall") {
		color = Style.colors.orange;
	} else {
		color = Style.colors.grayblue;
	}
	message_line1 = localize("banner.urgency_cancelled", {
		lname: from.lname,
		fname: from.fname,
	});
	validate = dispatch("banner:trigger:clear", {
		from: from_id,
		type: banner_type
	});

	return (
		<Banner
			time={time}
			date={date}
			message_line1={message_line1}
			message_line2={message_line2}
			color={color}
			onPress={validate}
			border
		/>
	);
}

UrgencyCancelBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import AtaContainer from "./AtaContainer";
import Card from "../Layout/Card";
import MainScroll from "../Layout/MainScroll";

var styles = StyleSheet.create({
	centerText: {
		alignItems: "center"
	}
});

export default class Disconnected extends PureComponent {
	render(){
		var context = this.context;
		var localize = context.localize;
		var disconnected = localize("disconnected.disconnected");
		var youarecurrentlyoffline = localize("disconnected.youarecurrentlyoffline");
		var pleasewaitamoment = localize("disconnected.pleasewaitamoment");

		return (
			<AtaContainer center>
				<MainScroll>
					<Card style={styles.centerText}>
						<StyledText bold primary largeHeading>{disconnected}</StyledText>
						<StyledText bold primary smallHeading>{youarecurrentlyoffline}</StyledText>
						<StyledText bold primary smallHeading>{pleasewaitamoment}</StyledText>
					</Card>
				</MainScroll>
			</AtaContainer>
		);
	}
}

Disconnected.contextTypes = {
	localize: PropTypes.func
};

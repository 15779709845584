import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function UrgencyCancelled(props, context){
	var notification = props.notification;
	var localize = context.localize;
	var urgencycallcancelled = localize("banner.urgency_cancelled", notification.from);
	var screen = props.screen;

	return (
		<TimelineItem content={urgencycallcancelled} iconName="info"  notification={notification} screen={screen} />
	);

}

UrgencyCancelled.contextTypes = {
	localize: PropTypes.func,
};

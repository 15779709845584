import PropTypes from "prop-types";
import React from "react";

import StyledButton from "ae-base/Base/StyledButton";

export default function WorkflowContinueButton(props, context) {
	var localize = context.localize;
	var dispatch = context.dispatch;

	var action = props.action;

	var title = localize("workflow.continue");

	var onPress = dispatch("activeworkflowaction:trigger:view", {
		action: action._id
	});

	return <StyledButton centered fullheight stretch contrast title={title} onPress={onPress} {...props} />;
}

WorkflowContinueButton.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Reminder from "./Reminder";

import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";

export default function PendingReminder(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;

	var history = props.history;
	var reminder = history.reminder;
	var content = reminder.content;
	var contentElement = (
		<StyledText>{content}</StyledText>
	);

	var readableDate = history.readable;

	var clear = localize("reminder.clear");

	var clearReminder = dispatch("managedpendingreminders:trigger:clear", {
		reminder: history._id,
	});

	var clearButton = (
		<StyledButton title={clear} icon="check-square-o" onPress={clearReminder} />
	);

	return (
		<Reminder title={readableDate} action={clearButton} content={contentElement} />
	);
}

PendingReminder.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

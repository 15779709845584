import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, {
	PureComponent
} from "react";

import ProfileSection from "../ProfileSection";
import BannerSound from "./BannerSound";
import Language from "./Language";
import AutoReply from "./AutoReply";
import Ringtone from "./Ringtone";
import ScheduleOnline from "./ScheduleOnline";
import BlockView from "ae-base/Base/BlockView";
import MediaSettings from "./MediaSettings";
import BluetoothSettings from "./BluetoothSettings";
import Divider from "../../../Layout/Divider";

export default class ApplicationSettings extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var model = props.model;
		var localize = context.localize;
		var options = model.options;
		var language = model.preferences.language;
		var autoreply = model.autoreply;
		var ringtone = model.preferences.ringtone;
		var display = props.profilesection.displaying.application;

		var title = localize("applicationsettings.title");

		var renderBody = null;
		if(display)
			renderBody = (
				<BlockView>
					<Language language={language} />
					<ScheduleOnline />
					<AutoReply autoreply={autoreply} />
					<BannerSound options={options} />
					<Ringtone ringtone={ringtone} />
					<MediaSettings options={options} />
					<Divider />
					<BluetoothSettings />
				</BlockView>
			);

		return (
			<BlockView>
				<ProfileSection title={title} section="application" display={display} />
				{renderBody}
			</BlockView>
		);
	}
}

ApplicationSettings.contextTypes = {
	localize: PropTypes.func,
};

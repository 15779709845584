import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import Banner from "./Banner";

import Style from "ae-style";

export default function UrgencyAcceptedBanner(props, context) {
	var color;
	var message_line1;
	var message_line2;
	var validate;
	var children;

	var dispatch = context.dispatch;
	var localize = context.localize;
	var config = context.config;

	var banner = props.banner;

	var time = banner.updated_at;
	var date = banner.formatted_date;
	var from = banner.from;
	var banner_type = banner.banner_type;

	var content = banner.content;
	var reason = content.reason;
	var from_id = content.from;
	var to = content.to;

	var personal_id = config.id;

	if (reason === "fall") {
		color = Style.colors.orange;
	} else {
		color = Style.colors.grayblue;
	}
	message_line1 = localize("banner.urgency_from", {
		lname: from.lname,
		fname: from.fname
	});
	message_line2 = localize("banner.urgency_accepted", {
		lname: to.lname,
		fname: to.fname,
	});

	if (reason === "fall" && personal_id === to._id) {
		validate = dispatch("fallinvalidator:trigger:start", {
			from: from_id
		});

	} else {
		validate = dispatch("banner:trigger:clear", {
			from: from_id,
			type: banner_type
		});
	}

	return (
		<Banner
			time={time}
			date={date}
			message_line1={message_line1}
			message_line2={message_line2}
			color={color}
			onPress={validate}
			border
		>
			{children}
		</Banner>
	);
}

UrgencyAcceptedBanner.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	config: PropTypes.object
};

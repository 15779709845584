import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
	StyleSheet,
} from "react-native";

import ButtonSpacer from "ae-base/Base/ButtonSpacer";
import StyledText from "ae-base/Base/StyledText";
import BlockView from "ae-base/Base/BlockView";

import CallPage from "../CallPage";
import CallButton from "../CallButton";
import Avatar from "../../Contacts/Avatar";
import UrgencyBanner from "../UrgencyBanner";

import Style from "ae-style";
import Breakpoints from "../../../Breakpoints";
import StyledButton from "ae-base/Base/StyledButton";

var styles = StyleSheet.create({
	calling: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	message: {
		marginVertical: Style.layout.marginLarge,
	},
	controlButton: {
		flexDirection: "row",
		justifyContent: "center"
	},
	whiteText: {
		color: "white"
	},
	messageButton: {
		borderRadius: 10
	},
	messageTextButton: {
		padding: 10,
		borderRadius: 10,
		backgroundColor: "rgba(255, 255, 255, .20)"
	}
});

export default class Incoming extends PureComponent {
	render(){
		var props = this.props;
		var context = this.context;
		var dispatch = context.dispatch;
		var localize = context.localize;
		var calling = props.calling;
		var hangupreply = props.hangupreply;
		var banner = props.banner;
		var who = calling.who;
		var name = who.lname + ", " + who.fname;
		var voiceOnly = calling.voiceOnly;
		var message = voiceOnly ? localize("incoming.message1") : localize("incoming.message2");
		var sizeAvatar = "verylarge";

		if(Breakpoints.smallerThan("mobile")) {
			sizeAvatar = "small";
		}

		var replyTitle = localize("incoming.replyTitle");
		var cancelText = localize("incoming.cancelText");
		var replyButtonTitle = localize("incoming.replyButtonTitle");
		var replymessage1 = localize("incoming.replymessage1");
		var replymessage2 = localize("incoming.replymessage2");
		var showReplyMessage = dispatch("hangupreply:trigger:view");
		var sendReplyMessage1 = dispatch("hangupreply:trigger:send", {
			autoreply: replymessage1
		});
		var sendReplyMessage2 = dispatch("hangupreply:trigger:send", {
			autoreply: replymessage2
		});var cancelReplyMessage = dispatch("hangupreply:trigger:cancel");
		var randerButtonOrMessage = hangupreply.view ? (
			<View>
				<StyledText centered tertiary >
					{replyTitle}
				</StyledText>
				<ButtonSpacer />
				<StyledButton id={"message1"} style={styles.messageTextButton} title={replymessage1} fullwidth centered clear textStyle={styles.whiteText} onPress={sendReplyMessage1} />
				<ButtonSpacer />
				<StyledButton id={"message2"} style={styles.messageTextButton} title={replymessage2} fullwidth centered clear textStyle={styles.whiteText} onPress={sendReplyMessage2} />
				<ButtonSpacer />
				<StyledButton id={"cancelMessage"} style={styles.messageButton} title={cancelText} fullwidth centered alert onPress={cancelReplyMessage} />
			</View>
		) : (
			<View>
				<View style={styles.controlButton} >
					<CallButton button={"accept"} />
					<ButtonSpacer />
					<CallButton button={"deny"} />
				</View>
				<ButtonSpacer />
				<StyledButton id={"replyButton"} style={styles.messageButton} title={replyButtonTitle} icon={"chat"} fullwidth centered contrast onPress={showReplyMessage} />
			</View>
		);

		return (
			<CallPage>
				<UrgencyBanner banner={banner} who={who} />
				<View style={styles.calling}>
					<Avatar person={who} size={sizeAvatar} />
					<BlockView style={styles.message}>
						<BlockView>
							<StyledText centered contrast largeHeading>{message}</StyledText>
						</BlockView>
						<BlockView>
							<StyledText centered contrast largeHeading>{name}</StyledText>
						</BlockView>
					</BlockView>
					{randerButtonOrMessage}
				</View>
			</CallPage>
		);
	}
}

Incoming.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

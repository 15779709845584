import PropTypes from "prop-types";

/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import {
	View,
	StyleSheet,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import Breakpoints from "../../../Breakpoints";

import SquareButton from "ae-base/Base/SquareButton";
import Heading from "ae-base/Base/Heading";
import NotificationIcon from "ae-base/Base/NotificationIcon";
import Style from "ae-style";

var MAX_BUTTONS = 6;

var styles = StyleSheet.create({
	buttons: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignSelf: "center"
	},
	notification: {
		position: "absolute",
		top: 0,
		right: 0
	}
});

var responsiveStyles = makeIncrements(MAX_BUTTONS);

export default function ButtonGrid(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;

	var title = props.title;
	var buttons = props.buttons || [];

	var permissions = props.permissions || {};
	var contact = props.contact || {};
	var newsections = props.newsections || [];
	var allSections = props.allSections || null;
	const group = props.group;

	var ownCareplan = props.ownCareplan;

	var who = contact._id;
	var userType = contact.type || [];

	var screen = props.screen;

	if(!who) return (
		null
	);
	var validButtons = buttons.filter(function (button) {
		var permissionName = button.permission;
		var permission = permissions[permissionName];
		if(!permission) return false;
		if ( allSections && !allSections[permissionName]) return false;
		var trigger = button.trigger;
		if(!trigger) return false;
		var matchType = button.type;
		if(matchType && userType.indexOf(matchType) === -1) return false;

		return true;
	});

	if(!validButtons.length) return null;

	var renderedTitle = title  ? (
		<Heading title={title} screen={screen} />
	) : null;

	var renderedButtons = validButtons.map(function(button){
		var permissionName = button.permission;
		var trigger = button.trigger;
		var section = button.section;

		var hasNotification = newsections[permissionName] ? (
			<NotificationIcon style={styles.notification} title="!" />
		) : null;

		var triggerPayload = {
			who,
			group
		};

		if(ownCareplan){
			triggerPayload.section = section;
		}else{
			if(permissionName.indexOf("careplan_") === 0)
				triggerPayload.section = permissionName.slice(9);
		}

		var buttonTitle = localize(button.title) || permissionName;
		var icon = button.icon;
		var action = dispatch(trigger, triggerPayload);

		// Default icon size is specified here.

		var textSize = 12;
		var iconSize = Style.imageSize.tiny;

		if(Breakpoints.smallerThan("small")) {
			iconSize = Style.imageSize.icon;
		}

		var titleLength = buttonTitle.length;
		var titleLengthThreshold = 25; 
		
		if (titleLength > titleLengthThreshold) {
			textSize = 9
		}

		return (
			<SquareButton
				key={permissionName}
				title={buttonTitle}
				textSize={textSize}
				icon={icon}
				iconSize={iconSize}
				onPress={action}>
				{hasNotification}
			</SquareButton>
		);
	});

	return (
		<View style={responsiveStyles.container}>
			{renderedTitle}
			<View style={styles.buttons}>
				{renderedButtons}
			</View>
		</View>
	);
}

ButtonGrid.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

function makeIncrements(buttons) {
	var i = buttons;
	var increment = Style.layout.squareButton + Style.layout.marginSmall;
	var definition = {};

	while(i){
		var size = increment * i;

		definition[size] = {
			container: {
				width: size
			}
		};

		i--;
	}

	return ResponsiveStylesheet.createSized("min-width", definition);
}

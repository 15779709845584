import React from "react";

import CardPage from "../../../Layout/CardPage";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import Form from "ae-forms";
import ReminderMakerSchema from "../../../../schemas/ReminderMaker";
import PropTypes from "prop-types";

var DEFAULT_DATA = {
	time: {
		hours: "0",
		minutes: "0",
	},
	days: {
		all: true
	},
	months: new Date().toJSON(),
	responseType: "confirm",
	repeat: 0,
	notification: 0,
	month: false
};

export default function Remindermaker(props, context) {
	var model = props.model;
	var dispatch = context.dispatch;

	var toast = model.toast;
	var myreminders = model.myreminders;
	var reminder_maker = myreminders.reminder_maker || {};
	var editing = reminder_maker.editing;
	var creating = reminder_maker.creating;
	var reminders = myreminders.ownreminders;
	var removing = reminders && reminders.removing;

	var screen = model.size;

	var defaultData = DEFAULT_DATA;
	var action = null;

	var deleteButton = null;
	var deleteReminder, confirmDelete, cancelDelete;

	var localize = context.localize;
	var deletetext = localize("remindermaker.delete");
	var title = localize("remindermaker.title");
	var confirmtext = localize("remindermaker.confirmtext");
	var canceltext = localize("remindermaker.canceltext");
	var remindersetup = localize("remindermaker.remindersetup");
	var discardchanges = localize("remindermaker.discardchanges");
	var remove = localize("remindermaker.remove");

	if (editing){
		defaultData = editing;
		defaultData.monthSwitch = !!editing.monthSwitch;
		if(!editing.months) defaultData.months = DEFAULT_DATA.months;
		action = dispatch("ownremindermaker:trigger:edit:finish");
		deleteReminder = dispatch("ownreminders:trigger:remove:start", {
			reminder: editing._id
		});
		deleteButton = (
			<StyledButton title={deletetext} icon="delete" alert onPress={deleteReminder} />
		);
	} else if (creating){
		action = dispatch("ownremindermaker:trigger:add:finish");
	}

	var shouldRemove = removing && editing && (removing === editing._id);

	if(shouldRemove){
		confirmDelete = dispatch("ownreminders:trigger:remove:confirm", {
			reminder: removing
		});
		cancelDelete = dispatch("ownreminders:trigger:remove:cancel");
	}

	var goBack = dispatch("desktop:trigger:page:back");

	var confirmation = (
		<ConfirmationModal
			open={shouldRemove}
			title={title}
			titleIcon="warning"
			confirmText={confirmtext}
			cancelText={canceltext}
			onConfirm={confirmDelete}
			onCancel={cancelDelete}
		>
			<StyledText contrast>{remove}</StyledText>
		</ConfirmationModal>
	);

	var formKey = editing && (editing._id + editing.updated_at);
	var loadedKey = Object.keys(defaultData || {}).length;

	return (
		<CardPage title={remindersetup} icon="notifications" edit={deleteButton} external={confirmation} toast={toast} screen={screen}>
			<Form key={formKey + loadedKey} schema={ReminderMakerSchema} defaultData={defaultData} onSubmit={action} onCancel={goBack} cancelText={discardchanges} />
		</CardPage>
	);
}

Remindermaker.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import TimelineItem from "./TimelineItem";

export default function MessageNotification(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;
	var notification = props.notification;
	var screen = props.screen;
	var group = notification.group || {};
	var type = notification.type;
	var attachment = notification.attachment;
	var goToNotification;
	if(type.indexOf("groupmessage") !== -1){
		goToNotification = dispatch("groupchat:trigger:view", {
			group: group
		});
	} else if (type.indexOf("livechat:message") !== -1){
		goToNotification = dispatch("activelivechatgroup:trigger:view", {
			group: group._id
		});
	} else {
		var fromID = notification.from._id;
		goToNotification = dispatch("messages:trigger:open", {
			from: fromID
		});
	}

	var contentToDisplay = notification.content;
	if(attachment)
		contentToDisplay = localize("messaging.sentAttachment");

	if (type.indexOf("systemmessage") !== -1){
		var metadata = notification.metadata;
		var systemContent = "";
		if(metadata.type === "GroupPersonAdd")
			systemContent = localize("groupchat.hasbeenadded", metadata.data);
		else
			systemContent = localize("groupchat.hasleft", metadata.data);
		contentToDisplay = systemContent;
	}
	else if(type.indexOf("groupmessage") !== -1){
		if(attachment) contentToDisplay = localize("groupchat.sentAttachment", notification.from);
		else contentToDisplay = notification.from.lname + ", " + notification.from.fname + ": " + notification.content;
	}
	var content = contentToDisplay || "";

	return (
		<TimelineItem content={content} iconName="aeto-messaging" onPress={goToNotification} clearable notification={notification} screen={screen} />
	);
}

MessageNotification.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

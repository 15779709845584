import PropTypes from "prop-types";
import React from "react";
import prop from "prop";

import Item from "../Item";
import Section from "../Section";

var getGivenNames = prop("GivenNames");
var getPreferredName = prop("PreferredName");
var getSurname = prop("Surname");
var getDateOfBirth = prop("DateOfBirth");
var getEmail = prop("Email");
var getDependants = prop("Dependants");
var getReligion = prop("Religion");
var getGender = prop("Gender.Name");
var getHealthLink = prop("HealthLink.Name");
var getChrisClientGuid = prop("ChrisClientGuid");
var getChrisClientNumber = prop("ChrisClientNumber");
var getAddress = prop("Address.AddressFreeLine");
var getCity = prop("Address.City");
var getProvince = prop("Address.Province.Name");
var getPostal = prop("Address.PostalCode");
var getPrimaryPhone = prop("PrimaryPhone.Number");
var getAlternatePhone = prop("AlternatePhone.Number");
var getPreferredContactBy = prop("PreferredContactBy.Name");
var getHealthCard = prop("HealthCard.Hcn");
var getHealthCardIssuedBy = prop("IssuedBy");
var getHcnVersion = prop("HealthCard.HcnVersion");
var getHealthCardReason = prop("HealthCard.Reason.Name");
var getFirstLanguage = prop("FirstLanguage.Name");
var getPreferredLanguage = prop("OfficialLanguage");
var getEthnicity = prop("Ethnicity.Name");
var getMaritalStatus = prop("MaritalStatus.Name");
var getResidenceType = prop("ResidenceType.Name");
var getLivingArrangement = prop("LivingArrangement.Name");
var getPrimaryContactFirstName = prop("PrimaryContact.FirstName");
var getPrimaryContactSurname = prop("PrimaryContact.Surname");
var getPrimaryContactRelationship = prop("PrimaryContact.Relationship");
var getPrimaryContactPhone = prop("PrimaryContact.Phone.Number");
var getEmergencyContactFirstName = prop("EmergencyContact.FirstName");
var getEmergencyContactSurname = prop("EmergencyContact.Surname");
var getEmergencyContactRelationship = prop("EmergencyContact.Relationship");
var getEmergencyContactPhone = prop("EmergencyContact.Phone.Number");
var getSubstituteDecisionMakerFirstName = prop("SubstituteDecisionMaker.FirstName");
var getSubstituteDecisionMakerSurname = prop("SubstituteDecisionMaker.Surname");
var getSubstituteDecisionMakerRelationship = prop("SubstituteDecisionMaker.Relationship");
var getSubstituteDecisionMakerPhone = prop("SubstituteDecisionMaker.Phone.Number");

export default function Client(props, context) {
	var careplan = props.careplan;

	var client = careplan || [];

	var localize = context.localize;
	var patientidentifiers = localize("client.patientidentifiers");
	var givenname = localize("client.givenname");
	var preferredname = localize("client.preferredname");
	var surname = localize("client.surname");
	var gender = localize("client.gender");
	var dateofbirth = localize("client.dateofbirth");
	var healthlink = localize("client.healthlink");
	var chrisclientguid = localize("client.chrisclientguid");
	var chrisclientnumber = localize("client.chrisclientnumber");
	var address = localize("client.address");
	var city = localize("client.city");
	var province = localize("client.province");
	var postalcode = localize("client.postalcode");
	var telephone = localize("client.telephone");
	var alternatetelephone = localize("client.alternatetelephone");
	var emailaddress = localize("client.emailaddress");
	var preferredcontactby = localize("client.preferredcontactby");
	var healthcardversion = localize("client.healthcardversion");
	var healthcardnumber = localize("client.healthcardnumber");
	var healthcardissuedby = localize("client.healthcardissuedby");
	var healthcardreason = localize("client.healthcardreason");
	var mothertougue = localize("client.mothertougue");
	var preferredlanguage = localize("client.preferredlanguage");
	var ethnicity = localize("client.ethnicity");
	var religion = localize("client.religion");
	var maritalstatus = localize("client.maritalstatus");
	var whereicurrentlylive = localize("client.whereicurrentlylive");
	var peoplewholivewithme = localize("client.peoplewholivewithme");
	var peoplewhodependonme = localize("client.peoplewhodependonme");
	var primarycontact = localize("client.primarycontact");
	var emergencycontact = localize("client.emergencycontact");
	var substitutedecisionmaker = localize("client.substitutedecisionmaker");

	var primaryContactName = (getPrimaryContactFirstName(client) || "") + " " + (getPrimaryContactSurname(client) || "");
	var emergencyContactName = (getEmergencyContactFirstName(client) || "") + " " + (getEmergencyContactSurname(client) || "");
	var substituteDecisionName = (getSubstituteDecisionMakerFirstName(client) || "") + " " + (getSubstituteDecisionMakerSurname(client) || "");

	return (
		<Section title={patientidentifiers}>
			<Item title={givenname} value={getGivenNames(client)} />

			<Item title={preferredname} value={getPreferredName(client)} />

			<Item title={surname} value={getSurname(client)} />

			<Item title={gender} value={getGender(client)} />

			<Item title={dateofbirth} value={getDateOfBirth(client)} />

			<Item title={healthlink} value={getHealthLink(client)} />

			<Item title={chrisclientguid} value={getChrisClientGuid(client)} />

			<Item title={chrisclientnumber} value={getChrisClientNumber(client)} />

			<Item title={address} value={getAddress(client)} />

			<Item title={city} value={getCity(client)} />

			<Item title={province} value={getProvince(client)} />

			<Item title={postalcode} value={getPostal(client)} />

			<Item title={telephone} value={getPrimaryPhone(client)} />

			<Item title={alternatetelephone} value={getAlternatePhone(client)} />

			<Item title={emailaddress} value={getEmail(client)} />

			<Item title={preferredcontactby} value={getPreferredContactBy(client)} />

			<Item title={healthcardversion} value={getHcnVersion(client)} />

			<Item title={healthcardnumber} value={getHealthCard(client)} />

			<Item title={healthcardreason} value={getHealthCardReason(client)} />

			<Item title={healthcardissuedby} value={getHealthCardIssuedBy(client)} />

			<Item title={mothertougue} value={getFirstLanguage(client)} />

			<Item title={preferredlanguage} value={getPreferredLanguage(client)} />

			<Item title={ethnicity} value={getEthnicity(client)} />

			<Item title={religion} value={getReligion(client)} />

			<Item title={maritalstatus} value={getMaritalStatus(client)} />

			<Item title={whereicurrentlylive} value={getResidenceType(client)} />

			<Item title={peoplewholivewithme} value={getLivingArrangement(client)} />

			<Item title={peoplewhodependonme} value={getDependants(client)} />

			<Item title={primarycontact} values={[
				primaryContactName,
				getPrimaryContactRelationship(client),
				getPrimaryContactPhone(client)
			]} />

			<Item title={emergencycontact} values={[
				emergencyContactName,
				getEmergencyContactRelationship(client),
				getEmergencyContactPhone(client)
			]} />

			<Item title={substitutedecisionmaker} values={[
				substituteDecisionName,
				getSubstituteDecisionMakerRelationship(client),
				getSubstituteDecisionMakerPhone(client),
			]} />
		</Section>
	);

}

Client.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {
	Component
} from "react";
import {
	StyleSheet,
	View,
	Dimensions,
} from "react-native";
import prop from "prop";
var getVideoInputs = prop("input.sources.video.length");

import SquareButton from "../../SquareButton";
import VideoView from "../../../Calling/VideoView";
import StyledText from "ae-base/Base/StyledText";
import Person from "../Person";
import ConferenceInviter from "../../ConferenceInviter";
import ATouchAwayInfoBar from "../../ATouchAwayInfoBar";

import Style from "ae-style";

var styles = StyleSheet.create({
	container: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: Style.colors.black,
		paddingBottom: Style.layout.paddingMedium,
		flexWrap: "nowrap",
	},
	contentContainer: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	},
	streamsContainer: {
		flexGrow: 1,
		flexShrink: 1,
		flexDirection: "row-reverse",
		justifyContent: "center",
		alignItems: "center",
	},
	rightContainer: {
		flexGrow: 0,
		flexShrink: 1,
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		minWidth: Dimensions.get("window").width / 7,
	},
	remoteContainer: {
		flex: 1,
		height: "100%",
		justifyContent: "flex-start",
	},
	remoteTitleContainer: {
		paddingBottom: Style.layout.paddingSmall,
	},
	streams: {
		flex: 1,
		padding: Style.layout.paddingSmall,
		flexDirection: "row-reverse",
		justifyContent: "space-around",
		alignItems: "center",
	},
	localStreamContainer: {
		minWidth: Dimensions.get("window").width / 7,
		minHeight: Dimensions.get("window").width / 8,
		paddingTop: 20,
		alignSelf: "center",
		transform: [{"rotateY": "180deg"}],
	},
	message: {
		backgroundColor: Style.colors.white,
		padding: Style.layout.paddingMedium,
		borderRadius: Style.layout.atouchaway.border,
		alignSelf: "center",
	},
	callActionButton: {
		alignSelf: "center",
		padding: Style.layout.paddingSmall,
	},
	buttonRow: {
		flexDirection: "row",
	},
	mainSection: {
		flexDirection: "row",
		justifyContent: "center",
	},
	remotePlaceholderContainer: {
		alignSelf: "center",
		padding: Style.layout.paddingSmall,
	}
});

export default class Connected extends Component {
	componentDidMount(){
		var dispatch = this.context.dispatch;
		setTimeout(dispatch("buzz:trigger:video:recalculate"), 16);
		setTimeout(dispatch("buzz:trigger:video:show"), 64);
	}
	render(){
		var props = this.props;
		var context = this.context;
		var dispatch = context.dispatch;
		var localize = context.localize;

		var screen = props.screen;

		var calling = props.calling;
		var streams = calling.streams;
		var remoteURL = streams.remote;
		var conferenceURL = streams.conference;
		var localURL = streams.local;
		var voiceOnly = streams.voiceOnly;
		var who = calling.who;
		var conference_state = calling.conference_state;
		var isConference = conference_state !== "closed";
		var settings = props.settings;
		var switchCamera = settings.switch_camera;
		var callgroup = props.callgroup;

		var availability = props.availability;
		var callinputswitcher = props.callinputswitcher;
		var conferenceinviter = props.conferenceinviter || {};
		var conferenceInviterList = conferenceinviter.list || [];

		var hasVideo = getVideoInputs(callinputswitcher) > 1;
		var canSwitchVideo = hasVideo && (!voiceOnly) && switchCamera;

		var screenWidth = Dimensions.get("window").width;
		var screenHeight = Dimensions.get("window").height;
		var remoteIconSize = ((isConference && screenWidth < 750) || screenWidth < 600) ? 160 : null;
		var numButtons = 3 + !isConference + canSwitchVideo;
		var callControlButtonSize =
			(screenHeight / numButtons > 150) ? {small: true} :
				(screenHeight / numButtons > 115) ? {tiny: true} : {microscopic: true};

		var changecamera = localize("atouchawaycalling.changecamera");
		var changeAudio = localize("atouchawaycalling.toggleAudio");
		var invite = localize("atouchawaycalling.invite");

		var onInvite = dispatch("conferenceinviter:trigger:open");
		var onChangeCamera = dispatch("callinputswitcher:trigger:toggle");
		var onChangeAudio = dispatch("audiooutputselector:trigger:toggle");

		var renderedStreams = [(
			<Remote
				who={who}
				callgroup={callgroup}
				voiceOnly={voiceOnly}
				stream={remoteURL}
				size={remoteIconSize}
				smallMode={callControlButtonSize.tiny || callControlButtonSize.microscopic}
			/>
		)];

		var callControlButtons = [];
		!isConference && callControlButtons.push(
			<View style={styles.callActionButton}>
				<SquareButton
					title={invite}
					icon={"users"}
					onPress={onInvite}
					contrast
					secondary
					{...callControlButtonSize}
				/>
			</View>
		);
		callControlButtons.push(
			<View style={styles.callActionButton}>
				<SquareButton
					id={"localAudio"}
					title={changeAudio}
					icon={"volume-up"}
					onPress={onChangeAudio}
					contrast
					secondary
					{...callControlButtonSize}
				/>
			</View>
		);
		canSwitchVideo && callControlButtons.push(
			<View style={styles.callActionButton}>
				<SquareButton
					title={changecamera}
					icon={"aeto-camera_flip"}
					onPress={onChangeCamera}
					contrast
					secondary
					{...callControlButtonSize}
				/>
			</View>
		);

		if(isConference) {
			var conferencePerson = calling.conference || {};
			var isConferenceConnecting = conference_state !== "connected";

			var shouldHide = (conferenceURL && conferenceURL.phonertc);

			if(!shouldHide)
				renderedStreams.push((
					<Remote
						who={conferencePerson}
						stream={conferenceURL}
						connecting={isConferenceConnecting}
						state={conference_state}
						voiceOnly={voiceOnly}
						size={remoteIconSize}
						smallMode={callControlButtonSize.tiny || callControlButtonSize.microscopic}s
					/>
				));
		}

		var localVideoStream = voiceOnly || !localURL ? null : (
			<View style={styles.localStreamContainer} id="ae_local_video">
				<VideoView stream={localURL} muted />
			</View>
		);

		return (
			<View style={styles.container} screen={screen}>
				<ATouchAwayInfoBar incall model={props.model} />
				<View style={styles.contentContainer}>
					<View style={styles.streamsContainer}>
						{renderedStreams}
					</View>
					<View style={styles.rightContainer}>
						{localVideoStream}
						{callControlButtons}
					</View>
				</View>
				{conferenceinviter.open && (<ConferenceInviter list={conferenceInviterList} calling={calling} availability={availability} />)}
			</View>
		);
	}
}
function Message(props) {
	var message = props.message;

	return (
		<View style={styles.message}>
			<StyledText black bold>
				{message}
			</StyledText>
		</View>
	);
}

function Remote(props, context) {
	var localize = context.localize;

	var stream = props.stream;
	var who = props.who;
	var voiceOnly = props.voiceOnly;
	var connecting = props.connecting;
	var state = props.state;
	var callgroup = props.callgroup;
	var size = props.size;
	var smallMode = props.smallMode;

	var message = localize("atouchawaycalling.connectedwith", who);

	if (smallMode)
		message = who.lname + ", " + who.fname;

	if(callgroup) {
		message = localize("callgroups.connectedwith", callgroup);
		who = callgroup;
	}

	if(voiceOnly || connecting) {
		if(state === "calling")
			message = localize("atouchawaycalling.calling", who);
		if(state === "connecting")
			message = localize("atouchawaycalling.connectingto");

		return (
			<View style={styles.remotePlaceholderContainer}>
				<Person who={who} message={message} stream={stream} size={size} />
			</View>
		);
	} else {
		return (
			<View style={styles.remoteContainer}>
				<VideoView stream={stream} id="ae_remote_video" />
				<View style={styles.remoteTitleContainer}>
					<Message message={message} />
				</View>
			</View>
		);
	}
}

Connected.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

Remote.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";

import ChangeEmailSchema from "../../../../schemas/ChangeEmail";
import Form from "ae-forms";
import Divider from "../../../Layout/Divider";
import BlockView from "ae-base/Base/BlockView";

export default function ChangeEmail(props, context){
	var dispatch = context.dispatch;

	var preferences = props.preferences;

	var loadedKey = Object.keys(preferences || {}).length;

	return (
		<BlockView>
			<Form id_prefix={context.id_prefix + "changeEmailForm"} key={loadedKey} schema={ChangeEmailSchema} defaultData={preferences} onSubmit={dispatch("preferences:trigger:preferences:update:email")} />
			<Divider />
		</BlockView>
	);
}

ChangeEmail.contextTypes = {
	id_prefix: PropTypes.string,
	dispatch: PropTypes.func,
};

import React from "react";
import PropTypes from "prop-types";
import MyHealthComponent from "./MyHealthComponent";
import { useMeasureUnits } from "@aetonix/hooks";

export default function BloodSugarLevel (props, context){
	var model = props.model;
	var bloodsugar = model.bloodsugar;

	var sugar = bloodsugar.cumulative;
	var reverse_sugar = bloodsugar.reverse; // needed for chart

	var localize = context.localize;

	const { glucometerUnits: glucoseUnits } = useMeasureUnits();
	var oldUnits = glucoseUnits === "mg" ? "meanblood" : "glucose";

	var units = localize("bloodsugar." + glucoseUnits);
	var today = null;
	var section = model.page;
	var textformat = "{" + glucoseUnits + "}";
	var readFromDevice = props.readFromDevice;

	// Sets up the format to the 'today' button
	if (sugar.length) {
		var graphdata = adjust_time(reverse_sugar, null, glucoseUnits, oldUnits).slice(-10); // for chart
		today = (sugar[0].history[glucoseUnits] || sugar[0].history[oldUnits]);
	}

	return (
		<MyHealthComponent model={model} readFromDevice={readFromDevice} section={section} textformat={textformat} latestReading={today} reverseEntries={graphdata} units={units} activeModel={bloodsugar} xaxis={"x"} yaxis={"y"} />
	);
}

function adjust_time(data, forBoxGraphic, glucoseUnits, oldUnits) {
	var adjusted = data.map(function (item) {
		var time = time_label(item.updated_at, false);
		var value = (typeof item.history[glucoseUnits] === "string")
			? parseFloat(item.history[glucoseUnits])
			: item.history[glucoseUnits];
		if(!value) {
			value = (typeof item.history[oldUnits] === "string")
				? parseFloat(item.history[oldUnits])
				: item.history[oldUnits];
		}
		return {
			x: time,
			y: value
		};
	});
	return adjusted;
}

function time_label(data, forBoxGraphic) {
	var parsed = time_parse(data, forBoxGraphic);

	return (parsed.month + "-" + parsed.day + "  " + parsed.time);
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}

BloodSugarLevel.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

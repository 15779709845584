
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import PropTypes from "prop-types";
import {
	StyleSheet,
	View,
} from "react-native";

import HealthHeader from "../HealthHeader";
import MainScroll from "../../../Layout/MainScroll";
import PageContainer from "../../../Layout/PageContainer";
import MainContent from "../../../Layout/MainContent";
import StandardReminder from "./StandardReminder";
import HistoryReminder from "./HistoryReminder";
import ListAdd from "ae-base/List/ListAdd";
import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import StyledButton from "ae-base/Base/StyledButton";

MyReminders.propTypes = {
	model: PropTypes.object
};

var styles = StyleSheet.create({
	more: {
		marginVertical: Style.layout.marginContentVertical,
		alignItems: "center",
		justifyContent: "center"
	},
	view: {
		margin: Style.layout.marginBordered,
	}
});

export default function MyReminders(props, context) {
	var dispatch = context.dispatch;
	var localize = context.localize;
	var model = props.model;
	var myreminders = model.myreminders;
	var reminderList = myreminders.ownreminders.list || [];
	var reminderHistoryList = myreminders.ownreminderhistory || [];
	var renderedReminders = reminderList.map(function(reminder) {
		return (
			<StandardReminder key={reminder._id} reminder={reminder} />
		);
	});

	var renderedRemindersHistory = reminderHistoryList.map(function (history) {
		if(!(history.response || history.response === 0)) return null;
		return (
			<HistoryReminder key={history._id} history={history} />
		);
	});
	var renderedRemindersTitle = reminderList.length === 0 ? null : <StyledText bold smallHeading>{localize("managedreminders.manage")}</StyledText>;
	var renderedRemindersHistoryTitle = reminderHistoryList.length === 0 ? null : <StyledText bold smallHeading>{localize("managedreminders.history")}</StyledText>;

	var shouldShowMore = reminderHistoryList.length >= 16;
	var loadMoreHistory = dispatch("ownreminderhistory:trigger:more");

	var renderMore = shouldShowMore ? (
		<View style={styles.more}>
			<StyledButton title={localize("managedreminders.loadmore")} icon="dropdown" onPress={loadMoreHistory} />
		</View>
	) : null;

	var addReminder = dispatch("ownremindermaker:trigger:add:start");
	return (
		<PageContainer saving={model.saving} toast={model.toast}>
			<MainScroll>
				<HealthHeader title={localize("managedreminders.reminders")} />
				<MainContent>
					<View style={styles.view}>
						{renderedRemindersTitle}
						{renderedReminders}
						<ListAdd title={localize("managedreminders.setupanewreminder")} onPress={addReminder} />
						{renderedRemindersHistoryTitle}
						{renderedRemindersHistory}
						{renderMore}
					</View>
				</MainContent>
			</MainScroll>
		</PageContainer>
	);
}

MyReminders.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React from "react";
import {
	Image,
	StyleSheet,
	Dimensions
} from "react-native";

import ConfirmationModal from "ae-base/Base/ConfirmationModal";
import StyledText from "ae-base/Base/StyledText";

import Style from "ae-style";

var styles = StyleSheet.create({
	image: {
		margin: Style.layout.marginSmall,
	}
});

export default function UploadConfirm(props, context){
	var localize = context.localize;
	var open = props.open;
	var file = props.file;
	var image = props.image;
	var onConfirm = props.onConfirm;
	var onCancel = props.onCancel;
	var toast = props.toast;
	var name = file ? file.name : "";
	var dimensions = Dimensions.get("window");

	var screen = props.screen;

	var height = dimensions.height;
	var width = dimensions.width;

	var areYouSure = localize("messaging.uploadsure", {
		filename: name
	});

	var imageStyle = [styles.image];

	var confirmText = localize("messaging.yesupload");
	var cancelText = localize("messaging.noupload");

	var title = localize("messaging.fileuploading");

	var renderImage;
	if(image && (width < height)){
		var imageSource = image.image;
		imageStyle.push({
			maxWidth: width / 3,
			maxHeight: height / 3,
			width: image.width,
			height: image.height
		});
		renderImage = (
			<Image style={imageStyle} resizeMode="contain" source={imageSource} key={imageSource.uri} />
		);
	}

	return (
		<ConfirmationModal
			open={open}
			title={title}
			titleIcon="warning"
			confirmText={confirmText}
			cancelText={cancelText}
			onConfirm={onConfirm}
			onCancel={onCancel}
			toast={toast}
			screen={screen}>
			{renderImage}
			<StyledText contrast>{areYouSure}</StyledText>
		</ConfirmationModal>
	);
}

UploadConfirm.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

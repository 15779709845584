import PropTypes from "prop-types";
import React from "react";
import {
	View,
	Image,
	StyleSheet,
	Dimensions,
	Platform
} from "react-native";

import Card from "../Layout/Card";
import Form from "ae-forms";
import AtaContainer from "./AtaContainer";
import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import PasswordSchema from "../../schemas/ATouchAwayAboutPassword";
import LogoImg from "../../Resources/img/Aetonix_logo.png";

import Style from "ae-style";

import MainScroll from "../Layout/MainScroll";

var styles = StyleSheet.create({
	margined: {
		marginTop: Style.layout.marginMedium
	},
	buttonView: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: Style.layout.marginBordered
	},
	logoImg: {
		width: 180,
		height: 120,
		resizeMode: "contain"
	},
	aboutfooter: {
		flexDirection: "row",
		justifyContent: "space-between"
	},
	portrait: {
		flexDirection: "column"
	}
});


export default function About(props, context){
	var config = context.config;
	var localize = context.localize;
	var dispatch = context.dispatch;
	var about = props.aboutbox;
	var showPassword = about.showPassword;
	var closeAction = dispatch("about:trigger:close");
	var versionNumber = config.version;
	var buildNumber = config.build;
	var buildType = config.type;
	var versionText = localize("versionnumber.version") + " " + versionNumber + " - #" + buildNumber + " " + buildType;

	var title = localize("atouchawayabout.title");
	var subTitle = localize("atouchawayabout.subtitle");
	var line1 = localize("atouchawayabout.line1");
	var line2 = localize("atouchawayabout.line2");
	var closeText = localize("atouchawayabout.closetext");
	var confirmText = localize("atouchawayabout.unlock");
	var settingButton = localize("atouchawayabout.setting");
	var closeButton = localize("atouchawayabout.closebutton");
	var passwordErrorText = localize("atouchawayabout.passworderror");

	var width = Dimensions.get("window").width;
	var height = Dimensions.get("window").height;

	var licenseContainerStyle = [styles.aboutfooter];
	var buttonContainer = [styles.buttonView];
	if(width < height){
		licenseContainerStyle.push(styles.portrait);
		buttonContainer.push(styles.portrait);
	}


	const submitStyle = {
		justifyContent: Platform.OS !== "web" ? 'center' : "flex-end",
		maxWidth: width < 400 ? '50%' : undefined,
		marginLeft: width < 400 ? 80 : undefined,
	};

	const textStyle = {
		fontSize: (Platform.OS !== "web" && width < 400 ) ? 10 : 20,
		flexWrap: 'wrap',
	};

	var renderSetting = showPassword ? (
		<Form schema={PasswordSchema}
			submitText={confirmText}
			onSubmit={dispatch("about:trigger:unlock")}
			onCancel={closeAction}
			submitStyle={submitStyle}
			textStyle={textStyle}
			cancelText={closeButton}
			isATouchAway
		/>
	) : (
		<View style={buttonContainer}>
			<StyledButton title={settingButton} onPress={dispatch("about:trigger:settings")} />
			<StyledButton alert title={closeButton} onPress={closeAction} />
		</View>
	);

	var renderPasswordError = about.passwordError ? (
		<StyledText alert>{passwordErrorText}</StyledText>
	) : (
		null
	);

	return (
		<AtaContainer>
			<MainScroll>
				<Card>
					<Image resizeMethod="resize" style={styles.logoImg} source={LogoImg} />
					<View style={licenseContainerStyle}>
						<StyledText black emphasized>{title}</StyledText>
						<StyledButton title={localize("register.licenseagreement")} onPress={dispatch("license:trigger:show")} icon="notebook" />
					</View>
					<StyledText style={styles.margined} bold>{subTitle}</StyledText>
					<StyledText style={styles.margined}>{line1}</StyledText>
					<StyledText style={styles.margined}>{line2}</StyledText>
					{renderSetting}
					{renderPasswordError}
					<View style={licenseContainerStyle}>
						<StyledText style={styles.margined} small bold>{closeText}</StyledText>
						<StyledText style={styles.margined} small bold>{versionText}</StyledText>
					</View>
				</Card>
			</MainScroll>
		</AtaContainer>
	);
}

About.contextTypes = {
	config: PropTypes.object,
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

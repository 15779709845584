import PropTypes from "prop-types";
import React from "react";

import Heading from "ae-base/Base/Heading";
import StyledButton from "ae-base/Base/StyledButton";

import { View } from "react-native";

const styles = {
	sectionContent: {
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	}
}

export default function GroupHeading(props, context){
	var dispatch = context.dispatch;
	var localize = context.localize;
	var contrast = props.contrast;
	var count = props.count;
	var section = props.section;
	var displaying = props.displaying;
	var screen = props.screen;

	var headerYou = localize("groupchat.headerYou", {
		count: count
	});
	var nogroups = localize("groupchat.nogroups");
	var minimize = localize("groupchat.minimize");
	var	maximize = localize("groupchat.maximize");

	var headerMessage = headerYou;
	var icon = displaying ? "directup" : "dropdown";
	var title = displaying ? minimize : maximize;
	var toggleDisplaying = dispatch("homepage:trigger:toggle:" + section);

	if(section === "nogroups") {
		headerMessage = nogroups;
		icon = null;
		title = null;
		toggleDisplaying = null;
	}

	return (
			<View style={styles.sectionContent}>
					<StyledButton
						id={context.id_prefix + section}
						icon={count ? icon : undefined}
						clear
						title={headerMessage}
						smallHeading
						contrast={contrast}
						onPress={count ? toggleDisplaying : undefined}  
					/>
			</View>
	);
}

GroupHeading.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
};

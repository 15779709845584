import PropTypes from "prop-types";
import React from "react";
import {
	StyleSheet,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import Style from "ae-style";
import NoticePageBase from "./NoticePageBase";

var styles = StyleSheet.create({
	noticeTitle: {
		margin: Style.layout.marginBordered
	},
});

export default function NoticePage(props, context) {
	var localize = context.localize;

	var disconnectedTitl = localize("disconnected.disconnected");
	var disconnectedMessage = localize("disconnected.pleasewaitamonment");

	return (
		<NoticePageBase>
			<StyledText style={styles.noticeTitle} bold black largeHeading>{disconnectedTitl}</StyledText>
			<StyledText style={styles.noticeTitle} bold black emphasized>{disconnectedMessage}</StyledText>
		</NoticePageBase>
	);
}

NoticePage.contextTypes = {
	localize: PropTypes.func
};

import PropTypes from "prop-types";
import React, {
	PureComponent,
} from "react";
import {
	StyleSheet,
	View
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import StyledButton from "ae-base/Base/StyledButton";
import BlockView from "ae-base/Base/BlockView";

import Style from "ae-style";

var styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		padding: Style.layout.paddingSmall * 2,
		justifyContent: "center",
		alignItems: "center"
	}
});

export default class SimplifiedFooter extends PureComponent {
	render() {
		var props = this.props;
		var context = this.context;
		var model = props.model;
		var personal = model.personal;
		var dispatch = context.dispatch;

		var testModeEnabled = !!personal.testModeEnabled;
		var localize = context.localize;

		var contents = [];
		if (testModeEnabled) {
			contents.push((
				<BlockView key={"testmode"}>
					<StyledText bold smallHeading color={Style.colors.orange}>
						<StyledIcon color={Style.colors.orange} name={"fa-bug"} />
						{" "}
						{localize("testmode.title")}
					</StyledText>
				</BlockView>
			));
		}

		return (
			<View style={styles.page}>
				{contents}
			</View>
		);
	}
}

SimplifiedFooter.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};

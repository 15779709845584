import PropTypes from "prop-types";
import React, {
	PureComponent
} from "react";
import {
	View,
} from "react-native";

import StyledText from "ae-base/Base/StyledText";
import StyledButton from "ae-base/Base/StyledButton";
import ButtonSpacer from "ae-base/Base/ButtonSpacer";

import ListItem from "ae-base/List/ListItem";
import ListIcon from "ae-base/List/ListIcon";
import ListContent from "ae-base/List/ListContent";
import Style from "ae-style";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../../utils/date-utils";

const styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		content: {
			flex: 1,
			flexDirection: "column",
			maxWidth: Style.layout.maxContent / 3,
		},
		icon: {
			marginVertical: Style.layout.marginSlim,
			marginHorizontal: Style.layout.marginSlim

		}
	},
	512: {
		content: {
			maxWidth: Style.layout.maxContent
		}
	}
});

export default class Note extends PureComponent {
	render() {
		const props = this.props;
		const context = this.context;
		const dispatch = context.dispatch;
		const submission = props.submission;
		const id = submission._id;
		const note_title = submission.data.title;
		const formatDate = context.formatDate;
		const updated_at = submission.updated_at;

		const format_date = formatDate(updated_at, DATE_TIME_FORMAT);

		let title;
		const editorName = submission.editorData.lname + ', ' + submission.editorData.fname;
		const localize = context.localize;

		const renderSubmissionTitle = note_title ? (
			<StyledText bold>{note_title}</StyledText>
		) : null;
		const edittitle = localize("note.edit");
		const viewtitle = localize("note.view");
		if (editorName) {
			title = localize("groupforms.edit_by") + editorName;
		}

		const edit = dispatch("managedforms:trigger:update:start", {
			submission: id
		});

		const view = dispatch("managedforms:trigger:view", {
			submission: id,
		});

		return(
			<ListItem >
				<ListContent>
					<View style={styles.icon}>
						<ListIcon name="notebook" />
					</View>
					<View		style={styles.content}>
						{renderSubmissionTitle}
						<StyledText>{format_date}</StyledText>
						<StyledText bold>{title}</StyledText>
					</View>
				</ListContent>
				<StyledButton title={edittitle} icon="compose" onPress={edit} />
				<ButtonSpacer />
				<StyledButton title={viewtitle} contrast onPress={view} />
			</ListItem>
		);
	}
}

Note.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	formatDate: PropTypes.func,
};

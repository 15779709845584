import PropTypes from "prop-types";
import React from "react";
import {
	View
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Style from "ae-style";

import PageContainer from "../../Layout/PageContainer";
import MainScroll from "../../Layout/MainScroll";
import BackButton from "ae-base/Base/BackButton";

import ChangeViewer from "./ChangeViewer";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		back: {
			marginLeft: Style.layout.marginBordered,
			marginTop: Style.layout.marginBordered
		}
	},
	512: {
		back: {
			marginLeft: Style.layout.marginContent,
			marginTop: Style.layout.marginContentVertical
		}
	}
});

export default function Proposal(props, context){
	var changeViewers = props.careplan.changeViewer || [];
	var careplan = props.careplan;
	var permissions = props.permissions;
	var screen = props.screen;

	var renderChanges = changeViewers.map(function(changeViewer, index){
		var renderControls = (index === 0);
		return (
			<ChangeViewer key={index} changeViewer={changeViewer} careplan={careplan} renderControls={renderControls} permissions={permissions} screen={screen} />
		);
	});

	var localize = context.localize;
	var gobacktomanagedusersprofile = localize("proposal.gobacktomanagedusersprofile");

	return (
		<PageContainer contrast>
			<MainScroll>
				<View style={styles.back}>
					<BackButton title={gobacktomanagedusersprofile} onPress={context.dispatch("managedcareplanchangeviewer:trigger:hide")} />
				</View>
				{renderChanges}
			</MainScroll>
		</PageContainer>
	);
}

Proposal.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
